import { FaInstagram, FaLinkedin, FaFacebookSquare } from "react-icons/fa";
import { useMediaQuery, BreakPoints } from "utils/animations/animationHelper";
import { useSidebarCollapsed } from "utils/ui/uiHelpers";

const Footer: React.FC = () => {
  const buildVersion = process.env.REACT_APP_BUILD_VERSION;
  const nodeEnv = process.env.NODE_ENV;

  const isSidebarCollapsed = useSidebarCollapsed();
  const isDesktop = useMediaQuery(BreakPoints.LG);

  return (
    <footer
      id="footer"
      className={`${
        isDesktop ? (isSidebarCollapsed ? "pl-24" : "pl-64") : "pl-0"
      } w-full dark:text-gray-200 mt-12`}
    >
      <div className="mx-auto">
        <div className="">
          <div className="mx-8">
            <div className="md:flex justify-between items-center text-xs">
              <div className="text-center text-gray-500 dark:text-gray-200 sm:text-left py-3 md:py-4 mx-4 w-full sm:w-2/3">
                <a
                  href="https://facebook.com/aboutGOLF"
                  className="text-gray-400 dark:text-gray-200 hover:text-gray-500 mr-1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="sr-only">Facebook</span>
                  <FaFacebookSquare className="size-5 inline" />
                </a>
                <a
                  href="https://www.instagram.com/aboutgolf_/"
                  className="text-gray-400 dark:text-gray-200 hover:text-gray-500 mr-1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="sr-only">Instagram</span>
                  <FaInstagram className="size-5 inline" />
                </a>
                <div className="block sm:inline"></div>
                <a
                  href="https://www.linkedin.com/company/aboutgolf-limited/"
                  className="text-gray-400 dark:text-gray-200 hover:text-gray-500 mr-4"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="sr-only">LinkedIn</span>
                  <FaLinkedin className="size-5 inline" />
                </a>
                <a
                  href="https://aboutgolf.com"
                  target="_blank"
                  rel="noreferrer"
                  className="no-underline  mr-4"
                >
                  aG Home
                </a>
                <a
                  href="https://www.aboutgolf.com/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                  className="no-underline mr-4"
                >
                  Privacy
                </a>
                <a
                  href="https://aboutgolf.my.site.com/Knowledgebase/s/"
                  target="_blank"
                  rel="noreferrer"
                  className="no-underline mr-4"
                >
                  Knowledgebase
                </a>
                <a
                  href="https://www.aboutgolf.com/support"
                  target="_blank"
                  rel="noreferrer"
                  className="no-underline mr-4"
                >
                  Support
                </a>
                <a
                  href="https://aboutgolf.atlassian.net/servicedesk/customer/portal/3"
                  target="_blank"
                  rel="noreferrer"
                  className="no-underline mr-4"
                >
                  Feedback
                </a>
              </div>
              <div className="md:flex md:flex-row-reverse text-gray-500 dark:text-gray-200 sm:text-right py-4 w-1/3 mx-auto ">
                <div className="text-center text-xs">
                  &copy; 2023 aboutGOLF. All rights reserved.
                  {nodeEnv !== "production" ? (
                    <div>Build version: {buildVersion}</div>
                  ) : (
                    <div style={{ display: "none" }}>
                      Build version: {buildVersion}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
