import ServiceRequestContainer from "components/control/dashboard/ServiceRequestContainer";
import { AnimatePresence, motion } from "framer-motion";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { useServiceRequestsByFacilityId } from "modules/service-requests/serviceRequestHooks";
import { MotionVariantType } from "../../types/enums";
import {
  containerMotionVariants,
  containerChildMotionVariants,
} from "utils/animations/animationHelper";

const Alerts: React.FC = () => {
  const currentFacilityId = useGetCurrentLocalFacilityId();

  const [currentFacilityServiceReqests] = useServiceRequestsByFacilityId(
    currentFacilityId
  );

  return (
    <section
      className="flex flex-col w-full items-center justify-center overflow-hidden pt-4"
      id="serviceRequestContainer"
      data-testid="serviceRequestContainer"
    >
      <AnimatePresence>
        <motion.div
          variants={containerMotionVariants}
          initial={MotionVariantType.HIDDEN}
          animate={MotionVariantType.SHOW}
          className="w-full"
        >
          {currentFacilityServiceReqests.map((request) => (
            <motion.div
              key={request.id}
              className="w-full inline-block"
              variants={containerChildMotionVariants}
            >
              <ServiceRequestContainer
                serviceRequest={request}
                key={request.id}
              />
            </motion.div>
          ))}
        </motion.div>
      </AnimatePresence>
    </section>
  );
};
export default Alerts;
