import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setSessionStorageValue } from "utils/hooks/localStorage";

export type LeagueState = {
  selectedLeagueId: string;
  selectedLeagueRoundId?: string;
};

const initialState: LeagueState = {
  selectedLeagueId: "",
  selectedLeagueRoundId: "",
};

const slice = createSlice({
  name: "league",
  initialState,
  reducers: {
    setSelectedLeagueId(
      state,
      action: PayloadAction<{ selectedLeagueId: string }>
    ): void {
      state.selectedLeagueId = action.payload.selectedLeagueId;
      setSessionStorageValue(
        "selectedLeagueId",
        action.payload.selectedLeagueId
      );
    },
    setSelectedRoundId(
      state,
      action: PayloadAction<{ selectedLeagueRoundId: string }>
    ): void {
      state.selectedLeagueRoundId = action.payload.selectedLeagueRoundId;
      setSessionStorageValue(
        "selectedRoundId",
        action.payload.selectedLeagueRoundId
      );
    },
  },
});

const { actions, reducer: leagueReducer } = slice;

const { setSelectedLeagueId, setSelectedRoundId } = actions;

export { leagueReducer, setSelectedLeagueId, setSelectedRoundId };
