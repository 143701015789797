import { Simulator } from "Models";

type MissingSimsListProps = {
  missingSimulators: Simulator[];
};

const MissingSimsList: React.FC<MissingSimsListProps> = ({
  missingSimulators,
}) => {
  return (
    <div className="mt-3 p-2 text-gray-600 text-center rounded-md border-2 bg-gray-100 border-gray-300">
      <strong className="text-red-500">Warning</strong> - the following
      simulators do not have the selected course:
      <ul>
        {missingSimulators.map((sim, i) => (
          <li key={sim.id} className="inline">
            <span className="font-semibold text-gray-900">{sim.name}</span>
            {missingSimulators.length !== i + 1 ? ", " : ""}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MissingSimsList;
