import "assets/css/spinnerAnimation.css";

type OwnProps = {
  class?: string;
};

const ServiceRequestIcon: React.FC<OwnProps> = (props) => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      className={`${props.class} fill-current`}
      fill-current={props.class}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99588 0C11.6038 0 14.5497 2.9482 14.6736 6.68962L14.6773 6.92119V10.702L15.9067 13.6145C15.9683 13.7602 16 13.9175 16 14.0766C16 14.7136 15.5015 15.2301 14.8864 15.2301L10.6685 15.2315C10.6685 16.7605 9.47195 18 7.99588 18C6.57255 18 5.40913 16.8474 5.32784 15.3941L5.3229 15.2294L1.11387 15.2301C0.961226 15.2301 0.810215 15.1976 0.670208 15.1346C0.106114 14.8808 -0.152547 14.2014 0.0924751 13.617L1.31443 10.7028V6.9211C1.31496 3.08695 4.30067 0 7.99588 0ZM9.33181 15.2294L6.65958 15.2315C6.65958 15.996 7.25789 16.6158 7.99588 16.6158C8.69048 16.6158 9.26135 16.0668 9.32602 15.3648L9.33181 15.2294ZM7.99588 1.38424C5.03808 1.38424 2.65115 3.85211 2.65072 6.92119V10.9905L1.45342 13.8459H14.5459L13.341 10.9914L13.3411 6.93306L13.3378 6.72524C13.2389 3.7413 10.8837 1.38424 7.99588 1.38424Z"
        className={`${props.class} fill-current`}
        fill-current={props.class}
      />
    </svg>
  );
};
export default ServiceRequestIcon;

ServiceRequestIcon.defaultProps = {
  class: "text-zinc-800 dark:text-white",
};
