import { FormRow } from "components/ui/form";
import { DefaultRoundSettings } from "Models";
import { Controller, useFormContext } from "react-hook-form";
import { GimmeDistance, HandicapMode, ScoringType } from "types/enums";
import {
  roundConfigEnumsAsReactSelectOptions,
  titleCase,
  trueFalseOptions,
} from "utils/enums/enumHelper";
import { ErrorMessage } from "@hookform/error-message";
import StablefordOptions from "./StablefordOptions";
import TeamManagement from "./TeamManagement";
import Label from "components/ui/form/Label";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import SingleSimCourseAndHoles from "./single-sim/SingleSimCourseAndHoles";
import FacilityCourseAndHoles from "./facility/FacilityCourseAndHoles";
import { customStyles } from "utils/ui/uiHelpers";

const RoundSpecificForm: React.FC<RoundSpecificFormProps> = (props) => {
  const { control, watch, formState, getValues } = useFormContext();
  const { defaultRoundSettings } = props;

  const watchAll = watch();

  const doesScoringTypeIncludeField = (
    scoringType: string,
    fieldName: string
  ): boolean => {
    const scoringTypesAndFields: { [key: string]: Array<string> } = {
      STROKE: ["handicapMode"],
      MATCH: ["teams", "scotchFoursome", "handicapMode"],
      SCRAMBLE: ["mulligans", "teams"],
      STABLEFORD: ["handicapMode", "stableford"],
    };

    return scoringTypesAndFields[scoringType]?.includes(fieldName);
  };

  return (
    <div>
      <FormRow header="How many minutes at the range before the round?">
        <Controller
          name="rangeTime"
          control={control}
          defaultValue={getValues("rangeTime") || 0}
          render={({ field }) => (
            <input
              {...field}
              className="border-2 border-gray-300 rounded-lg dark:text-black w-full px-4 py-2"
              type="number"
              min={0}
              step={1}
            />
          )}
        />
      </FormRow>
      <div className="pt-4">
        {props.simulatorId ? (
          <SingleSimCourseAndHoles simulatorId={props.simulatorId} />
        ) : (
          <FacilityCourseAndHoles />
        )}
      </div>
      <FormRow>
        <Label
          name="Auto Concede"
          tooltipText="The number of shots before you automatically move on to the next hole"
        />
        <Controller
          name="roundInfo.autoConcede"
          control={control}
          defaultValue={
            getValues("roundInfo.autoConcede") ||
            defaultRoundSettings?.autoConcede ||
            roundConfigEnumsAsReactSelectOptions.autoConcede[0].value
          }
          render={({ field }) => (
            <Select
              {...field}
              closeMenuOnSelect={true}
              isMulti={false}
              options={roundConfigEnumsAsReactSelectOptions.autoConcede}
              value={roundConfigEnumsAsReactSelectOptions.autoConcede.find(
                (c) => c.value === field.value
              )}
              onChange={(val: { label: string; value: string }) =>
                field.onChange(val.value)
              }
              styles={customStyles}
            />
          )}
        />
      </FormRow>
      <FormRow>
        <Label
          name="Auto Continue"
          tooltipText="The wait time between finishing the current hole and moving on to the next."
        />
        <Controller
          name="autoContinue"
          control={control}
          defaultValue={
            getValues("autoContinue") ||
            defaultRoundSettings?.autoContinue ||
            roundConfigEnumsAsReactSelectOptions.autoContinue[0].value
          }
          render={({ field }) => (
            <Select
              {...field}
              closeMenuOnSelect={true}
              isMulti={false}
              options={roundConfigEnumsAsReactSelectOptions.autoContinue}
              value={roundConfigEnumsAsReactSelectOptions.autoContinue.find(
                (c) => c.value === field.value
              )}
              onChange={(val: { label: string; value: string }) =>
                field.onChange(val.value)
              }
              styles={customStyles}
            />
          )}
        />
      </FormRow>
      <FormRow header="Auto Drop">
        <Controller
          name="roundInfo.autoDrop"
          control={control}
          defaultValue={
            getValues("roundInfo.autoDrop") ||
            defaultRoundSettings?.autoDrop ||
            roundConfigEnumsAsReactSelectOptions?.autoDrop[1].value
          }
          render={({ field }) => (
            <Select
              {...field}
              closeMenuOnSelect={true}
              isMulti={false}
              options={roundConfigEnumsAsReactSelectOptions.autoDrop}
              value={roundConfigEnumsAsReactSelectOptions.autoDrop.find(
                (c) => c.value === field.value
              )}
              onChange={(val: { label: string; value: string }) =>
                field.onChange(val.value)
              }
              styles={customStyles}
              className="disabled:cursor-not-allowed disabled:bg-gray-100"
              id="roundAutoDrop"
            />
          )}
        />
      </FormRow>
      <FormRow>
        <Label
          name="Gimme Distance"
          tooltipText="The distance from the pin at which an auto gimme is received"
        />
        <Controller
          name="roundInfo.gimmeDistance"
          control={control}
          defaultValue={
            getValues("roundInfo.gimmeDistance")
              ? getValues("roundInfo.gimmeDistance")
              : defaultRoundSettings?.gimmeDistance ??
                roundConfigEnumsAsReactSelectOptions.gimmeDistance[0].value
          }
          render={({ field }) => (
            <Select
              {...field}
              closeMenuOnSelect={true}
              isMulti={false}
              options={roundConfigEnumsAsReactSelectOptions.gimmeDistance}
              value={roundConfigEnumsAsReactSelectOptions.gimmeDistance.find(
                (c) => c.value === field.value
              )}
              onChange={(val: { label: string; value: string }) =>
                field.onChange(val.value)
              }
              styles={customStyles}
            />
          )}
        />
      </FormRow>
      {watchAll.roundInfo?.gimmeDistance ||
      defaultRoundSettings?.gimmeDistance !== GimmeDistance.OFF ? (
        <FormRow>
          <Label
            name="Auto Gimme"
            tooltipText="The number of shots a 'gimme' costs a player"
          />
          <Controller
            name="roundInfo.autoGimmes"
            control={control}
            defaultValue={
              getValues("roundInfo.autoGimmes")
                ? getValues("roundInfo.autoGimmes")
                : defaultRoundSettings?.autoGimmes ??
                  roundConfigEnumsAsReactSelectOptions.autoGimme[0].value
            }
            render={({ field }) => (
              <Select
                {...field}
                closeMenuOnSelect={true}
                isMulti={false}
                options={roundConfigEnumsAsReactSelectOptions.autoGimme}
                value={roundConfigEnumsAsReactSelectOptions.autoGimme.find(
                  (c) => c.value === field.value
                )}
                onChange={(val: { label: string; value: string }) =>
                  field.onChange(val.value)
                }
                styles={customStyles}
              />
            )}
          />
        </FormRow>
      ) : null}
      <FormRow header="Ground">
        <Controller
          name="roundInfo.groundConditions"
          control={control}
          rules={{ required: "Ground Conditions is required" }}
          defaultValue={
            getValues("roundInfo.groundConditions") ||
            defaultRoundSettings?.groundConditions ||
            roundConfigEnumsAsReactSelectOptions?.groundConditions[2].value
          }
          render={({ field }) => (
            <Select
              {...field}
              closeMenuOnSelect={true}
              isMulti={false}
              options={roundConfigEnumsAsReactSelectOptions.groundConditions}
              value={roundConfigEnumsAsReactSelectOptions.groundConditions.find(
                (c) => c.value === field.value
              )}
              onChange={(val: { label: string; value: string }) =>
                field.onChange(val.value)
              }
              styles={customStyles}
            />
          )}
        />
        <ErrorMessage
          errors={formState.errors}
          name="roundInfo.groundConditions"
          render={({ message }) => (
            <div className="text-sm text-red-500">{message}</div>
          )}
        />
      </FormRow>
      <FormRow header="Green Stimp">
        <Controller
          name="roundInfo.stimpSetting"
          control={control}
          rules={{ required: "Green Stimp is required" }}
          defaultValue={
            getValues("roundInfo.stimpSetting")
              ? getValues("roundInfo.stimpSetting")
              : defaultRoundSettings?.stimpSetting ??
                roundConfigEnumsAsReactSelectOptions?.stimpSetting[1].value
          }
          render={({ field }) => (
            <Select
              {...field}
              closeMenuOnSelect={true}
              isMulti={false}
              options={roundConfigEnumsAsReactSelectOptions?.stimpSetting}
              value={roundConfigEnumsAsReactSelectOptions?.stimpSetting.find(
                (c) => c.value === field.value
              )}
              onChange={(val: { label: string; value: string }) =>
                field.onChange(val.value)
              }
              styles={customStyles}
            />
          )}
        />
      </FormRow>
      <FormRow header="Mens Tee">
        <Controller
          name="roundInfo.mensTee"
          control={control}
          rules={{ required: "Mens Tee is required" }}
          defaultValue={
            getValues("roundInfo.mensTee")
              ? getValues("roundInfo.mensTee")
              : defaultRoundSettings?.mensTee ??
                roundConfigEnumsAsReactSelectOptions?.teeType[0].value
          }
          render={({ field }) => (
            <Select
              {...field}
              options={roundConfigEnumsAsReactSelectOptions.teeType}
              value={roundConfigEnumsAsReactSelectOptions.teeType.find(
                (c) => c.value === field.value
              )}
              onChange={(val: { label: string; value: string }) =>
                field.onChange(val.value)
              }
              styles={customStyles}
            />
          )}
        />
        <ErrorMessage
          errors={formState.errors}
          name="roundInfo.mensTee"
          render={({ message }) => (
            <div className="text-sm text-red-500">{message}</div>
          )}
        />
      </FormRow>
      <FormRow header="Pin Type">
        <Controller
          name="roundInfo.pinType"
          control={control}
          defaultValue={
            getValues("roundInfo.pinType")
              ? getValues("roundInfo.pinType")
              : defaultRoundSettings?.pinType ??
                roundConfigEnumsAsReactSelectOptions?.pinType[0].value
          }
          rules={{ required: "Pin Type is required" }}
          render={({ field }) => (
            <Select
              {...field}
              closeMenuOnSelect={true}
              isMulti={false}
              options={roundConfigEnumsAsReactSelectOptions.pinType}
              value={roundConfigEnumsAsReactSelectOptions.pinType.find(
                (c) => c.value === field.value
              )}
              onChange={(val: { label: string; value: string }) =>
                field.onChange(val.value)
              }
              styles={customStyles}
            />
          )}
        />
        <ErrorMessage
          errors={formState.errors}
          name="roundInfo.pinType"
          render={({ message }) => (
            <div className="text-sm text-red-500">{message}</div>
          )}
        />
      </FormRow>
      <FormRow header="Sky">
        <Controller
          name="roundInfo.skyConditions"
          control={control}
          rules={{ required: "Sky is required" }}
          defaultValue={
            getValues("roundInfo.skyConditions")
              ? getValues("roundInfo.skyConditions")
              : defaultRoundSettings?.skyConditions ??
                roundConfigEnumsAsReactSelectOptions?.skyConditions[0].value
          }
          render={({ field }) => (
            <Select
              {...field}
              closeMenuOnSelect={true}
              isMulti={false}
              options={roundConfigEnumsAsReactSelectOptions.skyConditions}
              value={roundConfigEnumsAsReactSelectOptions.skyConditions.find(
                (c) => c.value === field.value
              )}
              onChange={(val: { label: string; value: string }) =>
                field.onChange(val.value)
              }
              styles={customStyles}
            />
          )}
        />
        <ErrorMessage
          errors={formState.errors}
          name="roundInfo.skyConditions"
          render={({ message }) => (
            <div className="text-sm text-red-500">{message}</div>
          )}
        />
      </FormRow>
      <FormRow header="Time of Day">
        <Controller
          name="roundInfo.timeConditions"
          control={control}
          rules={{ required: "Time of Day is required" }}
          defaultValue={
            getValues("roundInfo.timeConditions")
              ? getValues("roundInfo.timeConditions")
              : defaultRoundSettings?.timeConditions ??
                roundConfigEnumsAsReactSelectOptions?.timeOfDay[1].value
          }
          render={({ field }) => (
            <Select
              {...field}
              closeMenuOnSelect={true}
              isMulti={false}
              options={roundConfigEnumsAsReactSelectOptions.timeOfDay}
              value={roundConfigEnumsAsReactSelectOptions.timeOfDay.find(
                (c) => c.value === field.value
              )}
              onChange={(val: { label: string; value: string }) =>
                field.onChange(val.value)
              }
              styles={customStyles}
            />
          )}
        />
      </FormRow>
      <FormRow header="Wind Direction">
        <Controller
          name="roundInfo.windDirection"
          control={control}
          rules={{ required: "Wind Direction is required" }}
          defaultValue={
            getValues("roundInfo.windDirection")
              ? getValues("roundInfo.windDirection")
              : defaultRoundSettings?.windDirection ??
                roundConfigEnumsAsReactSelectOptions?.windDirection[3].value
          }
          render={({ field }) => (
            <Select
              {...field}
              closeMenuOnSelect={true}
              isMulti={false}
              options={roundConfigEnumsAsReactSelectOptions.windDirection}
              value={roundConfigEnumsAsReactSelectOptions.windDirection.find(
                (c) => c.value === field.value
              )}
              onChange={(val: { label: string; value: string }) =>
                field.onChange(val.value)
              }
              styles={customStyles}
            />
          )}
        />
      </FormRow>
      <FormRow header="Wind Speed">
        <Controller
          name="roundInfo.windSpeed"
          control={control}
          defaultValue={
            getValues("roundInfo.windSpeed")
              ? getValues("roundInfo.windSpeed")
              : defaultRoundSettings?.windSpeed ??
                roundConfigEnumsAsReactSelectOptions?.windSpeed[1].value
          }
          rules={{ required: "Wind Speed is required" }}
          render={({ field }) => (
            <Select
              {...field}
              closeMenuOnSelect={true}
              isMulti={false}
              options={roundConfigEnumsAsReactSelectOptions.windSpeed}
              value={roundConfigEnumsAsReactSelectOptions.windSpeed.find(
                (c) => c.value === field.value
              )}
              onChange={(val: { label: string; value: string }) =>
                field.onChange(val.value)
              }
              styles={customStyles}
            />
          )}
        />
        <ErrorMessage
          errors={formState.errors}
          name="roundInfo.windSpeed"
          render={({ message }) => (
            <div className="text-sm text-red-500">{message}</div>
          )}
        />
      </FormRow>
      <FormRow header="Womens Tee">
        <Controller
          name="roundInfo.womensTee"
          control={control}
          rules={{ required: "Mens Tee is required" }}
          defaultValue={
            getValues("roundInfo.womensTee")
              ? getValues("roundInfo.womensTee")
              : defaultRoundSettings?.womensTee ??
                roundConfigEnumsAsReactSelectOptions?.teeType[0].value
          }
          render={({ field }) => (
            <Select
              {...field}
              options={roundConfigEnumsAsReactSelectOptions.teeType}
              value={roundConfigEnumsAsReactSelectOptions.teeType.find(
                (c) => c.value === field.value
              )}
              onChange={(val: { label: string; value: string }) =>
                field.onChange(val.value)
              }
              styles={customStyles}
            />
          )}
        />
        <ErrorMessage
          errors={formState.errors}
          name="roundInfo.womensTee"
          render={({ message }) => (
            <div className="text-sm text-red-500">{message}</div>
          )}
        />
      </FormRow>
      <FormRow>
        <span className="text-md block brand-dark-gray poppins dark:text-gray-200">
          Scoring Options
        </span>
      </FormRow>
      <FormRow header="Scoring">
        <Controller
          name="roundInfo.scoringType"
          rules={{ required: "Scoring Type is required" }}
          control={control}
          defaultValue={
            getValues("roundInfo.scoringType")
              ? getValues("roundInfo.scoringType")
              : defaultRoundSettings?.scoringType ??
                roundConfigEnumsAsReactSelectOptions?.scoringType[0].value
          }
          render={({ field }) => (
            <Select
              {...field}
              closeMenuOnSelect={true}
              isMulti={false}
              options={roundConfigEnumsAsReactSelectOptions.scoringType}
              value={roundConfigEnumsAsReactSelectOptions.scoringType.find(
                (c) => c.value === field.value
              )}
              onChange={(val: { label: string; value: string }) =>
                field.onChange(val.value)
              }
              styles={customStyles}
            />
          )}
        />
      </FormRow>
      {doesScoringTypeIncludeField(
        ScoringType[
          watchAll.roundInfo?.scoringType || defaultRoundSettings?.scoringType
        ],
        "stableford"
      ) &&
        watchAll.roundInfo?.scoringType !== ScoringType.STROKE && (
          <StablefordOptions />
        )}

      <FormRow header="Allow Mulligans">
        <Controller
          name={"roundInfo.mulligans"}
          control={control}
          defaultValue={
            getValues("roundInfo.mulligans")
              ? getValues("roundInfo.mulligans")
              : defaultRoundSettings?.mulligans ??
                roundConfigEnumsAsReactSelectOptions?.mulligan[1].value
          }
          render={({ field }) => (
            <Select
              {...field}
              closeMenuOnSelect={true}
              isMulti={false}
              options={roundConfigEnumsAsReactSelectOptions.mulligan}
              value={roundConfigEnumsAsReactSelectOptions.mulligan.find(
                (c) => c.value === field.value
              )}
              onChange={(val: { label: string; value: string }) =>
                field.onChange(val.value)
              }
              styles={customStyles}
              className="disabled:cursor-not-allowed disabled:bg-gray-100"
              id="roundMulligans"
            />
          )}
        />
      </FormRow>

      {doesScoringTypeIncludeField(
        ScoringType[
          watchAll.roundInfo?.scoringType || defaultRoundSettings?.scoringType
        ],
        "scotchFoursome"
      ) && (
        <FormRow header="Scotch Foursome">
          <Controller
            name="roundInfo.matchSettings.scotchFoursome"
            defaultValue={
              getValues("roundInfo.matchSettings.scotchFoursome")
                ? getValues("roundInfo.matchSettings.scotchFoursome")
                : false
            }
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                closeMenuOnSelect={true}
                isMulti={false}
                options={trueFalseOptions}
                value={trueFalseOptions.find((c) => c.value === field.value)}
                onChange={(val: { label: string; value: string }) =>
                  field.onChange(val.value)
                }
                styles={customStyles}
                className="disabled:cursor-not-allowed disabled:bg-gray-100"
                id="roundAutoDrop"
              />
            )}
          />
        </FormRow>
      )}

      {doesScoringTypeIncludeField(
        ScoringType[watchAll.roundInfo?.scoringType],
        "teams"
      ) &&
        props.simulatorId && (
          <TeamManagement
            scoringType={titleCase(
              ScoringType[watchAll.roundInfo?.scoringType]
            )}
          />
        )}

      {watchAll.roundInfo?.scoringType !== ScoringType.SCRAMBLE && (
        <div>
          <FormRow>
            <Label
              name="Handicap Mode"
              tooltipText="The mode of handicap used for the round"
            />
            <Controller
              name="roundInfo.handicapMode"
              control={control}
              defaultValue={
                getValues("roundInfo.handicapMode")
                  ? getValues("roundInfo.handicapMode")
                  : defaultRoundSettings?.handicapMode ??
                    roundConfigEnumsAsReactSelectOptions?.handicapMode[2].value
              }
              render={({ field }) => (
                <Select
                  {...field}
                  closeMenuOnSelect={true}
                  isMulti={false}
                  isSearchable={false}
                  options={roundConfigEnumsAsReactSelectOptions.handicapMode}
                  value={roundConfigEnumsAsReactSelectOptions.handicapMode.find(
                    (c) => c.value === field.value
                  )}
                  onChange={(val: { label: string; value: string }) =>
                    field.onChange(val.value)
                  }
                  styles={customStyles}
                />
              )}
            />
          </FormRow>
          {watchAll.roundInfo?.handicapMode === HandicapMode.ABSOLUTE ||
          watchAll.roundInfo?.handicapMode === HandicapMode.RELATIVE ||
          defaultRoundSettings?.handicapMode !== HandicapMode.OFF ? (
            <div>
              <FormRow header="Handicap Percentage">
                <Controller
                  name="roundInfo.handicapPercentage"
                  control={control}
                  rules={{ required: "Handicap Percentage is required" }}
                  defaultValue={
                    getValues("roundInfo.handicapPercentage") ||
                    roundConfigEnumsAsReactSelectOptions.handicapPercentage[0]
                      .value
                  }
                  render={({ field }) => (
                    <Select
                      {...field}
                      closeMenuOnSelect={true}
                      isMulti={false}
                      isSearchable={false}
                      options={
                        roundConfigEnumsAsReactSelectOptions.handicapPercentage
                      }
                      value={roundConfigEnumsAsReactSelectOptions.handicapPercentage.find(
                        (c) => c.value === field.value
                      )}
                      onChange={(val: { label: string; value: string }) =>
                        field.onChange(val.value)
                      }
                      styles={customStyles}
                    />
                  )}
                />
                <ErrorMessage
                  errors={formState.errors}
                  name="roundInfo.handicapPercentage"
                  render={({ message }) => (
                    <div className="text-sm text-red-500">{message}</div>
                  )}
                />
              </FormRow>
              <FormRow header="Course Rating and Slope">
                <Controller
                  name="roundInfo.courseRatingSlope"
                  control={control}
                  defaultValue={
                    getValues("roundInfo.handicapPercentage") || false
                  }
                  render={({ field }) => (
                    <Select
                      {...field}
                      closeMenuOnSelect={true}
                      isMulti={false}
                      isSearchable={false}
                      options={trueFalseOptions}
                      value={trueFalseOptions.find(
                        (c) => c.value === field.value
                      )}
                      onChange={(val: { label: string; value: string }) =>
                        field.onChange(val.value)
                      }
                      styles={customStyles}
                    />
                  )}
                />
              </FormRow>
            </div>
          ) : null}
        </div>
      )}

      <ReactTooltip place="right" />
    </div>
  );
};

export default RoundSpecificForm;

type RoundSpecificFormProps = {
  simulatorId?: string;
  defaultRoundSettings: DefaultRoundSettings;
};
