import { AppQueryConfig } from "QueryTypes";
import { League, LeaguePayload } from "Models";
import { leagueQueryDefaultOptions } from "./leagueHelpers";

const urls = {
  leaguesBase(facilityId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facility/${facilityId}/league`;
  },
  leaguesByleagueId(facilityId: string, leagueId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facility/${facilityId}/league/${leagueId}`;
  },
  leaguesByFacilityId(facilityId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facility/${facilityId}/leagues/`;
  },
  deleteleague(facilityId: string, leagueId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facility/${facilityId}/league/${leagueId}`;
  },
};

export const makeCreateLeagueMutation = (
  facilityId: string,
  body: LeaguePayload
): AppQueryConfig => ({
  url: urls.leaguesBase(facilityId),
  queryKey: `makeCreateleagueMutation:${facilityId}`,
  options: {
    ...leagueQueryDefaultOptions(),
    method: "POST",
  },
  body,
  transform: (responseJson: League) => {
    return {
      leagues: {
        byFacilityId: {
          [facilityId]: { [responseJson.id]: responseJson },
        },
      },
    };
  },
  update: {
    leagues: (oldValue, newValue) => ({
      ...oldValue,
      byFacilityId: {
        ...oldValue?.byFacilityId,
        ...{
          [facilityId]: {
            ...oldValue?.byFacilityId?.[facilityId],
            ...newValue.byFacilityId[facilityId],
          },
        },
      },
    }),
  },
});

export const getLeaguesByFacilityId = (facilityId: string): AppQueryConfig => ({
  url: urls.leaguesByFacilityId(facilityId),
  options: {
    ...leagueQueryDefaultOptions(),
    method: "GET",
  },
  transform: (responseJson: League[]) => {
    let obj: { [id: string]: League } = {};

    responseJson.map((league) => {
      return (obj[league?.id] = league);
    });

    return {
      leagues: {
        byFacilityId: {
          [facilityId]: obj,
        },
      },
    };
  },
  update: {
    leagues: (oldValue, newValue) => ({
      ...oldValue,
      byFacilityId: {
        ...oldValue?.byFacilityId,
        ...{
          [facilityId]: {
            ...oldValue?.byFacilityId?.[facilityId],
            ...newValue.byFacilityId[facilityId],
          },
        },
      },
    }),
  },
});

export const makeUpdateLeagueMutation = (
  facilityId: string,
  body: League
): AppQueryConfig => ({
  url: urls.leaguesByleagueId(facilityId, body.id),
  queryKey: `makeUpdateleagueMutation:${body.id}`,
  options: {
    ...leagueQueryDefaultOptions(),
    method: "PATCH",
  },
  body,
  transform: (responseJson: League) => {
    let obj: { [id: string]: League } = {};
    obj[responseJson?.id] = responseJson;

    return {
      leagues: {
        byFacilityId: {
          [facilityId]: obj,
        },
      },
    };
  },
  update: {
    leagues: (oldValue, newValue) => ({
      ...oldValue,
      byFacilityId: {
        ...oldValue?.byFacilityId,
        ...{
          [facilityId]: {
            ...oldValue?.byFacilityId?.[facilityId],
            ...newValue.byFacilityId[facilityId],
          },
        },
      },
    }),
  },
});

export const makeDeleteLeagueMutation = (
  leagueId: string,
  facilityId: string
): AppQueryConfig => ({
  url: urls.deleteleague(facilityId, leagueId),
  options: {
    ...leagueQueryDefaultOptions(),
    method: "DELETE",
  },
  optimisticUpdate: {
    leagues: (reduxState) => {
      delete reduxState.byFacilityId[facilityId][leagueId];
      return reduxState;
    },
  },
});

export const getClubhouseLeagueDto = (
  facilityId: string,
  leagueId: string
): AppQueryConfig => ({
  url: urls.leaguesByleagueId(facilityId, leagueId),
  queryKey: `getClubhouseLeagueDto:${leagueId}`,
  options: {
    ...leagueQueryDefaultOptions(),
    method: "GET",
  },
  transform: (responseJson: League) => {
    let obj: { [id: string]: League } = {};
    obj[responseJson?.id] = responseJson;

    return {
      leagues: {
        byFacilityId: {
          [facilityId]: obj,
        },
      },
    };
  },
  update: {
    leagues: (oldValue, newValue) => ({
      ...oldValue,
      byFacilityId: {
        ...oldValue?.byFacilityId,
        ...{
          [facilityId]: {
            ...oldValue?.byFacilityId?.[facilityId],
            ...newValue.byFacilityId[facilityId],
          },
        },
      },
    }),
  },
});
