import { ClubhouseRoundTeamDto, CreateTeamCommandPayload } from "Models";
import { UseDataInfo } from "QueryTypes";
import { EntitiesState, RootState } from "StoreTypes";
import { useSelector } from "react-redux";
import { RunMutation, useMutation, useRequest } from "redux-query-react";
import {
  getTeamsByFacilityAndLeagueId,
  getTeamsByFacilityLeagueAndRoundId,
  makeCreateLeagueTeamMutation,
  makeDeleteLeagueTeamMutation,
  makeUpdateLeagueTeamMutation,
} from "./leagueTeamsQueries";
import { QueryState } from "redux-query";

export const useLeagueTeamsByFacilityAndLeagueId = (
  facilityId: string,
  leagueId: string
): UseDataInfo<ClubhouseRoundTeamDto[]> => {
  const [queryState, refreshLeagueTeams] = useRequest(
    getTeamsByFacilityAndLeagueId(facilityId, leagueId)
  );
  const leagueTeams = useSelector(
    (state: RootState): ClubhouseRoundTeamDto[] => {
      if (!state.entities.leagueTeams?.byLeagueId?.[leagueId]) return [];

      let leagueTeams: ClubhouseRoundTeamDto[] = Object.values<ClubhouseRoundTeamDto>(
        state.entities?.leagueTeams?.byLeagueId?.[leagueId]
      );

      return leagueTeams;
    }
  );

  return [leagueTeams, queryState, refreshLeagueTeams];
};

export const useLeagueTeamsByFacilityLeagueAndRoundId = (
  facilityId: string,
  leagueId: string,
  roundId: string
): UseDataInfo<ClubhouseRoundTeamDto[]> => {
  const [queryState, refreshLeagueTeams] = useRequest(
    getTeamsByFacilityLeagueAndRoundId(facilityId, leagueId, roundId)
  );
  const leagueTeams = useSelector(
    (state: RootState): ClubhouseRoundTeamDto[] => {
      if (!state.entities.leagueTeams?.byLeagueId?.[leagueId]) return [];

      let leagueTeams: ClubhouseRoundTeamDto[] = Object.values<ClubhouseRoundTeamDto>(
        state.entities?.leagueTeams?.byLeagueId?.[leagueId]
      );

      return leagueTeams;
    }
  );

  return [leagueTeams, queryState, refreshLeagueTeams];
};

export const useCreateLeagueTeam = (
  facilityId: string,
  leagueId: string
): [QueryState, RunMutation<EntitiesState, [CreateTeamCommandPayload]>] => {
  const [
    createLeagueTeamQueryState,
    createLeagueTeam,
  ] = useMutation((body: CreateTeamCommandPayload) =>
    makeCreateLeagueTeamMutation(facilityId, leagueId, body)
  );

  return [createLeagueTeamQueryState, createLeagueTeam];
};

export const useUpdateLeagueTeam = (
  facilityId: string,
  leagueId: string,
  teamId: string
): [QueryState, RunMutation<EntitiesState, [CreateTeamCommandPayload]>] => {
  const [
    updateLeagueTeamQueryState,
    updateLeagueTeam,
  ] = useMutation((body: CreateTeamCommandPayload) =>
    makeUpdateLeagueTeamMutation(facilityId, leagueId, teamId, body)
  );

  return [updateLeagueTeamQueryState, updateLeagueTeam];
};

export const useDeleteLeagueTeam = (
  facilityId: string,
  leagueId: string,
  teamId: string
): [QueryState, RunMutation<EntitiesState>] => {
  const [deleteLeagueTeamQueryState, deleteLeagueTeam] = useMutation(() =>
    makeDeleteLeagueTeamMutation(facilityId, leagueId, teamId)
  );

  return [deleteLeagueTeamQueryState, deleteLeagueTeam];
};
