import { RoundConfiguration } from "Models";
import { RootState } from "StoreTypes";

export const getFacilityRoundConfigurations = (
  state: RootState,
  facilityId: string
): RoundConfiguration[] => {
  if (!state.entities.roundConfigurations?.byFacilityId?.[facilityId])
    return [];
  return Object.values(
    state.entities.roundConfigurations?.byFacilityId?.[facilityId]
  );
};
