import { UseDataInfo } from "QueryTypes";
import { SimulatorState } from "Models";
import { useRequest } from "redux-query-react";
import { getSimulatorStateQuery } from "./simulatorStateQueries";
import { useSelector } from "react-redux";
import { RootState } from "StoreTypes";
export const useSimulatorState = (
  simId: string
): UseDataInfo<SimulatorState> => {
  const [queryState, refreshSimulatorState] = useRequest(
    getSimulatorStateQuery(simId)
  );

  const simulatorState = useSelector(
    (state: RootState): SimulatorState =>
      state.entities.simulatorStates?.[simId]
  );

  return [simulatorState, queryState, refreshSimulatorState];
};
