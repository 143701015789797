import { QueryState } from "redux-query";
import { UseDataInfo } from "QueryTypes";
import { useSelector } from "react-redux";
import { EntitiesState, RootState } from "StoreTypes";
import { RunMutation, useMutation, useRequest } from "redux-query-react";
import { GameScorecard, ScorecardPayload } from "ScorecardModels";
import {
  getScorecardsBySearch,
  makeDismissAllScorecardsMutation,
} from "./scorecardLookupQueries";

export const useScorecardsBySearch = (
  facilityId: string,
  body: ScorecardPayload
): UseDataInfo<GameScorecard[]> => {
  const [queryState, refreshScorecards] = useRequest(
    getScorecardsBySearch(facilityId, body)
  );
  const scorecards = useSelector((state: RootState): GameScorecard[] => {
    if (!state.entities.scorecards?.byFacilityId?.[facilityId]) return [];
    return Object.values(
      state.entities?.scorecards?.byFacilityId?.[facilityId]
    ).sort(
      (a: GameScorecard, b: GameScorecard) =>
        new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
    );
  });

  return [scorecards, queryState, refreshScorecards];
};

export const useDismissScorecards = (
  facilityId: string
): [QueryState, RunMutation<EntitiesState>] => {
  const [
    dismissServiceRequestQueryState,
    dismissServiceRequest,
  ] = useMutation(() => makeDismissAllScorecardsMutation(facilityId));

  return [dismissServiceRequestQueryState, dismissServiceRequest];
};
