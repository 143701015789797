import "assets/css/spinnerAnimation.css";

type OwnProps = {
  class?: string;
};

const SortIcon: React.FC<OwnProps> = (props) => {
  return (
    <svg
      width="11"
      height="8"
      viewBox="0 0 11 8"
      className={`${props.class} fill-current`}
      fill-current={props.class}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.25047 9.13043e-07L8.19714 0.00342474C8.00538 0.0282471 7.85761 0.185094 7.85761 0.374885L7.85714 6.71893L6.17053 5.11062L6.12647 5.07433C5.97263 4.96552 5.75436 4.97771 5.61492 5.11082C5.4616 5.25733 5.46171 5.49465 5.61518 5.64101L7.97431 7.89031L8.01837 7.9266C8.17221 8.03542 8.39048 8.02322 8.52987 7.89012L10.8851 5.64081L10.923 5.59872C11.0371 5.45192 11.0244 5.24363 10.8848 5.11062L10.8408 5.07433C10.6869 4.96552 10.4686 4.97771 10.3292 5.11082L8.64285 6.72093L8.64332 0.374885L8.63971 0.324016C8.61373 0.141032 8.44936 9.13043e-07 8.25047 9.13043e-07ZM2.46945 0.109812L0.115055 2.35664L0.0770214 2.39869C-0.0370905 2.54545 -0.0244038 2.75372 0.115076 2.88681L0.159139 2.92311C0.31294 3.03201 0.53119 3.01987 0.67066 2.88679L2.35557 1.27816L2.35592 7.6251L2.3595 7.67598C2.38552 7.85897 2.54989 7.99998 2.74877 7.99998L2.80208 7.99658C2.99384 7.97174 3.14163 7.81489 3.14163 7.6251L3.14128 1.27916L4.82947 2.88709L4.87357 2.92334C5.02747 3.03206 5.24569 3.01977 5.38502 2.88651C5.53829 2.73995 5.53803 2.50259 5.38444 2.35635L3.02474 0.109512L2.98067 0.0732832C2.82687 -0.0353883 2.60882 -0.023187 2.46945 0.109812Z"
        className={`${props.class} fill-current`}
        fill-current={props.class}
      />
    </svg>
  );
};
export default SortIcon;

SortIcon.defaultProps = {
  class: "text-zinc-800 dark:text-white",
};
