import { Dispatch, SetStateAction } from "react";
import { styles } from "./SimulatorReservation.styles";
import {
  DateTimeRange,
  dateTimeRanges,
  TodayOrTomorrow,
} from "modules/simulator-reservations/simulatorReservationHelpers";
import TodayIcon from "components/svg/TodayIcon";
import ReservationsIcon from "components/svg/ReservationsIcon";

const ShowQueueItemsByDateOption: React.FC<ShowQueueItemsByDateOptionProps> = ({
  selectedDate,
  setSelectedDate,
  cssClasses,
}) => {
  const { date } = selectedDate;

  const handleGetSimReservationsToday = () => {
    setSelectedDate(dateTimeRanges.today);
  };

  const handleGetSimReservationsTomorrow = () => {
    setSelectedDate(dateTimeRanges.tomorrow);
  };

  return (
    <div className={styles.simulatorReservationSelectDayOptionContainer}>
      <div className={styles.simulatorReservationSelectDayOptionInnerContainer}>
        <button
          type="button"
          className={`${cssClasses}  ${
            date === TodayOrTomorrow.TODAY
              ? styles.queueActiveDay
              : styles.queueInactiveDay
          } rounded-l-lg`}
          onClick={() => handleGetSimReservationsToday()}
        >
          <div className="flex justify-center text-xs">
            <TodayIcon
              class={`${date === TodayOrTomorrow.TODAY ? "text-white" : ""}`}
            />
            <span className="flex ml-2">Today</span>
          </div>
        </button>
        <button
          type="button"
          className={`${cssClasses}  ${
            date === TodayOrTomorrow.TOMORROW
              ? styles.queueActiveDay
              : styles.queueInactiveDay
          }  rounded-r-lg`}
          onClick={() => handleGetSimReservationsTomorrow()}
        >
          <div className="flex justify-center items-center">
            <ReservationsIcon
              class={`${date === TodayOrTomorrow.TOMORROW ? "text-white" : ""}`}
            />
            <span className="flex ml-2 text-xs">Tomorrow</span>
          </div>
        </button>
      </div>
    </div>
  );
};
export default ShowQueueItemsByDateOption;

type ShowQueueItemsByDateOptionProps = {
  cssClasses?: string;
  selectedDate: DateTimeRange;
  setSelectedDate: Dispatch<SetStateAction<DateTimeRange>>;
};

ShowQueueItemsByDateOption.defaultProps = {
  cssClasses: "",
};
