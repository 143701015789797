import { useSimulatorState } from "modules/simulator-state/simulatorStateHooks";
import { useEffect } from "react";
import { updateEntities } from "redux-query";
import { TimerStatus } from "types/enums";
import { useAppDispatch } from "utils/hooks";
import { subtractSecondsFromTimerArray } from "utils/timer/timerHelpers";

type CountdownTimerProps = {
  simulatorId: string;
};
const CountdownTimer: React.FC<CountdownTimerProps> = (props) => {
  const dispatch = useAppDispatch();
  const [simulatorState] = useSimulatorState(props.simulatorId);

  const tick = () => {
    if (simulatorState && simulatorState.timerStatus === TimerStatus.RUNNING) {
      dispatch(
        updateEntities({
          simulatorStates: (prev) => ({
            ...prev,
            ...{
              [props.simulatorId]: {
                ...prev[props.simulatorId],
                ...{
                  timerValue: subtractSecondsFromTimerArray(
                    prev[props.simulatorId]?.timerValue,
                    1
                  ),
                  timerStatus: prev[props.simulatorId]?.timerStatus,
                },
              },
            },
          }),
        })
      );
    }
  };

  useEffect(() => {
    const timerId = setInterval(() => tick(), 1000);
    return () => clearInterval(timerId);
  });

  return null;
};

export default CountdownTimer;
