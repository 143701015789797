import SpinnerAnimation from "components/svg/SpinnerAnimation";
import { useState } from "react";
import {
  getLocalStorageValue,
  LocalStorageItems,
  localStorageValueToBoolean,
} from "utils/hooks/localStorage";
import styles from "./DashboardComponents.styles";

const ShowLoadingAnimation: React.FC<ShowLoadingAnimationProps> = (props) => {
  const [isDarkMode] = useState(() =>
    localStorageValueToBoolean(getLocalStorageValue(LocalStorageItems.DARKMODE))
      ? true
      : false
  );

  return (
    <>
      <div
        className={` ${
          props.showListView
            ? styles.listViewContainer
            : styles.simulatorIsOffContainer
        } `}
      >
        <div
          className={` ${
            props.showListView
              ? styles.showListAnimation
              : styles.ShowLoadingAnimation
          } `}
        >
          <SpinnerAnimation color={isDarkMode ? "#e4e4e7" : "#f2f2e6"} />
          <strong
            className={`${
              isDarkMode ? "text-gray-200" : "text-gray-700"
            } poppins font-bold`}
          >
            Loading...
          </strong>
        </div>
      </div>
    </>
  );
};
export default ShowLoadingAnimation;

type ShowLoadingAnimationProps = {
  showListView: boolean;
};

ShowLoadingAnimation.defaultProps = {
  showListView: false,
};
