import { useMemo, useEffect } from "react";
import { styles } from "./Events.styles";
import { FormRow } from "components/ui/form";
import { customStyles } from "utils/ui/uiHelpers";
import { EventCompetitionType } from "types/enums";
import { ErrorMessage } from "@hookform/error-message";
import { useSelectedEvent } from "modules/events/eventHooks";
import { Controller, useFormContext } from "react-hook-form";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { roundConfigEnumsAsReactSelectOptions } from "utils/enums/enumHelper";
import { eventStartDatePassed } from "modules/events/eventHelpers";
import Select from "react-select";

const CreateEventFormCoursePinType: React.FC<CreateEventFormCoursePinTypeProps> = (
  props
) => {
  const { control, formState, watch, setValue, getValues } = useFormContext();
  const { roundNumber } = props;

  const currentFacilityId = useGetCurrentLocalFacilityId();
  const competitionType = watch("type");
  const startDatePassed = eventStartDatePassed(getValues("startDate"));
  const selectedEvent = useSelectedEvent(currentFacilityId);

  const hidePinTypeInput = useMemo(() => {
    return competitionType === EventCompetitionType.LONG_DRIVE;
  }, [competitionType]);

  const pinTypeOptionName = useMemo(() => {
    return `roundInfo[${roundNumber}].pin`;
  }, [roundNumber]);

  const handlePinTypeChange = (value: string) => {
    setValue(pinTypeOptionName, value);
  };

  useEffect(() => {
    if (!selectedEvent) {
      setValue(
        pinTypeOptionName,
        roundConfigEnumsAsReactSelectOptions?.pinType[0].value
      );
    }
  }, [selectedEvent, setValue, pinTypeOptionName]);

  return (
    <>
      {!hidePinTypeInput && (
        <FormRow
          header="Pin Type"
          fontSize={styles.formRowLabel}
          hidden={hidePinTypeInput}
        >
          <Controller
            name={pinTypeOptionName}
            control={control}
            rules={{ required: "Pin Type is required" }}
            defaultValue={watch(pinTypeOptionName)}
            render={({ field }) => (
              <Select
                {...field}
                closeMenuOnSelect={true}
                isMulti={false}
                options={roundConfigEnumsAsReactSelectOptions.pinType}
                value={roundConfigEnumsAsReactSelectOptions.pinType.find(
                  (c) => c.value === field.value
                )}
                onChange={(val: { label: string; value: string }) =>
                  handlePinTypeChange(val.value)
                }
                styles={customStyles}
                isDisabled={startDatePassed}
                id="pinType"
                name="pinType"
              />
            )}
          />
          <ErrorMessage
            errors={formState.errors}
            name={pinTypeOptionName}
            render={({ message }) => (
              <div className="text-sm text-red-500">{message}</div>
            )}
          />
        </FormRow>
      )}
    </>
  );
};
export default CreateEventFormCoursePinType;

type CreateEventFormCoursePinTypeProps = {
  roundNumber?: number;
};
