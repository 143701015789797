import { AppQueryConfig } from "QueryTypes";
import { CourseEntitiesState } from "StoreTypes";
import { Course, CourseWithMissingSimulatorAssignments } from "Models";
import { reduxQueryDefaultOptions } from "utils/queries/queryHelpers";

const urls = {
  simulatorCourseList(simId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/simulators/${simId}/courselist`;
  },
  facilityCourseList(facilityId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facilities/${facilityId}/courses`;
  },
};

/**
 * Query for a simulator's courses.
 * @param simId
 */
export const getCoursesBySimQuery = (simId: string): AppQueryConfig => ({
  url: urls.simulatorCourseList(simId),
  queryKey: `getSimulatorCoursesQuery:${simId}`,
  options: { ...reduxQueryDefaultOptions() },
  transform: (responseJson: Course[]): { courses: CourseEntitiesState } => {
    let obj: { [shortName: string]: Course } = {};
    let arr: string[] = [];
    responseJson.forEach((course: Course) => {
      obj[course.shortName] = course;
      arr.push(course.shortName);
    });
    return { courses: { courseData: obj, bySimId: { [simId]: arr } } };
  },
  update: {
    courses: (oldValue, newValue): CourseEntitiesState => ({
      ...oldValue,
      courseData: {
        ...oldValue?.courseData,
        ...newValue.courseData,
      },
      bySimId: {
        ...oldValue?.bySimId,
        ...newValue.bySimId,
      },
    }),
  },
});

export const getCoursesByFacilityQuery = (
  facilityId: string
): AppQueryConfig => ({
  url: urls.facilityCourseList(facilityId),
  queryKey: `getFacilityCoursesQuery:${facilityId}`,
  options: { ...reduxQueryDefaultOptions() },
  transform: (
    responseJson: CourseWithMissingSimulatorAssignments[]
  ): { courses: CourseEntitiesState } => {
    let courseObj: { [shortName: string]: Course } = {};
    let missingSimsObj: { [facilityId: string]: string[] } = {};
    responseJson.forEach((courseWithMissingSims) => {
      courseObj[courseWithMissingSims.course.shortName] =
        courseWithMissingSims.course;
      missingSimsObj[courseWithMissingSims.course.shortName] =
        courseWithMissingSims.missingSimulatorIds;
    });
    return {
      courses: {
        courseData: courseObj,
        missingSimulatorsByCourse: missingSimsObj,
      },
    };
  },
  update: {
    courses: (oldValue, newValue): CourseEntitiesState => ({
      ...oldValue,
      courseData: {
        ...oldValue?.courseData,
        ...newValue.courseData,
      },
      missingSimulatorsByCourse: {
        ...oldValue?.missingSimulatorsByCourse,
        ...newValue.missingSimulatorsByCourse,
      },
    }),
  },
});
