import { useAppDispatch } from "utils/hooks";
import { ModalType } from "utils/modals/modalHelpers";
import { modalAction } from "ui-modules/modals/modalSlice";
import Row from "components/ui/layout/Row";
import Modal from "components/shared/Modal";
import LeagueLeaderboard from "./LeagueLeaderboard";
import { useGetSelectedLeague } from "modules/leagues/leagueHooks";

const LeagueLeaderboardModal: React.FC<LeagueRoundGroupModalProps> = (
  props
) => {
  const dispatch = useAppDispatch();
  const league = useGetSelectedLeague();

  const handleCloseModal = async () => {
    dispatch(
      modalAction({
        isOpen: false,
        modalName: ModalType.LeagueLeaderboard,
      })
    );
  };

  return (
    <Modal
      modalName={ModalType.LeagueLeaderboard}
      closeModalCallback={handleCloseModal}
      hideHeader={false}
      hideFooter={false}
      title={`${league?.name} Leaderboard` || props.title}
      modalSize="w-full h-full md:w-1/2 2xl:w-1/2"
      body={
        <Row cssClasses="px-8 pb-4">
          <LeagueLeaderboard />
        </Row>
      }
    />
  );
};

type LeagueRoundGroupModalProps = {
  title?: string;
  modalName: string;
};

export default LeagueLeaderboardModal;
