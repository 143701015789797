import { AppQueryConfig } from "QueryTypes";
import { ScorecardTextType, ScorecardImageType } from "EntityTypes";
import { getCurrentUserToken } from "modules/user";

const urls = {
  scorecardText(simulatorId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/simulators/${simulatorId}/scorecardtext`;
  },
  scorecardImage(simulatorId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/simulators/${simulatorId}/scorecardimage`;
  },
};

const scorecardQueryDefaultOptions = (): {
  headers: { authorization: string; "API-Key": string };
} => ({
  headers: {
    authorization: `Bearer ${getCurrentUserToken()}`,
    "API-Key": `${process.env.REACT_APP_API_KEY}`,
  },
});

export const getTextScorecardBySimQuery = (
  simulatorId: string
): AppQueryConfig => ({
  url: urls.scorecardText(simulatorId),
  queryKey: `getTextScorecardBySimQuery:${simulatorId}`,
  options: {
    headers: {
      ...scorecardQueryDefaultOptions().headers,
    },
    method: "GET",
  },
  transform: (response: { data: string }) => {
    const obj: {
      [simulatorId: string]: string;
    } = { [simulatorId]: btoa(response.data) };
    return response.data === ""
      ? { scorecardTextStates: {} }
      : { scorecardTextStates: obj };
  },
  update: {
    scorecardTextStates: (oldValue, newValue): ScorecardTextType => ({
      ...oldValue,
      ...newValue,
    }),
  },
});

export const getImageScorecardBySimQuery = (
  simulatorId: string
): AppQueryConfig => ({
  url: urls.scorecardImage(simulatorId),
  queryKey: `getImageScorecardBySimQuery:${simulatorId}`,
  options: {
    headers: {
      ...scorecardQueryDefaultOptions().headers,
    },
    method: "GET",
  },
  transform: (response: { data: string }) => {
    const obj: {
      [simulatorId: string]: string;
    } = { [simulatorId]: response.data };
    return response.data === ""
      ? { scorecardImageStates: {} }
      : { scorecardImageStates: obj };
  },
  update: {
    scorecardImageStates: (oldValue, newValue): ScorecardImageType => ({
      ...oldValue,
      ...newValue,
    }),
  },
});
