import React from "react";
import { iconOptions, radioButtonWidthHelper } from "utils/icons/iconHelpers";
import RadioTileReactHookForm from "./RadioTileReactHookForm";

const RadioTileGroup: React.FC<RadioTileGroupProps> = React.forwardRef(
  (props, ref) => {
    const {
      options,
      icon,
      radioGroupName,
      onClick,
      hideLabel,
      defaultValue,
      disabled,
    } = props;

    return (
      <div className="flex w-full" onClick={onClick}>
        {options?.map((item, i) => (
          <RadioTileReactHookForm
            label={item.label}
            value={item.value}
            name={radioGroupName}
            iconOptions={icon ? icon[i] : undefined}
            width={radioButtonWidthHelper(options.length)}
            hideLabel={hideLabel}
            defaultValue={defaultValue}
            key={i}
            onClick={onClick}
            disabled={disabled}
          />
        ))}
      </div>
    );
  }
);
export default RadioTileGroup;

type RadioTileGroupProps = {
  options?: Array<RadioTileProps>;
  icon?: iconOptions[];
  radioGroupName: string;
  onClick?: any;
  hideLabel?: boolean;
  defaultValue?: any;
  disabled?: boolean;
};

type RadioTileProps = {
  label: string;
  value: any;
};

RadioTileGroup.defaultProps = {
  hideLabel: false,
};
