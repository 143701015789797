import { motion } from "framer-motion";
import { MotionVariantType } from "types/enums";
import {
  onlineEventsTrayAction,
  createEventTrayAction,
} from "ui-modules/nav/navSlice";
import { buttonMotionVariants } from "utils/animations/animationHelper";
import { useAppDispatch } from "utils/hooks";
import { styles } from "./Events.styles";

const CreateEventFormOverlay: React.FC<CreateEventFormOverlayProps> = (
  props
) => {
  const dispatch = useAppDispatch();

  const setOnlineEventsTray = () => {
    dispatch(onlineEventsTrayAction({ isOpen: false }));
    dispatch(createEventTrayAction({ isOpen: false }));
  };

  return (
    <div className={styles.invalidInstallIdContainer}>
      <div className={styles.invalidInstallIdOverlay}></div>
      <div className={styles.invalidInstallIdContent}>
        <i className={styles.invalidInstallIdIcon}></i>
        Some simulators are not on the latest build and are unable to currently
        use the events feature. Please run update center on all your simulators
        to update to a minimum of Simulator build 1772 and Connect Build
        2.0.20221122. Once on the latest builds, boot your sim and Connect will
        send its install ID to Clubhouse, which is required for Events. For help
        please contact customer support at 1-800-455-GOLF or
        support@aboutgolf.com.
        <br />
        <motion.button
          variants={buttonMotionVariants}
          initial={MotionVariantType.REST}
          whileHover={MotionVariantType.HOVER}
          whileTap={MotionVariantType.PRESSED}
          key="createEventFormOverlayButton"
          className={styles.invalidInstallIdButton}
          onClick={setOnlineEventsTray}
        >
          Click here to close
        </motion.button>
      </div>
    </div>
  );
};
export default CreateEventFormOverlay;

type CreateEventFormOverlayProps = {};

CreateEventFormOverlay.defaultProps = {};
