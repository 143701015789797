import React from "react";
import { FaLinkedin, FaInstagram, FaFacebook } from "react-icons/fa";
import Row from "./../../ui/layout/Row";

const SocialIcons: React.FC = () => {
  return (
    <Row cssClasses="block mt-2 space-x-4 mx-auto text-center items-center mb-2">
      <a
        href="https://facebook.com/aboutGOLF"
        className="text-gray-400 dark:text-gray-200 hover:text-gray-500"
        target="_blank"
        rel="noreferrer"
      >
        <span className="sr-only">Facebook</span>
        <FaFacebook className="fab fa-facebook text-xl" />
      </a>
      <a
        href="https://www.instagram.com/aboutgolf_/"
        className="text-gray-400 dark:text-gray-200 hover:text-gray-500"
        target="_blank"
        rel="noreferrer"
      >
        <span className="sr-only">Instagram</span>
        <FaInstagram className="fab fa-instagram text-xl" />
      </a>
      <a
        href="https://www.linkedin.com/company/aboutgolf-limited/mycompany/"
        className="text-gray-400 dark:text-gray-200 hover:text-gray-500"
        target="_blank"
        rel="noreferrer"
      >
        <span className="sr-only">LinkedIn</span>
        <FaLinkedin className="text-xl" />
      </a>
    </Row>
  );
};
export default SocialIcons;
