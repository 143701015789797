import { ImWarning } from "react-icons/im";
import { useAppDispatch } from "utils/hooks";
import { modalAction } from "ui-modules/modals/modalSlice";
import { ModalType, isModalOpen } from "utils/modals/modalHelpers";
import Row from "components/ui/layout/Row";
import Modal from "components/shared/Modal";

const ConfirmDeleteAlert: React.FC<Props> = (props) => {
  const {
    confirmButtonText,
    cancelButtonText,
    callback,
    bodyText,
    modalName,
  } = props;
  const dispatch = useAppDispatch();

  const handleConfirm = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    if (callback) callback(true);
    setModalClose();
  };

  const handleCancel = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    setModalClose();
  };

  const setModalClose = () => {
    dispatch(
      modalAction({
        isOpen: false,
        modalName: modalName,
      })
    );
  };

  return (
    <>
      {isModalOpen(modalName) && (
        <Modal
          hideFooter={true}
          hideHeader={true}
          modalName={ModalType.ConfirmDeleteAlert}
          modalSize="w-3/4 sm:w-1/2 md:w-2/3 xl:w-1/2 rounded-lg"
          body={
            <Row cssClasses="p-4">
              <Row cssClasses="flex flex-col text-md items-center justify-center my-4 text-center poppins">
                <div className="flex flex-none items-center justify-center w-16 h-16 rounded-full bg-brandGreen text-[0.625rem] font-medium text-white">
                  <ImWarning className="h-10 w-10" />
                </div>
                <div className="flex pt-4 dark:text-gray-50">{bodyText}</div>
              </Row>
              <Row cssClasses="mt-6 text-center pr-1 flex flex-row w-full pt-2.5 text-xs items-center">
                <div className="flex w-1/2 m-auto">
                  <button
                    onClick={(e) => handleConfirm(e)}
                    className="poppins py-2 px-4 bg-red-500 hover:bg-red-700 text-white rounded shadow hover:shadow-inner hover:text-white text-sm font-semibold w-full "
                    tabIndex={4}
                  >
                    {confirmButtonText}
                  </button>
                </div>

                {cancelButtonText && (
                  <div className="flex w-1/2 ml-2">
                    <button
                      onClick={(e) => handleCancel(e)}
                      className="poppins py-2 px-4 bg-white-500 hover:bg-gray-700 text-black rounded shadow hover:shadow-inner hover:text-white text-sm font-semibold w-full dark:bg-gray-50 dark:hover:bg-gray-200 dark:text-zinc-800"
                      tabIndex={4}
                    >
                      {cancelButtonText}
                    </button>
                  </div>
                )}
              </Row>
            </Row>
          }
        />
      )}
    </>
  );
};
export default ConfirmDeleteAlert;

type Props = {
  title?: string;
  modalName: string;
  callback?: any;
  bodyText: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  showModal?: boolean;
  confirmed?: boolean;
};

ConfirmDeleteAlert.defaultProps = {
  title: "",
  confirmButtonText: "Confirm",
  showModal: false,
  confirmed: false,
};
