import { PlayerUser } from "Models";
import { FormatOptionLabelContext } from "react-select/src/Select";

const FormattedUserSearchOption = ({
  value,
  context,
}: {
  value: PlayerUser;
  context: FormatOptionLabelContext;
}) => {
  return context === "menu" ? (
    <div className="flex w-full border-b border-gray-200 dark:border-white px-4 h-8">
      {value.displayName ? (
        <div className="flex w-1/2">{value.displayName}</div>
      ) : (
        <div>{value.username}</div>
      )}{" "}
      {value.email && (
        <div className="flex text-gray-500 w-1/2 text-right justify-end">
          {value.email}
        </div>
      )}
    </div>
  ) : (
    <div>{value.username || value.displayName || value.email}</div>
  );
};
export default FormattedUserSearchOption;
