import { useState } from "react";
import { ImWarning } from "react-icons/im";
import { useFormContext } from "react-hook-form";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { useSelectedSimulatorReservation } from "modules/simulator-reservations/simulatorReservationHooks";
import { modalName, ModalType } from "utils/modals/modalHelpers";
import ConfirmDeleteAlert from "components/shared/ConfirmDeleteAlert";

const SimulatorReservationPlayerCheck = () => {
  const { watch } = useFormContext();
  const [confirmNumberOfSimPlayers, setConfirmNumberOfSimPlayers] = useState(
    false
  );
  const currentFacilityId = useGetCurrentLocalFacilityId();
  const selectedSimulatorReservation = useSelectedSimulatorReservation(
    currentFacilityId
  );
  const confirmLessPlayersModalName = modalName(ModalType.ConfirmDeleteAlert);
  const morePlayersThanReserved =
    selectedSimulatorReservation &&
    watch("roundInfo.players")?.length >
      selectedSimulatorReservation?.numberInParty
      ? true
      : false;

  const lessPlayersThanReserved =
    selectedSimulatorReservation &&
    watch("roundInfo.players")?.length <
      selectedSimulatorReservation?.numberInParty
      ? true
      : false;

  return (
    <>
      <ConfirmDeleteAlert
        modalName={confirmLessPlayersModalName}
        bodyText="There are less players in the round than in the reservation"
        showModal={lessPlayersThanReserved && !confirmNumberOfSimPlayers}
        callback={() => setConfirmNumberOfSimPlayers(true)}
      />

      {morePlayersThanReserved && (
        <div className="mt-6 p-2 bg-brandYellow rounded-md text-center">
          <ImWarning className="w-5 h-5" /> More players are being sent to the
          sim than are in the reservation
        </div>
      )}
    </>
  );
};
export default SimulatorReservationPlayerCheck;
