import FormData from "form-data";
import { AppQueryConfig } from "QueryTypes";
import { reduxQueryDefaultOptions } from "utils/queries/queryHelpers";
import { CreateGroupPayload, Group, Event } from "Models";
import axios from "axios";

const urls = {
  eventsBase(): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/events`;
  },
  eventsByEventId(eventId: number): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/events/${eventId}`;
  },
  eventsByFacilityId(facilityId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/events/facilities/${facilityId}`;
  },
  groupsByFacilityId(facilityId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/groups/facilities/${facilityId}`;
  },
  createGroup(): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/groups/private`;
  },
  deleteEvent(eventId: number): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/events/${eventId}`;
  },
};

export const makeCreateEventMutation = (
  facilityId: string,
  body: FormData
): AppQueryConfig => ({
  url: urls.eventsBase(),
  queryKey: `makeCreateEventMutation:${facilityId}`,
  options: {
    headers: {
      ...reduxQueryDefaultOptions().headers,
    },
    method: "POST",
  },
  body,
  transform: (responseJson: Event) => {
    return {
      events: {
        byFacilityId: {
          [facilityId]: { [responseJson.id]: responseJson },
        },
      },
    };
  },
  update: {
    events: (oldValue, newValue) => ({
      ...oldValue,
      byFacilityId: {
        ...oldValue?.byFacilityId,
        ...{
          [facilityId]: {
            ...oldValue?.byFacilityId?.[facilityId],
            ...newValue.byFacilityId[facilityId],
          },
        },
      },
    }),
  },
});

export const makeCreateGroupMutation = (
  facilityId: string,
  body: CreateGroupPayload
): AppQueryConfig => ({
  url: urls.createGroup(),
  queryKey: `makeCreateGroupMutation:${facilityId}`,
  options: {
    ...reduxQueryDefaultOptions(),
    method: "POST",
  },
  body,
  transform: (responseJson: Group) => {
    let obj: { [id: string]: Group } = { [responseJson?.id]: responseJson };

    return {
      groups: {
        byFacilityId: {
          [facilityId]: obj,
        },
      },
    };
  },
  update: {
    groups: (oldValue, newValue) => ({
      ...oldValue,
      byFacilityId: {
        ...oldValue?.byFacilityId,
        ...{
          [facilityId]: {
            ...oldValue?.byFacilityId?.[facilityId],
            ...newValue.byFacilityId[facilityId],
          },
        },
      },
    }),
  },
});

export const getEventsByFacilityId = (facilityId: string): AppQueryConfig => ({
  url: urls.eventsByFacilityId(facilityId),
  queryKey: `getEvents:${facilityId}`,
  options: {
    ...reduxQueryDefaultOptions(),
    method: "GET",
  },
  transform: (responseJson: Event[]) => {
    let obj: { [id: string]: Event } = {};

    responseJson.map((event) => {
      return (obj[event?.id] = event);
    });

    return {
      events: {
        byFacilityId: {
          [facilityId]: obj,
        },
      },
    };
  },
  update: {
    events: (oldValue, newValue) => ({
      ...oldValue,
      byFacilityId: {
        ...oldValue?.byFacilityId,
        ...{
          [facilityId]: {
            ...oldValue?.byFacilityId?.[facilityId],
            ...newValue.byFacilityId[facilityId],
          },
        },
      },
    }),
  },
});

export const getEventsByEventId = async (eventId: number) => {
  await axios
    .post(
      urls.eventsByEventId(eventId),
      {},
      {
        headers: {
          ...reduxQueryDefaultOptions().headers,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const makeUpdateEventMutation = (
  eventId: number,
  facilityId: string,
  body: FormData
): AppQueryConfig => ({
  url: urls.eventsByEventId(eventId),
  queryKey: `makeUpdateEventMutation:${eventId}`,
  options: {
    headers: {
      ...reduxQueryDefaultOptions().headers,
    },
    method: "PATCH",
  },
  body,
  transform: (responseJson: Event) => {
    let obj: { [id: string]: Event } = {};
    obj[responseJson?.id] = responseJson;

    return {
      events: {
        byFacilityId: {
          [facilityId]: obj,
        },
      },
    };
  },
  update: {
    events: (oldValue, newValue) => ({
      ...oldValue,
      byFacilityId: {
        ...oldValue?.byFacilityId,
        ...{
          [facilityId]: {
            ...oldValue?.byFacilityId?.[facilityId],
            ...newValue.byFacilityId[facilityId],
          },
        },
      },
    }),
  },
});

export const makeDeleteEventMutation = (
  eventId: number,
  facilityId: string
): AppQueryConfig => ({
  url: urls.deleteEvent(eventId),
  queryKey: `deleteEvent:${eventId}`,
  options: {
    ...reduxQueryDefaultOptions(),
    method: "DELETE",
  },
  optimisticUpdate: {
    events: (reduxState) => {
      delete reduxState.byFacilityId[facilityId][eventId];
      return reduxState;
    },
  },
});

export const getGroupsByFacilityId = (facilityId: string): AppQueryConfig => ({
  url: urls.groupsByFacilityId(facilityId),
  queryKey: `groupsByFacilityId:${facilityId}`,
  options: {
    ...reduxQueryDefaultOptions(),
    method: "GET",
  },
  transform: (responseJson: Group[]) => {
    let obj: { [id: string]: Group } = {};
    responseJson.map((tour) => (obj[tour.id] = tour));
    return {
      groups: {
        byFacilityId: {
          [facilityId]: obj,
        },
      },
    };
  },
  update: {
    groups: (oldValue, newValue) => ({
      ...oldValue,
      byFacilityId: {
        ...oldValue?.byFacilityId,
        ...{
          [facilityId]: {
            ...oldValue?.byFacilityId?.[facilityId],
            ...newValue.byFacilityId[facilityId],
          },
        },
      },
    }),
  },
});
