import { useState } from "react";
import CreatableSelect from "react-select/creatable";

const CreatableMultiSelect: React.FC<CreatableMultiSelectProps> = (props) => {
  const [, setSelectedOption] = useState(props.list[0].name);
  const handleChange = (newValue: any, actionMeta: any) => {
    setSelectedOption(newValue);
  };

  return (
    <CreatableSelect
      isMulti
      closeMenuOnSelect={false}
      onChange={handleChange}
      options={props.list}
    />
  );
};

export default CreatableMultiSelect;

type CreatableMultiSelectProps = {
  list: Array<any>;
  value?: string;
  label?: string;
};

CreatableMultiSelect.defaultProps = {};
