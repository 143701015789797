import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { RootState } from "StoreTypes";
import {
  BreakPoints,
  buttonMotionVariants,
  useMediaQuery,
} from "utils/animations/animationHelper";
import { useAppDispatch } from "utils/hooks";
import { modalAction } from "ui-modules/modals/modalSlice";
import { ModalType } from "utils/modals/modalHelpers";
import { SiGooglemarketingplatform } from "react-icons/si";
import { useSidebarCollapsed } from "utils/ui/uiHelpers";
import ReactTooltip from "react-tooltip";
import EventCard from "components/control/events/EventCard";
import MarketingModal from "./MarketingModal";

const Marketing = () => {
  const dispatch = useAppDispatch();

  const isSidebarCollapsed = useSidebarCollapsed();
  const isDesktop = useMediaQuery(BreakPoints.LG);

  const isMarketingModalOpen = useSelector(
    (state: RootState) =>
      state.modals?.modalSettings[ModalType.MarketingModal]?.isOpen
  );

  const setMarketingModal = () =>
    dispatch(
      modalAction({ isOpen: true, modalName: ModalType.MarketingModal })
    );

  const listItemClassName =
    "col-span-1 flex flex-col text-center bg-white  dark:bg-zinc-800  dark:text-gray-200 rounded-lg hover:shadow-lg shadow divide-y divide-gray-200";

  return (
    <div
      className={`Events w-full ${
        isDesktop ? (isSidebarCollapsed ? "pl-24" : "pl-64") : "pl-0"
      }`}
    >
      {isMarketingModalOpen && (
        <MarketingModal modalName={ModalType.MarketingModal} />
      )}
      <div className="flex flex-col md:flex-row min-h-screen w-full max-w-screen-xl mx-auto mt-8 md:mt-12">
        <div className="mt-8 sm:mx-auto sm:max-w-3xl px-4">
          <motion.ul
            role="list"
            className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3"
            initial={{ x: 0 }}
            animate={{
              transition: {
                staggerChildren: 0.1,
                delayChildren: 0.3,
              },
            }}
          >
            <motion.li
              whileTap="tap"
              whileHover="hover"
              variants={buttonMotionVariants}
              className={listItemClassName}
            >
              <button
                onClick={() => setMarketingModal()}
                className="hover:bg-shadow-sm"
                data-testid="btnOpenLockerUserHandicapTray"
                id="btnOpenLockerUserHandicapTray"
              >
                <EventCard
                  title="Marketing Materials"
                  description="Access course guides, marketing materials and informational videos"
                  icon={SiGooglemarketingplatform}
                  enabled={true}
                  testId="updateAgHandicapCard"
                />
              </button>
            </motion.li>
          </motion.ul>
          <ReactTooltip backgroundColor="#777" textColor="#fff" />
        </div>
      </div>
    </div>
  );
};
export default Marketing;
