import { normalize } from "normalizr";
import { AppQueryConfig } from "QueryTypes";
import { facilityAndSimSchema } from "./facilitySchema";
import { FacilitiesEntitiesState, SimulatorsEntitiesState } from "StoreTypes";
import { Facility } from "Models";
import { reduxQueryDefaultOptions } from "utils/queries/queryHelpers";

const urls = {
  userFacilities(userId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facilities/user/${userId}`;
  },
};

export const getUserFacilitiesQuery = (userId: string): AppQueryConfig => ({
  url: urls.userFacilities(userId),
  queryKey: `getUserFacilities:${userId}`,
  options: { ...reduxQueryDefaultOptions() },
  transform: (responseJson: Facility[]) => {
    return normalize(responseJson, [facilityAndSimSchema]).entities;
  },
  update: {
    facilities: (oldValue, newValue): FacilitiesEntitiesState => ({
      ...oldValue,
      ...newValue,
    }),
    simulators: (oldValue, newValue): SimulatorsEntitiesState => ({
      ...oldValue,
      ...newValue,
    }),
  },
});
