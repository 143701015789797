export const globalStyles = {
  primaryButton:
    "bg-brandYellow text-brandDarkGray dark:text-black rounded-lg mr-3 poppins text-xs font-medium leading-4 hover:bg-darkCream disabled:cursor-not-allowed",
  secondaryButton: "",
  tertiaryButton:
    "text-black bg-gray-200 dark:bg-zinc-500 rounded-lg font-medium poppins hover:bg-brandGreyText dark:hover:bg-zinc-400 hover:text-white tracking-tight text-xs shadow disabled:cursor-not-allowed",
  buttonSmall: "py-1 px-4",
  buttonMedium: "py-2 px-6",
  buttonLarge: "py-2.5 px-10 ",
  toolTip:
    "poppins text-black text-xs bg-white dark:bg-zinc-800 dark:text-gray-50 left-0 top-2 p-2 border-2 border-[#cccccc] rounded float-right inline",
};
