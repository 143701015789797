import "assets/css/spinnerAnimation.css";

type OwnProps = {
  class?: string;
};

const StoreIcon: React.FC<OwnProps> = (props) => {
  return (
    <svg
      className={`${props.class} fill-current`}
      fill-current={props.class}
      width="20"
      height="20"
      viewBox="0 0 16 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3 5V3C3 1.34315 4.34315 0 6 0C6.7684 0 7.4692 0.28886 8 0.7639C8.5308 0.28885 9.2317 0 10 0C11.6569 0 13 1.34315 13 3V5H14.5C15.3284 5 16 5.67157 16 6.5V16.505C16 18.4352 14.4352 20 12.505 20H4C1.79086 20 0 18.2091 0 16V6.5C0 5.67157 0.67157 5 1.5 5H3ZM9.635 18.5C9.241 17.9343 9.01 17.2466 9.01 16.505V6.5H1.5V16C1.5 17.3807 2.61929 18.5 4 18.5H9.635ZM7.5 5V3C7.5 2.17157 6.8284 1.5 6 1.5C5.17157 1.5 4.5 2.17157 4.5 3V5H7.5ZM9 5H11.5V3C11.5 2.17157 10.8284 1.5 10 1.5C9.535 1.5 9.1195 1.71156 8.8444 2.04368C8.9453 2.34403 9 2.66563 9 3V5ZM10.51 16.505C10.51 17.6068 11.4032 18.5 12.505 18.5C13.6068 18.5 14.5 17.6068 14.5 16.505V6.5H10.51V16.505Z" />
    </svg>
  );
};
export default StoreIcon;

StoreIcon.defaultProps = {
  class: "text-zinc-800 dark:text-white",
};
