import { RoundConfiguration, StartRoundPayload } from "Models";
import { useSelector } from "react-redux";
import { QueryState } from "redux-query";
import { RunMutation, useMutation, useRequest } from "redux-query-react";
import { EntitiesState, RootState } from "StoreTypes";
import { getFacilityRoundConfigurations } from "./roundConfigurationSelectors";
import {
  getFacilityRoundConfigs,
  makeAddRoundConfigurationMutation,
  makeDeleteRoundConfigurationMutation,
} from "./roundConfigurationQueries";
import { RoundConfigurationToMultiSimsPayload, UseDataInfo } from "QueryTypes";
import { makeSendRoundConfigurationToSimulatorsMutation } from "./roundConfigurationQueries";

export const useFacilityRoundConfigurations = (
  facilityId: string
): UseDataInfo<RoundConfiguration[]> => {
  const [queryState, refreshFacilityRoundConfigurations] = useRequest(
    getFacilityRoundConfigs(facilityId)
  );

  const facilityRoundConfigurations = useSelector((state: RootState) =>
    getFacilityRoundConfigurations(state, facilityId)
  );

  return [
    facilityRoundConfigurations,
    queryState,
    refreshFacilityRoundConfigurations,
  ];
};

export const useAddRoundConfiguration = (
  facilityId: string
): [QueryState, RunMutation<EntitiesState, [StartRoundPayload]>] => {
  const [
    addRoundQueryState,
    addRound,
  ] = useMutation((body: StartRoundPayload) =>
    makeAddRoundConfigurationMutation(facilityId, body)
  );
  return [addRoundQueryState, addRound];
};

export const useDeleteRoundConfiguration = (
  facilityId: string,
  roundConfigId: string
): [QueryState, RunMutation<EntitiesState>] => {
  const [deleteRoundQueryState, deleteRound] = useMutation(() =>
    makeDeleteRoundConfigurationMutation(facilityId, roundConfigId)
  );
  return [deleteRoundQueryState, deleteRound];
};

export const useBulkSendRoundConfiguration = (): [
  QueryState,
  RunMutation<EntitiesState, [RoundConfigurationToMultiSimsPayload]>
] => {
  const [
    bulkSendRoundConfigurationQueryState,
    sendRoundConfiguration,
  ] = useMutation((body: RoundConfigurationToMultiSimsPayload) =>
    makeSendRoundConfigurationToSimulatorsMutation(body)
  );

  return [bulkSendRoundConfigurationQueryState, sendRoundConfiguration];
};
