import { connect } from "react-redux";
import { RootState } from "StoreTypes";
import { Listbox } from "@headlessui/react";
import { FiChevronDown } from "react-icons/fi";
import { getCurrentFacility } from "modules/facility/facilitySelectors";
import { FacilityNormalized } from "EntityTypes";
import { useAppDispatch } from "utils/hooks";
import { setCurrentFacility } from "ui-modules/facility-control/facilityControlSlice";
import Row from "./Row";

type StateProps = {
  selectedFacility: FacilityNormalized | undefined;
};

type OwnProps = {
  userFacilities: FacilityNormalized[];
};

type Props = StateProps & OwnProps;

const mapStateToProps = (state: RootState) => ({
  selectedFacility: getCurrentFacility(state),
});

const FacilityList: React.FC<Props> = ({
  selectedFacility,
  userFacilities,
}) => {
  const dispatch = useAppDispatch();
  const useSetSelectedFacility = (value: string) => {
    dispatch(setCurrentFacility({ currentFacility: value }));
  };

  return (
    <div className="flex align-middle">
      {userFacilities.length > 1 ? (
        <Listbox value={selectedFacility?.id} onChange={useSetSelectedFacility}>
          <Listbox.Button className="brand-heading relative w-full mt-1 py-2 pl-3 pr-8 text-left bg-white  dark:bg-zinc-800  dark:text-gray-200 cursor-default">
            <span className="">{selectedFacility?.name}</span>
            <span className="absolute inset-y-0 right-0 flex items-center pointer-events-none">
              <FiChevronDown className="size-5 inline" />
            </span>
          </Listbox.Button>
          <Listbox.Options
            as="ul"
            className="absolute m:w-120 py-1 mt-16 ml-2 overflow-hidden text-xs bg-white  dark:bg-zinc-800  max-h-60 sm:text-sm rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            {userFacilities.map((facility) => (
              <Listbox.Option
                className="text-gray-900 dark:text-gray-200 
              cursor-pointer select-none relative py-2 pl-4 pr-4 hover:text-gray-600 hover:bg-gray-200 dark:hover:bg-zinc-600 dark:hover:text-gray-200 hover:cursor-p text-xs"
                key={facility.id}
                value={facility.id}
              >
                {facility.name}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Listbox>
      ) : (
        <Row cssClasses="brand-heading text-lg text-gray-500 dark:text-gray-200 font-semibold relative w-full py-2 pl-3 pr-4 text-left border-r border-gray">
          {userFacilities[0]?.name}
        </Row>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(FacilityList);
