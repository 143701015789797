import { ErrorMessage } from "@hookform/error-message";
import { FormRow } from "components/ui/form";
import Label from "components/ui/form/Label";
import Row from "components/ui/layout/Row";
import { Course, Hole } from "Models";
import { useLeagueRoundStartDatePassed } from "modules/leagues/leagueHelpers";
import { Controller, useFormContext } from "react-hook-form";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import { ArrayHelper } from "utils/arrays/arrayHelpers";
import { customStyles } from "utils/ui/uiHelpers";

type CourseAndHolesFormProps = {
  courses: Course[];
  holes: Hole[];
};

const LeagueRoundCourseAndHolesForm: React.FC<CourseAndHolesFormProps> = ({
  courses,
  holes,
}) => {
  const { control, watch, formState, setValue } = useFormContext();
  const watchAll = watch();

  const courseOptions: { label: string; value: Course }[] = courses.map(
    (course) => {
      return { label: course.displayName || course.shortName, value: course };
    }
  );

  const startDatePassed = useLeagueRoundStartDatePassed();

  const handleMultiselectFieldChange = (
    e: { label: string; value: any }[],
    field: string
  ) => {
    setValue(
      field,
      e.map((x) => x.value)
    );
  };

  const handleCourseChange = (e: { label: string; value: Course }) => {
    setValue("course", e.value.shortName);
  };

  const selectFront9 = () =>
    setValue("courseSettings.selectedHoles", ArrayHelper.fillWithRange(1, 9));

  const selectBack9 = () =>
    setValue("courseSettings.selectedHoles", ArrayHelper.fillWithRange(10, 18));

  const selectAll18 = () =>
    setValue("courseSettings.selectedHoles", ArrayHelper.fillWithRange(1, 18));

  return (
    <div>
      <FormRow
        header="Which course are you playing?"
        fontSize="text-xs font-normal text-gray-800 dark:text-gray-200 poppins pb-3 mt-5"
      >
        <Controller
          name="course"
          defaultValue={watch("course") || ""}
          control={control}
          rules={{ required: "Course selection is required" }}
          render={({ field }) => (
            <Select
              closeMenuOnSelect={true}
              isMulti={false}
              {...field}
              options={courseOptions}
              value={courseOptions.find(
                (option) => option.value.shortName === field.value
              )}
              onChange={handleCourseChange}
              styles={customStyles}
              isDisabled={startDatePassed}
            />
          )}
        />
        <ErrorMessage
          errors={formState.errors}
          name="course"
          render={({ message }) => (
            <div className="text-sm text-red-500">{message}</div>
          )}
        />
      </FormRow>
      {watchAll.course ? (
        <Row cssClasses="pt-4">
          <Label
            name="Which holes do you want to play?"
            cssClasses="text-xs font-normal text-gray-800 dark:text-gray-200 poppins pb-3 mt-5"
          />
          {holes?.length === 18 && (
            <div className="flex flex-row mb-5">
              <div
                onClick={selectFront9}
                className={`border rounded-full  text-sm  font-bold px-4 py-2 mt-2 mr-2 inline items-center  ${
                  startDatePassed
                    ? "hover:cursor-not-allowed bg-gray-300 border-gray-300 text-gray-700"
                    : "text-white border-brandGreen bg-brandGreen hover:bg-brandGreen hover:cursor-pointer"
                }`}
              >
                Select Front 9
              </div>
              <div
                onClick={selectBack9}
                className={`border rounded-full  text-sm  font-bold px-4 py-2 mt-2 mr-2 inline items-center  ${
                  startDatePassed
                    ? "hover:cursor-not-allowed bg-gray-300 border-gray-300 text-gray-700"
                    : "text-white border-brandGreen bg-brandGreen hover:bg-brandGreen hover:cursor-pointer"
                }`}
              >
                Select Back 9
              </div>
              <div
                onClick={selectAll18}
                className={`border rounded-full  text-sm  font-bold px-4 py-2 mt-2 mr-2 inline items-center  ${
                  startDatePassed
                    ? "hover:cursor-not-allowed bg-gray-300 border-gray-300 text-gray-700"
                    : "text-white border-brandGreen bg-brandGreen hover:bg-brandGreen hover:cursor-pointer"
                }`}
              >
                Select All 18
              </div>
            </div>
          )}
          <Controller
            name="courseSettings.selectedHoles"
            key="courseSettings.selectedHoles"
            control={control}
            rules={{ required: "Hole selection is required" }}
            defaultValue={holes.map((hole) => hole.number)}
            render={({ field }) => (
              <MultiSelect
                {...field}
                options={holes.map((hole) => {
                  return {
                    label: `${hole.number} Par: ${hole.par}`,
                    value: hole.number,
                  };
                })}
                value={holes
                  .filter((hole) =>
                    watchAll.courseSettings.selectedHoles?.includes(hole.number)
                  )
                  .map((hole) => {
                    return {
                      label: hole.number.toString(),
                      value: hole.number,
                    };
                  })}
                onChange={(e: { label: string; value: number }[]) =>
                  handleMultiselectFieldChange(
                    e,
                    "courseSettings.selectedHoles"
                  )
                }
                labelledBy="Holes"
                hasSelectAll={true}
                selectAllLabel="Select/Unselect All Holes"
                disableSearch={true}
                className={`dark:text-black dark:placeholder-black rounded-lg ${
                  startDatePassed
                    ? "bg-gray-500 opacity-40 cursor-not-allowed"
                    : ""
                }`}
                disabled={startDatePassed}
              />
            )}
          />
        </Row>
      ) : null}
      <ErrorMessage
        errors={formState.errors}
        name="courseSettings.selectedHoles"
        render={({ message }) => (
          <div className="text-sm text-red-500">{message}</div>
        )}
      />
    </div>
  );
};

export default LeagueRoundCourseAndHolesForm;
