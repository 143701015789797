import { useFormContext } from "react-hook-form";

const StablefordOptions = () => {
  const { register } = useFormContext();
  return (
    <div className="mt-1 flex justify-around flex-wrap bg-gray-200 dark:bg-zinc-800 p-2 rounded dark:text-gray-200">
      <div className="flex-col">
        <div className="text-sm font-semibold text-gray-500 dark:text-gray-200 py-2 heading">
          Triple Eagle
        </div>
        <input
          className="border border-gray-300 rounded dark:text-black"
          defaultValue="11"
          type="number"
          {...register("roundInfo.stablefordSettings.tripleEagle")}
        />
      </div>
      <div className="flex-col">
        <div className="text-sm font-semibold text-gray-500 dark:text-gray-200 py-2 heading">
          Double Eagle
        </div>
        <input
          className="border border-gray-300 rounded dark:text-black"
          defaultValue="8"
          type="number"
          {...register("roundInfo.stablefordSettings.doubleEagle")}
        />
      </div>
      <div className="flex-col">
        <div className="text-sm font-semibold text-gray-500 dark:text-gray-200 py-2 heading">
          Eagle
        </div>
        <input
          className="border border-gray-300 rounded dark:text-black"
          defaultValue="5"
          type="number"
          {...register("roundInfo.stablefordSettings.eagle")}
        />
      </div>
      <div className="flex-col">
        <div className="text-sm font-semibold text-gray-500 dark:text-gray-200 py-2 heading">
          Birdie
        </div>
        <input
          className="border border-gray-300 rounded dark:text-black"
          defaultValue="2"
          type="number"
          {...register("roundInfo.stablefordSettings.birdie")}
        />
      </div>
      <div className="flex-col">
        <div className="text-sm font-semibold text-gray-500 dark:text-gray-200 py-2 heading">
          Par
        </div>
        <input
          className="border border-gray-300 rounded dark:text-black"
          defaultValue="0"
          type="number"
          {...register("roundInfo.stablefordSettings.par")}
        />
      </div>
      <div className="flex-col">
        <div className="text-sm font-semibold text-gray-500 dark:text-gray-200 py-2 heading">
          Bogey
        </div>
        <input
          className="border border-gray-300 rounded dark:text-black"
          defaultValue="-1"
          type="number"
          {...register("roundInfo.stablefordSettings.bogey")}
        />
      </div>
      <div className="flex-col">
        <div className="text-sm font-semibold text-gray-500 dark:text-gray-200 py-2 heading">
          Double Bogey
        </div>
        <input
          className="border border-gray-300 rounded dark:text-black"
          defaultValue="-3"
          type="number"
          {...register("roundInfo.stablefordSettings.doubleBogey")}
        />
      </div>
    </div>
  );
};

export default StablefordOptions;
