import { motion } from "framer-motion";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { League, LeagueRosterEntryDto } from "Models";
import { useToasterStore } from "react-hot-toast";
import { useCallback, useEffect, useState } from "react";
import { sortStringArrayByProperty } from "utils/arrays/arrayHelpers";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import {
  getApiResponseErrorMessage,
  isApiStatusSuccess,
} from "utils/api/apiHelper";
import {
  GenericNotificationType,
  showCustomNotification,
} from "utils/notifications/notificationHelpers";
import { styles } from "./RosterAndGroups.styles";
import { Gender, MotionVariantType } from "types/enums";
import {
  dailyQueueContainer,
  dailyQueueListItemVariant,
} from "utils/animations/animationHelper";
import { useGetClubhouseLeagueDto } from "modules/leagues/leagueHooks";

import {
  useLeagueRosterByLeagueId,
  useRemovePlayerFromLeagueRoster,
} from "modules/leagues/leagueRosterHooks";
import CreateLeagueRosterSearch from "./CreateLeagueRosterSearch";
import { AiOutlineMinusCircle } from "react-icons/ai";

type CreateLeagueRosterFormProps = {
  callback?: React.Dispatch<React.SetStateAction<boolean>>;
  selectedLeague: League;
};

const CreateLeagueRosterForm: React.FC<CreateLeagueRosterFormProps> = (
  props
) => {
  const { selectedLeague } = props;
  const [selectedPlayer, setSelectedPlayer] = useState<LeagueRosterEntryDto>();
  const [sortAscending, setSortAscending] = useState<boolean>(true);
  const [sortProperty, setSortProperty] = useState<string>(
    "firstName" || "aGHandicapIndex" || "lastName"
  );

  const toastIsActive = useToasterStore().toasts.length > 0 ? true : false;
  const currentFacilityId = useGetCurrentLocalFacilityId();

  const [leagueRoster, ,] = useLeagueRosterByLeagueId(
    currentFacilityId,
    selectedLeague?.id
  );
  const [, removePlayerFromRoster] = useRemovePlayerFromLeagueRoster(
    currentFacilityId,
    selectedLeague?.id,
    selectedPlayer?.userGuid || ""
  );
  const [, , updateLeague] = useGetClubhouseLeagueDto(
    currentFacilityId,
    selectedLeague?.id
  );

  const sortedLeagueRoster = sortStringArrayByProperty(
    leagueRoster,
    sortProperty as keyof LeagueRosterEntryDto,
    sortAscending
  );

  const handleRemovePlayerFromRoster = (player: LeagueRosterEntryDto) => {
    if (window.confirm(`Remove ${player.userName} from roster?`)) {
      setSelectedPlayer(player);
    }
  };

  const removeSelectedPlayerFromRoster = useCallback(
    async (player: LeagueRosterEntryDto) => {
      let response = await removePlayerFromRoster();

      if (response && isApiStatusSuccess(response?.status)) {
        await showCustomNotification(player?.userName + " removed from roster");
        await updateLeague();
      } else {
        if (!toastIsActive) {
          showCustomNotification(
            getApiResponseErrorMessage(response?.body),
            GenericNotificationType.ERROR
          );
        }
      }
    },
    [removePlayerFromRoster, toastIsActive, updateLeague]
  );

  function handleRosterSortOnClick(sortProperty: string) {
    setSortProperty(sortProperty);
    setSortAscending(!sortAscending);
  }

  const LeagueRosterHeader = () => {
    return (
      <>
        <div className="w-full py-4 mb-4 text-sm poppins font-semibold">
          Players List{" "}
          <span className="bg-gray-100 rounded-lg ml-2 p-1 px-2">
            {leagueRoster?.length}
          </span>
        </div>
        <div className="grid grid-cols-12 gap-2 pt-4 text-sm poppins font-semibold">
          <div className="hidden"></div>
          <div className="col-span-8 sm:col-span-3 text-gray-400">
            <button onClick={() => handleRosterSortOnClick("firstName")}>
              First{" "}
              {sortProperty === "firstName" ? (
                sortAscending ? (
                  <FaSortUp className="size-4 mr-2 text-black dark:text-white " />
                ) : (
                  <FaSortDown className="size-4 mr-2 text-black dark:text-white " />
                )
              ) : (
                <FaSort className="size-4 mr-2" />
              )}
            </button>
            <button onClick={() => handleRosterSortOnClick("lastName")}>
              Last{" "}
              {sortProperty === "lastName" ? (
                sortAscending ? (
                  <FaSortUp className="size-4 mr-2 text-black dark:text-white " />
                ) : (
                  <FaSortDown className="size-4 mr-2 text-black dark:text-white " />
                )
              ) : (
                <FaSort className="size-4 mr-2" />
              )}
            </button>
          </div>
          <div className="hidden sm:block sm:col-span-1 text-gray-400"></div>
          <div className="hidden sm:block sm:col-span-3 text-gray-400">
            Email
          </div>
          <div className="hidden sm:block sm:col-span-3 text-gray-400"></div>
          <div className="col-span-2 xs:col-span-1 text-gray-400 text-left">
            <button onClick={() => handleRosterSortOnClick("aGHandicapIndex")}>
              HCP{" "}
              {sortProperty === "aGHandicapIndex" ? (
                sortAscending ? (
                  <FaSortUp className="size-4 mr-2 text-black dark:text-white " />
                ) : (
                  <FaSortDown className="size-4 mr-2 text-black dark:text-white " />
                )
              ) : (
                <FaSort className="size-4 mr-2" />
              )}
            </button>
          </div>
          <div className="col-span-1 text-gray-400 text-center"></div>
        </div>

        <div className="hidden text-xs text-gray-300 mt-1">
          "" indicates no first or last name for this Locker profile. Username
          is used instead.
        </div>
      </>
    );
  };

  useEffect(() => {
    if (selectedPlayer) {
      removeSelectedPlayerFromRoster(selectedPlayer);
      setSelectedPlayer(undefined);
    }
  }, [selectedPlayer, removeSelectedPlayerFromRoster]);

  return (
    <>
      <div className={styles.leagueRosterFormContainer}>
        <div className="flex flex-row">
          <CreateLeagueRosterSearch />
        </div>
        <LeagueRosterHeader />
        {leagueRoster && leagueRoster.length > 0 ? (
          <>
            <motion.div
              variants={dailyQueueContainer}
              initial={MotionVariantType.HIDDEN}
              animate={MotionVariantType.SHOW}
              key={`leagueRosterList_${leagueRoster.length}`}
            >
              {sortedLeagueRoster.length > 0 &&
                sortedLeagueRoster.map((player, index) => {
                  return (
                    <motion.div
                      animate={{ opacity: 1, x: 0 }}
                      layout
                      variants={dailyQueueListItemVariant}
                      className={styles.leagueRosterListItem}
                      key={`leagueRoster-${player.userGuid}-${index}`}
                    >
                      <div className="hidden" key={index}></div>
                      <div className="col-span-8 sm:col-span-3 text-ellipsis truncate w-full">
                        {player?.firstName ? (
                          <>
                            <span
                              className={
                                sortProperty === "firstName"
                                  ? "font-bold mr-1"
                                  : "mr-1"
                              }
                            >
                              {player.firstName}
                            </span>
                            <span
                              className={
                                sortProperty === "lastName" ? "font-bold" : ""
                              }
                            >
                              {player.lastName}
                            </span>
                          </>
                        ) : (
                          <span className="text-gray-500">
                            "{player.userName}"
                          </span>
                        )}
                      </div>
                      <div className="hidden sm:block col-span-1">
                        <span className="bg-gray-100 dark:bg-zinc-900 rounded-md px-2 py-1 mr-2 text-zinc-600 dark:text-gray-50">
                          {Gender[player?.gender].charAt(0)}
                        </span>
                      </div>
                      <div className="hidden sm:block sm:col-span-3 text-ellipsis truncate w-full">
                        {player.userEmail}
                      </div>
                      <div className="hidden sm:block sm:col-span-3 text-ellipsis truncate w-full"></div>
                      <div className="col-span-2 sm:col-span-1 self-center">
                        {player.aGHandicapIndex}
                      </div>
                      <div className="col-span-1 text-right">
                        <button
                          onClick={() => handleRemovePlayerFromRoster(player)}
                          className="ml-2"
                        >
                          <AiOutlineMinusCircle className="size-4 fill-red-500 text-red-500" />
                        </button>
                      </div>
                    </motion.div>
                  );
                })}
            </motion.div>
          </>
        ) : (
          <div className="text-xs text-gray-400 p-8 dark:bg-zinc-800 dark:text-gray-200 text-center h-full">
            <MdClose className="block border border-gray-400 rounded p-1 mb-2 w-6 h-6 text-gray-400 dark:text-gray-200" />
            Players will appear here after you add one. Start typing a name in
            the search bar above then click an item from the list to add your
            first player.
          </div>
        )}
      </div>
    </>
  );
};

export default CreateLeagueRosterForm;
