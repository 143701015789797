import { AppQueryConfig } from "QueryTypes";
import { reduxQueryDefaultOptions } from "utils/queries/queryHelpers";
import { SimulatorReservation, SimulatorReservationPayload } from "Models";

const urls = {
  simulatorReservationsBase(): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/simulator-reservations/`;
  },
  simulatorReservationById(simulatorReservationId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/simulator-reservations/${simulatorReservationId}`;
  },
  getSimulatorReservations(
    facilityId: string,
    startTime?: string,
    endTime?: string
  ): string {
    return startTime && endTime
      ? `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facilities/${facilityId}/simulator-reservations?startTime=${startTime}&endTime=${endTime}`
      : `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facilities/${facilityId}/simulator-reservations`;
  },
};

export const makeCreateSimulatorReservationMutation = (
  facilityId: string,
  body: SimulatorReservationPayload
): AppQueryConfig => ({
  url: urls.simulatorReservationsBase(),
  queryKey: `createSimulatorReservation:${facilityId}`,
  options: {
    ...reduxQueryDefaultOptions(),
    method: "POST",
  },
  body,
  transform: (responseJson: SimulatorReservation) => {
    return {
      simulatorReservations: {
        byFacilityId: {
          [facilityId]: { [responseJson.id]: responseJson },
        },
      },
    };
  },
  update: {
    simulatorReservations: (oldValue, newValue) => ({
      ...oldValue,
      byFacilityId: {
        ...oldValue?.byFacilityId,
        ...{
          [facilityId]: {
            ...oldValue?.byFacilityId?.[facilityId],
            ...newValue.byFacilityId[facilityId],
          },
        },
      },
    }),
  },
});

export const getSimulatorReservationsByFacilityId = (
  facilityId: string,
  startTime?: string,
  endTime?: string
): AppQueryConfig => ({
  url: urls.getSimulatorReservations(facilityId, startTime, endTime),
  queryKey:
    startTime && endTime
      ? `getSimulatorReservations:${facilityId}-${startTime}-${endTime}`
      : `getSimulatorReservations:${facilityId}`,
  options: {
    ...reduxQueryDefaultOptions(),
    method: "GET",
  },
  transform: (responseJson: SimulatorReservation[]) => {
    let obj: { [id: string]: SimulatorReservation } = {};
    responseJson.map((sim) => (obj[sim.id] = sim));
    return {
      simulatorReservations: {
        byFacilityId: {
          [facilityId]: obj,
        },
      },
    };
  },
  update: {
    simulatorReservations: (oldValue, newValue) => ({
      ...oldValue,
      byFacilityId: {
        ...oldValue?.byFacilityId,
        ...{
          [facilityId]: {
            ...oldValue?.byFacilityId?.[facilityId],
            ...newValue.byFacilityId[facilityId],
          },
        },
      },
    }),
  },
});

export const makeDeleteSimulatorReservationMutation = (
  simulatorReservationId: string,
  facilityId: string
): AppQueryConfig => ({
  url: urls.simulatorReservationById(simulatorReservationId),
  queryKey: `deleteSimulatorReservation:${simulatorReservationId}`,
  options: {
    ...reduxQueryDefaultOptions(),
    method: "DELETE",
  },
  optimisticUpdate: {
    simulatorReservations: (reduxState) => {
      delete reduxState.byFacilityId[facilityId][simulatorReservationId];
      return reduxState;
    },
  },
});

export const makeUpdateSimulatorReservationMutation = (
  simulatorReservationId: string,
  facilityId: string,
  body: SimulatorReservationPayload
): AppQueryConfig => ({
  url: urls.simulatorReservationById(simulatorReservationId),
  queryKey: `makeUpdateSimulatorReservationMutation:${simulatorReservationId}`,
  options: {
    ...reduxQueryDefaultOptions(),
    method: "PATCH",
  },
  body,
  transform: (responseJson: SimulatorReservation) => {
    return {
      simulatorReservations: {
        byFacilityId: {
          [facilityId]: { [responseJson.id]: responseJson },
        },
      },
    };
  },
  update: {
    simulatorReservations: (oldValue, newValue) => ({
      ...oldValue,
      byFacilityId: {
        ...oldValue?.byFacilityId,
        ...{
          [facilityId]: {
            ...oldValue?.byFacilityId?.[facilityId],
            ...newValue.byFacilityId[facilityId],
          },
        },
      },
    }),
  },
});

export const makeSendSimulatorReservationToSimMutation = (
  simulatorReservationId: string
): AppQueryConfig => ({
  url: urls.simulatorReservationById(simulatorReservationId),
  options: {
    ...reduxQueryDefaultOptions(),
    method: "POST",
  },
});
