import { LeagueType } from "types/enums";
import { styles } from "./Leagues.styes";
import { LeagueLeaderboardPlayerDto } from "Models";

type LeagueScorecardProps = {
  callback?: React.Dispatch<React.SetStateAction<boolean>>;
  leaderboardPlayer: LeagueLeaderboardPlayerDto;
  leagueType: LeagueType;
};

const LeagueScorecard: React.FC<LeagueScorecardProps> = (props) => {
  const { leaderboardPlayer, leagueType } = props;

  return (
    <div onClick={(e) => e.stopPropagation()} className={`w-full`}>
      <div
        key={leaderboardPlayer.playerGuid}
        className={`${styles.modalResultsRow}dark:text-gray-200
        `}
      >
        <div className="col-span-1">
          {leaderboardPlayer.placeDisplay || leaderboardPlayer.place}
        </div>
        <div className="col-span-3">
          {leagueType === LeagueType.TEAM
            ? leaderboardPlayer?.teamName
            : leaderboardPlayer.playerName}
        </div>
        <div className="hidden md:inline-block col-span-2"></div>
        <div className="hidden md:inline-block col-span-2"></div>

        <div className="col-span-2 pl-2">
          <span className="border-1 border-transparent rounded-lg w-20 text-xs font-normal p-1">
            {leaderboardPlayer.totalPoints}
          </span>
        </div>

        <div className="col-span-1"></div>
        <div className="col-span-1 items-center text-right pr-2">
          <div className={styles.showScorecardButton}></div>
        </div>
      </div>
    </div>
  );
};

export default LeagueScorecard;
