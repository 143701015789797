import { useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  showCustomNotification,
  GenericNotificationType,
} from "utils/notifications/notificationHelpers";
import {
  useCreateEvent,
  useSelectedEvent,
  useUpdateEvent,
} from "modules/events/eventHooks";
import { ButtonWithIcon } from "components/ui/form";
import {
  convertObjectToFormData,
  eventDefaults,
  formatEventPayloadToObject,
} from "modules/events/eventHelpers";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import CreateEventFormRoundContainer from "./CreateEventFormRoundContainer";
import CreateEventFormEventOptionContainer from "./CreateEventFormEventOptionContainer";
import CreateEventFormCompetitionOptionContainer from "./CreateEventFormCompetitionOptionContainer";
import { FaArrowRight } from "react-icons/fa";
import { useAppDispatch } from "utils/hooks";
import { createEventTrayAction } from "ui-modules/nav/navSlice";
import Row from "components/ui/layout/Row";

const CreateEventForm = () => {
  const dispatch = useAppDispatch();
  const currentFacilityId = useGetCurrentLocalFacilityId();
  const selectedEvent = formatEventPayloadToObject(
    useSelectedEvent(currentFacilityId)
  );

  const methods = useForm({
    defaultValues: selectedEvent || eventDefaults(),
  });

  const [eventImage, setEventImage] = useState<Blob | MediaSource>();

  const [createEventQueryState, createEvent] = useCreateEvent(
    currentFacilityId
  );
  const [updateEventQueryState, updateEvent] = useUpdateEvent(
    selectedEvent?.id || 0,
    currentFacilityId
  );

  const onSubmit = async (formData: any) => {
    const data = convertObjectToFormData(formData, eventImage);

    if (Object.keys(selectedEvent).length > 0) {
      await updateEvent(data);
    } else {
      await createEvent(data);
    }
  };

  useEffect(() => {
    if (createEventQueryState.status === 400) {
      showCustomNotification(
        "An error occurred while updating " + methods.getValues("name"),
        GenericNotificationType.ERROR
      );
    }
    if (
      createEventQueryState.isFinished &&
      createEventQueryState.status === 200
    ) {
      showCustomNotification(
        methods.getValues("name") + " created successfully.",
        GenericNotificationType.SUCCESS
      );
      dispatch(createEventTrayAction({ isOpen: false }));
    }
  }, [createEventQueryState, methods, dispatch]);

  useEffect(() => {
    if (
      updateEventQueryState.isFinished &&
      updateEventQueryState.status === 200
    ) {
      showCustomNotification(
        methods.getValues("name") + " updated successfully.",
        GenericNotificationType.SUCCESS
      );
      dispatch(createEventTrayAction({ isOpen: false }));
    }
  }, [updateEventQueryState, methods, dispatch]);

  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          data-testid="createEventForm"
          className="mt-8"
        >
          <CreateEventFormEventOptionContainer callback={setEventImage} />
          <CreateEventFormCompetitionOptionContainer />
          <CreateEventFormRoundContainer />
          <Row cssClasses="mb-8">
            <ButtonWithIcon
              buttonType="submit"
              text={
                Object.keys(selectedEvent).length > 0
                  ? "Update Event"
                  : "Create Event"
              }
              showIcon={true}
              icon={FaArrowRight}
              fullWidth={true}
              disabled={
                createEventQueryState.isPending ||
                updateEventQueryState.isPending
              }
            />
          </Row>
        </form>
      </FormProvider>
    </>
  );
};
export default CreateEventForm;
