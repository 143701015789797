import { styles } from "./Timer.styles";
import { SimulatorState } from "Models";
import { TimerAction, TimerStatus } from "types/enums";
import { FiPause, FiPlay, FiPlus } from "react-icons/fi";
import { MdOutlineTimerOff } from "react-icons/md";
import { useAppDispatch } from "utils/hooks";
import { modalAction } from "ui-modules/modals/modalSlice";
import { modalName, ModalType } from "utils/modals/modalHelpers";
import ReactTooltip from "react-tooltip";
import { sendTimerToSimulator } from "modules/simulator";
import {
  showResponseNotification,
  notificationMessageGroup,
} from "utils/notifications/notificationHelpers";
import {
  pendoTrackBasicEvent,
  ClubhouseEvents,
} from "utils/pendo/pendoHelpers";
import { updateEntities } from "redux-query";
import { TimerValueArray } from "utils/timer/timerHelpers";

type TimerIconControlsProps = {
  simulatorState: SimulatorState;
};

const TimerIconControls: React.FC<TimerIconControlsProps> = ({
  simulatorState,
}) => {
  const dispatch = useAppDispatch();

  const simControlModalName = modalName(
    ModalType.SimControlModal,
    simulatorState.id
  );
  const paused = simulatorState?.timerStatus === TimerStatus.PAUSED;

  const setModal = (modalName: string) =>
    dispatch(modalAction({ isOpen: true, modalName }));

  const handlePauseTimer = async () => {
    const response = sendTimerToSimulator(
      TimerAction[TimerAction.PAUSE],
      simulatorState.id,
      undefined
    );
    await showResponseNotification(
      response,
      notificationMessageGroup.pauseTimer
    );
    await updateReduxTimerStatus(TimerStatus.PAUSED);
    pendoTrackBasicEvent(ClubhouseEvents.PAUSE_TIMER, simulatorState.id);
  };

  const handleUnpauseTimer = async () => {
    const response = sendTimerToSimulator(
      TimerAction[TimerAction.UNPAUSE],
      simulatorState.id,
      undefined
    );

    await showResponseNotification(
      response,
      notificationMessageGroup.unpauseTimer
    );
    await updateReduxTimerStatus(TimerStatus.RUNNING);
    pendoTrackBasicEvent(ClubhouseEvents.UNPAUSE_TIMER, simulatorState.id);
  };

  const handleClearTimer = async () => {
    const response = sendTimerToSimulator(
      TimerAction[TimerAction.CLEAR_DISABLE],
      simulatorState.id,
      undefined
    );
    await showResponseNotification(
      response,
      notificationMessageGroup.clearTimer
    );
    await updateReduxTimerStatus(TimerStatus.CLEARED, [0, 0, 0]);
    pendoTrackBasicEvent(ClubhouseEvents.CLEAR_TIMER, simulatorState.id);
  };

  const updateReduxTimerStatus = async (
    status: TimerStatus,
    timerValue?: TimerValueArray
  ) => {
    dispatch(
      updateEntities({
        simulatorStates: (prev) => ({
          ...prev,
          ...{
            [simulatorState.id]: {
              ...prev[simulatorState.id],
              ...{
                timerStatus: status,
                timerValue: timerValue
                  ? timerValue
                  : prev[simulatorState.id]?.timerValue,
              },
            },
          },
        }),
      })
    );
  };

  return (
    <div className={styles.timerIconControlsContainer}>
      <div className={styles.timerIconButtonContainer}>
        <button onClick={() => setModal(simControlModalName)}>
          <FiPlus
            className={`${styles.timerButtonIcon} hover:bg-green-600`}
            data-tip="Add Time"
            data-for="timerIconTooltip"
            data-padding="10px"
          />
        </button>
      </div>
      <div className={styles.timerIconButtonContainer}>
        <button onClick={() => handleUnpauseTimer()} className="">
          <FiPlay
            className={`${styles.timerButtonIcon} hover:bg-yellow-400   ${
              paused ? "" : "hidden"
            }`}
            data-tip="Unpause Timer"
            data-for="timerIconTooltip"
            data-padding="10px"
          />
        </button>
        <button onClick={() => handlePauseTimer()}>
          <FiPause
            className={`${styles.timerButtonIcon} hover:bg-yellow-400 ${
              paused ? "hidden" : ""
            }`}
            data-tip="Pause Timer"
            data-for="timerIconTooltip"
            data-padding="10px"
          />
        </button>
      </div>
      <div className={styles.timerIconButtonContainer}>
        <>
          <button onClick={() => handleClearTimer()}>
            <MdOutlineTimerOff
              className={`${styles.timerButtonIcon} hover:bg-red-400`}
              data-tip="Clear Timer"
              data-for="timerIconTooltip"
              data-padding="10px"
            />
          </button>
          <ReactTooltip
            place="bottom"
            delayShow={200}
            backgroundColor="#f9fafb"
            textColor="#4b5563"
            border={true}
            borderColor="#d1d5db"
            id="timerIconTooltip"
            className="!text-xs"
          />
        </>
      </div>
    </div>
  );
};
export default TimerIconControls;
