import { FaPlus } from "react-icons/fa";
import { useSelector } from "react-redux";
import { RootState } from "StoreTypes";
import { createEventTrayAction } from "ui-modules/nav/navSlice";
import { useAppDispatch } from "utils/hooks";
import { styles } from "./Events.styles";

const CreateEventButton = () => {
  const dispatch = useAppDispatch();

  const isCreateEventTrayOpen = useSelector(
    (state: RootState) => state.nav?.createEventTray?.isOpen
  );

  const setCreateEventTray = () => {
    if (!isCreateEventTrayOpen)
      dispatch(createEventTrayAction({ isOpen: !isCreateEventTrayOpen }));
  };

  return (
    <>
      <button
        className={styles.createButton}
        onClick={() => setCreateEventTray()}
        id="btnOpenCreateNewRoundTray"
        data-testid="btnOpenCreateNewRoundTray"
      >
        <span className="sr-only">Create Event Button</span>
        <FaPlus />
      </button>
    </>
  );
};
export default CreateEventButton;
