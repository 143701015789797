import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Provider as ReduxQueryProvider } from "redux-query-react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store/rootReducer";
import { RootState } from "StoreTypes";
import replaceAllInserter from "string.prototype.replaceall";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import ReactGA from "react-ga4";

replaceAllInserter.shim();

export const getQueries = (state: RootState) => state.queries;
const launchDarklyClientId = `${process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID}`;

const nodeEnv = process.env.NODE_ENV;

ReactGA.initialize("G-0N3NVVZ4BM", {
  testMode: nodeEnv !== "production" ? true : false,
});

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: launchDarklyClientId,
    reactOptions: {
      useCamelCaseFlagKeys: true,
    },
  });
  return ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <ReduxQueryProvider queriesSelector={getQueries}>
          <LDProvider>
            <App />
          </LDProvider>
        </ReduxQueryProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
