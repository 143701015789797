import { UseDataInfo } from "QueryTypes";
import { QueryState } from "redux-query";
import { useSelector } from "react-redux";
import { EntitiesState, RootState } from "StoreTypes";
import { leagueQueryDefaultOptions } from "./leagueHelpers";
import { useSelectedLeagueRoundId } from "./leagueRoundHooks";
import { RunMutation, useMutation, useRequest } from "redux-query-react";
import {
  AssignAndRankScorecardsRequestDto,
  GameScorecard,
  LeagueScorecardPayload,
  TeamScorecardDto,
} from "ScorecardModels";
import {
  LeagueLeaderboardDto,
  PointScorecardsDto,
  RankedScorecardsDto,
  RoundLeaderboardDto,
  ScorecardToRoundPayload,
  ScorecardValues,
} from "Models";
import {
  getLeagueLeaderboardByLeagueId,
  getLeagueScorecardsByRound,
  getRankedRoundScorecards,
  getRoundLeaderboardByRoundId,
  patchApplyPointsToScorecards,
  putAssignScorecardToRound,
  putAutoAssignScorecardsToRound,
  putRankRoundScorecards,
  putUpdateRoundScorecard,
} from "./leagueScorecardQueries";
import axios from "axios";

export const useLeagueRoundLeaderboardByRoundId = (
  facilityId: string,
  leagueId: string,
  roundId: string
): UseDataInfo<RoundLeaderboardDto> => {
  const [queryState, refreshLeagueRoundLeaderboard] = useRequest(
    getRoundLeaderboardByRoundId(facilityId, leagueId, roundId)
  );
  const leagueRoundLeaderboard = useSelector(
    (state: RootState): RoundLeaderboardDto => {
      if (!state.entities.leagueRoundLeaderboard?.byRoundId?.[roundId])
        return {} as RoundLeaderboardDto;

      let leagueRoundScorecard =
        state.entities?.leagueRoundLeaderboard?.byRoundId?.[roundId];

      return leagueRoundScorecard;
    }
  );

  return [leagueRoundLeaderboard, queryState, refreshLeagueRoundLeaderboard];
};

export const useLeagueRoundRankedScorecards = (
  facilityId: string,
  leagueId: string,
  roundId: string
): UseDataInfo<RankedScorecardsDto> => {
  const [queryState, refreshLeagueRoundRankedScorecards] = useRequest(
    getRankedRoundScorecards(facilityId, leagueId, roundId)
  );
  const leagueRoundRankedScorecards = useSelector(
    (state: RootState): RankedScorecardsDto => {
      if (!state.entities.leagueRoundRankedScorecards?.byRoundId?.[roundId])
        return {} as RankedScorecardsDto;

      let leagueRoundRankedScorecards =
        state.entities?.leagueRoundRankedScorecards?.byRoundId?.[roundId];

      return leagueRoundRankedScorecards;
    }
  );

  return [
    leagueRoundRankedScorecards,
    queryState,
    refreshLeagueRoundRankedScorecards,
  ];
};

export const useRankLeagueRoundScorecards = (
  facilityId: string,
  leagueId: string,
  roundId: string
): UseDataInfo<RankedScorecardsDto> => {
  const [queryState, refreshLeagueRoundRankedScorecards] = useRequest(
    putRankRoundScorecards(facilityId, leagueId, roundId)
  );
  const leagueRoundRankedScorecards = useSelector(
    (state: RootState): RankedScorecardsDto => {
      if (!state.entities.leagueRoundRankedScorecards?.byRoundId?.[roundId])
        return {} as RankedScorecardsDto;

      let leagueRoundRankedScorecards =
        state.entities?.leagueRoundRankedScorecards?.byRoundId?.[roundId];

      return leagueRoundRankedScorecards;
    }
  );

  return [
    leagueRoundRankedScorecards,
    queryState,
    refreshLeagueRoundRankedScorecards,
  ];
};

export const useAddScorecardToRound = (
  facilityId: string,
  leagueId: string,
  roundId: string
): [QueryState, RunMutation<EntitiesState, [ScorecardToRoundPayload]>] => {
  const [
    addScorecardToRoundQueryState,
    addScorecardToRound,
  ] = useMutation((body: ScorecardToRoundPayload) =>
    putAssignScorecardToRound(facilityId, leagueId, roundId, body)
  );

  return [addScorecardToRoundQueryState, addScorecardToRound];
};

export const useAutoAddScorecardsToRound = (
  facilityId: string,
  leagueId: string,
  roundId: string
): [
  QueryState,
  RunMutation<EntitiesState, [AssignAndRankScorecardsRequestDto]>
] => {
  const [
    addScorecardToRoundQueryState,
    addScorecardToRound,
  ] = useMutation((body: AssignAndRankScorecardsRequestDto) =>
    putAutoAssignScorecardsToRound(facilityId, leagueId, roundId, body)
  );

  return [addScorecardToRoundQueryState, addScorecardToRound];
};

export const useLeaugeScorecardsByRound = (
  facilityId: string,
  leagueId: string,
  roundId: string
): UseDataInfo<GameScorecard[]> => {
  const [queryState, refreshScorecards] = useRequest(
    getLeagueScorecardsByRound(facilityId, leagueId, roundId)
  );
  const scorecards = useSelector((state: RootState): GameScorecard[] => {
    if (!state.entities.scorecards?.byFacilityId?.[facilityId]) return [];
    return Object.values(
      state.entities?.scorecards?.byFacilityId?.[facilityId]
    ).sort(
      (a: GameScorecard, b: GameScorecard) =>
        new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
    );
  });

  return [scorecards, queryState, refreshScorecards];
};

export const useApplyPointsToScorecards = (
  facilityId: string,
  leagueId: string,
  roundId: string
): [QueryState, RunMutation<EntitiesState, [PointScorecardsDto]>] => {
  const [
    applyPointsToScorecardsQueryState,
    applyPointsToScorecards,
  ] = useMutation((body: PointScorecardsDto) =>
    patchApplyPointsToScorecards(facilityId, leagueId, roundId, body)
  );

  return [applyPointsToScorecardsQueryState, applyPointsToScorecards];
};

export const useUpdateRoundScorecard = (
  facilityId: string,
  leagueId: string,
  roundId: string,
  scorecardId: string,
  body: ScorecardValues
): [QueryState, RunMutation<EntitiesState, [ScorecardValues]>] => {
  const [
    updateRoundScorecardsQueryState,
    updateRoundScorecards,
  ] = useMutation((body: ScorecardValues) =>
    putUpdateRoundScorecard(facilityId, leagueId, roundId, scorecardId, body)
  );

  return [updateRoundScorecardsQueryState, updateRoundScorecards];
};

export const useLeagueLeaderboardByLeagueId = (
  facilityId: string,
  leagueId: string
): UseDataInfo<LeagueLeaderboardDto> => {
  const [queryState, refreshLeagueLeaderboard] = useRequest(
    getLeagueLeaderboardByLeagueId(facilityId, leagueId)
  );
  const leagueLeaderboard = useSelector(
    (state: RootState): LeagueLeaderboardDto => {
      if (!state.entities.leagueLeaderboard?.byLeagueId?.[leagueId])
        return {} as LeagueLeaderboardDto;

      let leagueLeaderboard =
        state.entities?.leagueLeaderboard?.byLeagueId?.[leagueId];

      return leagueLeaderboard;
    }
  );

  return [leagueLeaderboard, queryState, refreshLeagueLeaderboard];
};

export const useGetTeamScorecardByRoundTeamId = (roundTeamId: string) => {
  const selectedLeagueRoundId = useSelectedLeagueRoundId();

  const roundTeamScorecard = useSelector(
    (state: RootState): RoundLeaderboardDto => {
      if (
        !state.entities.leagueRoundLeaderboard?.byRoundId?.[
          selectedLeagueRoundId
        ]
      )
        return {} as RoundLeaderboardDto;

      let leagueLeaderboardTeams =
        state.entities?.leagueRoundLeaderboard?.byRoundId?.[
          selectedLeagueRoundId
        ];

      return leagueLeaderboardTeams;
    }
  );

  let selectedTeamScorecard = {} as TeamScorecardDto;
  let leagueLeaderboardTeams = roundTeamScorecard?.teams as TeamScorecardDto[];

  leagueLeaderboardTeams?.map((teamScorecard: TeamScorecardDto) => {
    if (teamScorecard?.roundTeamId === roundTeamId) {
      selectedTeamScorecard = teamScorecard;
    }
    return selectedTeamScorecard;
  });

  return selectedTeamScorecard;
};

export const getLeagueRoundScorecards = (
  facilityId: string,
  leagueId: string,
  roundId: string,
  body: LeagueScorecardPayload
) => {
  axios
    .get<GameScorecard>(
      `${process.env.REACT_APP_HTTPS_PROTOCOL}${
        process.env.REACT_APP_BASE_URL
      }/api/facility/${facilityId}/league/${leagueId}/round/${roundId}/scorecards/search${
        body?.playerName ? `?playerName=${body?.playerName}` : ""
      }`,
      {
        ...leagueQueryDefaultOptions(),
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};
