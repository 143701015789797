export const styles = {
  label: "text-sm text-gray-500 dark:text-gray-200 float-left py-1",
  checkbox:
    "text-brandGreen rounded focus:ring-0 ring-opacity-0 border-2 border-gray-500 text-md mr-2 mb-0.5",
  createButton:
    "float-right bg-white dark:bg-zinc-900 border border-gray-200 hover:bg-brandGreen hover:text-white dark:hover:bg-green-600 text-gray-400 dark:text-gray-200 text-xs w-8 h-8 rounded-full shadow-sm hover:animate-pulse",
  refreshButton:
    "float-right bg-white dark:bg-zinc-900 border border-gray-200 hover:bg-brandGreen hover:text-white dark:hover:bg-green-600 text-gray-400 dark:text-gray-200 text-xs w-8 h-8 rounded-full shadow-sm hover:animate-pulse mr-1",
  simulatorReservationListItem:
    "py-2 hover:border-gray-300 hover:cursor-pointer w-full",
  simulatorReservationListItemHighlight:
    "py-2 hover:cursor-pointer w-full bg-gray-100 dark:bg-zinc-600 ",
  simulatorReservationQueueContainer:
    "px-4 py-4 sm:px-6 poppins dark:text-white",
  simulatorReservationQueueTopRow: "flex items-center justify-between",
  simulatorReservationQueueBottomRow: "ml-2 flex-shrink-0 flex",
  simulatorReservationTime:
    "text-xs text-gray-800 dark:text-white font-semibold brand-heading",
  simulatorReservationName:
    "px-2 inline-flex text-xs leading-5 brand-heading text-gray-800 dark:text-white",
  simulatorReservationPlayers:
    "flex items-center text-xs text-gray-400 dark:text-white brand-subheading text-normal",
  simulatorReservationOpenItem:
    "mt-1 flex items-center text-xs text-gray-600 pr-0",
  dailyQueueContainer: "h-full mt-8 md:mt-0",
  dailyQueueItemsContainer: "rounded-md shadow-sm bg-gray-100 w-full",
  dailyQueueItemsList: "relative z-0 divide-y divide-gray-200 mb-8 w-full",
  simulatorReservationSelectDayOptionContainer:
    "flex items-center mt-6 align-right w-full",
  simulatorReservationSelectDayOptionInnerContainer:
    "flex flex-row bg-white dark:bg-zinc-900 p-1 text-sm text-gray-500 leading-none border border-gray-200 dark:border-zinc-600 rounded-xl w-full",
  simulatorReservationSelectDayOptionLeftButton:
    "flex w-1/2 items-center justify-center rounded-l-md border border-r-0 py-1.5 pl-3 pr-4 focus:relative md:w-1/2 md:px-2 md:hover:bg-gray-50 text-xs",
  simulatorReservationSelectDayOptionRightButton:
    "flex w-1/2 items-center justify-center rounded-r-md border border-l-0 py-1.5 pl-4 pr-3 focus:relative md:w-1/2 md:px-2 md:hover:bg-gray-50 text-xs",
  simulatorReservationSelectDayOptionSpan:
    "relative -mx-px h-9 w-px bg-gray-300",
  queueActiveDay:
    "flex items-center poppins justify-items-center content-center w-1/2 place-content-center text-sm bg-brandGreen text-gray-50 dark:text-gray-200 dark:bg-zinc-800 rounded-md transition-colors duration-300 ease-in focus:outline-none px-4 py-2",
  queueInactiveDay:
    "flex items-center poppins justify-items-center content-center w-1/2 place-content-center text-sm items-center dark:bg-zinc-900 text-zinc-800 dark:text-gray-50 transition-colors duration-100 ease-out focus:outline-none rounded-r-md px-4 py-2",
  queuePreviewRightColumn: "text-right text-gray-400 poppins py-2",
  queuePreviewRow:
    "border-b border-gray-200 dark:border-zinc-800 dark:text-white",
};
