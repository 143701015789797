import { SimulatorReservation, SimulatorReservationPayload } from "Models";
import {
  getSimulatorReservationsByFacilityId,
  makeCreateSimulatorReservationMutation,
  makeUpdateSimulatorReservationMutation,
} from "modules/simulator-reservations/simulatorReservationQueries";
import { UseDataInfo } from "QueryTypes";
import { useSelector } from "react-redux";
import { QueryState, EntitiesState } from "redux-query";
import { RunMutation, useMutation, useRequest } from "redux-query-react";
import { RootState } from "StoreTypes";
import { simulatorReservationIsWithinTimeRange } from "./simulatorReservationHelpers";
import {
  makeDeleteSimulatorReservationMutation,
  makeSendSimulatorReservationToSimMutation,
} from "./simulatorReservationQueries";
import { SendRoundRangeToSimInfo } from "Messages";

export const useCreateSimulatorReservation = (
  facilityId: string
): [QueryState, RunMutation<EntitiesState, [SimulatorReservationPayload]>] => {
  const [
    createSimulatorReservationQueryState,
    createSimulatorReservation,
  ] = useMutation((body: SimulatorReservationPayload) =>
    makeCreateSimulatorReservationMutation(facilityId, body)
  );

  return [createSimulatorReservationQueryState, createSimulatorReservation];
};

export const useSimulatorReservationsByFacilityId = (
  facilityId: string,
  startTime?: string,
  endTime?: string
): UseDataInfo<SimulatorReservation[]> => {
  const [queryState, refreshSimulatorReservations] = useRequest(
    getSimulatorReservationsByFacilityId(facilityId, startTime, endTime)
  );

  const simulatorReservations = useSelector(
    (state: RootState): SimulatorReservation[] => {
      if (!state.entities.simulatorReservations?.byFacilityId?.[facilityId])
        return [];

      let simulatorReservations: SimulatorReservation[] =
        startTime && endTime
          ? Object.values<SimulatorReservation>(
              state.entities?.simulatorReservations?.byFacilityId?.[facilityId]
            ).filter((simulatorReservation) =>
              simulatorReservationIsWithinTimeRange(
                simulatorReservation,
                startTime,
                endTime
              )
            )
          : Object.values<SimulatorReservation>(
              state.entities?.simulatorReservations?.byFacilityId?.[facilityId]
            );

      return simulatorReservations.sort(
        (a: SimulatorReservation, b: SimulatorReservation) =>
          new Date(a.startTime).valueOf() - new Date(b.startTime).valueOf()
      );
    }
  );

  return [simulatorReservations, queryState, refreshSimulatorReservations];
};

export const useDeleteSimulatorReservations = (
  reservationId: string,
  facilityId: string
): [QueryState, RunMutation<EntitiesState>] => {
  const [queryState, deleteSimulatorReservation] = useMutation(() =>
    makeDeleteSimulatorReservationMutation(reservationId, facilityId)
  );
  return [queryState, deleteSimulatorReservation];
};

export const useUpdateSimulatorReservation = (
  reservationId: string,
  facilityId: string
): [QueryState, RunMutation<EntitiesState, [SimulatorReservationPayload]>] => {
  const [
    queryState,
    updateSimulatorReservation,
  ] = useMutation((body: SimulatorReservationPayload) =>
    makeUpdateSimulatorReservationMutation(reservationId, facilityId, body)
  );

  return [queryState, updateSimulatorReservation];
};

export const useSendSimulatorReservationToSim = (
  simulatorReservationId: string
): [QueryState, RunMutation<EntitiesState, [string]>] => {
  const [
    senddToSimulatorQueryState,
    sendSimulatorReservation,
  ] = useMutation(() =>
    makeSendSimulatorReservationToSimMutation(simulatorReservationId)
  );

  return [senddToSimulatorQueryState, sendSimulatorReservation];
};

export const useSelectedSimulatorReservation = (facilityId: string) => {
  return useSelector(
    (state: RootState): SendRoundRangeToSimInfo => {
      let selectedSimulatorReservation =
        state.simulatorReservation?.selectedSimulatorReservation;
      return selectedSimulatorReservation;
    }
  );
};

export const useSelectedQueueItem = (facilityId: string) => {
  return useSelector(
    (state: RootState): SimulatorReservation => {
      let selectedQueueItemId = state.queue?.selectedQueueItem;

      return state.entities?.simulatorReservations?.byFacilityId?.[
        facilityId
      ]?.[selectedQueueItemId];
    }
  );
};
