import { PlayerUser } from "Models";
import { styles } from "./RosterAndGroups.styles";
import { FormatOptionLabelContext } from "react-select/src/Select";
import { formatTeamMemberPhone } from "modules/leagues/leagueHelpers";

const FormattedRosterSearchResults = ({
  value,
  context,
}: {
  value: PlayerUser;
  context: FormatOptionLabelContext;
}) => {
  return context === "menu" ? (
    <div className="flex flex-row w-full">
      {value.displayName ? (
        <div className="flex flex-col w-2/5">
          <div className="flex">{value.displayName}</div>
          <div className="flex text-gray-200 text-[10px]">
            {value.phoneNumber && formatTeamMemberPhone(value.phoneNumber)}
          </div>
        </div>
      ) : null}
      {value.email ? (
        <div className="flex text-gray-500 w-2/5 items-center">
          {value.email}
        </div>
      ) : null}
      <div className="flex w-1/5 items-center">
        <span className={styles.clickToAddButton}>Click to add</span>
      </div>
    </div>
  ) : (
    <div>{value.displayName || value.email}</div>
  );
};
export default FormattedRosterSearchResults;
