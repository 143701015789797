import { FaPlus } from "react-icons/fa";
import { useAppDispatch } from "utils/hooks";
import { styles } from "./SimulatorReservation.styles";
import { queueItemTrayAction } from "ui-modules/nav/navSlice";
import { modalAction } from "ui-modules/modals/modalSlice";
import { ModalType } from "utils/modals/modalHelpers";

const CreateQueueItemButton = () => {
  const dispatch = useAppDispatch();

  const setCreateNewQueueItemTray = () => {
    dispatch(modalAction({ isOpen: true, modalName: ModalType.QueueModal }));
    dispatch(queueItemTrayAction({ isOpen: true }));
  };

  return (
    <>
      <button
        className={styles.createButton}
        onClick={() => setCreateNewQueueItemTray()}
        id="btnOpenCreateQueueItemTray"
        data-testid="btnOpenCreateQueueItemTray"
      >
        <span className="sr-only">Create New Reservation Button</span>
        <FaPlus className="" />
      </button>
    </>
  );
};
export default CreateQueueItemButton;
