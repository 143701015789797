import {
  getApiResponseErrorMessage,
  isApiStatusSuccess,
} from "utils/api/apiHelper";
import {
  ClubhouseEvents,
  pendoTrackBasicEvent,
  pendoTrackEventWithProperties,
} from "utils/pendo/pendoHelpers";
import {
  useCreateLeague,
  useDeleteLeague,
  useLeaguesByFacilityId,
  useSelectedLeague,
  useUpdateLeague,
} from "modules/leagues/leagueHooks";
import {
  GenericNotificationType,
  showCustomNotification,
} from "utils/notifications/notificationHelpers";
import { League } from "Models";
import { styles } from "./Leagues.styes";
import { useAppDispatch } from "utils/hooks";
import toast, { useToasterStore } from "react-hot-toast";
import {
  customStyles,
  datetimeStringEndOfDay,
  datetimeStringStartOfDay,
  enforceMinAndMaxScores,
  filterTime,
} from "utils/ui/uiHelpers";
import { FormRow } from "components/ui/form";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { ActionPromiseValue, EntitiesState } from "redux-query";
import { createLeagueTrayAction } from "ui-modules/nav/navSlice";
import { eventStartDatePassed } from "modules/events/eventHelpers";
import {
  HandicapMode,
  HandicapPercentage,
  LeagueHandicapMethod,
  LeagueHandicapMode,
  LeagueScoring,
  LeagueTeamSize,
  LeagueType,
  MatchPlayAssignment,
  MatchPlayStyle,
  MatchPlayTeamStyle,
  TeamPlayFormat,
} from "types/enums";
import {
  roundConfigEnumsAsReactSelectOptions,
  trueFalseOptions,
} from "utils/enums/enumHelper";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { leagueDefaults } from "modules/leagues/leagueHelpers";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Row from "components/ui/layout/Row";
import { TiInfoOutline } from "react-icons/ti";
import { v4 as uuidv4 } from "uuid";
import { ErrorMessage } from "@hookform/error-message";
import { MdWarningAmber } from "react-icons/md";
import { CgSpinner } from "react-icons/cg";
import { useFlags } from "launchdarkly-react-client-sdk";
import ConfirmDeleteAlert from "components/shared/ConfirmDeleteAlert";
import { modalName, ModalType } from "utils/modals/modalHelpers";
import { modalAction } from "ui-modules/modals/modalSlice";
import { setSelectedLeagueId } from "modules/leagues/leagueSlice";

const CreateLeagueContainer: React.FC<CreateLeagueContainerProps> = (props) => {
  const dispatch = useAppDispatch();
  const { scramble } = useFlags();

  const currentFacilityId = useGetCurrentLocalFacilityId();
  const toastIsActive = useToasterStore().toasts.length > 0 ? true : false;
  const selectedLeague = useSelectedLeague();

  const deleteConfirmModalName = modalName(
    `{${ModalType.ConfirmDeleteAlert}-league-${selectedLeague?.id}}`
  );

  const methods = useForm<League>({
    defaultValues: selectedLeague || leagueDefaults(currentFacilityId),
  });

  const [createLeagueQueryState, createLeague] = useCreateLeague(
    currentFacilityId
  );
  const [updateLeagueQueryState, updateLeague] = useUpdateLeague(
    currentFacilityId
  );

  const [, deleteLeague] = useDeleteLeague(
    selectedLeague?.id,
    currentFacilityId
  );
  const [, , refreshLeagues] = useLeaguesByFacilityId(currentFacilityId);

  const startDatePassed = eventStartDatePassed(selectedLeague?.leagueStartDate);
  const endDatePassed = eventStartDatePassed(selectedLeague?.leagueEndDate);
  const defaultGroupSize = methods.watch("settings.defaultGroupSize") || 4;

  const defaultGroupSizeOptions = [
    { label: "2", value: 2, teamSize: [2], scoring: [1, 2, 3] },
    { label: "3", value: 3, teamSize: [3], scoring: [1, 2, 3] },
    { label: "4", value: 4, teamSize: [2, 4], scoring: [1, 2, 3] },
    { label: "6", value: 6, teamSize: [2, 3], scoring: [3] },
    { label: "8", value: 8, teamSize: [4], scoring: [1, 2, 3] },
  ];

  function handleResponseCallback(league: League) {
    refreshLeagues();
    pendoTrackEventWithProperties(ClubhouseEvents.CREATE_LEAGUE, league);
    dispatch(createLeagueTrayAction({ isOpen: false }));

    if (!toastIsActive) {
      const toastMessage = selectedLeague
        ? `${league.name} has been updated successfully`
        : `${league.name} has been created successfully`;

      const toastId = selectedLeague
        ? `toast-updateLeague${uuidv4()}`
        : `toast-createLeague${uuidv4()}`;

      toast.success(toastMessage, { id: toastId });
    }
  }

  const onSubmit = async (data: League) => {
    let response = {} as ActionPromiseValue<EntitiesState> | undefined;

    if (selectedLeague) {
      response = await updateLeague(data);
      pendoTrackBasicEvent(ClubhouseEvents.LEAGUE_EDIT);
    } else {
      response = await createLeague(data);
      pendoTrackBasicEvent(ClubhouseEvents.LEAGUE_CREATE);
    }

    if (isApiStatusSuccess(response?.status)) {
      handleResponseCallback(data);
      dispatch(
        modalAction({
          isOpen: false,
          modalName: ModalType.LeaugeCreateEdit,
        })
      );
    } else {
      showCustomNotification(
        getApiResponseErrorMessage(response?.body),
        GenericNotificationType.ERROR
      );

      if (response?.body?.errors?.LeagueStartDate) {
        methods.setError("leagueStartDate", {
          message: response?.body?.errors?.LeagueStartDate,
        });
        document.getElementById("leagueStartDate")?.focus();
      }

      if (response?.body?.errors?.LeagueEndDate) {
        methods.setError("leagueEndDate", {
          message: response?.body?.errors?.LeagueEndDate,
        });
        document.getElementById("leagueEndDate")?.focus();
      }
    }
  };

  const handleMatchPlayGroupSize = () => {
    const isAsyncMatchPlay = methods.watch(
      "settings.allowAsynchronousMatchPlay"
    );
    const isTeamLeague =
      methods.watch("settings.leagueType") === LeagueType.TEAM;
    const leagueTeamSize = Number(methods.getValues("settings.leagueTeamSize"));

    if (isAsyncMatchPlay) {
      if (!isTeamLeague) {
        methods.setValue("settings.defaultGroupSize", 4);
        toast.success("Group size set to 4 for Individual League");
      } else {
        const leagueDefaultGroupSize = leagueTeamSize * 2;
        methods.setValue("settings.defaultGroupSize", leagueDefaultGroupSize);
      }
    } else if (
      !isAsyncMatchPlay &&
      methods.watch("settings.leagueType") === LeagueType.INDIVIDUAL &&
      methods.watch("settings.leagueScoringType") === LeagueScoring.MATCH_PLAY
    ) {
      methods.setValue("settings.defaultGroupSize", 2);
      toast.success("Group size set to 2 for Match Play League");
    }
  };

  const handleStartTime = (value: Date) => {
    methods.setValue("leagueStartDate", datetimeStringStartOfDay(value));
    methods.clearErrors("leagueStartDate");
  };

  const handleEndTime = (value: Date) => {
    if (value) {
      methods.setValue("leagueEndDate", datetimeStringEndOfDay(value));
      methods.clearErrors("leagueEndDate");
    }
  };

  const handleConfirmDeleteCallback = (value: boolean) => {
    let response;
    if (value) {
      response = deleteLeague();
      response?.then((res) => {
        if (isApiStatusSuccess(res?.status)) {
          showCustomNotification(
            "League deleted successfully",
            GenericNotificationType.SUCCESS
          );
          pendoTrackBasicEvent(ClubhouseEvents.LEAGUE_DELETE);
          dispatch(
            setSelectedLeagueId({
              selectedLeagueId: "",
            })
          );
          dispatch(
            modalAction({
              isOpen: false,
              modalName: ModalType.LeaugeCreateEdit,
            })
          );
        } else {
          showCustomNotification(
            "An error occurred",
            GenericNotificationType.ERROR
          );
        }
      });
    }
  };

  const setModalOpen = (modalName: string) =>
    dispatch(modalAction({ isOpen: true, modalName }));

  const handleDeleteLeagueClick = (
    e: React.SyntheticEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    setModalOpen(deleteConfirmModalName);
  };

  return (
    <div className="flex w-full h-full flex-col">
      <ConfirmDeleteAlert
        modalName={deleteConfirmModalName}
        bodyText={`Are you sure you want to delete ${selectedLeague?.name}?`}
        confirmButtonText="Delete"
        cancelButtonText="Cancel"
        callback={handleConfirmDeleteCallback}
      />
      <Row cssClasses="mb-8">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {startDatePassed && (
              <div className="text-center m-auto text-red-500 text-xs">
                <TiInfoOutline className="text-xl" /> Only certain fields are
                able to be edited for a league that is in progress.
              </div>
            )}
            <FormRow header="League Name" fontSize={styles.formRowLabel}>
              <input
                {...methods.register("name")}
                type="text"
                className={styles.inputClassName}
                required={true}
                id="createEditLeagueName"
                data-testid="createEditLeagueName"
                autoFocus
                placeholder="Enter League Name"
                onBlur={(e) => {
                  methods.setValue(
                    "name",
                    e.target.value.replace(/\s+/g, " ").trim()
                  );
                }}
                disabled={startDatePassed}
              />
            </FormRow>
            <FormRow header="League Description" fontSize={styles.formRowLabel}>
              <textarea
                {...methods.register("description")}
                className={styles.inputClassName}
                required={true}
                id="createEditLeagueDescription"
                data-testid="createEditLeagueDescription"
                disabled={endDatePassed}
                maxLength={1024}
              />
            </FormRow>
            <div className="flex flex-row pt-2">
              <div className="flex flex-col w-1/2 mr-4">
                <div className="flex w-full py-2 mb-2 pb-1 font-semibold text-sm">
                  League Start Date
                </div>
                <div className="w-full">
                  <ErrorMessage
                    errors={methods.formState.errors}
                    name="leagueStartDate"
                    render={({ message }) => (
                      <div className="text-sm text-red-500">{message}</div>
                    )}
                  />
                  <DatePicker
                    {...methods.register("leagueStartDate")}
                    onChange={(date: Date) => handleStartTime(date)}
                    onBlur={(e) =>
                      handleStartTime(new Date(e.target.value) as Date)
                    }
                    selected={
                      methods.watch("leagueStartDate") &&
                      new Date(methods.watch("leagueStartDate"))
                    }
                    showTimeSelect={false}
                    dateFormat="MMMM d, yyyy"
                    filterTime={filterTime}
                    className={`${styles.datePicker} w-full`}
                    minDate={new Date()}
                    placeholderText="Select Start Date"
                    id="leagueStartDate"
                    required={true}
                    disabled={startDatePassed}
                    autoComplete="off"
                    showPopperArrow={false}
                  />
                </div>
              </div>
              <div className="flex flex-col w-1/2">
                <div className="flex w-full py-2 mb-2 pb-1 font-semibold text-sm">
                  League End Date
                </div>
                <div className="w-full">
                  <ErrorMessage
                    errors={methods.formState.errors}
                    name="leagueEndDate"
                    render={({ message }) => (
                      <div className="text-sm text-red-500">{message}</div>
                    )}
                  />
                  <DatePicker
                    {...methods.register("leagueEndDate")}
                    onChange={(date: Date) => handleEndTime(date)}
                    onBlur={(e) => {
                      if (e.target.value) {
                        handleEndTime(new Date(e.target.value) as Date);
                      }
                    }}
                    selected={
                      methods.watch("leagueEndDate") &&
                      new Date(methods.watch("leagueEndDate"))
                    }
                    showTimeSelect={false}
                    dateFormat="MMMM d, yyyy"
                    filterTime={filterTime}
                    className={`${styles.datePicker} w-full`}
                    minDate={new Date()}
                    placeholderText="Select End Date"
                    id="leagueEndDate"
                    required={true}
                    disabled={endDatePassed}
                    autoComplete="off"
                    showPopperArrow={false}
                  />
                </div>
              </div>
            </div>
            <FormRow header="League Type" fontSize={styles.formRowLabel}>
              <Controller
                name="settings.leagueType"
                control={methods.control}
                rules={{ required: "League type is required" }}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={roundConfigEnumsAsReactSelectOptions.leagueType}
                    defaultValue={
                      roundConfigEnumsAsReactSelectOptions.leagueType[0]
                    }
                    value={roundConfigEnumsAsReactSelectOptions.leagueType.find(
                      (c) =>
                        c.value === methods.getValues("settings.leagueType")
                    )}
                    onChange={(val: { label: string; value: LeagueType }) => {
                      methods.setValue("settings.leagueType", val.value);
                      if (val.value === LeagueType.INDIVIDUAL) {
                        methods.setValue(
                          "settings.allowAsynchronousTeamPlay",
                          null
                        );
                      } else {
                        methods.setValue(
                          "settings.allowAsynchronousTeamPlay",
                          true
                        );
                      }
                    }}
                    styles={customStyles}
                    required={true}
                    id="createEditLeagueType"
                    isDisabled={startDatePassed}
                    clasName="valid:border-green-500"
                  />
                )}
              />
              <ErrorMessage
                errors={methods.formState.errors}
                name="settings.leagueType"
                render={({ message }) => (
                  <div className="text-sm text-red-500">{message}</div>
                )}
              />
            </FormRow>
            <FormRow header="League Format" fontSize={styles.formRowLabel}>
              <Controller
                name="settings.leagueScoringType"
                control={methods.control}
                rules={{ required: "League format is required" }}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={roundConfigEnumsAsReactSelectOptions.leagueScoring.filter(
                      (leagueScoringType) => {
                        if (
                          methods.watch("settings.leagueType") ===
                          LeagueType.INDIVIDUAL
                        ) {
                          if (
                            leagueScoringType.value !== LeagueScoring.SCRAMBLE
                          ) {
                            return true;
                          }
                        } else {
                          if (!scramble) {
                            if (
                              leagueScoringType.value !== LeagueScoring.SCRAMBLE
                            ) {
                              return true;
                            } else {
                              return false;
                            }
                          }
                          return true;
                        }
                        return false;
                      }
                    )}
                    value={roundConfigEnumsAsReactSelectOptions.leagueScoring.find(
                      (c) =>
                        c.value === methods.watch("settings.leagueScoringType")
                    )}
                    onChange={(val: {
                      label: string;
                      value: LeagueScoring;
                    }) => {
                      methods.setValue("settings.leagueScoringType", val.value);
                      if (
                        methods.watch("settings.leagueScoringType") ===
                        LeagueScoring.STROKE_PLAY
                      ) {
                        methods.setValue("settings.allowRepeatMatches", null);
                        methods.setValue(
                          "settings.allowAsynchronousMatchPlay",
                          undefined
                        );
                      } else if (
                        methods.watch("settings.leagueScoringType") ===
                        LeagueScoring.SCRAMBLE
                      ) {
                        methods.setValue(
                          "settings.allowAsynchronousTeamPlay",
                          false
                        );
                        methods.setValue(
                          "settings.handicapMethod",
                          LeagueHandicapMethod.GROSS
                        );
                        methods.setValue(
                          "settings.handicapMode",
                          HandicapMode.OFF
                        );
                        methods.setValue(
                          "settings.allowAsynchronousMatchPlay",
                          undefined
                        );
                        methods.setValue("settings.allowRepeatMatches", null);
                        methods.setValue(
                          "settings.allowAsynchronousTeamPlay",
                          null
                        );
                      } else {
                        methods.watch("settings.allowAsynchronousTeamPlay")
                          ? methods.setValue(
                              "settings.allowAsynchronousMatchPlay",
                              methods.watch(
                                "settings.allowAsynchronousTeamPlay"
                              )
                            )
                          : methods.setValue(
                              "settings.allowAsynchronousMatchPlay",
                              true
                            );
                        methods.setValue("settings.allowRepeatMatches", 0);
                      }
                      handleMatchPlayGroupSize();
                    }}
                    styles={customStyles}
                    required={true}
                    id="createEditLeagueType"
                    isDisabled={startDatePassed}
                  />
                )}
              />
              <ErrorMessage
                errors={methods.formState.errors}
                name="settings.leagueScoringType"
                render={({ message }) => (
                  <div className="text-sm text-red-500">{message}</div>
                )}
              />
            </FormRow>
            <FormRow
              header="Allow Asynchronous Team Play"
              fontSize={styles.formRowLabel}
              hidden={
                methods.watch("settings.leagueType") ===
                  LeagueType.INDIVIDUAL ||
                methods.watch("settings.leagueScoringType") ===
                  LeagueScoring.SCRAMBLE
                  ? true
                  : false
              }
              tooltip="Selecting on allows teammates to play their league rounds in different Sims"
            >
              <Controller
                name="settings.allowAsynchronousTeamPlay"
                control={methods.control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={trueFalseOptions}
                    value={trueFalseOptions.find(
                      (c) =>
                        c.value ===
                        methods.watch("settings.allowAsynchronousTeamPlay")
                    )}
                    onChange={(val: { label: string; value: boolean }) => {
                      methods.setValue(
                        "settings.allowAsynchronousTeamPlay",
                        val.value
                      );
                      if (
                        methods.watch("settings.leagueScoringType") ===
                        LeagueScoring.STROKE_PLAY
                      ) {
                        methods.setValue(
                          "settings.allowAsynchronousMatchPlay",
                          null
                        );
                      } else if (
                        methods.watch("settings.leagueType") === LeagueType.TEAM
                      ) {
                        methods.setValue(
                          "settings.allowAsynchronousMatchPlay",
                          val.value
                        );
                      }
                    }}
                    styles={customStyles}
                    required={true}
                    id="createEditLeagueAllowAsynchronousTeamPlay"
                    isDisabled={startDatePassed}
                    defaultValue={
                      methods.watch("settings.leagueScoringType") ===
                      LeagueScoring.STROKE_PLAY
                        ? trueFalseOptions[0]
                        : trueFalseOptions[1]
                    }
                  />
                )}
              />
            </FormRow>
            <FormRow
              header="Team Size"
              fontSize={styles.formRowLabel}
              hidden={
                methods.watch("settings.leagueType") === LeagueType.INDIVIDUAL
                  ? true
                  : false
              }
              tooltip="Number of players per team. Selected number for team size will require that number of players on each team."
            >
              <Controller
                name="settings.leagueTeamSize"
                control={methods.control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={roundConfigEnumsAsReactSelectOptions.leagueTeamSize.filter(
                      (leagueTeamSize) => {
                        if (
                          methods.watch("settings.leagueScoringType") ===
                            LeagueScoring.SCRAMBLE ||
                          methods.watch("settings.leagueScoringType") ===
                            LeagueScoring.STROKE_PLAY
                        ) {
                          return true;
                        } else {
                          if (leagueTeamSize.value === LeagueTeamSize.TWO) {
                            return true;
                          }
                        }
                        return false;
                      }
                    )}
                    value={roundConfigEnumsAsReactSelectOptions.leagueTeamSize.find(
                      (c) =>
                        c.value === methods.watch("settings.leagueTeamSize")
                    )}
                    onChange={(val: { label: string; value: number }) => {
                      methods.setValue("settings.leagueTeamSize", val.value);
                      methods.setValue("settings.defaultGroupSize", val.value);
                    }}
                    styles={customStyles}
                    required={true}
                    id="createEditLeagueTeamSize"
                    isDisabled={startDatePassed}
                  />
                )}
              />
              <ErrorMessage
                errors={methods.formState.errors}
                name="settings.leagueTeamSize"
                render={({ message }) => (
                  <div className="text-sm text-red-500">{message}</div>
                )}
              />
            </FormRow>
            <FormRow
              header="Allow Asynchronous Matches"
              fontSize={styles.formRowLabel}
              hidden={
                methods.watch("settings.leagueScoringType") !==
                LeagueScoring.MATCH_PLAY
                  ? true
                  : false
              }
              tooltip="If set to “On” matches do not have to play in the Sim at the same time. For team leagues this setting will automatically be set to the same value as your choice for Allow Asynchronous teams"
            >
              <Controller
                name="settings.allowAsynchronousMatchPlay"
                control={methods.control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={trueFalseOptions}
                    value={trueFalseOptions.find(
                      (c) =>
                        c.value ===
                        methods.watch("settings.allowAsynchronousMatchPlay")
                    )}
                    onChange={(val: { label: string; value: boolean }) => {
                      methods.setValue(
                        "settings.allowAsynchronousMatchPlay",
                        val.value
                      );
                      handleMatchPlayGroupSize();
                    }}
                    styles={customStyles}
                    required={true}
                    id="createEditLeagueAllowAsynchronousMatchPlay"
                    defaultValue={
                      methods.watch("settings.allowAsynchronousTeamPlay") ||
                      trueFalseOptions[0]
                    }
                    isDisabled={
                      methods.watch("settings.leagueType") === LeagueType.TEAM
                        ? true
                        : false
                    }
                  />
                )}
              />
            </FormRow>
            <FormRow
              header="Maximum Group Size"
              fontSize={styles.formRowLabel}
              tooltip="The maximum number of players per round group (i.e. foursome or twosome). Optimal round group size is 2 or 4 players. For synchronous match play leagues only participants of the match can be in the round group."
            >
              <Controller
                name="settings.defaultGroupSize"
                control={methods.control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={defaultGroupSizeOptions.filter((c) => {
                      if (
                        c.teamSize.includes(
                          methods.watch("settings.leagueTeamSize") || 2
                        ) &&
                        c.scoring.includes(
                          methods.watch("settings.leagueScoringType") || 1
                        )
                      ) {
                        return true;
                      } else {
                        return false;
                      }
                    })}
                    value={defaultGroupSizeOptions.find(
                      (c) =>
                        c.value === methods.watch("settings.defaultGroupSize")
                    )}
                    onChange={(val: { label: string; value: number }) => {
                      methods.setValue("settings.defaultGroupSize", val.value);
                    }}
                    styles={customStyles}
                    required={true}
                    id="createEditLeagueDefaultGroupSize"
                    defaultValue={defaultGroupSizeOptions[0]}
                  />
                )}
              />
            </FormRow>
            <div className="hidden">
              <FormRow
                header="Number of Round Groups Per Sim"
                fontSize={styles.formRowLabel}
                tooltip="The number of round groups allowed to play in the SIM at the same time. We recommend keeping this at 1."
              >
                <input
                  {...methods.register("settings.defaultSimGroups")}
                  type="number"
                  name={`settings.defaultSimGroups`}
                  min={1}
                  max={defaultGroupSize > 4 ? 2 : 3}
                  className={styles.inputClassName}
                  defaultValue={1}
                  onBlur={(e) => {
                    methods.setValue(
                      "settings.defaultSimGroups",
                      Number(e.currentTarget.value)
                    );
                  }}
                  onChange={(e) => enforceMinAndMaxScores(e.target)}
                />
                {defaultGroupSize > 4 && (
                  <div className="text-xs text-gray-400 dark:text-gray-50 pt-2">
                    <MdWarningAmber className="size-4 text-zinc-800" /> When
                    default group size is greater than 4 the default sim groups
                    are limited to a max of 2
                  </div>
                )}
              </FormRow>
            </div>
            <FormRow header="League Handicap" fontSize={styles.formRowLabel}>
              <Controller
                name="settings.handicapMethod"
                control={methods.control}
                rules={{ required: "League handicap is required" }}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={
                      roundConfigEnumsAsReactSelectOptions.leagueHandicap
                    }
                    value={roundConfigEnumsAsReactSelectOptions.leagueHandicap.find(
                      (c) =>
                        c.value === methods.watch("settings.handicapMethod")
                    )}
                    onChange={(val: {
                      label: string;
                      value: LeagueHandicapMethod;
                    }) =>
                      methods.setValue("settings.handicapMethod", val.value)
                    }
                    styles={customStyles}
                    required={true}
                    id="createEditLeagueHandicapMethod"
                    isDisabled={
                      startDatePassed ||
                      methods.watch("settings.leagueScoringType") ===
                        LeagueScoring.SCRAMBLE
                    }
                  />
                )}
              />
              <ErrorMessage
                errors={methods.formState.errors}
                name="settings.handicapMethod"
                render={({ message }) => (
                  <div className="text-sm text-red-500">{message}</div>
                )}
              />
            </FormRow>
            <FormRow
              header="Handicap Calculator"
              fontSize={styles.formRowLabel}
              hidden={
                methods.watch("settings.handicapMethod") !==
                LeagueHandicapMethod.NET
                  ? true
                  : false
              }
            >
              <Controller
                name="settings.handicapMode"
                control={methods.control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={
                      roundConfigEnumsAsReactSelectOptions.leagueHandicapMode
                    }
                    value={roundConfigEnumsAsReactSelectOptions.leagueHandicapMode.find(
                      (c) => c.value === methods.watch("settings.handicapMode")
                    )}
                    onChange={(val: {
                      label: string;
                      value: LeagueHandicapMode;
                    }) => {
                      methods.setValue("settings.handicapMode", val.value);
                      if (val.value === LeagueHandicapMode.COURSE) {
                        methods.setValue(
                          "settings.handicapPercentage",
                          HandicapPercentage["100%"]
                        );
                      }
                    }}
                    styles={customStyles}
                    id="createEditLeagueHandicapMethod"
                    isDisabled={startDatePassed}
                  />
                )}
              />
              <ErrorMessage
                errors={methods.formState.errors}
                name="settings.handicapMode"
                render={({ message }) => (
                  <div className="text-sm text-red-500">{message}</div>
                )}
              />
              {methods.watch("settings.handicapMode") ===
                LeagueHandicapMode.PLAYER && (
                <div className="text-xs text-gray-400 dark:text-gray-50 pt-2">
                  <MdWarningAmber className="size-4 text-zinc-800" /> Please
                  note, player handicaps aren’t fully supported in the SIM, your
                  onscreen scorecard might not match the final scorecard.
                </div>
              )}
            </FormRow>
            <FormRow
              header="Handicap Allowance"
              fontSize={styles.formRowLabel}
              hidden={
                methods.watch("settings.handicapMethod") !==
                  LeagueHandicapMethod.NET ||
                methods.watch("settings.handicapMode") ===
                  LeagueHandicapMode.COURSE
                  ? true
                  : false
              }
            >
              <Controller
                name="settings.handicapPercentage"
                control={methods.control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={
                      roundConfigEnumsAsReactSelectOptions.handicapPercentage
                    }
                    value={roundConfigEnumsAsReactSelectOptions.handicapPercentage.find(
                      (c) =>
                        c.value === methods.watch("settings.handicapPercentage")
                    )}
                    onChange={(val: {
                      label: string;
                      value: HandicapPercentage;
                    }) =>
                      methods.setValue("settings.handicapPercentage", val.value)
                    }
                    styles={customStyles}
                    id="createEditLeagueHandicapPercentage"
                    isDisabled={startDatePassed}
                    defaultValue={
                      roundConfigEnumsAsReactSelectOptions.handicapPercentage[0]
                    }
                  />
                )}
              />
              <ErrorMessage
                errors={methods.formState.errors}
                name="handicapPercentage"
                render={({ message }) => (
                  <div className="text-sm text-red-500">{message}</div>
                )}
              />
            </FormRow>
            <FormRow
              header="Play Format"
              fontSize={styles.formRowLabel}
              hidden={true}
            >
              <Select
                {...methods.register("settings.teamPlayFormat")}
                options={roundConfigEnumsAsReactSelectOptions.teamPlayFormat}
                value={roundConfigEnumsAsReactSelectOptions.teamPlayFormat.find(
                  (c) => c.value === methods.watch("settings.teamPlayFormat")
                )}
                onChange={(val: { label: string; value: TeamPlayFormat }) =>
                  methods.setValue("settings.teamPlayFormat", val.value)
                }
                styles={customStyles}
                required={true}
                id="createEditLeagueTeamPlayFormat"
                isDisabled={startDatePassed}
                defaultValue={
                  roundConfigEnumsAsReactSelectOptions.teamPlayFormat[0]
                }
              />
            </FormRow>
            <FormRow
              header="Match Play Assignment"
              fontSize={styles.formRowLabel}
              hidden={
                methods.watch("settings.leagueScoringType") !==
                LeagueScoring.MATCH_PLAY
                  ? true
                  : false
              }
            >
              <Select
                {...methods.register("settings.matchPlayAssignment")}
                options={
                  roundConfigEnumsAsReactSelectOptions.matchPlayAssignment
                }
                value={roundConfigEnumsAsReactSelectOptions.matchPlayAssignment.find(
                  (c) =>
                    c.value === methods.watch("settings.matchPlayAssignment")
                )}
                onChange={(val: {
                  label: string;
                  value: MatchPlayAssignment;
                }) =>
                  methods.setValue("settings.matchPlayAssignment", val.value)
                }
                styles={customStyles}
                required={true}
                id="createEditLeagueMatchPlayAssignment"
                isDisabled={startDatePassed}
                defaultValue={
                  roundConfigEnumsAsReactSelectOptions.matchPlayAssignment[0]
                }
              />
            </FormRow>
            <FormRow
              header="Match Play Style"
              fontSize={styles.formRowLabel}
              hidden={true}
            >
              <Select
                {...methods.register("settings.matchPlayStyle")}
                options={roundConfigEnumsAsReactSelectOptions.matchPlayStyle}
                value={roundConfigEnumsAsReactSelectOptions.matchPlayStyle.find(
                  (c) => c.value === methods.watch("settings.matchPlayStyle")
                )}
                onChange={(val: { label: string; value: MatchPlayStyle }) =>
                  methods.setValue("settings.matchPlayStyle", val.value)
                }
                styles={customStyles}
                required={true}
                id="createEditLeagueMatchPlayStyle"
                isDisabled={startDatePassed}
                defaultValue={
                  roundConfigEnumsAsReactSelectOptions.matchPlayStyle[0]
                }
              />
            </FormRow>
            <FormRow
              header="Match Play Setting"
              fontSize={styles.formRowLabel}
              hidden={true}
            >
              <Select
                {...methods.register("settings.matchTeamPlayStyle")}
                options={
                  roundConfigEnumsAsReactSelectOptions.matchPlayTeamStyle
                }
                value={roundConfigEnumsAsReactSelectOptions.matchPlayTeamStyle.find(
                  (c) =>
                    c.value === methods.watch("settings.matchTeamPlayStyle")
                )}
                onChange={(val: { label: string; value: MatchPlayTeamStyle }) =>
                  methods.setValue("settings.matchTeamPlayStyle", val.value)
                }
                styles={customStyles}
                required={true}
                id="createEditLeagueMatchTeamPlayStyle"
                isDisabled={startDatePassed}
                defaultValue={
                  roundConfigEnumsAsReactSelectOptions.matchPlayTeamStyle[0]
                }
              />
            </FormRow>
            <FormRow
              header="Number of Repeat Matches Allowed"
              fontSize={styles.formRowLabel}
              hidden={
                methods.watch("settings.leagueScoringType") !==
                LeagueScoring.MATCH_PLAY
                  ? true
                  : false
              }
              tooltip="How many times do you want the system to allow you to repeat an assigned match?"
            >
              <input
                {...methods.register("settings.allowRepeatMatches")}
                type="number"
                name={`settings.allowRepeatMatches`}
                min={0}
                max={99}
                className={styles.inputClassName}
                defaultValue={
                  methods.watch("settings.leagueScoringType") ===
                  LeagueScoring.STROKE_PLAY
                    ? undefined
                    : 0
                }
                onBlur={(e) => {
                  methods.setValue(
                    "settings.allowRepeatMatches",
                    Number(e.currentTarget.value)
                  );
                }}
                onChange={(e) => enforceMinAndMaxScores(e.target)}
                disabled={startDatePassed}
              />
            </FormRow>

            <button
              type="submit"
              className="poppins bg-brandYellow text-black hover:bg-brandGreen hover:text-white mt-8 w-full text-center py-3 rounded-lg"
            >
              {createLeagueQueryState?.isPending ||
              updateLeagueQueryState?.isPending ? (
                <>
                  <CgSpinner className="size-4 animate-spin mr-2" />{" "}
                  {selectedLeague ? "Saving League..." : "Creating League..."}
                </>
              ) : selectedLeague ? (
                "Save Changes "
              ) : (
                "Create League "
              )}
            </button>
            {selectedLeague && (
              <button
                type="button"
                className="poppins bg-gray-200 text-red-500 hover:bg-black hover:text-white mt-4 w-full text-center py-3 rounded-lg"
                onClick={(e) => handleDeleteLeagueClick(e)}
              >
                Delete League
              </button>
            )}
          </form>
        </FormProvider>
      </Row>
    </div>
  );
};

export default CreateLeagueContainer;

type CreateLeagueContainerProps = {
  selectedLeague?: League;
};
