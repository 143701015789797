export const styles = {
  container: [
    "flex",
    "flex-col",
    "min-h-screen",
    "w-full",
    "max-w-screen-xl",
    "mx-auto",
  ].join(" "),
  innerContainer: ["flex", "flex-col", "w-full"].join(" "),
  titleRow: [
    "flex-row",
    "text-2xl",
    "border-b",
    "border-gray-400",
    "mb-4",
  ].join(" "),
  title: [
    "block",
    "md:inline",
    "pl-2",
    "text-center",
    "md:text-left",
    "mb-2",
    "md:mb-0",
    "w-full",
    "md:w-auto",
    "m-auto",
    "md:m-0",
  ].join(" "),
  row: ["flex", "flex-col", "flex-row", "border-b", "pb-4", "mb-2"].join(" "),
  rowSmall: ["flex", "flex-col", "flex-row", "border-b", "py-2"].join(" "),
  text: [
    "text-sm",
    "pt-2",
    "text-gray-500 dark:text-gray-200",
    "font-normal",
  ].join(" "),
  headerColumn: [
    "w-full",
    "flex",
    "flex-col",
    "p-0 md:p-2",
    "text-md brand-heading dark:text-gray-50",
  ].join(" "),
  fieldColumn: ["w-full", "flex", "flex-col", "p-0 md:p-2"].join(" "),
  innerRow: ["w-full", "p-2"].join(" "),
  submit: [
    "w-full",
    "block",
    "bg-brandYellow",
    "hover:bg-brandGreen hover:text-white",
    "text-black",
    "py-2",
    "px-4",
    "mt-2",
    "border",
    "border-gray-200",
    "rounded",
    "min-w-min",
    "w-full hover:animate-pulse",
    "focus:ring-2",
    "focus:ring-blue-600",
    "active:bg-green-700",
    "disabled:opacity-50",
    "disabled:cursor-not-allowed",
  ].join(" "),
  delete: [
    "w-auto",
    "inline",
    "bg-brandGreen",
    "shadow",
    "hover:bg-red-700",
    "hover:shadow-inner",
    "text-white",
    "text-sm",
    "poppins",
    "px-4",
    "border",
    "border-gray-200",
    "rounded-lg",
    "w-full hover:animate-pulse",
    "h-full",
  ].join(" "),
  view: [
    "w-auto",
    "inline",
    "bg-green-500",
    "hover:bg-green-700",
    "hover:shadow",
    "text-white",
    "text-sm",
    "px-2",
    "border",
    "border-white",
    "rounded",
    "w-full hover:animate-pulse",
  ].join(" "),
  input: [
    "border",
    "h-10",
    "rounded",
    "w-full",
    "block",
    "px-2",
    "border-gray-300",
    "dark:text-white dark:bg-zinc-900",
  ].join(" "),
  inputError: [
    "border-2",
    "h-10",
    "rounded",
    "w-full",
    "block",
    "px-2",
    "border-red-600",
    "dark:text-black",
  ].join(" "),
  selectError: ["border-2", "border-red-600", "rounded"].join(" "),
};
export default styles;
