import { useCallback, useEffect, useState } from "react";
import { PlayerUser } from "Models";
import { components } from "react-select";
import { useToasterStore } from "react-hot-toast";
import { customStylesMiminalist } from "utils/ui/uiHelpers";
import { userSearch } from "utils/userSearch/userSearchHelpers";
import {
  useGetClubhouseLeagueDto,
  useGetSelectedLeague,
} from "modules/leagues/leagueHooks";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import {
  useAddPlayersToLeagueRoster,
  useLeagueRosterByLeagueId,
} from "modules/leagues/leagueRosterHooks";
import {
  getApiResponseErrorMessage,
  isApiStatusSuccess,
} from "utils/api/apiHelper";
import {
  GenericNotificationType,
  showCustomNotification,
} from "utils/notifications/notificationHelpers";
import debounce from "debounce-promise";
import AsyncSelect from "react-select/async";
import FormattedRosterSearchResults from "./FormattedRosterSearchResults";
import CreateLeaugeRosterLockerUser from "./CreateLeaugeRosterLockerUser";

const CreateLeagueRosterSearch = () => {
  const toastIsActive = useToasterStore().toasts.length > 0 ? true : false;
  const currentFacilityId = useGetCurrentLocalFacilityId();
  const selectedLeague = useGetSelectedLeague();

  const [selectedOption, setSelectedOption] = useState<PlayerUser | null>(null);
  const [expandLockerPlayerMenu, setExpandLockerPlayerMenu] = useState(false);
  const [selectedPlayerGuid, setSelectedPlayerGuid] = useState<string>();

  const [leagueRoster, ,] = useLeagueRosterByLeagueId(
    currentFacilityId,
    selectedLeague?.id
  );

  const [, addPlayersToRoster] = useAddPlayersToLeagueRoster(
    currentFacilityId,
    selectedLeague?.id,
    selectedPlayerGuid || ""
  );

  const [, , updateLeague] = useGetClubhouseLeagueDto(
    currentFacilityId,
    selectedLeague?.id
  );

  const getAsyncOptions = (inputValue: string) => {
    return new Promise<PlayerUser[]>((resolve) => {
      if (inputValue.length > 2) {
        const options = userSearch(inputValue);
        resolve(options);
      } else {
        const options: PlayerUser[] = [];
        resolve(options);
      }
    });
  };

  const loadOptions = (inputValue: string) => {
    return getAsyncOptions(inputValue).then((options) => {
      return options.filter((option) => option.hasOnlineAccount === true);
    });
  };

  const debouncedLoadOptions = debounce(loadOptions, 500, {
    leading: true,
  });

  const handleSearchOnChange = async (option: PlayerUser | null) => {
    option?.userGuid
      ? setSelectedPlayerGuid(option?.userGuid)
      : showCustomNotification(
          "Selected player does not have a valid Locker account. Please add a different player.",
          GenericNotificationType.ERROR
        );
    setSelectedOption(null);
  };

  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>
          No players found.{" "}
          <button
            onClick={() => setExpandLockerPlayerMenu(true)}
            className="border-b border-blue-500"
          >
            Create a new Locker user
          </button>
          .
        </span>
      </components.NoOptionsMessage>
    );
  };

  const addPlayerToRoster = useCallback(
    async (userGuid: string) => {
      if (leagueRoster?.find((player) => player.userGuid === userGuid)) {
        showCustomNotification(
          "Player has already been added to roster",
          GenericNotificationType.ERROR
        );
        return;
      } else {
        let response = await addPlayersToRoster();

        if (response && isApiStatusSuccess(response?.status)) {
          showCustomNotification("Player has been added to roster");
          updateLeague();
        } else {
          if (!toastIsActive) {
            showCustomNotification(
              getApiResponseErrorMessage(response?.body),
              GenericNotificationType.ERROR
            );
          }
        }
      }
    },
    [leagueRoster, addPlayersToRoster, updateLeague, toastIsActive]
  );

  useEffect(() => {
    if (selectedPlayerGuid) {
      addPlayerToRoster(selectedPlayerGuid);
      setSelectedPlayerGuid("");
    }
  }, [selectedPlayerGuid, addPlayerToRoster]);

  return (
    <div className="flex flex-col w-full mb-4">
      <div className="flex flex-row w-full">
        <div className="flex w-1/5 poppins font-semibold items-center content-center dark:text-gray-50">
          League Roster
        </div>
        <div className="flex w-4/5 items-center">
          <AsyncSelect
            components={{ NoOptionsMessage }}
            RequireAtLeastOne
            defaultMenuIsOpen={false}
            closeMenuOnSelect={true}
            backspaceRemovesValue={false}
            loadOptions={(query) => debouncedLoadOptions(query)}
            getOptionValue={(player: PlayerUser) => JSON.stringify(player)}
            filterOptions={false}
            onChange={(option) => handleSearchOnChange(option)}
            formatOptionLabel={(option, { context }) => {
              return (
                <FormattedRosterSearchResults
                  value={option}
                  context={context}
                />
              );
            }}
            styles={customStylesMiminalist}
            placeholder={"Search by name, email, phone number..."}
            isClearable
            autoFocus
            id="lockerUserEditFormSearchField"
            className="w-full rounded-full bg-gray-50 dark:bg-zinc-900"
            key={`CreateLeagueRosterSearch`}
            value={selectedOption}
          />
        </div>
      </div>
      <div className="block w-full">
        {expandLockerPlayerMenu && (
          <CreateLeaugeRosterLockerUser
            showHideComponentCallback={setExpandLockerPlayerMenu}
            returnLockerUserGuidCallback={setSelectedPlayerGuid}
            returnGuidOnly={true}
          />
        )}
      </div>
    </div>
  );
};

export default CreateLeagueRosterSearch;
