import { AppQueryConfig } from "QueryTypes";
import { leagueQueryDefaultOptions } from "./leagueHelpers";
import {
  PostRound,
  LeagueRound,
  ClubhouseRoundDto,
  ClubhouseNewRoundInfoDto,
} from "Models";
import axios from "axios";

const urls = {
  leagueRoundsBase(): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/leagues`;
  },
  leaguesByleagueId(leagueId: number): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/leagues/${leagueId}`;
  },
  leaguesByFacilityId(facilityId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facility/${facilityId}/leagues/`;
  },
  deleteleagueRound(
    facilityId: string,
    leagueId: string,
    roundId: string
  ): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facility/${facilityId}/league/${leagueId}/round/${roundId}`;
  },
  createLeagueRound(facilityId: string, leagueId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facility/${facilityId}/league/${leagueId}/round`;
  },
  getNextRoundNumber(
    facilityId: string,
    leagueId: string,
    roundId: string
  ): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facility/${facilityId}/league/${leagueId}/round/${roundId}/next`;
  },
  updateLeagueRound(
    facilityId: string,
    leagueId: string,
    roundId: string
  ): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facility/${facilityId}/league/${leagueId}/round/${roundId}`;
  },
  leagueRoundsByLeagueId(facilityId: string, leagueId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facility/${facilityId}/league/${leagueId}/rounds`;
  },
  finalizeLeagueRound(
    facilityId: string,
    leagueId: string,
    roundId: string
  ): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facility/${facilityId}/league/${leagueId}/round/${roundId}/end`;
  },
};

export const makeCreateLeagueRoundMutation = (
  facilityId: string,
  leagueId: string,
  body: PostRound
): AppQueryConfig => ({
  url: urls.createLeagueRound(facilityId, leagueId),
  queryKey: `makeCreateleagueMutation:${facilityId}-${leagueId}`,
  options: {
    ...leagueQueryDefaultOptions(),
    method: "POST",
  },
  body,
  transform: (responseJson: ClubhouseRoundDto) => {
    return {
      leagueRounds: {
        byLeagueId: {
          [leagueId]: { [responseJson.id]: responseJson },
        },
      },
    };
  },
  update: {
    leagueRounds: (oldValue, newValue) => ({
      ...oldValue,
      byLeagueId: {
        ...oldValue?.byLeagueId,
        ...{
          [leagueId]: {
            ...oldValue?.byLeagueId?.[leagueId],
            ...newValue.byLeagueId[leagueId],
          },
        },
      },
    }),
  },
});

export const makeDeleteLeagueRoundMutation = (
  leagueId: string,
  facilityId: string,
  roundId: string
): AppQueryConfig => ({
  url: urls.deleteleagueRound(facilityId, leagueId, roundId),
  queryKey: `deleteLeague:${leagueId}`,
  options: {
    ...leagueQueryDefaultOptions(),
    method: "DELETE",
  },
  optimisticUpdate: {
    leagueRounds: (reduxState) => {
      delete reduxState.byLeagueId[leagueId][roundId];
      return reduxState;
    },
  },
});
export const makeUpdateLeagueRoundMutation = (
  facilityId: string,
  leagueId: string,
  roundId: string,
  body: LeagueRound
): AppQueryConfig => ({
  url: urls.updateLeagueRound(facilityId, leagueId, roundId),
  queryKey: `makeUpdateLeagueRoundMutation:${body.id}`,
  options: {
    ...leagueQueryDefaultOptions(),
    method: "PUT",
  },
  body,
  transform: (responseJson: ClubhouseRoundDto) => {
    let obj: { [id: string]: ClubhouseRoundDto } = {};
    obj[responseJson?.id] = responseJson;

    return {
      leagueRounds: {
        byLeagueId: {
          [leagueId]: obj,
        },
      },
    };
  },
  update: {
    leagueRounds: (oldValue, newValue) => ({
      ...oldValue,
      byLeagueId: {
        ...oldValue?.byLeagueId,
        ...{
          [leagueId]: {
            ...oldValue?.byLeagueId?.[leagueId],
            ...newValue.byLeagueId[leagueId],
          },
        },
      },
    }),
  },
});

export const getRoundsByLeagueId = (
  facilityId: string,
  leagueId: string
): AppQueryConfig => ({
  url: urls.leagueRoundsByLeagueId(facilityId, leagueId),
  options: {
    ...leagueQueryDefaultOptions(),
    method: "GET",
  },
  transform: (responseJson: ClubhouseRoundDto[]) => {
    let obj: { [id: string]: ClubhouseRoundDto } = {};

    responseJson.map((leagueRound) => {
      return (obj[leagueRound?.id] = leagueRound);
    });

    return {
      leagueRounds: {
        byLeagueId: {
          [leagueId]: obj,
        },
      },
    };
  },
  update: {
    leagueRounds: (oldValue, newValue) => ({
      byLeagueId: {
        ...oldValue?.byLeagueId,
        ...{
          [leagueId]: {
            ...oldValue?.byLeagueId?.[leagueId],
            ...newValue.byLeagueId[leagueId],
          },
        },
      },
    }),
  },
});

export const getClubhouseRoundDto = (
  facilityId: string,
  leagueId: string,
  roundId: string
): AppQueryConfig => ({
  url: urls.updateLeagueRound(facilityId, leagueId, roundId),
  queryKey: `getClubhouseRoundDto:${roundId}`,
  options: {
    ...leagueQueryDefaultOptions(),
    method: "GET",
  },
  transform: (responseJson: ClubhouseRoundDto) => {
    let obj: { [id: string]: ClubhouseRoundDto } = {};
    obj[responseJson?.id] = responseJson;

    return {
      leagueRounds: {
        byLeagueId: {
          [leagueId]: obj,
        },
      },
    };
  },
  update: {
    leagueRounds: (oldValue, newValue) => ({
      ...oldValue,
      byLeagueId: {
        ...oldValue?.byLeagueId,
        ...{
          [leagueId]: {
            ...oldValue?.byLeagueId?.[leagueId],
            ...newValue.byLeagueId[leagueId],
          },
        },
      },
    }),
  },
});

export const getNextRound = async (
  facilityId: string,
  leagueId: string,
  roundId: string
): Promise<any> => {
  return await axios
    .get<ClubhouseNewRoundInfoDto>(
      urls.getNextRoundNumber(facilityId, leagueId, roundId),
      {
        ...leagueQueryDefaultOptions(),
      }
    )
    .then((res) => {
      return {
        status: res.status,
        data: res.data,
      };
    })
    .catch((error) => {
      return { data: error.data, status: error.status };
    });
};

export const putFinalizeLeagueRound = (
  facilityId: string,
  leagueId: string,
  roundId: string
): AppQueryConfig => ({
  url: urls.finalizeLeagueRound(facilityId, leagueId, roundId),
  queryKey: `putFinalizeLeagueRound:${roundId}`,
  options: {
    ...leagueQueryDefaultOptions(),
    method: "PUT",
  },
  transform: (responseJson: ClubhouseRoundDto) => {
    let obj: { [id: string]: ClubhouseRoundDto } = {};
    obj[responseJson?.id] = responseJson;

    return {
      leagueRounds: {
        byLeagueId: {
          [leagueId]: obj,
        },
      },
    };
  },
  update: {
    leagueRounds: (oldValue, newValue) => ({
      ...oldValue,
      byLeagueId: {
        ...oldValue?.byLeagueId,
        ...{
          [leagueId]: {
            ...oldValue?.byLeagueId?.[leagueId],
            ...newValue.byLeagueId[leagueId],
          },
        },
      },
    }),
  },
});
