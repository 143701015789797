import Modal from "components/shared/Modal";
import MultiSimControls from "components/control/sim-control/MultiSimControls";
import Row from "components/ui/layout/Row";

const BulkSimControlModal: React.FC<SimControlModalProps> = (props) => {
  return (
    <Modal
      modalName={props.modalName}
      hideHeader={false}
      body={
        <Row cssClasses="px-8 py-4">
          <MultiSimControls />
        </Row>
      }
      title="Manage Sims"
      hideFooter={true}
      modalSize="w-full h-full sm:w-1/2 h-3/4"
    />
  );
};

type SimControlModalProps = {
  title?: string;
  modalName: string;
  simulatorIds: string[];
};

BulkSimControlModal.defaultProps = {
  title: "Bulk Sim Control",
};

export default BulkSimControlModal;
