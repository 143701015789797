import { useEffect, useState } from "react";
import { BlobList } from "Models";
import {
  downloadFile,
  getBlobsInContainer,
  humanReadableFileName,
  viewableFileTypes,
} from "utils/azure/azureHelpers";

const MarketingHubFileList: React.FC = () => {
  const [fileList, setFileList] = useState<BlobList[]>([]);

  useEffect(() => {
    getBlobsInContainer().then((list: any) => {
      setFileList(list);
    });
  }, []);

  return (
    <ul className="divide-y divide-gray-300">
      {fileList.map((file: any, index) => (
        <li
          key={index}
          className="flex items-center justify-between gap-x-6 py-5"
        >
          <div className="min-w-0">
            <div className="flex items-start gap-x-3">
              <p className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-200">
                {humanReadableFileName(file.name)}
              </p>
            </div>
          </div>
          <div className="flex flex-none items-center gap-x-4">
            {viewableFileTypes.includes(file.name.split(".").pop()) && (
              <a
                href={file.url}
                className="text-black bg-gray-200 dark:bg-zinc-500 rounded-lg w-full py-2 font-normal poppins hover:bg-brandGreyText dark:hover:bg-zinc-400 hover:text-white tracking-tight text-xs shadow px-4"
                target="_blank"
                rel="noopener noreferrer"
              >
                View<span className="sr-only">, {file.name}</span>
              </a>
            )}
            <button
              onClick={() => downloadFile(file.url, file.name)}
              className="text-black bg-gray-200 dark:bg-zinc-500 rounded-lg w-full py-2 font-normal poppins hover:bg-brandGreyText dark:hover:bg-zinc-400 hover:text-white tracking-tight text-xs shadow px-4"
            >
              Download<span className="sr-only">, {file.name}</span>
            </button>
          </div>
        </li>
      ))}
    </ul>
  );
};
export default MarketingHubFileList;
