import { DefaultRoundSettings } from "Models";
import { normalize } from "normalizr";
import { AppQueryConfig, DefaultRoundSettingsUpdateBody } from "QueryTypes";
import { reduxQueryDefaultOptions } from "utils/queries/queryHelpers";
import { defaultRoundSettings } from "./defaultRoundSettingsSchema";

const urls = {
  facilityDefaultRoundSettings(facilityId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facilities/${facilityId}/default-round-settings`;
  },
};

export const facilityDefaultRoundSettingsQuery = (
  facilityId: string
): AppQueryConfig => ({
  url: urls.facilityDefaultRoundSettings(facilityId),
  queryKey: `facilityDefaultRoundSettings:${facilityId}`,
  options: { ...reduxQueryDefaultOptions() },
  transform: (responseJson: DefaultRoundSettings) => {
    return normalize(responseJson, defaultRoundSettings).entities;
  },
  update: {
    defaultRoundSettings: (oldValue, newValue) => ({
      ...oldValue,
      ...newValue,
    }),
  },
});

export const makeUpdateDefaultRoundSettingsMutation = (
  facilityId: string,
  body: DefaultRoundSettingsUpdateBody
): AppQueryConfig => ({
  url: urls.facilityDefaultRoundSettings(facilityId),
  queryKey: `makeRoundConfigMutation:${facilityId}`,
  options: {
    ...reduxQueryDefaultOptions(),
    method: "PATCH",
  },
  body,
  transform: (responseJson: DefaultRoundSettings) => {
    return normalize(responseJson, defaultRoundSettings).entities;
  },
  update: {
    defaultRoundSettings: (oldValue, newValue) => ({
      ...oldValue,
      ...newValue,
    }),
  },
});
