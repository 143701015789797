import { DateTime } from "luxon";
import { useEffect } from "react";
import { styles } from "./Events.styles";
import { ErrorMessage } from "@hookform/error-message";
import { Controller, useFormContext } from "react-hook-form";
import { useSelectedEvent } from "modules/events/eventHooks";
import { eventStartDatePassed } from "modules/events/eventHelpers";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import DatePicker from "react-datepicker";

const EventDateSelect = () => {
  const {
    control,
    formState,
    getValues,
    setValue,
    clearErrors,
    watch,
  } = useFormContext();

  const currentFacilityId = useGetCurrentLocalFacilityId();
  const selectedEvent = useSelectedEvent(currentFacilityId);

  const startDatePassed = eventStartDatePassed(getValues("startDate"));

  const handleStartTime = (value: Date) => {
    setValue("startDate", value);
    clearErrors("startDate");
  };
  const handleEndTime = (value: Date) => {
    setValue("endDate", value);
    clearErrors("endDate");
  };

  const filterTime = (date: { getTime: () => number }) => {
    const isPastTime = new Date().getTime() > date.getTime();
    return !isPastTime;
  };

  useEffect(() => {
    if (getValues("startDate"))
      setValue("startDate", new Date(getValues("startDate")));
    if (getValues("endDate"))
      setValue("endDate", new Date(getValues("endDate")));
  }, [getValues, setValue]);

  return (
    <div className={styles.dateContainer}>
      <div>
        <span className={styles.dateSpan}>Start Date</span>
        <Controller
          name="startDate"
          control={control}
          rules={{ required: "Start date is required" }}
          data-testid="startDate"
          render={({ field }) => (
            <DatePicker
              {...field}
              onChange={(date: Date) => handleStartTime(date)}
              selected={watch("startDate")}
              showTimeSelect
              timeIntervals={30}
              timeCaption="Time"
              dateFormat="MMMM d, yyyy h:mm aa"
              className={styles.datePicker}
              minDate={new Date()}
              disabled={startDatePassed && selectedEvent !== undefined}
              filterTime={filterTime}
              placeholderText="Select Start Date"
              id="eventStartDate"
            />
          )}
        />
        <ErrorMessage
          errors={formState.errors}
          name="startDate"
          render={({ message }) => (
            <div className="text-sm text-red-500">{message}</div>
          )}
        />
      </div>
      <div>
        <span className={styles.dateSpan}>End Date</span>
        <Controller
          name="endDate"
          control={control}
          rules={{ required: "End date is required" }}
          data-testid="endDate"
          render={({ field }) => (
            <DatePicker
              {...field}
              onChange={(date: Date) => handleEndTime(date)}
              selected={watch("endDate")}
              showTimeSelect
              timeIntervals={30}
              timeCaption="Time"
              dateFormat="MMMM d, yyyy h:mm aa"
              className={styles.datePicker}
              minDate={new Date(DateTime.local().toISO())}
              filterTime={filterTime}
              placeholderText="Select End Date"
              id="eventEndDate"
            />
          )}
        />
        <ErrorMessage
          errors={formState.errors}
          name="endDate"
          render={({ message }) => (
            <div className="text-sm text-red-500">{message}</div>
          )}
        />
      </div>
    </div>
  );
};
export default EventDateSelect;
