import "assets/css/spinnerAnimation.css";

type OwnProps = {
  class?: string;
};

const MoonIcon: React.FC<OwnProps> = (props) => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      className={`${props.class} fill-current`}
      fill-current={props.class}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8316 13.4962C12.4262 17.803 7.09891 19.2787 2.93267 16.7921C1.7981 16.115 0.842974 15.1989 0.115509 14.1069C-0.127714 13.7418 0.0296186 13.2376 0.43337 13.0882C3.71451 11.8741 5.47186 10.4674 6.49161 8.45513C7.5652 6.33668 7.84259 4.01617 7.09159 0.835376C6.98778 0.395539 7.32378 -0.0232497 7.76116 0.00100468C9.11719 0.0762076 10.4419 0.478862 11.6432 1.19587C15.8095 3.68239 17.2369 9.18942 14.8316 13.4962ZM7.64881 9.08175C6.55902 11.2322 4.7648 12.7722 1.71132 14.0325C2.24107 14.659 2.87114 15.196 3.58586 15.6226C7.12721 17.7362 11.6556 16.4818 13.7002 12.821C15.7448 9.16016 14.5315 4.47901 10.99 2.36541C10.2262 1.90952 9.40511 1.60339 8.55831 1.45111C9.12189 4.49775 8.75837 6.8923 7.64881 9.08175Z"
        className={`${props.class} fill-current`}
        fill-current={props.class}
      />
    </svg>
  );
};
export default MoonIcon;

MoonIcon.defaultProps = {
  class: "text-zinc-800 dark:text-white",
};
