import { DateTime, Duration, Interval } from "luxon";
import { TimerStatus } from "../../types/enums";

export type TimerValueArray = [number, number, number];

export const humanReadableTimerValue = (timerValueArray: TimerValueArray) => {
  const duration = Duration.fromObject(
    timerValueArray[0] > 0
      ? {
          hours: timerValueArray[0],
          minutes: timerValueArray[1],
        }
      : { minutes: timerValueArray[1] }
  );
  return timerValueArray ? duration.toHuman({ unitDisplay: "narrow" }) : "";
};

export const timerStringToTimerArray = (
  timerValue: string
): TimerValueArray => {
  return timerValue.split(":").map((el) => parseInt(el)) as TimerValueArray;
};

export const timerArrayToTimerString = (
  timerValueArray: TimerValueArray
): string => {
  const convertedValues = timerValueArray?.map((value) => {
    if (value < 10) {
      return "0" + value.toString();
    }
    return value.toString();
  });

  return convertedValues?.join(":");
};

export const addMinutesToTimerArray = (
  timerValueArray: TimerValueArray,
  minutesToAdd: number
): TimerValueArray => {
  timerValueArray[0] += Math.floor(minutesToAdd / 60);
  timerValueArray[1] += minutesToAdd % 60;
  if (timerValueArray[1] > 60) {
    timerValueArray[0] += Math.floor(timerValueArray[1] / 60);
    timerValueArray[1] = timerValueArray[1] % 60;
  }
  return timerValueArray;
};

export const subtractSecondsFromTimerArray = (
  timerValueArray: TimerValueArray,
  secondsToSubract: number
): TimerValueArray => {
  if (
    !timerValueArray[0] &&
    !timerValueArray[1] &&
    timerValueArray[2] - secondsToSubract < 0
  ) {
    return [0, 0, 0];
  }

  timerValueArray[1] -= Math.floor(secondsToSubract / 60);
  timerValueArray[2] -= secondsToSubract % 60;

  if (timerValueArray[2] < 0) {
    timerValueArray[1] += Math.floor(timerValueArray[2] / 60);
    timerValueArray[2] = 60 + (timerValueArray[2] % 60);
  }

  if (timerValueArray[1] < 0) {
    timerValueArray[0] += Math.floor(timerValueArray[1] / 60);
    timerValueArray[1] = 60 + (timerValueArray[1] % 60);
  }

  return timerValueArray;
};

export const isNegative = (num: number): boolean => {
  if (typeof num === "number" && num < 0) {
    return true;
  }
  return false;
};

export const timerArrayToMinuteValue = (
  timerValue: TimerValueArray
): number => {
  return timerValue[0] * 60 + timerValue[1];
};

export const timerIsOffOrZero = (
  timerValue: TimerValueArray,
  timerStatus: TimerStatus
) => {
  if (!timerStatus || !timerValue) return true;

  return timerArrayToMinuteValue(timerValue) === 0 ||
    timerStatus === TimerStatus.OFF
    ? true
    : false;
};

export const recalculateEndTime = (endTime: Date) => {
  return Math.ceil(
    Interval.fromDateTimes(DateTime.now(), endTime).length("minutes")
  );
};

export const calculateDuration = (startTime: Date, endTime: Date) => {
  return Math.ceil(
    Interval.fromDateTimes(startTime, endTime).length("minutes")
  );
};
