import { AnimatePresence, motion } from "framer-motion";
import { isModalOpen } from "utils/modals/modalHelpers";
import { useAppDispatch } from "utils/hooks";
import { modalAction } from "ui-modules/modals/modalSlice";
import { MdClose } from "react-icons/md";
import MoveRoundContainer from "../round-control/MoveRoundContainer";

const MoveRoundInnerModal: React.FC<MoveRoundInnerModalProps> = (props) => {
  const dispatch = useAppDispatch();

  const { modalName, simulatorId, roundId, listView } = props;

  const setModalClose = () => {
    dispatch(modalAction({ isOpen: false, modalName: modalName }));
  };

  return (
    <>
      <AnimatePresence>
        {isModalOpen(modalName) && (
          <motion.div
            className="z-9999 h-full w-full absolute bg-white dark:bg-zinc-800 rounded-xl"
            initial={{ width: 0 }}
            animate={{ width: "100%" }}
            exit={{
              width: 0,
              opacity: 0.7,
              transition: { delay: 0.15, duration: 0.3 },
            }}
            key={`MoveRoundInnerModal-${modalName}`}
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key={`addSendMessageModalHeader-${modalName}`}
            >
              <div className="relative flex flex-row w-full items-end justify-end mr-2 mt-2 overflow-hidden h-6 pt-1">
                <button
                  onClick={() => setModalClose()}
                  className="z-10 flex gap-2 font-bold top-1 w-6 h-6 right-1"
                >
                  <MdClose className="w-6 h-6 text-black dark:text-white" />
                </button>
              </div>
              <div className="flex flex-col dark:bg-zinc-800">
                <MoveRoundContainer
                  modalName={modalName}
                  simulatorId={simulatorId}
                  roundId={roundId}
                  listView={listView}
                />
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
export default MoveRoundInnerModal;

type MoveRoundInnerModalProps = {
  simulatorId: string;
  simulatorName: string;
  roundId: string;
  modalName: string;
  listView?: boolean;
};
