import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setSessionStorageValue } from "utils/hooks/localStorage";

export type EventState = {
  selectedEventId: number;
};

const initialState: EventState = {
  selectedEventId: 0,
};

const slice = createSlice({
  name: "event",
  initialState,
  reducers: {
    setSelectedEventId(
      state,
      action: PayloadAction<{ selectedEventId: number }>
    ): void {
      state.selectedEventId = action.payload.selectedEventId;
      setSessionStorageValue(
        "selectedEventId",
        action.payload.selectedEventId.toString()
      );
    },
  },
});

const { actions, reducer: eventReducer } = slice;

const { setSelectedEventId } = actions;

export { eventReducer, setSelectedEventId };
