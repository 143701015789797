import { FaRedo } from "react-icons/fa";
import { styles } from "./SimulatorReservation.styles";

const ReloadSimulatorReservationsButton: React.FC<ReloadSimulatorReservationsButtonProps> = (
  props
) => {
  const { callback } = props;

  return (
    <>
      <button onClick={callback} className={`${styles.refreshButton}`}>
        <span className="sr-only">Refresh Simulator Reservations Button</span>
        <FaRedo />
      </button>
    </>
  );
};
export default ReloadSimulatorReservationsButton;

type ReloadSimulatorReservationsButtonProps = {
  showIconOnly?: boolean;
  callback?: any;
};
