import {
  getClubhouseLeagueDto,
  getLeaguesByFacilityId,
  makeDeleteLeagueMutation,
  makeUpdateLeagueMutation,
} from "modules/leagues/leagueQueries";
import { LeagueHandicapMethod, LeagueScoring, LeagueType } from "types/enums";
import { UseDataInfo } from "QueryTypes";
import { QueryState } from "redux-query";
import { useSelector } from "react-redux";
import { ClubhouseRoundDto, League, LeaguePayload } from "Models";
import { RunMutation, useMutation, useRequest } from "redux-query-react";
import { EntitiesState, RootState } from "StoreTypes";
import { makeCreateLeagueMutation } from "modules/leagues/leagueQueries";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";

export const useSelectedLeague = () => {
  return useSelector(
    (state: RootState): League => {
      return state.entities?.leagues?.byFacilityId?.[
        state.facility?.currentFacilityId
      ]?.[state.league?.selectedLeagueId];
    }
  );
};

export const useSelectedLeagueRound = () => {
  return useSelector(
    (state: RootState): ClubhouseRoundDto => {
      const roundId = state.league?.selectedLeagueRoundId;
      const leagueId = state.league?.selectedLeagueId;

      return state.entities?.leagueRounds?.byLeagueId?.[leagueId]?.[roundId];
    }
  );
};

export const useSelectedLeagueId = () => {
  return useSelector((state: RootState): string => {
    return state.league?.selectedLeagueId;
  });
};

export const useSelectedLeagueRoundId = () => {
  return useSelector((state: RootState): string => {
    return state.league?.selectedLeagueRoundId;
  });
};

export const useSelectedLeagueTypeIsTeam = () => {
  const selectedLeague = useGetSelectedLeague();
  return selectedLeague?.settings.leagueType === LeagueType.TEAM ||
    selectedLeague?.settings.leagueScoringType === LeagueScoring.SCRAMBLE
    ? true
    : false;
};

export const useSelectedLeagueScoringTypeIsScramble = () => {
  const selectedLeague = useGetSelectedLeague();
  return selectedLeague?.settings.leagueScoringType === LeagueScoring.SCRAMBLE
    ? true
    : false;
};

export const useSelectedLeagueIsNetScoring = () => {
  const selectedLeague = useGetSelectedLeague();
  return selectedLeague?.settings.handicapMethod === LeagueHandicapMethod.NET
    ? true
    : false;
};

export const useIsMatchLeague = () => {
  const selectedLeague = useGetSelectedLeague();
  return selectedLeague?.settings.leagueScoringType === LeagueScoring.MATCH_PLAY
    ? true
    : false;
};

export const useIsScrambleLeague = () => {
  const selectedLeague = useGetSelectedLeague();
  return selectedLeague?.settings.leagueScoringType === LeagueScoring.SCRAMBLE
    ? true
    : false;
};

export const useIsSynchronousMatchLeague = () => {
  const selectedLeague = useGetSelectedLeague();

  return selectedLeague?.settings?.leagueScoringType ===
    LeagueScoring.MATCH_PLAY &&
    selectedLeague?.settings?.allowAsynchronousMatchPlay === false
    ? true
    : false;
};

export const useIsAsyncTeamLeague = () => {
  const selectedLeague = useGetSelectedLeague();

  return selectedLeague?.settings?.allowAsynchronousTeamPlay;
};

export const useGetSelectedLeague = (): League => {
  const currentFacilityId = useGetCurrentLocalFacilityId();
  const selectedLeagueId = useSelector(
    (state: RootState) => state.league?.selectedLeagueId
  );
  return useSelector(
    (state: RootState): League => {
      return state.entities.leagues?.byFacilityId?.[currentFacilityId]?.[
        selectedLeagueId
      ];
    }
  );
};

export const useLeaguesByFacilityId = (
  facilityId: string
): UseDataInfo<League[]> => {
  const [queryState, refreshLeagues] = useRequest(
    getLeaguesByFacilityId(facilityId)
  );

  const leagues = useSelector((state: RootState): League[] => {
    if (!state.entities.leagues?.byFacilityId?.[facilityId]) return [];

    let leagues: League[] = Object.values<League>(
      state.entities?.leagues?.byFacilityId?.[facilityId]
    );

    return leagues.sort(
      (a: League, b: League) =>
        new Date(b.leagueStartDate).valueOf() -
        new Date(a.leagueStartDate).valueOf()
    );
  });

  return [leagues, queryState, refreshLeagues];
};

export const useCreateLeague = (
  facilityId: string
): [QueryState, RunMutation<EntitiesState, [League]>] => {
  const [
    createLeagueQueryState,
    createLeague,
  ] = useMutation((body: LeaguePayload) =>
    makeCreateLeagueMutation(facilityId, body)
  );

  return [createLeagueQueryState, createLeague];
};

export const useDeleteLeague = (
  leagueId: string,
  facilityId: string
): [QueryState, RunMutation<EntitiesState>] => {
  const [queryState, deleteLeague] = useMutation(() =>
    makeDeleteLeagueMutation(leagueId, facilityId)
  );
  return [queryState, deleteLeague];
};

export const useUpdateLeague = (
  facilityId: string
): [QueryState, RunMutation<EntitiesState, [League]>] => {
  const [queryState, updateLeague] = useMutation((body: League) =>
    makeUpdateLeagueMutation(facilityId, body)
  );

  return [queryState, updateLeague];
};

export const useGetClubhouseLeagueDto = (
  facilityId: string,
  leagueId: string
): UseDataInfo<League> => {
  const [queryState, refreshLeague] = useRequest(
    getClubhouseLeagueDto(facilityId, leagueId)
  );

  const league = useSelector(
    (state: RootState): League => {
      return state.entities.leagues?.byFacilityId?.[facilityId]?.[leagueId];
    }
  );

  return [league, queryState, refreshLeague];
};
