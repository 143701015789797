import { useCallback, useEffect, useState } from "react";
import { RoundConfiguration } from "Models";
import { useAppDispatch } from "utils/hooks";
import { modalAction } from "ui-modules/modals/modalSlice";
import { useDeleteRoundConfiguration } from "modules/round-configuration/roundConfigurationHooks";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import {
  showCustomNotification,
  GenericNotificationType,
} from "utils/notifications/notificationHelpers";
import Row from "components/ui/layout/Row";
import Modal from "components/shared/Modal";
import { FaExclamationTriangle } from "react-icons/fa";

const RoundConfigDeleteConfirm: React.FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const currentFacilityId = useGetCurrentLocalFacilityId();
  const [, setConfirmDelete] = useState(false);
  const [
    deleteRoundConfigurationQueryState,
    deleteRoundConfiguration,
  ] = useDeleteRoundConfiguration(currentFacilityId, props.roundConfig.id);
  const useHandleDelete = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    deleteRoundConfiguration();
    setConfirmDelete(true);
  };

  const setModalClose = useCallback(() => {
    dispatch(modalAction({ isOpen: false, modalName: props.modalName }));
  }, [dispatch, props.modalName]);

  useEffect(() => {
    if (deleteRoundConfigurationQueryState.isFinished) {
      setModalClose();
      switch (deleteRoundConfigurationQueryState.status) {
        case 400:
          return showCustomNotification(
            "An error has occurred. Check the log for details.",
            GenericNotificationType.ERROR
          );
        default:
          return showCustomNotification(
            "Configuration has been deleted succesfully."
          );
      }
    }
  }, [
    deleteRoundConfigurationQueryState.isFinished,
    deleteRoundConfigurationQueryState.status,
    setModalClose,
  ]);

  return (
    <Modal
      hideFooter={false}
      hideHeader={true}
      modalName={props.modalName}
      body={
        <Row cssClasses="p-4">
          <Row cssClasses="text-md font-semibold my-4 text-center">
            <FaExclamationTriangle className="text-red-600 mr-1" /> Do you want
            to delete '{props.title}'?
          </Row>
          <Row cssClasses="mt-6 text-center">
            <button
              onClick={useHandleDelete}
              className="py-1 px-2 bg-red-500 hover:bg-red-700 text-white rounded shadow hover:shadow-inner hover:text-white text-sm font-semibold wfull md:w-1/3"
              tabIndex={4}
              value={props.roundConfig.id}
            >
              Yes, delete it
            </button>
          </Row>
        </Row>
      }
    />
  );
};

type Props = {
  title?: string;
  modalName: string;
  roundConfig: RoundConfiguration;
  action?: string;
};

RoundConfigDeleteConfirm.defaultProps = {
  title: "",
};

export default RoundConfigDeleteConfirm;
