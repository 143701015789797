import { useState } from "react";
import { styles } from "./Leagues.styes";
import { HandicapMode } from "types/enums";
import { MdExpandMore } from "react-icons/md";
import { MdOutlineClose } from "react-icons/md";
import { PlayerScorecardDto } from "ScorecardModels";
import { Controller, useFormContext } from "react-hook-form";
import { RankedScorecardPlayer, RoundPar } from "Models";
import { useGetTeamScorecardByRoundTeamId } from "modules/leagues/leagueScorecardHooks";
import { useSelectedLeagueRoundLeaderboardPlayer } from "modules/leagues/leagueRoundHooks";
import ScorecardHolesLayout from "components/scorecard/ScorecardHolesLayout";

type LeaguePlayerRankedScorecardProps = {
  callback?: any;
  playerRankedScorecard: RankedScorecardPlayer;
  finalizeScorecards?: boolean;
  index?: number;
  showNetScore?: boolean;
  roundPar: RoundPar;
  isTeamLeague?: boolean;
  participantNumber?: number;
};

const LeaguePlayerRankedScorecard: React.FC<LeaguePlayerRankedScorecardProps> = (
  props
) => {
  const {
    playerRankedScorecard: leaderboardPlayer,
    finalizeScorecards,
    index,
    showNetScore,
    callback,
    isTeamLeague,
  } = props;
  const { control } = useFormContext();

  const [showScorecard, setShowScorecard] = useState(false);

  const teamScorecard = useGetTeamScorecardByRoundTeamId(
    leaderboardPlayer?.roundTeamId
  );

  const roundLeaderboardPlayer = useSelectedLeagueRoundLeaderboardPlayer(
    leaderboardPlayer?.playerGuid
  );

  const playerScorecard: PlayerScorecardDto = {
    playerName:
      roundLeaderboardPlayer?.playerName || leaderboardPlayer.playerName,
    playerDisplayName: roundLeaderboardPlayer?.playerDisplayName,
    playerGuid: roundLeaderboardPlayer?.playerGuid || "",
    userId: "",
    teeUsed: 0,
    handicapUsed: showNetScore ? HandicapMode.ABSOLUTE : HandicapMode.OFF,
    gender: roundLeaderboardPlayer?.gender,
    aGHandicap: roundLeaderboardPlayer?.agHandicap || 0,
    partOfLeague: roundLeaderboardPlayer?.partOfLeague,
    partOfRound: roundLeaderboardPlayer?.partOfRound,
    holes:
      roundLeaderboardPlayer?.holes.map((hole) => ({
        holeNumber: hole.holeNumber,
        par: hole.par,
        selected: hole.selected,
        handicapIndex: hole.handicapIndex,
        gross: hole.gross,
        net: hole.net,
        conceded: hole.conceded,
        operatorAdjustedScore: hole.operatorAdjustedScore,
      })) || [],
    gross: roundLeaderboardPlayer?.gross || {
      total: 0,
      in: 0,
      out: 0,
      strokesOverPar: 0,
      strokesOverParDetail: "",
    },
    net: roundLeaderboardPlayer?.net || {
      total: 0,
      in: 0,
      out: 0,
      strokesOverPar: 0,
      strokesOverParDetail: "",
    },
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      e.currentTarget.blur();
    }
  };

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={`w-full  ${
        showScorecard
          ? "px-4 md:px-12 bg-gray-50 dark:bg-zinc-600"
          : "px-4 md:px-12"
      }`}
    >
      <div
        key={leaderboardPlayer.playerGuid}
        className={`${styles.modalResultsRow} ${
          showScorecard ? "dark:text-black " : "dark:text-gray-200"
        }`}
      >
        <div className="col-span-1">
          {isTeamLeague ? (
            ""
          ) : leaderboardPlayer.currentRound?.place !== 0 ? (
            leaderboardPlayer.currentRound?.placeDisplay
          ) : (
            <span className="text-gray-400">
              {leaderboardPlayer.previousRound?.placeDisplay}
            </span>
          )}
        </div>
        <div className="col-span-3">{leaderboardPlayer.playerName}</div>
        <div className="hidden md:inline-block col-span-2">
          {leaderboardPlayer.agHandicap}
        </div>
        <div className="hidden md:inline-block col-span-2">
          {showNetScore
            ? roundLeaderboardPlayer?.net.total
            : roundLeaderboardPlayer?.gross.total}
        </div>

        {finalizeScorecards ? (
          <div className="col-span-2 text-left">
            <Controller
              name={`players.[${index}].points`}
              control={control}
              rules={{
                required: "Please enter a point value",
              }}
              defaultValue={leaderboardPlayer.currentRound?.points.toString()}
              render={({ field }) => (
                <input
                  {...field}
                  type="number"
                  min={0}
                  max={100}
                  step={0.5}
                  className="border-1 border-gray-200 rounded-lg w-20 text-xs font-normal p-1"
                  onKeyDown={(e) => handleKeyDown(e)}
                />
              )}
            />
            <Controller
              name={`players.[${index}].playerRoundLeaderBoardId`}
              control={control}
              defaultValue={leaderboardPlayer.playerRoundLeaderBoardId.toString()}
              render={({ field }) => (
                <input
                  {...field}
                  type="hidden"
                  className="border-1 border-gray-200 rounded-lg w-20 text-xs font-normal p-1"
                />
              )}
            />
          </div>
        ) : (
          <div className="col-span-2 pl-2">
            <span className="border-1 border-transparent rounded-lg w-20 text-xs font-normal p-1">
              {leaderboardPlayer.currentRound?.points}
            </span>
          </div>
        )}
        <div className="col-span-1">
          {showNetScore
            ? roundLeaderboardPlayer?.net.strokesOverParDetail
            : roundLeaderboardPlayer?.gross.strokesOverParDetail}
        </div>
        <div className="col-span-1 items-center text-right pr-2">
          <div
            onClick={() => setShowScorecard(!showScorecard)}
            className={styles.showScorecardButton}
          >
            {showScorecard ? (
              <>
                <MdOutlineClose
                  className={showScorecard ? "font-bold size-4" : "hidden"}
                />
              </>
            ) : (
              <>
                <MdExpandMore className="size-4" />
              </>
            )}
          </div>
        </div>
      </div>
      {showScorecard && (
        <div className="text-xs bg-gray-50 dark:bg-zinc-600 rounded-bl-lg rounded-br-lg dark:rounded-none p-0 md:p-4">
          <ScorecardHolesLayout
            playerScorecard={Array(playerScorecard)}
            allowEditing={true}
            scorecardId={roundLeaderboardPlayer?.scoreCardId}
            teamScorecard={teamScorecard}
            showNetScore={showNetScore}
            callback={callback}
          />
        </div>
      )}
    </div>
  );
};

export default LeaguePlayerRankedScorecard;
