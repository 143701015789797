import { Fragment } from "react";
import { RootState } from "StoreTypes";
import { useSelector } from "react-redux";
import { useAppDispatch } from "utils/hooks";
import { MdOutlineClose } from "react-icons/md";
import { Dialog, Transition } from "@headlessui/react";
import { navOverlayAction } from "ui-modules/nav/navSlice";
import Logo from "./Logo";
import Menu from "./Menu";
import LogoutButton from "./LogoutButton";
import DesktopSidebar from "./DesktopSidebar";

const LeftSidebar: React.FC = () => {
  const dispatch = useAppDispatch();

  const isMobileMenuOpen = useSelector(
    (state: RootState) => state.nav?.navOverlay?.isOpen
  );

  const setMenuOverlay = () =>
    dispatch(navOverlayAction({ isOpen: !isMobileMenuOpen }));

  return (
    <>
      <Transition.Root show={isMobileMenuOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setMenuOverlay}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 flex ">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setMenuOverlay()}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <MdOutlineClose
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>

                <div
                  className="flex grow w-full flex-col gap-y-5 overflow-y-auto bg-white  dark:bg-zinc-800  px-6 pb-4"
                  id="sidebarMobileMenuPanel"
                >
                  <div className="flex h-16 shrink-0 items-center">
                    <Logo logoSize="logo-medium" />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul
                      className="flex flex-1 flex-col gap-y-7"
                      id="mobileMenu"
                    >
                      <li>
                        <Menu />
                      </li>
                      <li></li>
                      <li className="mt-auto">
                        <LogoutButton />
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <DesktopSidebar />
    </>
  );
};
export default LeftSidebar;
