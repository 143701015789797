import { DateTime } from "luxon";
import { PlayerUser } from "Models";
import { useEffect, useState } from "react";
import { IoSearchOutline } from "react-icons/io5";
import { styles } from "../scorecard/Scorecard.styles";
import { scorcardSearchStyle } from "utils/ui/uiHelpers";
import { userSearch } from "utils/userSearch/userSearchHelpers";
import { LeagueScorecardPayload, RoundScorecardDto } from "ScorecardModels";
import { leagueQueryDefaultOptions } from "modules/leagues/leagueHelpers";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import {
  useSelectedLeagueId,
  useSelectedLeagueRoundId,
} from "modules/leagues/leagueRoundHooks";
import {
  showCustomNotification,
  GenericNotificationType,
} from "utils/notifications/notificationHelpers";
import axios from "axios";
import debounce from "debounce-promise";
import DatePicker from "react-datepicker";
import AsyncSelect from "react-select/async";
import ScorecardSearchFormattedOptions from "../scorecard/ScorecardSearchFormattedOptions";
import LeagueRoundScorecardSearchResults from "./LeagueRoundScorecardSearchResults";

const LeagueScorecardSearch: React.FC = () => {
  const currentFacilityId = useGetCurrentLocalFacilityId();
  const selectedLeagueId = useSelectedLeagueId();
  const selectedLeagueRoundId = useSelectedLeagueRoundId();

  const [selectedPlayers, setSelectedPlayers] = useState<PlayerUser[]>();
  const [
    scoreCardSearchData,
    setScorecardSearchData,
  ] = useState<LeagueScorecardPayload>({
    playerName: [],
    startDate: new Date(DateTime.local().minus({ days: 30 }).toLocaleString()),
    endDate: new Date(),
  });

  const [leagueRoundScorecards, setLeagueRoundScorecards] = useState<
    RoundScorecardDto[]
  >([]);

  const getAsyncOptions = (inputValue: string) => {
    return new Promise<PlayerUser[]>((resolve) => {
      if (inputValue.length > 3) {
        const options = userSearch(inputValue);
        resolve(options);
      } else {
        const options: PlayerUser[] = [];
        resolve(options);
      }
    });
  };

  const loadOptions = (inputValue: string) => {
    return getAsyncOptions(inputValue);
  };

  const debouncedLoadOptions = debounce(loadOptions, 500, {
    leading: true,
  });

  function usehandleSelectUser(selectedOptions: any) {
    setSelectedPlayers(selectedOptions);
    if ((selectedOptions as PlayerUser[]).length > 0) {
      setScorecardSearchData({
        ...scoreCardSearchData,
        playerName: (selectedOptions as PlayerUser[]).map(
          (player) => player.username
        ),
      });
    }
  }

  const handleClearScorecards = () => {
    setSelectedPlayers([]);
    setLeagueRoundScorecards([]);
    setScorecardSearchData({
      playerName: [""],
      startDate: new Date(
        DateTime.local().minus({ days: 30 }).toLocaleString()
      ),
      endDate: new Date(),
    });
  };

  useEffect(() => {
    let url = `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facility/${currentFacilityId}/league/${selectedLeagueId}/round/${selectedLeagueRoundId}/scorecards/search?includeIncompleteRounds=true&matchAnyTeamMembers=true`;
    if (scoreCardSearchData?.playerName?.length) {
      url = `${url}&name=${scoreCardSearchData.playerName}`;
    }
    if (scoreCardSearchData.startDate)
      url = `${url}&overrideRoundStart=${scoreCardSearchData.startDate.toISOString()}`;
    if (scoreCardSearchData.endDate)
      url = `${url}&overrideRoundEnd=${scoreCardSearchData.endDate.toISOString()}`;

    axios
      .get<RoundScorecardDto[]>(url, {
        ...leagueQueryDefaultOptions(),
      })
      .then((res) => {
        setLeagueRoundScorecards(res.data);
      })
      .catch((error) => {
        showCustomNotification(
          "An error occurred while searching for scorecards. Please try again. " +
            error,
          GenericNotificationType.ERROR
        );
      });
  }, [
    selectedPlayers,
    scoreCardSearchData,
    currentFacilityId,
    selectedLeagueId,
    selectedLeagueRoundId,
  ]);

  return (
    <>
      <div className="flex flex-row mt-8 bg-brandLightGrey dark:bg-zinc-600 rounded-full p-1 py-1.5 shadow border border-gray-100">
        <div className="flex flex-none w-1/2 mr-1">
          <AsyncSelect
            RequireAtLeastOne
            isMulti
            backspaceRemovesValue={false}
            noOptionsMessage={() => "No users found."}
            loadOptions={(query) => debouncedLoadOptions(query)}
            getOptionValue={(player: PlayerUser) => JSON.stringify(player)}
            filterOptions={false}
            formatOptionLabel={(option, { context }) => {
              return (
                <ScorecardSearchFormattedOptions
                  value={option}
                  context={context}
                />
              );
            }}
            styles={scorcardSearchStyle}
            placeholder={"Search by name and date range"}
            autoFocus
            id="lockerUserEditFormSearchField"
            key={`lockerUserEditFormSearchField__`}
            className="w-full"
            tabIndex="1"
            value={selectedPlayers}
            onChange={(options) => usehandleSelectUser(options)}
          />
        </div>
        <div className="flex flex-row mt-0.5 gap-1">
          <div className="flex w-2/5 rounded-full">
            <DatePicker
              onChange={(date: any) =>
                setScorecardSearchData({
                  ...scoreCardSearchData,
                  startDate: date,
                })
              }
              dateFormat="MMM d, yyyy"
              className={styles.datePicker}
              placeholderText="📅 Start"
              showTimeSelect
              selected={scoreCardSearchData.startDate}
              tabIndex={2}
            />
          </div>
          <div className="flex w-2/5">
            <DatePicker
              onChange={(date: any) =>
                setScorecardSearchData({
                  ...scoreCardSearchData,
                  endDate: date,
                })
              }
              dateFormat="MMM d, yyyy"
              className={styles.datePicker}
              placeholderText="📅 End"
              showTimeSelect
              selected={scoreCardSearchData.endDate}
              tabIndex={3}
            />
          </div>
          <div className="flex w-auto">
            <button className={styles.scorecardSearchButton} tabIndex={4}>
              <IoSearchOutline className="size-4 font-normal" />
            </button>
          </div>
        </div>
      </div>

      <>
        {leagueRoundScorecards?.length === 0 && selectedPlayers && (
          <div className="flex flex-row pt-4 ">
            <span className="text-brandGreen rounded-full poppins text-xs px-4 py-2">
              No scorecards found
            </span>
          </div>
        )}
        {leagueRoundScorecards?.length > 0 && (
          <div className="flex flex-row pt-4 ">
            <button
              className=" bg-darkCream text-brandGreen rounded-full poppins shadow text-xs px-4 py-2"
              onClick={() => handleClearScorecards()}
            >
              Clear
            </button>
          </div>
        )}
      </>

      <LeagueRoundScorecardSearchResults
        leagueRoundScorecards={leagueRoundScorecards}
      />
    </>
  );
};
export default LeagueScorecardSearch;
