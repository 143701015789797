import { LeagueRound } from "Models";
import { motion } from "framer-motion";
import { styles } from "../Leagues.styes";
import { titleCase } from "utils/enums/enumHelper";
import { useSelectedLeagueIsNetScoring } from "modules/leagues/leagueHooks";
import {
  AutoConcede,
  AutoContinue,
  AutoGimme,
  GimmeDistance,
  Ground,
  HandicapMode,
  PinType,
  ScoringType,
  Sky,
  TeeType,
  TimeOfDay,
  WindDirection,
  WindSpeed,
} from "types/enums";

const LeagueRoundCardDetails: React.FC<LeagueRoundCardDetailsProps> = (
  props
) => {
  const { selectedRound } = props;
  const isNetScoring = useSelectedLeagueIsNetScoring();

  return (
    <motion.div
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      key={`leagueRoundCardDetails-${selectedRound.id}`}
    >
      <div className={`${styles.leagueCardDateRow} border-t border-gray-200`}>
        <div className={styles.leagueCardCol}>
          <div className={styles.titleRow}>Auto Concede</div>
          <div className={styles.titleDescription}>
            {titleCase(AutoConcede[selectedRound.courseSettings?.autoConcede])}
          </div>
          <div className={styles.titleRow}>Auto Continue</div>
          <div className={styles.titleDescription}>
            {titleCase(
              AutoContinue[selectedRound.courseSettings?.autoContinue]
            )}
          </div>
          <div className={styles.titleRow}></div>
        </div>
        <div className={styles.leagueCardCol}>
          <div className={styles.titleRow}>Auto Drop</div>
          <div className={styles.titleDescription}>
            {selectedRound.courseSettings?.autoDrop ? "On" : "Off"}
          </div>
          <div className={styles.titleRow}>Auto Gimmes</div>
          <div className={styles.titleDescription}>
            {titleCase(AutoGimme[selectedRound.courseSettings?.autoGimmes])}
          </div>
          <div className={styles.titleRow}></div>
        </div>
      </div>
      <div className={styles.leagueCardDetailsRow}>
        <div className={styles.leagueCardCol}>
          <div className={styles.titleRow}>Gimme Distance</div>
          <div className={styles.titleDescription}>
            {titleCase(
              GimmeDistance[selectedRound.courseSettings?.gimmeDistance]
            )}
          </div>
          <div className={styles.titleRow}>Ground Conditions</div>
          <div className={styles.titleDescription}>
            {titleCase(Ground[selectedRound.courseSettings?.groundConditions])}
          </div>
          <div className={styles.titleRow}></div>
        </div>
        <div className={styles.leagueCardCol}>
          <div className={styles.titleRow}>Handicap</div>
          <div className={styles.titleDescription}>
            {isNetScoring
              ? "On"
              : titleCase(
                  HandicapMode[selectedRound.courseSettings?.handicapMode]
                )}
          </div>
          <div className={styles.titleRow}>Mulligans</div>
          <div className={styles.titleDescription}>
            {selectedRound.courseSettings?.mulligans ? "On" : "Off"}
          </div>
          <div className={styles.titleRow}></div>
        </div>
      </div>
      <div className={styles.leagueCardDetailsRow}>
        <div className={styles.leagueCardCol}>
          <div className={styles.titleRow}>Mens Tee</div>
          <div className={styles.titleDescription}>
            {titleCase(TeeType[selectedRound.courseSettings?.mensTee])}
          </div>
          <div className={styles.titleRow}>Pin Type</div>
          <div className={styles.titleDescription}>
            {titleCase(PinType[selectedRound.courseSettings?.pinType])}
          </div>
          <div className={styles.titleRow}></div>
        </div>
        <div className={styles.leagueCardCol}>
          <div className={styles.titleRow}>Scoring Type</div>
          <div className={styles.titleDescription}>
            {titleCase(ScoringType[selectedRound.courseSettings?.scoringType])}
          </div>
          <div className={styles.titleRow}>Sky Conditions</div>
          <div className={styles.titleDescription}>
            {titleCase(Sky[selectedRound.courseSettings?.skyConditions])}
          </div>

          <div className={styles.titleRow}></div>
        </div>
      </div>
      <div className={styles.leagueCardDetailsRow}>
        <div className={styles.leagueCardCol}>
          <div className={styles.titleRow}>Stimp Settings</div>
          <div className={styles.titleDescription}>
            {selectedRound.courseSettings?.stimpSetting}
          </div>
          <div className={styles.titleRow}>Time Of Day</div>
          <div className={styles.titleDescription}>
            {titleCase(TimeOfDay[selectedRound.courseSettings?.timeConditions])}
          </div>

          <div className={styles.titleRow}></div>
        </div>
        <div className={styles.leagueCardCol}>
          <div className={styles.titleRow}>Wind Direction</div>
          <div className={styles.titleDescription}>
            {WindDirection[selectedRound.courseSettings?.windDirection]}
          </div>
          <div className={styles.titleRow}>Wind Speed</div>
          <div className={styles.titleDescription}>
            {titleCase(WindSpeed[selectedRound.courseSettings?.windSpeed])}
          </div>

          <div className={styles.titleRow}></div>
        </div>
      </div>
      <div className={styles.leagueCardDetailsRow}>
        <div className={styles.leagueCardCol}>
          <div className={styles.titleRow}>Womens Tee</div>
          <div className={styles.titleDescription}>
            {titleCase(TeeType[selectedRound.courseSettings?.womensTee])}
          </div>
          <div className={styles.titleRow}></div>
          <div className={styles.titleDescription}></div>

          <div className={styles.titleRow}></div>
        </div>
        <div className={styles.leagueCardCol}>
          <div className={styles.titleRow}>Holes</div>
          <div className={styles.titleDescription}>
            {selectedRound.courseSettings?.selectedHoles.length}
          </div>
          <div className={styles.titleRow}></div>
          <div className={styles.titleDescription}></div>

          <div className={styles.titleRow}></div>
        </div>
      </div>
    </motion.div>
  );
};
export default LeagueRoundCardDetails;

type LeagueRoundCardDetailsProps = {
  selectedRound: LeagueRound;
};
