import { FormRow } from "components/ui/form";
import { eventStartDatePassed } from "modules/events/eventHelpers";
import { useSelectedEvent } from "modules/events/eventHooks";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { useEffect, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import Select from "react-select";
import {
  EventCompetitionType,
  EventHandicapMode,
  GimmeDistance,
  HandicapPercentage,
} from "types/enums";
import { roundConfigEnumsAsReactSelectOptions } from "utils/enums/enumHelper";
import { customStyles } from "utils/ui/uiHelpers";
import { styles } from "./Events.styles";

const CreateEventFormCompetitionOptionContainer: React.FC = () => {
  const { control, setValue, getValues, watch, clearErrors } = useFormContext();

  const competitionType = watch("type") || EventCompetitionType.STROKE;

  const hideStrokeOnlyInputs = useMemo(() => {
    return competitionType !== EventCompetitionType.STROKE;
  }, [competitionType]);

  const currentFacilityId = useGetCurrentLocalFacilityId();
  const selectedEvent = useSelectedEvent(currentFacilityId);
  const startDatePassed = selectedEvent
    ? eventStartDatePassed(selectedEvent.startDate)
    : false;
  const mulliganOptions = [
    { label: "On", value: true },
    { label: "Off", value: false },
  ];
  const handleHandicapChange = (e: { label: string; value: string }) => {
    const applyHandicaps = e.value === EventHandicapMode.OFF ? false : true;

    setValue("handicapMode", e.value);
    setValue("applyHandicaps", applyHandicaps);
  };

  const handleGimmesChange = (value: string) => {
    setValue("gimmes", value);
  };

  const handleMulligansChange = (value: boolean) => {
    setValue("mulligans", value);
    clearErrors("mulligans");
  };

  useEffect(() => {
    if (!selectedEvent) {
      setValue("type", EventCompetitionType.STROKE);
      setValue("handicapMode", EventHandicapMode.COURSE_HANDICAP);
      setValue("handicapLevel", HandicapPercentage["100%"]);
      setValue("gimmes", GimmeDistance.OFF);
      setValue("mulligans", false);
    }
  }, [setValue, selectedEvent]);

  return (
    <div id="competition" className="mt-6">
      <FormRow header="Tournament Replays" fontSize={styles.formRowLabel}>
        <Controller
          name="replays"
          control={control}
          defaultValue={1}
          render={({ field }) => (
            <input
              {...field}
              type="number"
              data-testid="replays"
              required
              min={0}
              step={1}
              max={99}
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-100"
              disabled={startDatePassed}
              id="eventReplays"
            />
          )}
        />
      </FormRow>
      <FormRow
        header="Handicap"
        fontSize={styles.formRowLabel}
        hidden={hideStrokeOnlyInputs}
      >
        <Controller
          name="handicapMode"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              options={roundConfigEnumsAsReactSelectOptions.eventsHandicapMode}
              value={roundConfigEnumsAsReactSelectOptions.eventsHandicapMode.find(
                (c) => c.value === getValues("handicapMode")
              )}
              onChange={(e: { label: string; value: any }) =>
                handleHandicapChange(e)
              }
              styles={customStyles}
              isDisabled={startDatePassed}
              className="disabled:cursor-not-allowed disabled:bg-gray-100"
              id="eventHandicapMode"
            />
          )}
        />
        <Controller
          name="handicapLevel"
          control={control}
          defaultValue={HandicapPercentage["100%"]}
          render={({ field }) => <input {...field} type="hidden" />}
        />
      </FormRow>
      <FormRow
        header="Gimme Distance"
        fontSize={styles.formRowLabel}
        hidden={hideStrokeOnlyInputs}
      >
        <Controller
          name="gimmes"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              closeMenuOnSelect={true}
              isMulti={false}
              options={roundConfigEnumsAsReactSelectOptions.eventGimmeDistance}
              value={roundConfigEnumsAsReactSelectOptions.eventGimmeDistance.find(
                (c) => c.value === watch("gimmes")
              )}
              onChange={(e: { label: string; value: string }) =>
                handleGimmesChange(e.value)
              }
              styles={customStyles}
              isDisabled={startDatePassed}
              className="disabled:cursor-not-allowed disabled:bg-gray-100"
              id="eventGimmeDistance"
            />
          )}
        />
      </FormRow>
      {!hideStrokeOnlyInputs && (
        <FormRow header="Allow Mulligans" fontSize={styles.formRowLabel}>
          <Controller
            name={"mulligans"}
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                closeMenuOnSelect={true}
                isMulti={false}
                options={[
                  { label: "On", value: true },
                  { label: "Off", value: false },
                ]}
                value={mulliganOptions.find(
                  (c) => c.value === watch("mulligans")
                )}
                onChange={(e: { label: string; value: boolean }) =>
                  handleMulligansChange(e.value)
                }
                styles={customStyles}
                isDisabled={startDatePassed}
                className="disabled:cursor-not-allowed disabled:bg-gray-100"
                id="eventMulligans"
              />
            )}
          />
        </FormRow>
      )}
    </div>
  );
};
export default CreateEventFormCompetitionOptionContainer;
