import { ScoringType } from "types/enums";
import { styles } from "./Scorecard.styles";
import { FaAngleRight } from "react-icons/fa";
import { titleCase } from "utils/enums/enumHelper";
import { AnimatePresence, motion } from "framer-motion";
import { MdOutlineClose } from "react-icons/md";
import { datetimeLocalFromUtcIso } from "../../utils/ui/uiHelpers";
import { showMatchWinnerBadge } from "modules/leagues/leagueHelpers";
import { dailyQueueListItemVariant } from "utils/animations/animationHelper";
import {
  isRoundMatchPlay,
  teamColorHelper,
} from "../../modules/scorecard-lookup/scorecardHelpers";
import {
  GameScorecard,
  PlayerScorecardDto,
  RoundScorecardDto,
} from "ScorecardModels";
import ScorecardDetails from "./ScorecardDetails";

const ScorecardListItem: React.FC<ScorecardListItemProps> = (props) => {
  const { scorecard, isOpen, callback } = props;

  const scorecardScoringType =
    "scoringType" in scorecard ? scorecard.scoringType : 0;
  const matchPlay = isRoundMatchPlay(scorecardScoringType);

  const TeamPlayers = ({ team }: { team: PlayerScorecardDto[] }) => {
    return (
      <>
        {team.length > 1 ? (
          <>
            <span className="mb-1 text-xs">Team {team[0].team}</span>
            {team.map((player) => (
              <div className="flex w-full items-left text-left align-left justify-start pb-1 text-[12px] text-brandDarkGreen">
                <div
                  className={`inline-block mt-1 mr-1 h-2 w-2 ${
                    teamColorHelper[player.team || 0]
                  }`}
                ></div>
                {player.playerName}
              </div>
            ))}
          </>
        ) : (
          <div className="flex text-brandDarkGreen">
            <div
              className={`inline-block mt-1 mr-1 h-2 w-2 ${
                teamColorHelper[team[0]?.team || 0]
              }`}
            ></div>
            {team[0]?.playerName}
          </div>
        )}
      </>
    );
  };

  const ScorecardListHeader = () => {
    return (
      <div className={styles.eventContainer}>
        <div className="flex flex-col justify-between items-left w-3/4">
          <ul className={`text-xs inline justify-items-start`}>
            {scorecard?.players.map((player) => (
              <li className="inline font-semibold text-brandDarkGreen dark:text-gray-50">
                {player.playerName},{" "}
              </li>
            ))}
            <div className={styles.eventContainerBottomRow}></div>
          </ul>
          <div className={`${styles.scorecardViewDetailsHeader} flex-col`}>
            <div className="sm:flex-row mb-1">
              <p className={styles.eventPlayers}>
                <span className=" dark:text-gray-50">
                  {scorecard?.courseName}
                </span>
                <span className="mx-2 text-gray-100 dark:text-gray-50">
                  {" "}
                  |{" "}
                </span>
                {datetimeLocalFromUtcIso(scorecard?.createdAt.toString())}
              </p>
            </div>
            <div className="sm:flex flex-row w-full text-xs dark:text-gray-50">
              <p className={styles.eventPlayers}>
                <span className="mr-2">Format:</span>{" "}
                {titleCase(ScoringType[scorecardScoringType])}
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-1/4 justify-items-end content-center">
          <div className={styles.eventOpenItem}>
            <button
              onClick={() => callback("")}
              className={styles.showScorecardButton}
            >
              {isOpen ? (
                <>
                  Close
                  <MdOutlineClose
                    className={isOpen ? "font-bold ml-1 " : "hidden"}
                  />
                </>
              ) : (
                <>
                  View Scorecard <FaAngleRight />
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    );
  };

  const MatchPlayListHeader = () => {
    const matchResults = (scorecard as GameScorecard).matchResults;
    const matchPlayers = (scorecard as GameScorecard).players;

    return (
      <div className={styles.scorecardListItemHeaderContainer}>
        <div className={styles.scorecardListItemHeaderColumnOne}>
          <div className={styles.scorecardListItemHeaderInnerContainer}>
            <div className={styles.scorecardListItemTeamNameColumn}>
              <div
                className={`${styles.scorecardListPlayerName} flex-col w-1/2`}
              >
                <TeamPlayers
                  team={matchPlayers.filter((player) => player.team === 1)}
                />
              </div>
              <div
                className={`${styles.scorecardListPlayerName} flex-col  w-1/2`}
              >
                <TeamPlayers
                  team={matchPlayers.filter((player) => player.team === 2)}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="sm:flex w-full pb-1">
              <p className={styles.eventPlayers}>
                <span className="">{scorecard?.courseName}</span>
                <span className="mx-2 text-gray-100">| </span>
                {datetimeLocalFromUtcIso(scorecard?.createdAt.toString())}
              </p>
            </div>
            <div className={styles.scorecardlistItemFormatRow}>
              <p className={styles.eventPlayers}>
                <span className="mr-2">Format:</span>{" "}
                {titleCase(ScoringType[scorecardScoringType])}
              </p>
            </div>
          </div>
        </div>
        <div className={styles.scorecardListItemHeaderColumnTwo}>
          <div className="flex justify-end text-right mb-2">
            <span
              className={`${styles.scorecardListItemWinner} 
              ${showMatchWinnerBadge(matchResults?.winner)}`}
            >
              Wins
            </span>
          </div>
          <div className={styles.scorecardListItemBottomRow}>
            <button
              onClick={() => callback("")}
              className={styles.showScorecardButton}
            >
              {isOpen ? (
                <>
                  Close
                  <MdOutlineClose
                    className={isOpen ? "font-bold ml-1 " : "hidden"}
                  />
                </>
              ) : (
                <>
                  View Scorecard <FaAngleRight />
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <motion.li
      animate={{ opacity: 1, x: 0 }}
      layout
      variants={dailyQueueListItemVariant}
      className={styles.eventListItemHighlight}
      key={`scorecardListItem-${scorecard.saveGameId}`}
    >
      <motion.div
        animate={{ opacity: 1, x: 0 }}
        layout
        className="block cursor-default bg-brandLightGrey dark:bg-zinc-600 rounded-lg"
      >
        {matchPlay ? <MatchPlayListHeader /> : <ScorecardListHeader />}
      </motion.div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            layout
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={`pb-2 px-6 cursor-default bg-brandLightGrey dark:bg-zinc-600 mt-2 rounded-lg ${
              isOpen ? "border-gray-400" : "border-gray-200"
            }`}
            key={`selectedScorecardItem-${scorecard.saveGameId}`}
          >
            <ScorecardDetails
              playerScorecard={scorecard.players}
              saveGameId={scorecard?.saveGameId}
              scoringType={scorecardScoringType}
              roundFinished={scorecard?.roundFinished}
              matchResults={
                matchPlay
                  ? (scorecard as GameScorecard).matchResults
                  : undefined
              }
              handicapMode={scorecard?.handicapMode}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </motion.li>
  );
};
export default ScorecardListItem;

type ScorecardListItemProps = {
  scorecard: GameScorecard | RoundScorecardDto;
  isOpen?: boolean;
  callback: React.Dispatch<React.SetStateAction<string>>;
};

ScorecardListItem.defaultProps = {};
