import { styles } from "./Leagues.styes";
import { useAppDispatch } from "utils/hooks";
import { AiOutlinePlus } from "react-icons/ai";
import { ClubhouseLeagueRoundPlayerDto } from "Models";
import { disableBackgroundScroll } from "utils/ui/uiHelpers";
import { leagueScorecardTrayAction } from "ui-modules/nav/navSlice";

type LeaguePlayerNoRoundScorecardProps = {
  callback?: React.Dispatch<React.SetStateAction<boolean>>;
  leaderboardPlayer: ClubhouseLeagueRoundPlayerDto;
};

const LeaguePlayerNoRoundScorecard: React.FC<LeaguePlayerNoRoundScorecardProps> = (
  props
) => {
  const dispatch = useAppDispatch();
  const { leaderboardPlayer } = props;

  const setScorecardTray = () => {
    disableBackgroundScroll();
    dispatch(leagueScorecardTrayAction({ isOpen: true }));
  };

  return (
    <div onClick={(e) => e.stopPropagation()} className="w-full px-4">
      <div
        key={leaderboardPlayer.playerId}
        className={styles.modalNoScorecardPlayerRow}
      >
        <div className="col-span-1">-</div>
        <div className="col-span-3">
          {leaderboardPlayer.firstName
            ? leaderboardPlayer.firstName + " " + leaderboardPlayer.lastName
            : leaderboardPlayer.userName}
        </div>
        <div className="hidden md:inline-block col-span-2">
          {leaderboardPlayer.agHandicap}
        </div>
        <div className="col-span-2 pl-1">-</div>
        <div className="col-span-2">-</div>
        <div className="col-span-1">-</div>
        <div className="col-span-1 items-center text-right pr-2">
          <div
            onClick={() => setScorecardTray()}
            className={styles.showScorecardButton}
          >
            <AiOutlinePlus className="size-4 p-1" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaguePlayerNoRoundScorecard;
