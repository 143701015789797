import { useEffect, useState } from "react";
import { useAppDispatch } from "utils/hooks";
import { ModalType } from "utils/modals/modalHelpers";
import { modalAction } from "ui-modules/modals/modalSlice";
import { setSelectedQueueItem } from "ui-modules/queue/queueSlice";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { useSelectedQueueItem } from "modules/simulator-reservations/simulatorReservationHooks";
import Row from "components/ui/layout/Row";
import Modal from "components/shared/Modal";
import QueueItemContainer from "./QueueItemContainer";

const QueueModal: React.FC<LeagueRoundGroupModalProps> = (props) => {
  const dispatch = useAppDispatch();

  const currentFacilityId = useGetCurrentLocalFacilityId();
  const selectedQueueItem = useSelectedQueueItem(currentFacilityId);
  const [createReservation, setCreateReservation] = useState(
    selectedQueueItem ? true : false
  );

  const handleCloseModal = async () => {
    await dispatch(
      setSelectedQueueItem({
        selectedQueueItem: "",
      })
    );
    await dispatch(
      modalAction({
        isOpen: false,
        modalName: ModalType.QueueModal,
      })
    );
  };

  useEffect(() => {
    selectedQueueItem
      ? setCreateReservation(true)
      : setCreateReservation(false);
  }, [selectedQueueItem]);

  return (
    <Modal
      modalName={props.modalName}
      closeModalCallback={handleCloseModal}
      hideHeader={false}
      title={selectedQueueItem ? "Edit Reservation" : "Create Reservation"}
      hideFooter={true}
      modalSize="w-full h-full md:w-1/2 2xl:w-1/2"
      body={
        <Row cssClasses="px-8 pb-4">
          <div className=" flex-row justify-end hidden">
            {!createReservation ? (
              <button
                className="poppins bg-brandYellow hover:bg-brandGreen dark:bg-brandYellow brand-dark-gray text-xs hover:text-white hover:dark:text-black poppins px-4 py-2 border dark:border-none border-white rounded-xl"
                onClick={() => setCreateReservation(true)}
              >
                Create Reservation
              </button>
            ) : (
              <button
                className="poppins bg-brandYellow hover:bg-brandGreen dark:bg-brandYellow brand-dark-gray text-xs hover:text-white hover:dark:text-black poppins px-4 py-2 border dark:border-none border-white rounded-xl"
                onClick={() => {
                  setCreateReservation(false);
                  dispatch(
                    setSelectedQueueItem({
                      selectedQueueItem: "",
                    })
                  );
                }}
              >
                Reservation List
              </button>
            )}
          </div>

          <QueueItemContainer />
        </Row>
      }
    />
  );
};

type LeagueRoundGroupModalProps = {
  title?: string;
  modalName: string;
};

QueueModal.defaultProps = {
  title: "Round Presets",
};

export default QueueModal;
