import { DateTime } from "luxon";
import { ErrorMessage } from "@hookform/error-message";
import { useFormContext } from "react-hook-form";
import { FaRegClock } from "react-icons/fa";

const TimerSelection: React.FC = () => {
  const methods = useFormContext();

  return (
    <div className="flex flex-wrap">
      <div className="flex flex-row h-10 w-full">
        <div className="flex w-32 border-r border-gray-200">
          <input
            className="border-2 border-gray-200 dark:text-black w-24 rounded-lg pl-2"
            defaultValue={0}
            type="number"
            min={0}
            {...methods.register("time", {
              valueAsNumber: true,
              value: 0,
              min: {
                value: 0,
                message: "Timer value must be positive",
              },
            })}
          />

          <ErrorMessage
            errors={methods.formState.errors}
            name="time"
            render={({ message }) => (
              <div className="text-sm text-red-500">{message}</div>
            )}
          />
        </div>
        <div className="flex w-auto p-4 rounded-lg text-brandDarkGray dark:text-gray-200 poppins text-sm items-center">
          <FaRegClock className="text-gray-600 mr-2 h-4 w-4" />
          {methods.watch("time") > 0 ? (
            <span className="text-brandDarkGray dark:text-gray-200">
              End time:{" "}
              {DateTime.now()
                .plus({ minutes: methods.watch("time") })
                .toFormat("h:mm a")}
            </span>
          ) : (
            <span className="text-gray-500 dark:text-gray-200">Timer off</span>
          )}
        </div>
      </div>
      <div className="text-brandDarkGray dark:text-gray-200 text-xs block pt-2">
        Entering a number above will add minutes to the timer
      </div>
    </div>
  );
};

export default TimerSelection;
