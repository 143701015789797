import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setSessionStorageValue } from "utils/hooks/localStorage";

export type FacilityState = {
  currentFacilityId: string;
};

const initialState: FacilityState = {
  currentFacilityId: "",
};

const slice = createSlice({
  name: "facility",
  initialState,
  reducers: {
    setCurrentFacility(
      state,
      action: PayloadAction<{ currentFacility: string }>
    ): void {
      state.currentFacilityId = action.payload.currentFacility;
      setSessionStorageValue("facilityId", action.payload.currentFacility);
    },
  },
});

const { actions, reducer: facilityReducer } = slice;

const { setCurrentFacility } = actions;

export { facilityReducer, setCurrentFacility };
