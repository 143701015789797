import { styles } from "./Leagues.styes";
import { MdClose } from "react-icons/md";
import { LeagueLeaderboardPlayerDto } from "Models";
import { useGetSelectedLeague } from "modules/leagues/leagueHooks";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { useLeagueLeaderboardByLeagueId } from "modules/leagues/leagueScorecardHooks";
import SpinnerAnimation from "components/svg/SpinnerAnimation";
import LeagueScorecard from "./LeagueScorecard";

type LeagueLeaderboardProps = {
  callback?: React.Dispatch<React.SetStateAction<boolean>>;
};

const LeagueLeaderboard: React.FC<LeagueLeaderboardProps> = (props) => {
  const league = useGetSelectedLeague();
  const currentFacilityId = useGetCurrentLocalFacilityId();

  const [
    leagueLeaderboard,
    leagueLeaderboardQueryState,
  ] = useLeagueLeaderboardByLeagueId(currentFacilityId, league?.id);

  const LeagueModalLoading = () => {
    return (
      <div className={styles.modalLoading}>
        <SpinnerAnimation color={"#f2f2e6"} />
      </div>
    );
  };

  const LeagueModalNoResults = () => {
    return (
      <div className={styles.modalNoResults}>
        <MdClose className={styles.noLeaguesIcon} />
        There is no leaderboard yet for {league?.name}.
      </div>
    );
  };

  return (
    <>
      <div tabIndex={0}>
        {leagueLeaderboardQueryState?.isFinished ? (
          leagueLeaderboard ? (
            <div className="w-full">
              <div className={styles.modalResultsHeaderRow}>
                <div className="col-span-1">Pos</div>
                <div className="col-span-3">Full Name</div>
                <div className="hidden md:inline-block col-span-2"></div>
                <div className="hidden md:inline-block col-span-2"></div>
                <div className="col-span-2 text-left">Points</div>
                <div className="col-span-1"></div>
              </div>
              {Object.keys(leagueLeaderboard?.scores).length === 0 && (
                <LeagueModalNoResults />
              )}
              {leagueLeaderboard &&
                leagueLeaderboard?.scores?.map(
                  (player: LeagueLeaderboardPlayerDto, index: number) => (
                    <LeagueScorecard
                      leaderboardPlayer={player}
                      key={`${player?.playerGuid}-scorecard`}
                      leagueType={leagueLeaderboard.leagueType}
                    />
                  )
                )}
            </div>
          ) : (
            <LeagueModalNoResults />
          )
        ) : (
          <LeagueModalLoading />
        )}
      </div>
    </>
  );
};

export default LeagueLeaderboard;
