import { motion } from "framer-motion";
import { useRoundById } from "modules/round/roundHooks";
import { useSelector } from "react-redux";
import { RootState } from "StoreTypes";
import SimStatusName from "./SimStatusName";
import { styles } from "./SingleSimListView.styles";

const SimListRoundInfo: React.FC<{ simulatorStateId: string }> = ({
  simulatorStateId,
}) => {
  const simulatorState = useSelector(
    (state: RootState) => state.entities?.simulatorStates?.[simulatorStateId]
  );
  const [currentRound] = useRoundById(simulatorState?.roundId);

  return (
    <>
      <div className="font-normal">
        <motion.span
          key={simulatorState?.status}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.75,
          }}
          className="inline"
        >
          <SimStatusName simulatorState={simulatorState} />
        </motion.span>
        {currentRound?.courseName ? (
          <span className={styles.span}>
            <br />
            {"Course: "}
            {currentRound?.courseName}
            <br />
          </span>
        ) : null}
        {currentRound?.holesCompleted ? (
          <span className={styles.span}>
            {"Hole: "}
            <motion.span
              key={currentRound?.holesCompleted}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 0.75,
              }}
              className="inline"
            >
              {currentRound?.holesCompleted}
            </motion.span>{" "}
            {" of "}
            {currentRound?.holesOriginallySelected.filter(Boolean).length}
            {" completed"}
          </span>
        ) : null}
      </div>
    </>
  );
};

export default SimListRoundInfo;
