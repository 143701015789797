const TextArea: React.FC<TextAreaProps> = (props) => {
  return (
    <textarea
      name={props.name}
      placeholder={props.placeholder}
      className={props.cssClass}
      id={props.id}
    ></textarea>
  );
};
export default TextArea;

type TextAreaProps = {
  name?: string;
  placeholder?: string;
  cssClass?: string;
  id?: string;
};
TextArea.defaultProps = {
  name: "",
  placeholder: "",
  cssClass:
    "p-3 mb-5 bg-white border border-gray-200 rounded shadow-sm h-16 sm:h-20 text-sm",
  id: "",
};
