import { styles } from "../Leagues.styes";
import { customStyles } from "utils/ui/uiHelpers";
import { ErrorMessage } from "@hookform/error-message";
import { Controller, useFormContext } from "react-hook-form";
import { FormRow, RadioTileGroup } from "components/ui/form";
import { useLeagueRoundStartDatePassed } from "modules/leagues/leagueHelpers";
import { roundConfigEnumsAsReactSelectOptions } from "utils/enums/enumHelper";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { useFacilityDefaultRoundSettings } from "modules/default-round-settings";
import {
  teeTypeIconHelper,
  groundConditionsIconHelper,
  weatherIconHelper,
  windSpeedIconHelper,
  windDirectionIconHelper,
} from "utils/icons/iconHelpers";
import Select from "react-select";
import { AutoContinue, AutoGimme, GimmeDistance } from "types/enums";

const CourseSettingsOptions = () => {
  const { control, setValue, getValues, watch, formState } = useFormContext();
  const currentFacilityId = useGetCurrentLocalFacilityId();
  const [defaultRoundSettings] = useFacilityDefaultRoundSettings(
    currentFacilityId
  );

  const startDatePassed = useLeagueRoundStartDatePassed();

  const handleAutoGimmesChange = () => {
    const gimmeDistance = getValues("courseSettings.gimmeDistance");
    const autoContinue = getValues("courseSettings.autoContinue");

    if (
      gimmeDistance !== GimmeDistance.OFF ||
      autoContinue !== AutoContinue.OFF
    ) {
      setValue("courseSettings.autoGimmes", AutoGimme.OFF);
    }
  };

  return (
    <>
      <FormRow header="Green Stimp" fontSize={styles.formRowLabel}>
        <Controller
          name="courseSettings.stimpSetting"
          control={control}
          defaultValue={
            getValues("courseSettings.stimpSetting") ||
            defaultRoundSettings?.stimpSetting ||
            roundConfigEnumsAsReactSelectOptions?.stimpSetting[1].value
          }
          rules={{ required: "Green Stimp is required" }}
          render={({ field }) => (
            <Select
              {...field}
              closeMenuOnSelect={true}
              isMulti={false}
              options={roundConfigEnumsAsReactSelectOptions?.stimpSetting}
              value={roundConfigEnumsAsReactSelectOptions?.stimpSetting.find(
                (c) => c.value === field.value
              )}
              onChange={(val: { label: string; value: string }) =>
                field.onChange(val.value)
              }
              styles={customStyles}
              isDisabled={startDatePassed}
            />
          )}
        />
        <ErrorMessage
          errors={formState.errors}
          name="courseSettings.stimpSetting"
          render={({ message }) => (
            <div className="text-sm text-red-500">{message}</div>
          )}
        />
      </FormRow>
      <FormRow header="Gimme Distance" fontSize={styles.formRowLabel}>
        <Controller
          name="courseSettings.gimmeDistance"
          defaultValue={
            getValues("courseSettings.gimmeDistance") ||
            defaultRoundSettings?.gimmeDistance ||
            roundConfigEnumsAsReactSelectOptions.gimmeDistance[0].value
          }
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              closeMenuOnSelect={true}
              isMulti={false}
              options={roundConfigEnumsAsReactSelectOptions.gimmeDistance}
              value={roundConfigEnumsAsReactSelectOptions.gimmeDistance.find(
                (c) => c.value === field.value
              )}
              onChange={(val: { label: string; value: string }) => {
                field.onChange(val.value);
                handleAutoGimmesChange();
              }}
              styles={customStyles}
              className="disabled:cursor-not-allowed disabled:bg-gray-100"
              id="eventGimmeDistance"
              isDisabled={startDatePassed}
            />
          )}
        />
        <ErrorMessage
          errors={formState.errors}
          name="courseSettings.gimmeDistance"
          render={({ message }) => (
            <div className="text-sm text-red-500">{message}</div>
          )}
        />
      </FormRow>
      <FormRow header="Auto Continue" fontSize={styles.formRowLabel}>
        <Controller
          name="courseSettings.autoContinue"
          control={control}
          defaultValue={
            getValues("courseSettings.autoContinue") ||
            defaultRoundSettings?.autoContinue ||
            roundConfigEnumsAsReactSelectOptions?.autoContinue[0].value
          }
          render={({ field }) => (
            <Select
              {...field}
              closeMenuOnSelect={true}
              isMulti={false}
              options={roundConfigEnumsAsReactSelectOptions.autoContinue}
              value={roundConfigEnumsAsReactSelectOptions.autoContinue.find(
                (c) => c.value === field.value
              )}
              onChange={(val: { label: string; value: string }) => {
                field.onChange(val.value);
                handleAutoGimmesChange();
              }}
              styles={customStyles}
              isDisabled={startDatePassed}
            />
          )}
        />
      </FormRow>
      <FormRow
        header="Auto Gimme"
        fontSize={styles.formRowLabel}
        hidden={
          watch("courseSettings.gimmeDistance") !== GimmeDistance.OFF &&
          watch("courseSettings.autoContinue") !== AutoContinue.OFF
            ? false
            : true
        }
      >
        <Controller
          name="courseSettings.autoGimmes"
          control={control}
          defaultValue={
            getValues("courseSettings.autoGimmes") ||
            roundConfigEnumsAsReactSelectOptions.autoGimme[0].value
          }
          render={({ field }) => (
            <Select
              {...field}
              closeMenuOnSelect={true}
              isMulti={false}
              options={roundConfigEnumsAsReactSelectOptions.autoGimme}
              value={roundConfigEnumsAsReactSelectOptions.autoGimme.find(
                (c) => c.value === field.value
              )}
              onChange={(val: { label: string; value: string }) =>
                field.onChange(val.value)
              }
              styles={customStyles}
              isDisabled={startDatePassed}
            />
          )}
        />
      </FormRow>
      <FormRow header="Mulligans" fontSize={styles.formRowLabel}>
        <Controller
          name={"courseSettings.mulligans"}
          control={control}
          defaultValue={
            getValues("courseSettings.mulligans") ||
            defaultRoundSettings?.mulligans ||
            roundConfigEnumsAsReactSelectOptions?.mulligan[1].value
          }
          render={({ field }) => (
            <Select
              {...field}
              closeMenuOnSelect={true}
              isMulti={false}
              options={roundConfigEnumsAsReactSelectOptions.mulligan}
              value={roundConfigEnumsAsReactSelectOptions.mulligan.find(
                (c) => c.value === field.value
              )}
              onChange={(val: { label: string; value: string }) =>
                field.onChange(val.value)
              }
              styles={customStyles}
              className="disabled:cursor-not-allowed disabled:bg-gray-100"
              id="eventMulligans"
              isDisabled={startDatePassed}
            />
          )}
        />
        <ErrorMessage
          errors={formState.errors}
          name="courseSettings.mulligans"
          render={({ message }) => (
            <div className="text-sm text-red-500">{message}</div>
          )}
        />
      </FormRow>
      <FormRow header="Auto Drop" fontSize={styles.formRowLabel}>
        <Controller
          name="courseSettings.autoDrop"
          control={control}
          defaultValue={
            getValues("courseSettings.autoDrop") ||
            defaultRoundSettings?.autoDrop ||
            roundConfigEnumsAsReactSelectOptions?.autoDrop[1].value
          }
          render={({ field }) => (
            <Select
              {...field}
              closeMenuOnSelect={true}
              isMulti={false}
              options={roundConfigEnumsAsReactSelectOptions.autoDrop}
              value={roundConfigEnumsAsReactSelectOptions.autoDrop.find(
                (c) => c.value === field.value
              )}
              onChange={(val: { label: string; value: string }) =>
                field.onChange(val.value)
              }
              styles={customStyles}
              isDisabled={startDatePassed}
            />
          )}
        />
      </FormRow>
      <FormRow header="Auto Concede" fontSize={styles.formRowLabel}>
        <Controller
          name="courseSettings.autoConcede"
          control={control}
          defaultValue={
            getValues("courseSettings.autoConcede") ||
            defaultRoundSettings?.autoConcede ||
            roundConfigEnumsAsReactSelectOptions?.autoConcede[0].value
          }
          render={({ field }) => (
            <Select
              {...field}
              closeMenuOnSelect={true}
              isMulti={false}
              options={roundConfigEnumsAsReactSelectOptions.autoConcede}
              value={roundConfigEnumsAsReactSelectOptions.autoConcede.find(
                (c) => c.value === field.value
              )}
              onChange={(val: { label: string; value: string }) =>
                field.onChange(val.value)
              }
              styles={customStyles}
              isDisabled={startDatePassed}
            />
          )}
        />
      </FormRow>
      <FormRow header="Time of Day" fontSize={styles.formRowLabel}>
        <Controller
          name="courseSettings.timeConditions"
          defaultValue={
            getValues("courseSettings.timeConditions") ||
            defaultRoundSettings?.timeConditions ||
            roundConfigEnumsAsReactSelectOptions?.timeOfDay[1].value
          }
          control={control}
          rules={{ required: "Time of Day is required" }}
          render={({ field }) => (
            <Select
              {...field}
              closeMenuOnSelect={true}
              isMulti={false}
              options={roundConfigEnumsAsReactSelectOptions.timeOfDay}
              value={roundConfigEnumsAsReactSelectOptions.timeOfDay.find(
                (c) => c.value === field.value
              )}
              onChange={(val: { label: string; value: string }) =>
                field.onChange(val.value)
              }
              styles={customStyles}
              isDisabled={startDatePassed}
            />
          )}
        />
      </FormRow>
      <FormRow header="Pin Type" fontSize={styles.formRowLabel}>
        <Controller
          name="courseSettings.pinType"
          control={control}
          defaultValue={
            getValues("courseSettings.pinType") ||
            roundConfigEnumsAsReactSelectOptions?.pinType[0].value
          }
          rules={{ required: "Pin Type is required" }}
          render={({ field }) => (
            <Select
              {...field}
              closeMenuOnSelect={true}
              isMulti={false}
              options={roundConfigEnumsAsReactSelectOptions.pinType}
              value={roundConfigEnumsAsReactSelectOptions.pinType.find(
                (c) => c.value === field.value
              )}
              onChange={(val: { label: string; value: string }) =>
                field.onChange(val.value)
              }
              styles={customStyles}
              isDisabled={startDatePassed}
            />
          )}
        />
      </FormRow>
      <FormRow header="Mens Tee" fontSize={styles.formRowLabel}>
        <Controller
          name="courseSettings.mensTee"
          control={control}
          render={({ field }) => (
            <RadioTileGroup
              {...field}
              radioGroupName="courseSettings.mensTee"
              options={roundConfigEnumsAsReactSelectOptions.teeType}
              icon={teeTypeIconHelper}
              onClick={(e: React.ChangeEvent<HTMLInputElement>) =>
                setValue("courseSettings.mensTee", e.target.value)
              }
              hideLabel={false}
              defaultValue={
                getValues("courseSettings.mensTee") ||
                roundConfigEnumsAsReactSelectOptions?.teeType[3].label.toLowerCase()
              }
              disabled={startDatePassed}
            />
          )}
        />
      </FormRow>
      <FormRow header="Womens Tee" fontSize={styles.formRowLabel}>
        <Controller
          name="courseSettings.womensTee"
          control={control}
          render={({ field }) => (
            <RadioTileGroup
              {...field}
              radioGroupName="courseSettings.womensTee"
              options={roundConfigEnumsAsReactSelectOptions.teeType}
              icon={teeTypeIconHelper}
              onClick={(e: React.ChangeEvent<HTMLInputElement>) =>
                setValue("courseSettings.womensTee", e.target.value)
              }
              hideLabel={false}
              defaultValue={
                getValues("courseSettings.womensTee") ||
                roundConfigEnumsAsReactSelectOptions?.teeType[0].value
              }
              disabled={startDatePassed}
            />
          )}
        />
      </FormRow>
      <FormRow header="Ground Conditions" fontSize={styles.formRowLabel}>
        <Controller
          name="courseSettings.ground"
          control={control}
          render={({ field }) => (
            <RadioTileGroup
              {...field}
              radioGroupName="courseSettings.groundConditions"
              options={roundConfigEnumsAsReactSelectOptions.groundConditions}
              icon={groundConditionsIconHelper}
              onClick={(e: React.ChangeEvent<HTMLInputElement>) =>
                setValue("courseSettings.groundConditions", e.target.value)
              }
              hideLabel={false}
              defaultValue={
                getValues("courseSettings.groundConditions")
                  ? getValues("courseSettings.groundConditions")
                  : roundConfigEnumsAsReactSelectOptions?.groundConditions[2]
                      .value
              }
              disabled={startDatePassed}
            />
          )}
        />
      </FormRow>
      <FormRow header="Sky" fontSize={styles.formRowLabel}>
        <Controller
          name="courseSettings.skyConditions"
          control={control}
          render={({ field }) => (
            <RadioTileGroup
              {...field}
              radioGroupName="courseSettings.skyConditions"
              options={roundConfigEnumsAsReactSelectOptions.skyConditions}
              icon={weatherIconHelper}
              onClick={(e: React.ChangeEvent<HTMLInputElement>) =>
                setValue("courseSettings.skyConditions", e.target.value)
              }
              hideLabel={false}
              defaultValue={
                getValues("courseSettings.skyConditions")
                  ? getValues("courseSettings.skyConditions")
                  : roundConfigEnumsAsReactSelectOptions?.skyConditions[0].value
              }
              disabled={startDatePassed}
            />
          )}
        />
      </FormRow>
      <FormRow header="Wind Speed" fontSize={styles.formRowLabel}>
        <Controller
          name="courseSettings.windSpeed"
          control={control}
          render={({ field }) => (
            <RadioTileGroup
              {...field}
              radioGroupName="courseSettings.windSpeed"
              options={roundConfigEnumsAsReactSelectOptions.eventsWindSpeed}
              icon={windSpeedIconHelper}
              onClick={(e: React.ChangeEvent<HTMLInputElement>) =>
                setValue("courseSettings.windSpeed", e.target.value)
              }
              hideLabel={false}
              defaultValue={
                getValues("courseSettings.windSpeed")
                  ? getValues("courseSettings.windSpeed")
                  : roundConfigEnumsAsReactSelectOptions?.eventsWindSpeed[1]
                      .value
              }
              disabled={startDatePassed}
            />
          )}
        />
      </FormRow>
      <FormRow header="Wind Direction" fontSize={styles.formRowLabel}>
        <Controller
          name="courseSettings.windDirection"
          control={control}
          render={({ field }) => (
            <RadioTileGroup
              {...field}
              radioGroupName="courseSettings.windDirection"
              options={roundConfigEnumsAsReactSelectOptions.windDirection}
              icon={windDirectionIconHelper}
              onClick={(e: React.ChangeEvent<HTMLInputElement>) =>
                setValue("courseSettings.windDirection", e.target.value)
              }
              hideLabel={false}
              defaultValue={
                getValues("courseSettings.windDirection")
                  ? getValues("courseSettings.windDirection")
                  : roundConfigEnumsAsReactSelectOptions?.windDirection[3].label.toString()
              }
              disabled={startDatePassed}
            />
          )}
        />
      </FormRow>
    </>
  );
};
export default CourseSettingsOptions;
