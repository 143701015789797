const Paragraph: React.FC<ParagraphProps> = (props) => {
  return <p className={`${props.cssClass}`}>{props.content}</p>;
};
export default Paragraph;

type ParagraphProps = {
  content?: string;
  cssClass?: string;
};
Paragraph.defaultProps = {
  content: "",
  cssClass: "mb-2 font-bold text-gray-700",
};
