import { AppQueryConfig } from "QueryTypes";
import {
  ClubhouseLeagueRoundPlayerDto,
  ClubhouseRoundGroupSimulatorDto,
  ClubhouseRoundTeamDto,
  PlayerRoundPayload,
  TeamRoundPayload,
} from "Models";
import { leagueQueryDefaultOptions } from "./leagueHelpers";
import axios from "axios";

const urls = {
  leagueCreateIndividualLeagueGroups(
    facilityId: string,
    leagueId: string,
    roundId: string
  ): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facility/${facilityId}/league/${leagueId}/round/${roundId}/players`;
  },
  leagueCreateTeamLeagueGroups(
    facilityId: string,
    leagueId: string,
    roundId: string
  ): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facility/${facilityId}/league/${leagueId}/round/${roundId}/teams`;
  },
  leagueAssignSimulator(
    facilityId: string,
    leagueId: string,
    roundId: string
  ): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facility/${facilityId}/league/${leagueId}/round/${roundId}/simulators`;
  },
  getAllPlayersInRound(
    facilityId: string,
    leagueId: string,
    roundId: string
  ): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facility/${facilityId}/league/${leagueId}/round/${roundId}/players`;
  },
  sendGroupsToSim(
    facilityId: string,
    leagueId: string,
    roundId: string
  ): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facility/${facilityId}/league/${leagueId}/round/${roundId}/send-groups-to-sim`;
  },
};

export const makeCreateIndividualLeagueGroupMutation = (
  facilityId: string,
  leagueId: string,
  roundId: string,
  body: PlayerRoundPayload[]
): AppQueryConfig => ({
  url: urls.leagueCreateIndividualLeagueGroups(facilityId, leagueId, roundId),
  queryKey: `makeCreateIndividualLeagueGroupMutation:${facilityId}-${leagueId}-${roundId}`,
  options: {
    ...leagueQueryDefaultOptions(),
    method: "PUT",
  },
  body,
  transform: (responseJson: ClubhouseLeagueRoundPlayerDto[]) => {
    return {
      leagueGroups: {
        byRoundId: {
          [roundId]: responseJson,
        },
      },
    };
  },
  update: {
    leagueGroups: (oldValue, newValue) => ({
      ...oldValue,
      byRoundId: {
        ...oldValue?.byRoundId,
        ...{
          [roundId]: {
            ...oldValue?.byRoundId?.[roundId],
            ...newValue.byRoundId[roundId],
          },
        },
      },
    }),
  },
});

export const makeAddPlayerToGroupMutation = (
  facilityId: string,
  leagueId: string,
  roundId: string,
  body: PlayerRoundPayload[]
): AppQueryConfig => ({
  url: urls.leagueCreateIndividualLeagueGroups(facilityId, leagueId, roundId),
  queryKey: `makeCreateIndividualLeagueGroupMutation:${facilityId}-${leagueId}-${roundId}`,
  options: {
    ...leagueQueryDefaultOptions(),
    method: "PATCH",
  },
  body,
  transform: (responseJson: ClubhouseLeagueRoundPlayerDto[]) => {
    return {
      leagueGroups: {
        byRoundId: {
          [roundId]: responseJson,
        },
      },
    };
  },
  update: {
    leagueGroups: (oldValue, newValue) => ({
      ...oldValue,
      byRoundId: {
        ...oldValue?.byRoundId,
        ...{
          [roundId]: {
            ...oldValue?.byRoundId?.[roundId],
            ...newValue.byRoundId[roundId],
          },
        },
      },
    }),
  },
});

export const makeRemovePlayerFromGroupMutation = (
  facilityId: string,
  leagueId: string,
  roundId: string,
  body: PlayerRoundPayload[]
): AppQueryConfig => ({
  url: urls.leagueCreateIndividualLeagueGroups(facilityId, leagueId, roundId),
  options: {
    ...leagueQueryDefaultOptions(),
    method: "PUT",
  },
  body,
  transform: (responseJson: ClubhouseLeagueRoundPlayerDto[]) => {
    return {
      leagueGroups: {
        byRoundId: {
          [roundId]: responseJson,
        },
      },
    };
  },
  update: {
    leagueGroups: (oldValue, newValue) => ({
      ...oldValue,
      byRoundId: {
        ...oldValue?.byRoundId,
        ...{
          [roundId]: {
            ...oldValue?.byRoundId?.[roundId],
            ...newValue.byRoundId[roundId],
          },
        },
      },
    }),
  },
});

export const makeCreateTeamLeagueGroupMutation = (
  facilityId: string,
  leagueId: string,
  roundId: string,
  body: TeamRoundPayload[]
): AppQueryConfig => ({
  url: urls.leagueCreateTeamLeagueGroups(facilityId, leagueId, roundId),
  queryKey: `makeCreateTeamLeagueGroupMutation:${facilityId}-${leagueId}-${roundId}`,
  options: {
    ...leagueQueryDefaultOptions(),
    method: "PUT",
  },
  body,
  transform: (responseJson: ClubhouseRoundTeamDto[]) => {
    let teamPlayers: ClubhouseLeagueRoundPlayerDto[] = [];

    responseJson.map((team) => {
      return team.teamMembers.map((player) => {
        return teamPlayers.push({
          groupNumber: player?.groupNumber,
          simulatorId: player?.simulatorId,
          teamId: team.teamId,
          teamName: team.name,
          roundId: team.roundId,
          playerId: player.playerId,
          userName: player.userName,
          firstName: player.firstName,
          lastName: player.lastName,
          agHandicap: player?.agHandicap || 0,
          email: player.email,
          phoneNumber: player.phoneNumber,
        });
      });
    });

    return {
      leagueGroups: {
        byRoundId: {
          [roundId]: teamPlayers,
        },
      },
    };
  },
  update: {
    leagueGroups: (oldValue, newValue) => ({
      ...oldValue,
      byRoundId: {
        ...oldValue?.byRoundId,
        ...{
          [roundId]: {
            ...oldValue?.byRoundId?.[roundId],
            ...newValue.byRoundId[roundId],
          },
        },
      },
    }),
  },
});

export const makeAssignGroupToSim = (
  facilityId: string,
  leagueId: string,
  roundId: string,
  body: ClubhouseRoundGroupSimulatorDto[]
): AppQueryConfig => ({
  url: urls.leagueAssignSimulator(facilityId, leagueId, roundId),
  queryKey: `makeAssignGroupToSim:${facilityId}-${leagueId}-${roundId}`,
  options: {
    ...leagueQueryDefaultOptions(),
    method: "PUT",
  },
  body,
  transform: (responseJson: ClubhouseLeagueRoundPlayerDto[]) => {
    return {
      leagueGroups: {
        byRoundId: {
          [roundId]: responseJson,
        },
      },
    };
  },
  update: {
    leagueGroups: (oldValue, newValue) => ({
      ...oldValue,
      byRoundId: {
        ...oldValue?.byRoundId,
        ...{
          [roundId]: {
            ...oldValue?.byRoundId?.[roundId],
            ...newValue.byRoundId[roundId],
          },
        },
      },
    }),
  },
});

export const getRosterByRoundId = (
  facilityId: string,
  leagueId: string,
  roundId: string
): AppQueryConfig => ({
  url: urls.getAllPlayersInRound(facilityId, leagueId, roundId),
  options: {
    ...leagueQueryDefaultOptions(),
    method: "GET",
  },
  transform: (responseJson: ClubhouseLeagueRoundPlayerDto[]) => {
    return {
      leagueGroups: {
        byRoundId: {
          [roundId]: responseJson,
        },
      },
    };
  },
  update: {
    leagueGroups: (oldValue, newValue) => ({
      ...oldValue,
      byRoundId: {
        ...oldValue?.byRoundId,
        ...{
          [roundId]: {
            ...oldValue?.byRoundId?.[roundId],
            ...newValue.byRoundId[roundId],
          },
        },
      },
    }),
  },
});

export const sendGroupToSim = async (
  facilityId: string,
  leagueId: string,
  roundId: string,
  payload: ClubhouseRoundGroupSimulatorDto[]
): Promise<any> => {
  return await axios
    .put(
      urls.sendGroupsToSim(facilityId, leagueId, roundId),
      payload,
      leagueQueryDefaultOptions()
    )
    .then((res) => {
      return {
        status: res.status,
        data: res.data,
      };
    })
    .catch((error) => {
      return { error: error.response, status: error.response.status };
    });
};
