import { DefaultRoundSettings } from "Models";
import { DefaultRoundSettingsUpdateBody, UseDataInfo } from "QueryTypes";
import { useSelector } from "react-redux";
import { QueryState } from "redux-query";
import { RunMutation, useMutation, useRequest } from "redux-query-react";
import { EntitiesState, RootState } from "StoreTypes";
import { getFacilityDefaultRoundSettings } from ".";
import {
  facilityDefaultRoundSettingsQuery,
  makeUpdateDefaultRoundSettingsMutation,
} from "./defaultRoundSettingsQueries";

export const useUpdateDefaultRoundSettings = (
  facilityId: string
): [
  QueryState,
  RunMutation<EntitiesState, [DefaultRoundSettingsUpdateBody]>
] => {
  const [
    updateDefaultRoundSettingsQueryState,
    updateDefaultRoundSettings,
  ] = useMutation((body: DefaultRoundSettingsUpdateBody) =>
    makeUpdateDefaultRoundSettingsMutation(facilityId, body)
  );

  return [updateDefaultRoundSettingsQueryState, updateDefaultRoundSettings];
};

export const useFacilityDefaultRoundSettings = (
  facilityId: string
): UseDataInfo<DefaultRoundSettings> => {
  const [queryState, refreshFacilityDefaultRoundSettings] = useRequest(
    facilityDefaultRoundSettingsQuery(facilityId)
  );

  const facilityDefaultRoundSettings = useSelector((state: RootState) =>
    getFacilityDefaultRoundSettings(state, facilityId)
  );

  return [
    facilityDefaultRoundSettings,
    queryState,
    refreshFacilityDefaultRoundSettings,
  ];
};
