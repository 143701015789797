import { RootState } from "StoreTypes";
import { useSelector } from "react-redux";
import { RoundConfiguration } from "Models";
import { FacilityNormalized } from "EntityTypes";
import { styles } from "./RoundConfigurationManagement.styles";
import { getCurrentFacilityId } from "modules/facility/facilitySelectors";
import { useFacilityRoundConfigurations } from "modules/round-configuration/roundConfigurationHooks";
import Row from "components/ui/layout/Row";
import RoundConfigDeleteRow from "./RoundConfigurationDeleteRow";
import GearIcon from "components/svg/GearIcon";

const DeleteRoundConfig: React.FC = () => {
  const currentFacilityId = useSelector((state: RootState) =>
    getCurrentFacilityId(state)
  );
  const [facilityRoundConfigs] = useFacilityRoundConfigurations(
    currentFacilityId
  );
  const currentFacility: FacilityNormalized = useSelector(
    (state: RootState) => state.entities.facilities?.[currentFacilityId]
  );
  const roundConfigs = facilityRoundConfigs as Array<RoundConfiguration>;

  return (
    <Row cssClasses={styles.innerContainer}>
      <Row cssClasses={styles.row}>
        <div className="flex flex-row py-6">
          <div className="flex w-10">
            <GearIcon />
          </div>
          <div className="flex flex-col">
            <div className="brand-heading dark:text-white">
              Saved Round Configurations
            </div>
            <div className="brand-subheading pt-2 text-gray-500 dark:text-white text-sm">
              Here are the current configs for {currentFacility?.name}
            </div>
          </div>
        </div>
      </Row>
      <Row cssClasses={styles.fieldColumn}>
        {roundConfigs.map((config) => {
          return <RoundConfigDeleteRow config={config} key={config.id} />;
        })}
      </Row>
    </Row>
  );
};
export default DeleteRoundConfig;
