import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  LocalStorageItems,
  localStorageValueToBoolean,
} from "utils/hooks/localStorage";

export type NavState = {
  navMenu: {
    isOpen: boolean;
  };
  navAlerts: {
    isOpen: boolean;
  };
  navSettings: {
    isOpen: boolean;
  };
  navOverlay: {
    isOpen: boolean;
  };
  darkMode: {
    isDarkMode: boolean;
  };
  helpDocs: {
    isOpen: boolean;
  };
  simulatorReservationTray: {
    isOpen: boolean;
  };
  roundSettings: {
    isOpen: boolean;
  };
  createRoundTray: {
    isOpen: boolean;
  };
  queueTray: {
    isOpen: boolean;
  };
  queueItemTray: {
    isOpen: boolean;
  };
  rexReservationTray: {
    isOpen: boolean;
  };
  lockerUserEditTray: {
    isOpen: boolean;
  };
  queue: {
    showOnDashboard: boolean;
  };
  supportCaseTray: {
    isOpen: boolean;
  };
  onlineEventsTray: {
    isOpen: boolean;
  };
  createEventTray: {
    isOpen: boolean;
  };
  events: {
    showOnPage: boolean;
  };
  scorecardTray: {
    isOpen: boolean;
  };
  collapseSidebar: {
    isOpen: boolean;
  };
  serviceRequestAlerts: {
    isOpen: boolean;
  };
  marketingHubTray: {
    isOpen: boolean;
  };
  createLeagueTray: {
    isOpen: boolean;
  };
  createLeagueRoundTray: {
    isOpen: boolean;
  };
  multiSimManagementTray: {
    isOpen: boolean;
  };
  createLeagueRoundGroupsTray: {
    isOpen: boolean;
  };
  leagueScorecardTray: {
    isOpen: boolean;
  };
};

const initialState: NavState = {
  navMenu: {
    isOpen: false,
  },
  navAlerts: {
    isOpen: false,
  },
  navSettings: {
    isOpen: false,
  },
  navOverlay: {
    isOpen: false,
  },
  darkMode: {
    isDarkMode: false,
  },
  helpDocs: {
    isOpen: false,
  },
  lockerUserEditTray: {
    isOpen: false,
  },
  simulatorReservationTray: {
    isOpen: false,
  },
  roundSettings: {
    isOpen: false,
  },
  createRoundTray: {
    isOpen: false,
  },
  queueTray: {
    isOpen: false,
  },
  queueItemTray: {
    isOpen: false,
  },
  rexReservationTray: {
    isOpen: false,
  },
  queue: {
    showOnDashboard:
      localStorage.getItem(LocalStorageItems.DASHBOARDQUEUE) === null
        ? true
        : localStorageValueToBoolean(
            localStorage.getItem(LocalStorageItems.DASHBOARDQUEUE)
          ),
  },
  supportCaseTray: {
    isOpen: false,
  },
  onlineEventsTray: {
    isOpen: false,
  },
  createEventTray: {
    isOpen: false,
  },
  events: {
    showOnPage:
      localStorage.getItem(LocalStorageItems.SHOWEVENTSLIST) === null
        ? true
        : localStorageValueToBoolean(
            localStorage.getItem(LocalStorageItems.SHOWEVENTSLIST)
          ),
  },
  scorecardTray: {
    isOpen: false,
  },
  collapseSidebar: {
    isOpen: false,
  },
  serviceRequestAlerts: {
    isOpen: false,
  },
  marketingHubTray: {
    isOpen: false,
  },
  createLeagueTray: {
    isOpen: false,
  },
  createLeagueRoundTray: {
    isOpen: false,
  },
  multiSimManagementTray: {
    isOpen: false,
  },
  createLeagueRoundGroupsTray: {
    isOpen: false,
  },
  leagueScorecardTray: {
    isOpen: false,
  },
};

const slice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    navMenuAction(state, action: PayloadAction<{ isOpen: boolean }>): void {
      state.navMenu.isOpen = action.payload.isOpen;
      if (action.payload.isOpen) {
        state.navAlerts.isOpen = false;
        state.navSettings.isOpen = false;
        state.navOverlay.isOpen = true;
      } else {
        state.navAlerts.isOpen = false;
        state.navSettings.isOpen = false;
        state.navOverlay.isOpen = false;
        state.navMenu.isOpen = false;
      }
    },
    navAlertsAction(state, action: PayloadAction<{ isOpen: boolean }>): void {
      state.navAlerts.isOpen = action.payload.isOpen;
      if (action.payload.isOpen) {
        state.navMenu.isOpen = false;
        state.navSettings.isOpen = false;
        state.navOverlay.isOpen = true;
      } else {
        state.navMenu.isOpen = false;
        state.navSettings.isOpen = false;
        state.navOverlay.isOpen = false;
        state.navMenu.isOpen = false;
      }
    },
    navSettingsAction(state, action: PayloadAction<{ isOpen: boolean }>): void {
      state.navSettings.isOpen = action.payload.isOpen;
      if (action.payload.isOpen) {
        state.navMenu.isOpen = false;
        state.navAlerts.isOpen = false;
        state.navOverlay.isOpen = true;
      } else {
        state.navMenu.isOpen = false;
        state.navAlerts.isOpen = false;
        state.navOverlay.isOpen = false;
        state.navSettings.isOpen = false;
      }
    },
    navOverlayAction(state, action: PayloadAction<{ isOpen: boolean }>): void {
      state.navOverlay.isOpen = action.payload.isOpen;
      if (action.payload.isOpen) {
      } else {
        state.navMenu.isOpen = false;
        state.navAlerts.isOpen = false;
        state.navOverlay.isOpen = false;
        state.navSettings.isOpen = false;
      }
    },
    darkModeAction(
      state,
      action: PayloadAction<{ isDarkMode: boolean }>
    ): void {
      state.darkMode.isDarkMode = action.payload.isDarkMode;
    },
    helpDocsAction(state, action: PayloadAction<{ isOpen: boolean }>): void {
      state.helpDocs.isOpen = action.payload.isOpen;
    },
    aGHandicapAction(state, action: PayloadAction<{ isOpen: boolean }>): void {
      state.lockerUserEditTray.isOpen = action.payload.isOpen;
    },
    simulatorReservationTrayAction(
      state,
      action: PayloadAction<{ isOpen: boolean }>
    ): void {
      state.simulatorReservationTray.isOpen = action.payload.isOpen;
    },
    roundSettingsAction(
      state,
      action: PayloadAction<{ isOpen: boolean }>
    ): void {
      state.roundSettings.isOpen = action.payload.isOpen;
    },
    createRexReservationTrayAction(
      state,
      action: PayloadAction<{ isOpen: boolean }>
    ): void {
      state.rexReservationTray.isOpen = action.payload.isOpen;
    },
    queueTrayAction(state, action: PayloadAction<{ isOpen: boolean }>): void {
      state.queueTray.isOpen = action.payload.isOpen;
    },
    queueItemTrayAction(
      state,
      action: PayloadAction<{ isOpen: boolean }>
    ): void {
      state.queueItemTray.isOpen = action.payload.isOpen;
    },
    queueAction(
      state,
      action: PayloadAction<{ showOnDashboard: boolean }>
    ): void {
      state.queue.showOnDashboard = action.payload.showOnDashboard;
    },
    supportCaseTrayAction(
      state,
      action: PayloadAction<{ isOpen: boolean }>
    ): void {
      state.supportCaseTray.isOpen = action.payload.isOpen;
    },
    onlineEventsTrayAction(
      state,
      action: PayloadAction<{ isOpen: boolean }>
    ): void {
      state.onlineEventsTray.isOpen = action.payload.isOpen;
    },
    createEventTrayAction(
      state,
      action: PayloadAction<{ isOpen: boolean }>
    ): void {
      state.createEventTray.isOpen = action.payload.isOpen;
    },
    eventsAction(state, action: PayloadAction<{ showOnPage: boolean }>): void {
      state.events.showOnPage = action.payload.showOnPage;
    },
    scorecardTrayAction(
      state,
      action: PayloadAction<{ isOpen: boolean }>
    ): void {
      state.scorecardTray.isOpen = action.payload.isOpen;
    },
    collapseSidebarAction(
      state,
      action: PayloadAction<{ isOpen: boolean }>
    ): void {
      state.collapseSidebar.isOpen = action.payload.isOpen;
    },
    serviceRequestAlerts(
      state,
      action: PayloadAction<{ isOpen: boolean }>
    ): void {
      state.serviceRequestAlerts.isOpen = action.payload.isOpen;
    },
    marketingHubTrayAction(
      state,
      action: PayloadAction<{ isOpen: boolean }>
    ): void {
      state.marketingHubTray.isOpen = action.payload.isOpen;
    },
    createLeagueTrayAction(
      state,
      action: PayloadAction<{ isOpen: boolean }>
    ): void {
      state.createLeagueTray.isOpen = action.payload.isOpen;
    },
    createLeagueRoundTrayAction(
      state,
      action: PayloadAction<{ isOpen: boolean }>
    ): void {
      state.createLeagueRoundTray.isOpen = action.payload.isOpen;
    },
    multiSimManagementTrayAction(
      state,
      action: PayloadAction<{ isOpen: boolean }>
    ): void {
      state.multiSimManagementTray.isOpen = action.payload.isOpen;
    },
    createLeagueRoundGroupsTrayAction(
      state,
      action: PayloadAction<{ isOpen: boolean }>
    ): void {
      state.createLeagueRoundGroupsTray.isOpen = action.payload.isOpen;
    },
    leagueScorecardTrayAction(
      state,
      action: PayloadAction<{ isOpen: boolean }>
    ): void {
      state.leagueScorecardTray.isOpen = action.payload.isOpen;
    },
  },
});

const { actions, reducer: navMenuReducer } = slice;

const {
  navMenuAction,
  navAlertsAction,
  navSettingsAction,
  navOverlayAction,
  darkModeAction,
  helpDocsAction,
  aGHandicapAction,
  simulatorReservationTrayAction,
  roundSettingsAction,
  createRexReservationTrayAction,
  queueTrayAction,
  queueItemTrayAction,
  queueAction,
  supportCaseTrayAction,
  onlineEventsTrayAction,
  createEventTrayAction,
  eventsAction,
  scorecardTrayAction,
  collapseSidebarAction,
  serviceRequestAlerts,
  marketingHubTrayAction,
  createLeagueTrayAction,
  createLeagueRoundTrayAction,
  multiSimManagementTrayAction,
  createLeagueRoundGroupsTrayAction,
  leagueScorecardTrayAction,
} = actions;

export {
  navMenuReducer,
  navMenuAction,
  navAlertsAction,
  navSettingsAction,
  navOverlayAction,
  darkModeAction,
  helpDocsAction,
  aGHandicapAction,
  simulatorReservationTrayAction,
  roundSettingsAction,
  createRexReservationTrayAction,
  queueTrayAction,
  queueItemTrayAction,
  queueAction,
  supportCaseTrayAction,
  onlineEventsTrayAction,
  createEventTrayAction,
  eventsAction,
  scorecardTrayAction,
  collapseSidebarAction,
  serviceRequestAlerts,
  marketingHubTrayAction,
  createLeagueTrayAction,
  createLeagueRoundTrayAction,
  multiSimManagementTrayAction,
  createLeagueRoundGroupsTrayAction,
  leagueScorecardTrayAction,
};
