import { ScorecardValues } from "Models";
import { HandicapMode } from "types/enums";
import { styles } from "./Scorecard.styles";
import { forwardRef, useState } from "react";
import { titleCase } from "utils/enums/enumHelper";
import {
  MatchResults,
  MatchScorecardResults,
  PlayerScorecardDto,
  ScorecardHoleElementDto,
  TeamScorecardDto,
} from "ScorecardModels";
import { enforceMinAndMaxScores } from "utils/ui/uiHelpers";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { putUpdateRoundScorecardAxios } from "modules/leagues/leagueScorecardQueries";
import {
  holeNumberMatchPlayHelper,
  holesWonHelper,
  runningResultWinnerHelper,
  scorecardHoleStylesHelper,
  teamColorHelper,
} from "../../modules/scorecard-lookup/scorecardHelpers";
import {
  useSelectedLeagueId,
  useSelectedLeagueRoundId,
  useSelectedLeagueRoundIsCompleted,
} from "modules/leagues/leagueRoundHooks";
import { isApiStatusSuccess } from "utils/api/apiHelper";

import {
  showCustomNotification,
  GenericNotificationType,
} from "utils/notifications/notificationHelpers";
import ReactTooltip from "react-tooltip";

type ScorecardHolesLayoutProps = {
  playerScorecard: PlayerScorecardDto[];
  teamScorecard?: TeamScorecardDto;
  allowEditing?: boolean;
  callback?: any;
  scorecardId?: string;
  showNetScore?: boolean;
  matchResults?: MatchResults;
  scrambleLeague?: boolean;
  handicapMode?: HandicapMode;
};

type HoleNumberProps = {
  holes: ScorecardHoleElementDto[];
  edit?: boolean;
  callback?: any;
  value?: any;
  scorecardId?: string;
  showNetScore?: boolean;
  backNine?: boolean;
  holesWon?: MatchScorecardResults[];
  teamId?: number;
};
interface HeaderProps {
  holes: ScorecardHoleElementDto[];
  par?: number;
  index?: number;
  holesWon?: MatchScorecardResults[];
  backNine?: boolean;
}
interface PlayerRowScoreProps {
  holes: ScorecardHoleElementDto[];
  par?: number;
  index?: number;
  scorecard: PlayerScorecardDto;
  showNetScore?: boolean;
  callback?: any;
  backNine?: boolean;
}

type ScrambleLeagueRowProps = {
  playerScorecard: PlayerScorecardDto[];
  showNetScore?: boolean;
  showBackNine?: boolean;
  teamId?: number;
};

const HoleNumberRow: React.FC<HoleNumberProps> = (props) => {
  const { holes, holesWon } = props;

  const matchPlayHoleWinner = (holeNumber: number) => {
    const hole =
      holesWon?.filter((hole) => hole.holeNumber === holeNumber) || [];

    return hole[0]?.holeResult;
  };

  return (
    <>
      {holes.map((hole, index) => {
        return (
          <div
            className={styles.scorecardLayoutColumn}
            key={`holeNumber-${index}`}
          >
            {holesWon ? (
              <div
                className={`flex justify-center items-center  ${
                  holeNumberMatchPlayHelper[
                    matchPlayHoleWinner(hole.holeNumber)
                  ]
                }`}
              >
                <div className="flex justify-center text-center ">
                  {hole.holeNumber}
                </div>
              </div>
            ) : (
              <>{hole.holeNumber}</>
            )}
          </div>
        );
      })}
    </>
  );
};

const ParRow: React.FC<HoleNumberProps> = (props) => {
  const { holes } = props;

  return (
    <>
      {holes.map((hole, index) => {
        return (
          <div className={styles.scorecardLayoutParColumn} key={`par-${index}`}>
            {hole.par}
          </div>
        );
      })}
    </>
  );
};

const MatchPlayRow: React.FC<HoleNumberProps> = (props) => {
  const { holesWon, holes, backNine } = props;
  const matchCalledHole =
    holesWon?.find((hole) => hole.matchCalled === true)?.holeNumber || 18;

  return (
    <>
      <div className={styles.scorecardLayoutPlayerRow}>
        <div className={styles.scorecardLayoutMatchRow}>
          <div className="font-brandDarkGray font-normal pr-2 truncate text-left">
            Score
          </div>
        </div>
        {holesWon &&
          holes.map((hole, index) => {
            return (
              <div
                className={styles.scorecardLayoutParColumn}
                key={`par-${index}`}
              >
                {matchCalledHole >= hole.holeNumber && (
                  <span
                    className={
                      runningResultWinnerHelper[
                        holesWon[
                          backNine ? hole.holeNumber - 10 : hole.holeNumber - 1
                        ]?.runningMatchWinner
                      ]
                    }
                  >
                    {holesWon[
                      backNine ? hole.holeNumber - 10 : hole.holeNumber - 1
                    ]?.matchCalled
                      ? holesWonHelper(
                          holesWon[
                            backNine
                              ? hole.holeNumber - 10
                              : hole.holeNumber - 1
                          ],
                          holes
                        )
                      : holesWon[
                          backNine ? hole.holeNumber - 10 : hole.holeNumber - 1
                        ]?.runningResult}
                  </span>
                )}
              </div>
            );
          })}
        <div className={styles.scorecardLayoutPlayerRowEnd}></div>
      </div>
    </>
  );
};

const ScoreRow: React.FC<HoleNumberProps> = (props) => {
  const { holes, edit, callback, showNetScore, backNine, value } = props;

  const handleUpdateHoleScore = (holeNumber: number, score: number) => {
    if (backNine) {
      const updatedHole = {
        ...value?.holes[holeNumber - 10],
        adjustedScore: score,
      };
      callback &&
        callback(() => {
          const updatedHoles = [...value?.holes];
          updatedHoles[holeNumber - 10] = updatedHole;
          return {
            ...value,
            holes: updatedHoles,
          };
        });
    } else {
      const updatedHole = {
        ...value?.holes[holeNumber - 1],
        adjustedScore: score,
      };

      callback &&
        callback({
          ...value,
          holes: [
            ...value?.holes.slice(0, holeNumber - 1),
            updatedHole,
            ...value?.holes.slice(holeNumber),
          ],
        });
    }
  };

  return (
    <>
      {holes.map((hole, index) => {
        return (
          <div
            className={`${styles.scorecardLayoutPlayerColumn}`}
            key={`playerScore-${index}`}
          >
            {edit && hole?.selected ? (
              <div className={`flex items-center`}>
                <div className="flex flex-1 justify-center text-gray-700 text-center">
                  <input
                    type="number"
                    name={`holes.[${hole.holeNumber}].adjusted_score`}
                    min={1}
                    max={99}
                    className="flex flex-1 w-8 h-8 sm:w-12 border-1 border-zinc-600 p-0 m-0 text-center text-xs rounded active:accent-teal-700"
                    defaultValue={
                      showNetScore
                        ? hole.net?.final || 0
                        : hole.gross?.final || 0
                    }
                    onBlur={(e) => {
                      handleUpdateHoleScore(
                        hole?.holeNumber,
                        Number(e.target.value)
                      );
                    }}
                    onChange={(e) => enforceMinAndMaxScores(e.target)}
                  />
                </div>
              </div>
            ) : (
              <div
                className={`${
                  hole.selected &&
                  scorecardHoleStylesHelper(
                    showNetScore
                      ? hole.net?.final || 0
                      : hole.gross?.final || 0,
                    hole?.par
                  )
                } flex items-center relative h-6`}
              >
                <div className="flex flex-1 justify-center text-gray-700 text-center ">
                  {hole?.conceded ? "X" : ""}
                  {hole.selected
                    ? showNetScore
                      ? hole.net?.final || 0
                      : hole.gross?.final || 0
                    : "-"}
                </div>
                {hole.selected && showNetScore && hole.net?.strokes !== 0 && (
                  <div className="strokesGiven">{hole.net?.strokes}</div>
                )}
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

const ScorecardTooltip = forwardRef((props, ref) => {
  return (
    <div className="bg-gray-50">
      <div className="flex flex-col justify-start my-2 text-black text-xs">
        <div className="font-semibold pb-0.5">Handicap</div>
        <div className="text-gray-400">
          + Strokes added, - Strokes subtracted
        </div>
      </div>
      <div className="flex flex-col justify-end my-2 text-black text-xs">
        <div className="font-semibold pb-1">Scoring</div>
        <div className="flex flex-row">
          <div className="flex">
            <div className="albatross rounded-full w-4 h-4 flex items-center">
              <div className="flex flex-1 justify-center text-gray-700 text-center"></div>
            </div>
            <div className="flex flex-row items-center ml-2 text-gray-400">
              Albatross
            </div>
          </div>
          <div className="flex ml-4">
            <div className="eagle rounded-full w-4 h-4 flex items-center">
              <div className="flex flex-1 justify-center text-gray-700 text-center"></div>
            </div>
            <div className="flex flex-row items-center ml-2 text-gray-400">
              Eagle
            </div>
          </div>
          <div className="flex ml-4">
            <div className="birdie rounded-full w-4 h-4 flex items-center">
              <div className="flex flex-1 justify-center text-gray-700 text-center"></div>
            </div>
            <div className="flex flex-row items-center ml-2 text-gray-400">
              Birdie
            </div>
          </div>
          <div className="flex ml-4">
            <div className="bogey w-4 h-4 flex items-center">
              <div className="flex flex-1 justify-center text-gray-700 text-center"></div>
            </div>
            <div className="flex flex-row items-center ml-2 text-gray-400">
              Bogey
            </div>
          </div>
          <div className="flex ml-4">
            <div className="doubleBogey w-4 h-4 flex items-center">
              <div className="flex flex-1 justify-center  text-gray-700 text-center"></div>
            </div>
            <div className="flex flex-row items-center ml-2 text-gray-400">
              Double bogey or worse
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const ScorecardHolesLayout: React.FC<ScorecardHolesLayoutProps> = (props) => {
  const {
    playerScorecard,
    allowEditing,
    scorecardId,
    teamScorecard,
    showNetScore,
    callback,
    matchResults,
    scrambleLeague,
    handicapMode,
  } = props;

  const scorecard = playerScorecard[0];

  const currentFacilityId = useGetCurrentLocalFacilityId();
  const selectedLeagueRoundId = useSelectedLeagueRoundId();
  const selectedLeagueId = useSelectedLeagueId();
  const selectedLeagueRoundIsCompleted = useSelectedLeagueRoundIsCompleted();

  const [edit, setEdit] = useState<boolean>(false);
  const [showFrontNine, setFrontNine] = useState<boolean>(true);

  const [, setScorecardValues] = useState<ScorecardValues>(
    {} as ScorecardValues
  );

  const defaultFrontNine = scorecard.holes.filter(
    (hole: ScorecardHoleElementDto) =>
      hole.holeNumber >= 1 && hole.holeNumber <= 9
  );
  const defaultBackNine = scorecard.holes.filter(
    (hole: ScorecardHoleElementDto) =>
      hole.holeNumber >= 10 && hole.holeNumber <= 18
  );

  const parFrontNine = defaultFrontNine
    .map((hole) => hole.par)
    .reduce((a, b) => a + b, 0);
  const parBackNine = defaultBackNine
    .map((hole) => hole.par)
    .reduce((a, b) => a + b, 0);

  let showFrontAndBackNine =
    defaultFrontNine.filter((hole) => hole.selected !== false).length > 0 &&
    defaultBackNine.filter((hole) => hole.selected !== false).length > 0;

  const handleCancelEdit = () => {
    if (!edit) {
      setScorecardValues({} as ScorecardValues);
    }
    setEdit(!edit);
  };

  const ScorecardHeader: React.FC<HeaderProps> = (props) => {
    const { holes, par, holesWon, backNine } = props;

    return (
      <>
        <div className={styles.scorecardLayoutHeaderRow}>
          <div className={styles.scorecardLayoutColumnStart}>Hole</div>
          <HoleNumberRow holes={holes} holesWon={holesWon} />
          <div className={styles.scorecardLayoutColumnEnd}>
            {backNine ? "In" : "Out"}
          </div>
        </div>

        <div className={styles.scorecardLayoutParRow}>
          <div className={styles.scorecardLayoutColumnStart}>Par</div>
          <ParRow holes={holes} />
          <div className={styles.scorecardLayoutColumnEnd}>{par}</div>
        </div>
      </>
    );
  };

  const PlayerScoreRow: React.FC<PlayerRowScoreProps> = (props) => {
    const { holes, index, scorecard, showNetScore, callback, backNine } = props;

    const [scorecardValues, setScorecardValues] = useState({
      holes: holes.map((hole) => {
        return {
          adjustedScore: showNetScore
            ? hole.selected
              ? hole?.net?.final
              : 0
            : hole.selected
            ? hole?.gross?.final
            : 0 || 0,
          holeNumber: hole?.holeNumber,
          originalScore: showNetScore
            ? hole.selected
              ? hole?.net?.original || 0
              : 0
            : hole.selected
            ? hole?.gross?.original || 0
            : 0,
          par: hole?.par,
          selected: hole?.selected,
          finalScore: showNetScore
            ? hole.selected
              ? hole?.net?.final || 0
              : 0
            : hole.selected
            ? hole?.gross?.final || 0
            : 0,
        };
      }),
    });

    const scorecardConfirmationMessage = () => {
      let message = "";
      scorecardValues.holes &&
        scorecardValues.holes.forEach((hole) => {
          hole.adjustedScore !== hole.finalScore &&
            (message =
              message +
              `Hole ${hole.holeNumber}: from ${hole.finalScore} to ${hole.adjustedScore}  \n`);
        });
      if (message !== "") {
        message =
          `Are you sure you want to submit the following score changes? \n` +
          message;
      }

      return message;
    };

    const handleSubmit = async () => {
      const message = scorecardConfirmationMessage();

      if (window.confirm(message)) {
        const response = await putUpdateRoundScorecardAxios(
          currentFacilityId,
          selectedLeagueId,
          selectedLeagueRoundId,
          scorecardId || "",
          scorecardValues
        );

        if (isApiStatusSuccess(response?.status)) {
          await showCustomNotification(
            "Scorecard updated successfully.",
            GenericNotificationType.SUCCESS
          );
          setEdit(!edit);
          callback && callback();
        } else {
          showCustomNotification(response?.body, GenericNotificationType.ERROR);
        }
      }
    };

    return (
      <>
        <div className={styles.scorecardLayoutPlayerRow}>
          <div className={styles.scorecardLayoutPlayerName}>
            <div className="font-brandDarkGray font-normal pr-2 truncate text-left">
              {matchResults && index && (
                <div
                  className={`inline-block mt-1 mr-1 h-2 w-2 ${teamColorHelper[index]}`}
                ></div>
              )}
              {scorecard.playerName}
              <br />
              {allowEditing &&
                !selectedLeagueRoundIsCompleted &&
                !showNetScore && (
                  <div className="block">
                    <button
                      type="button"
                      onClick={() => handleCancelEdit()}
                      className="inline text-brandGreen dark:text-black text-[10px] hover:text-brandYellow"
                    >
                      {!edit ? "Edit" : "Cancel"}
                    </button>
                    {edit && (
                      <button
                        type="button"
                        className="ml-2 inline text-brandGreen dark:text-black text-[10px] hover:text-brandYellow"
                        onClick={() => handleSubmit()}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                )}
            </div>
          </div>
          <ScoreRow
            holes={holes}
            edit={edit}
            callback={setScorecardValues}
            value={scorecardValues}
            scorecardId={scorecardId}
            showNetScore={showNetScore}
            teamId={playerScorecard[0].team}
            backNine={backNine}
          />
          <div className={styles.scorecardLayoutPlayerRowEnd}>
            {backNine
              ? showNetScore
                ? scorecard?.net?.in
                : scorecard?.gross?.in
              : showNetScore
              ? scorecard?.net?.out
              : scorecard?.gross?.out}
          </div>
        </div>
        {matchResults && index === 0 && (
          <MatchPlayRow
            holes={
              backNine
                ? scorecard?.holes?.filter((hole) => hole.holeNumber < 9)
                : scorecard?.holes?.filter(
                    (hole) => hole.holeNumber >= 1 && hole.holeNumber <= 9
                  )
            }
            holesWon={
              backNine
                ? matchResults?.holesWon?.filter((hole) => hole.holeNumber < 9)
                : matchResults?.holesWon?.filter(
                    (hole) => hole.holeNumber >= 1 && hole.holeNumber <= 9
                  )
            }
            backNine={false}
          />
        )}
      </>
    );
  };

  const FrontAndBackNineToggle: React.FC = () => {
    return (
      <>
        {defaultFrontNine.filter((hole) => hole.selected !== false).length >
          0 &&
          defaultBackNine.filter((hole) => hole.selected !== false).length >
            0 && (
            <div className={styles.frontAndBackToggleContainer}>
              <button
                className={`${
                  showFrontNine ? styles.frontAndBackToggleButtonActive : ""
                } ${styles.frontAndBackToggleButtonInactive}`}
                id="grid"
                onClick={() => setFrontNine(true)}
              >
                Front 9
              </button>
              <button
                className={` ${
                  !showFrontNine ? styles.frontAndBackToggleButtonActive : ""
                } ${styles.frontAndBackToggleButtonInactive}`}
                id="list"
                onClick={() => setFrontNine(false)}
              >
                Back 9
              </button>
            </div>
          )}
      </>
    );
  };

  const ScrambleLeagueRow: React.FC<ScrambleLeagueRowProps> = (props) => {
    const { playerScorecard, showNetScore, showBackNine, teamId } = props;

    let teamScorecard = playerScorecard.find(
      (scorecard) => scorecard.team === teamId
    );
    const scorecardHoles = teamScorecard?.holes.filter((hole) =>
      showBackNine
        ? hole.holeNumber >= 10 && hole.holeNumber <= 18
        : hole.holeNumber >= 1 && hole.holeNumber <= 9
    );
    const teamName = playerScorecard
      .filter((scorecard) => scorecard.team === teamId)
      .map((scorecard) => scorecard.playerName)
      .join(", ");

    return (
      <div className={styles.scorecardLayoutPlayerRow}>
        <div className={styles.scorecardLayoutPlayerName}>
          <div className="font-brandDarkGray font-normal pr-2 truncate">
            {teamName}
          </div>
        </div>
        <ScoreRow
          holes={scorecardHoles || []}
          scorecardId={scorecardId}
          showNetScore={showNetScore}
        />
        <div className={styles.scorecardLayoutPlayerRowEnd}>
          {showBackNine
            ? showNetScore
              ? teamScorecard?.net.in
              : teamScorecard?.gross.in
            : showNetScore
            ? teamScorecard?.net.out
            : teamScorecard?.gross.out}
        </div>
      </div>
    );
  };

  return (
    <div
      key={`${playerScorecard[0].playerGuid}-${playerScorecard[0].playerName}-${scorecardId}`}
      id="scorecardLayoutContainerX"
    >
      <div className="flex flex-row m-0 p-0 justify-end">
        <div className="flex justify-start w-3/5">
          <FrontAndBackNineToggle />
          {handicapMode !== undefined && (
            <div className="poppins ml-2 flex items-center text-xs text-gray-500 dark:text-gray-200 ">
              Handicap: {titleCase(HandicapMode[handicapMode])}
            </div>
          )}
        </div>
        <div className="flex items-center justify-end w-2/5 text-brandDarkGreen">
          <IoMdInformationCircleOutline
            className="size-5 text-zinc-600 dark:text-gray-50 cursor-pointer m-0"
            data-tip
            data-for={`scorecard-${scorecardId}`}
          />
        </div>
      </div>
      {matchResults ? (
        <>
          {((showFrontAndBackNine && showFrontNine) ||
            (!showFrontAndBackNine &&
              defaultFrontNine.filter((hole) => hole.selected !== false)
                .length > 0)) && (
            <div
              className={styles.scorecardLayoutContainer}
              key={`${scorecard.playerGuid}-frontNine-match`}
            >
              <ScorecardHeader
                holes={defaultFrontNine}
                par={parFrontNine}
                holesWon={matchResults.holesWon}
                backNine={false}
              />

              {playerScorecard
                ?.filter((player) => player.team === 1)
                ?.map((scorecard) => {
                  return (
                    <PlayerScoreRow
                      holes={scorecard.holes.filter(
                        (hole) => hole.holeNumber >= 1 && hole.holeNumber <= 9
                      )}
                      index={scorecard.team}
                      scorecard={scorecard}
                      showNetScore={showNetScore}
                      callback={callback}
                      backNine={false}
                    />
                  );
                })}
              <MatchPlayRow
                holes={scorecard.holes.filter(
                  (hole) => hole.holeNumber >= 1 && hole.holeNumber <= 9
                )}
                holesWon={matchResults.holesWon?.filter(
                  (hole) => hole.holeNumber >= 1 && hole.holeNumber <= 9
                )}
                backNine={false}
              />
              {playerScorecard
                ?.filter((player) => player.team === 2)
                ?.map((scorecard) => {
                  return (
                    <PlayerScoreRow
                      holes={scorecard.holes.filter(
                        (hole) => hole.holeNumber >= 1 && hole.holeNumber <= 9
                      )}
                      index={scorecard.team}
                      scorecard={scorecard}
                      showNetScore={showNetScore}
                      callback={callback}
                      backNine={false}
                    />
                  );
                })}
            </div>
          )}
          {((showFrontAndBackNine && !showFrontNine) ||
            (!showFrontAndBackNine &&
              defaultBackNine.filter((hole) => hole.selected !== false).length >
                0)) && (
            <div
              className={styles.scorecardLayoutContainer}
              key={`${scorecard.playerGuid}-backNine-match`}
            >
              <ScorecardHeader
                holes={defaultBackNine}
                par={parBackNine}
                holesWon={matchResults.holesWon}
                backNine={true}
              />

              {playerScorecard
                ?.filter((player) => player.team === 1)
                ?.map((scorecard) => {
                  return (
                    <PlayerScoreRow
                      holes={scorecard.holes.filter(
                        (hole) => hole.holeNumber >= 10 && hole.holeNumber <= 18
                      )}
                      index={scorecard.team}
                      scorecard={scorecard}
                      showNetScore={showNetScore}
                      callback={callback}
                      backNine={true}
                    />
                  );
                })}

              <MatchPlayRow
                holes={scorecard.holes.filter(
                  (hole) => hole.holeNumber >= 10 && hole.holeNumber <= 18
                )}
                holesWon={matchResults.holesWon?.filter(
                  (hole) => hole.holeNumber >= 10 && hole.holeNumber <= 18
                )}
                backNine={true}
              />

              {playerScorecard
                ?.filter((player) => player.team === 2)
                ?.map((scorecard) => {
                  return (
                    <PlayerScoreRow
                      holes={scorecard.holes.filter(
                        (hole) => hole.holeNumber >= 10 && hole.holeNumber <= 18
                      )}
                      index={scorecard.team}
                      scorecard={scorecard}
                      showNetScore={showNetScore}
                      callback={callback}
                      backNine={true}
                    />
                  );
                })}
            </div>
          )}
        </>
      ) : (
        <>
          {((showFrontAndBackNine && showFrontNine) ||
            (!showFrontAndBackNine &&
              defaultFrontNine.filter((hole) => hole.selected !== false)
                .length > 0)) && (
            <div
              className={styles.scorecardLayoutContainer}
              key={`${scorecard.playerGuid}-frontNine`}
            >
              <ScorecardHeader holes={defaultFrontNine} par={parFrontNine} />

              {scrambleLeague ? (
                <>
                  <ScrambleLeagueRow
                    showBackNine={false}
                    playerScorecard={playerScorecard}
                    showNetScore={showNetScore}
                    teamId={1}
                  />
                  <ScrambleLeagueRow
                    showBackNine={false}
                    playerScorecard={playerScorecard}
                    showNetScore={showNetScore}
                    teamId={2}
                  />
                </>
              ) : (
                playerScorecard.map((scorecard, index) => {
                  let frontNineHoles = scorecard.holes.filter(
                    (hole) => hole.holeNumber >= 1 && hole.holeNumber <= 9
                  );

                  return (
                    <PlayerScoreRow
                      holes={frontNineHoles}
                      index={index}
                      scorecard={scorecard}
                      showNetScore={showNetScore}
                      callback={callback}
                    />
                  );
                })
              )}

              {teamScorecard && Object.keys(teamScorecard).length > 0 && (
                <div className={styles.scorecardLayoutPlayerRow}>
                  <div className={styles.scorecardLayoutPlayerName}>
                    <div className="font-brandDarkGray font-normal pr-2 truncate">
                      {teamScorecard.teamName}
                    </div>
                  </div>
                  <ScoreRow
                    holes={
                      teamScorecard?.holes.filter(
                        (hole) => hole.holeNumber >= 1 && hole.holeNumber <= 9
                      ) || []
                    }
                    scorecardId={scorecardId}
                    showNetScore={showNetScore}
                  />
                  <div className={styles.scorecardLayoutPlayerRowEnd}>
                    {showNetScore
                      ? teamScorecard.net.out
                      : teamScorecard.gross.out}
                  </div>
                </div>
              )}
            </div>
          )}
          {((showFrontAndBackNine && !showFrontNine) ||
            (!showFrontAndBackNine &&
              defaultBackNine.filter((hole) => hole.selected !== false).length >
                0)) && (
            <div
              className={styles.scorecardLayoutContainer}
              key={`${scorecard.playerGuid}-backNine`}
            >
              <ScorecardHeader
                holes={defaultBackNine}
                par={parBackNine}
                backNine={true}
              />

              {scrambleLeague ? (
                <>
                  <ScrambleLeagueRow
                    showBackNine={true}
                    playerScorecard={playerScorecard}
                    showNetScore={showNetScore}
                    teamId={1}
                  />
                  <ScrambleLeagueRow
                    showBackNine={true}
                    playerScorecard={playerScorecard}
                    showNetScore={showNetScore}
                    teamId={2}
                  />
                </>
              ) : (
                playerScorecard.map((scorecard, index) => {
                  let backNineHoles = scorecard.holes.filter(
                    (hole) => hole.holeNumber >= 10 && hole.holeNumber <= 18
                  );

                  return (
                    <PlayerScoreRow
                      holes={backNineHoles}
                      index={index}
                      scorecard={scorecard}
                      showNetScore={showNetScore}
                      callback={callback}
                      backNine={true}
                    />
                  );
                })
              )}

              {teamScorecard && Object.keys(teamScorecard).length > 0 && (
                <div className={styles.scorecardLayoutPlayerRow}>
                  <div className={styles.scorecardLayoutPlayerName}>
                    <div className="font-brandDarkGray font-normal pr-2 truncate">
                      {teamScorecard.teamName}
                    </div>
                  </div>
                  <ScoreRow
                    holes={
                      teamScorecard?.holes.filter(
                        (hole) => hole.holeNumber >= 10 && hole.holeNumber <= 18
                      ) || []
                    }
                    scorecardId={scorecardId}
                    showNetScore={showNetScore}
                    backNine={true}
                  />
                  <div className={styles.scorecardLayoutPlayerRowEnd}>
                    {showNetScore
                      ? teamScorecard.net.in
                      : teamScorecard.gross.in}
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
      <ReactTooltip
        id={`scorecard-${scorecardId}`}
        place="left"
        multiline={true}
        className="p-0 m-0"
      >
        <ScorecardTooltip />
      </ReactTooltip>
    </div>
  );
};
export default ScorecardHolesLayout;
