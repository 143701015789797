import Select from "react-select";
import Row from "components/ui/layout/Row";
import { RoundConfiguration } from "Models";
import { customStyles } from "utils/ui/uiHelpers";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { useFacilityRoundConfigurations } from "modules/round-configuration/roundConfigurationHooks";
import { useState } from "react";
import { motion } from "framer-motion";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";

const RoundConfigurationSelect: React.FC<RoundConfigurationSelectProps> = (
  props
) => {
  const [showSettings, setShowSettings] = useState(false);
  const currentFacilityId = useGetCurrentLocalFacilityId();
  const [facilityRoundConfigs] = useFacilityRoundConfigurations(
    currentFacilityId
  );

  return (
    <>
      <Row cssClasses="flex-row">
        <span className="text-md block text-black dark:text-gray-200">
          <button onClick={() => setShowSettings(!showSettings)}>
            {showSettings ? (
              <FaAngleDown className="mr-2 h-5 w-5" />
            ) : (
              <FaAngleRight className="mr-2 h-5 w-5" />
            )}
            Load From Saved Round Settings
          </button>
        </span>
      </Row>
      {showSettings && (
        <motion.div
          className="flex-row mt-2"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          <Select
            isSearchable={true}
            isClearable={true}
            closeMenuOnSelect={true}
            options={facilityRoundConfigs}
            styles={customStyles}
            getOptionLabel={(config: RoundConfiguration) =>
              config.name || "Defaults"
            }
            getOptionValue={(config: RoundConfiguration) => config}
            onChange={(e: RoundConfiguration) => {
              props.onChange(e);
            }}
            placeholder={"Select a Saved Round Setting"}
          />
          <Row cssClasses="pt-2 text-gray-400 dark:text-gray-200 text-xs">
            Selecting an option here will populate the fields in the form below
            with the selected saved round settings.
          </Row>
        </motion.div>
      )}
    </>
  );
};
export default RoundConfigurationSelect;

type RoundConfigurationSelectProps = {
  onChange: React.Dispatch<
    React.SetStateAction<RoundConfiguration | undefined>
  >;
};
