import { useCallback } from "react";
import { RootState } from "StoreTypes";
import { MdClose } from "react-icons/md";
import { useSelector } from "react-redux";
import { useAppDispatch } from "utils/hooks";
import { AnimatePresence, motion } from "framer-motion";
import { styles } from "components/shared/helpdocs/HelpDocTray.styles";
import { createRexReservationTrayAction } from "ui-modules/nav/navSlice";
import SimulatorReservationContainer from "components/control/round-control/round-configuration-form/SimulatorReservationContainer";

const RexReservationTray = () => {
  const dispatch = useAppDispatch();

  const isRexReservationTrayOpen = useSelector(
    (state: RootState) => state.nav?.rexReservationTray?.isOpen
  );
  const handleRexReservationTrayClosed = useCallback(() => {
    dispatch(createRexReservationTrayAction({ isOpen: false }));
  }, [dispatch]);

  return (
    <AnimatePresence>
      {isRexReservationTrayOpen && (
        <>
          <motion.div
            initial={{ x: "100%" }}
            animate={{
              x: "0px",
            }}
            exit={{
              x: "100%",
            }}
            transition={{ type: "spring", bounce: 0, duration: 0.75 }}
            className={`${styles.trayContainer} z-9998`}
            key="createQueueItemTray"
            data-testid="createQueueItemTray"
            id="createQueueItemTray"
          >
            <div className={styles.headerContainer}>
              <div className={styles.headerCloseButton}>
                <button
                  onClick={() => handleRexReservationTrayClosed()}
                  className={styles.button}
                >
                  <span className="text-white">
                    <MdClose className="h-4 w-4" />
                  </span>{" "}
                  Close
                </button>
              </div>
              <div className={styles.headerTitle}>
                <h2 className={styles.trayHeader}>Confirm Reservation</h2>
              </div>
              <div className={styles.headerRightButton}></div>
            </div>
            <div className={styles.innerContainer}>
              <SimulatorReservationContainer />
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
            id="reservationTrayOverlay"
            transition={{ type: "spring", bounce: 0, duration: 0.2 }}
            className={styles.overlay}
          ></motion.div>
        </>
      )}
    </AnimatePresence>
  );
};
export default RexReservationTray;
