import { RootState } from "StoreTypes";
import { useSelector } from "react-redux";
import { useAppDispatch } from "utils/hooks";
import { FaArrowLeft } from "react-icons/fa";
import { enableBackgroundScroll } from "utils/ui/uiHelpers";
import { styles } from "../scorecard/Scorecard.styles";
import { AnimatePresence, motion } from "framer-motion";
import { leagueScorecardTrayAction } from "ui-modules/nav/navSlice";
import { useSelectedLeague } from "../../modules/leagues/leagueHooks";
import LeagueScorecardSearch from "./LeagueScorecardSearch";

const LeagueScorecardSearchTray = () => {
  const dispatch = useAppDispatch();
  const selectedLeague = useSelectedLeague();

  const isLeagueScorecardTrayOpen = useSelector(
    (state: RootState) => state.nav.leagueScorecardTray?.isOpen
  );

  const setScorecardTray = () => {
    enableBackgroundScroll();
    dispatch(leagueScorecardTrayAction({ isOpen: !isLeagueScorecardTrayOpen }));
  };

  return (
    <AnimatePresence>
      {isLeagueScorecardTrayOpen && (
        <>
          <motion.div
            initial={{ x: "100%" }}
            animate={{
              x: 0,
            }}
            exit={{
              x: "100%",
            }}
            transition={{ type: "spring", bounce: 0, duration: 0.5 }}
            className={styles.trayContainer}
            data-testid="scorecardTray"
            id="scorecardTray"
          >
            <div className={styles.headerContainer}>
              <div className={styles.headerCloseButton}>
                <button
                  onClick={() => setScorecardTray()}
                  className="rounded-full shadow bg-darkCream dark:bg-zinc-900 poppins py-1 px-1.5"
                >
                  <FaArrowLeft className="h-4 w-4" />
                </button>
              </div>
              <div className={styles.headerTitle}>
                <h2 className={styles.trayHeader}>
                  {selectedLeague?.name} Scorecards
                </h2>
              </div>
              <div className={styles.headerRightButton}></div>
            </div>
            <div className={styles.innerContainer}>
              <LeagueScorecardSearch />
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};
export default LeagueScorecardSearchTray;
