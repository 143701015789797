import { BlobServiceClient } from "@azure/storage-blob";

const account = "agmarketinghub";
const sas = process.env.REACT_APP_AZURE_SAS;
export const containerName = "marketing";
export const blobServiceClient = new BlobServiceClient(
  `https://${account}.blob.core.windows.net?${sas}`
);
export const containerClient = blobServiceClient.getContainerClient(
  containerName
);
export const viewableFileTypes = ["pdf", "jpg", "jpeg", "png", "mp4"];

export const getBlobsInContainer = async () => {
  const returnedBlobUrls = [];

  // eslint-disable-next-line
  for await (const blob of containerClient.listBlobsFlat()) {
    const blobItem = {
      url: `https://${account}.blob.core.windows.net/${containerName}/${blob.name}?${sas}`,
      name: blob.name,
    };

    returnedBlobUrls.push(blobItem);
  }

  return returnedBlobUrls;
};

export function humanReadableFileName(fileName: string): string {
  const fileNameWithoutExtension = fileName.split(".")[0];
  const words = fileNameWithoutExtension.split("_");
  const humanReadableWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return humanReadableWords.join(" ");
}

export const downloadFile = (fileUrl: string, fileName: string) => {
  fetch(fileUrl, {
    method: "GET",
    headers: {},
  })
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));

      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;

      document.body.appendChild(link);

      link.click();

      link?.parentNode?.removeChild(link);
    });
};
