import { useAppDispatch } from "utils/hooks";
import { ModalType } from "utils/modals/modalHelpers";
import { modalAction } from "ui-modules/modals/modalSlice";
import Row from "components/ui/layout/Row";
import Modal from "components/shared/Modal";
import SettingsContainer from "components/shared/settings/SettingsContainer";

const RoundPresetsModal: React.FC<LeagueRoundGroupModalProps> = (props) => {
  const dispatch = useAppDispatch();

  const handleCloseModal = async () => {
    dispatch(
      modalAction({
        isOpen: false,
        modalName: ModalType.RoundPresets,
      })
    );
  };

  return (
    <Modal
      modalName={props.modalName}
      closeModalCallback={handleCloseModal}
      hideHeader={false}
      title={`Round Presets` || props.title}
      hideFooter={true}
      modalSize="w-full h-full md:w-3/4 2xl:w-1/2"
      body={
        <Row cssClasses="px-8 py-4">
          <SettingsContainer />
        </Row>
      }
    />
  );
};

type LeagueRoundGroupModalProps = {
  title?: string;
  modalName: string;
};

RoundPresetsModal.defaultProps = {
  title: "Round Presets",
};

export default RoundPresetsModal;
