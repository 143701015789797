import {
  sendControlToSimulator,
  simulatorControlType,
} from "modules/simulator";
import {
  notificationMessageGroup,
  showResponseNotification,
} from "utils/notifications/notificationHelpers";
import ReactTooltip from "react-tooltip";
import { BsPower } from "react-icons/bs";
import { RiRestartLine } from "react-icons/ri";
import styles from "../SingleFacilityControlDashboard.styles";

type OwnProps = {
  simulatorId: string;
  isActive?: boolean;
};

const ShutdownAndRestartButtons: React.FC<OwnProps> = (props) => {
  const useHandleShutDown = () => {
    if (window.confirm("Shut down this sim?")) {
      const response = sendControlToSimulator(
        simulatorControlType.shutdown,
        props.simulatorId
      );

      showResponseNotification(response, notificationMessageGroup.shutdown);
    }
  };

  const useHandleRestart = () => {
    if (window.confirm("Restart this sim?")) {
      const response = sendControlToSimulator(
        simulatorControlType.restart,
        props.simulatorId
      );

      showResponseNotification(response, notificationMessageGroup.restart);
    }
  };

  return (
    <div className={styles.singleSimCardViewRestartPowerContainer}>
      <button
        data-tip="Power off this sim"
        data-testid="shutdown-button"
        data-for={`shutdownAndRestartButtons-${props.simulatorId}`}
        onClick={useHandleShutDown}
        className={styles.singleSimCardViewPowerButton}
      >
        <BsPower className="text-normal" />
      </button>
      <button
        data-tip="Restart this sim"
        data-testid="restart-button"
        data-for={`shutdownAndRestartButtons-${props.simulatorId}`}
        onClick={useHandleRestart}
        className={styles.singleSimCardViewRestartButton}
      >
        <RiRestartLine className=" text-normal" />
      </button>
      <ReactTooltip
        place="top"
        delayShow={200}
        backgroundColor="#f9fafb"
        textColor="#4b5563"
        border={true}
        borderColor="#d1d5db"
        id={`shutdownAndRestartButtons-${props.simulatorId}`}
        className="!text-xs"
      />
    </div>
  );
};
export default ShutdownAndRestartButtons;
