import { BsClipboardData } from "react-icons/bs";
import { styles } from "../ui/layout/Header.styles";
import { modalAction } from "ui-modules/modals/modalSlice";
import { ModalType } from "utils/modals/modalHelpers";
import { useAppDispatch } from "utils/hooks";

const ScorecardLookupButton = () => {
  const dispatch = useAppDispatch();

  const setScorecardModal = () => {
    dispatch(
      modalAction({ isOpen: true, modalName: ModalType.ScorecardSearch })
    );
  };

  return (
    <div className="pl-6 text-center items-center md:border-l md:border-gray-200">
      <button
        type="button"
        onClick={() => setScorecardModal()}
        id="headerScorecardLookupButton"
        data-tip="Scorecard Search"
        data-for="headerButtonsTooltip"
      >
        <BsClipboardData
          className={`${styles.buttonIcon} dark:text-gray-200`}
        />
      </button>
      <span className={styles.buttonText}>Score</span>
    </div>
  );
};

export default ScorecardLookupButton;
