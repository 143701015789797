import { AnimatePresence, motion } from "framer-motion";
import { useSelector } from "react-redux";
import { RootState } from "StoreTypes";
import {
  createEventTrayAction,
  onlineEventsTrayAction,
} from "ui-modules/nav/navSlice";
import { useAppDispatch } from "utils/hooks";
import { styles } from "./Events.styles";
import EventsList from "../../control/events/EventsList";
import CreateEventTray from "./CreateEventTray";

const OnlineEventsTray = () => {
  const dispatch = useAppDispatch();

  const isOnlineEventsTrayOpen = useSelector(
    (state: RootState) => state.nav?.onlineEventsTray?.isOpen
  );

  const setOnlineEventsTray = () => {
    dispatch(onlineEventsTrayAction({ isOpen: !isOnlineEventsTrayOpen }));
    dispatch(createEventTrayAction({ isOpen: false }));
  };

  return (
    <AnimatePresence>
      {isOnlineEventsTrayOpen && (
        <>
          <motion.div
            initial={{ x: "100%" }}
            animate={{
              x: 0,
            }}
            exit={{
              x: "100%",
            }}
            transition={{ type: "spring", bounce: 0, duration: 0.4 }}
            className={styles.trayContainer}
            data-testid="onlineEventsTray"
            id="onlineEventsTray"
          >
            <div className={styles.headerContainer}>
              <div className={styles.headerCloseButton}>
                <button
                  onClick={() => setOnlineEventsTray()}
                  className={styles.button}
                >
                  Close
                </button>
              </div>
              <div className={styles.headerTitle}>
                <h2 className={styles.trayHeader}>Online Events</h2>
              </div>
              <div className={styles.headerRightButton}></div>
            </div>
            <div className={styles.innerContainer}>
              <EventsList />
              <CreateEventTray />
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
            transition={{ type: "spring", bounce: 0, duration: 0.2 }}
            onClick={() => setOnlineEventsTray()}
            className={styles.overlay}
            data-testid="onlineEventTrayOverlay"
            id="onlineEventTrayOverlay"
          />
        </>
      )}
    </AnimatePresence>
  );
};
export default OnlineEventsTray;
