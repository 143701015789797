import cn from "classnames";
import { createElement } from "react";
import { iconOptions } from "utils/icons/iconHelpers";
import styles from "./RadioTile.less";

const RadioTile: React.FC<RadioTileProps> = (props) => {
  const {
    label,
    value,
    width,
    iconOptions,
    name,
    onClick,
    hideLabel,
    defaultValue,
    disabled,
  } = props;
  const id = label?.replace(/ /g, "");

  return (
    <div className={cn(styles.inputContainer, width, " p-1 mb-2")}>
      {value === defaultValue ? (
        <input
          id={id}
          className={cn(
            styles.radioButton,
            " disabled:cursor-not-allowed disabled:bg-gray-100"
          )}
          type="radio"
          name={name}
          value={value}
          onChange={onClick}
          disabled={disabled}
          defaultChecked
        />
      ) : (
        <input
          id={id}
          className={cn(
            styles.radioButton,
            " disabled:cursor-not-allowed disabled:bg-gray-100"
          )}
          type="radio"
          name={name}
          value={value}
          onChange={onClick}
          disabled={disabled}
        />
      )}
      <div
        className={cn(
          styles.radioTile,
          "bg-white rounded-md shadow p-2 border border-gray-300"
        )}
      >
        <div className={cn(styles.icon, styles.walkIcon)}>
          {iconOptions &&
            createElement(iconOptions?.icon, {
              color: iconOptions?.color,
            })}
        </div>
        <label
          htmlFor={id}
          className={cn(styles.radioTileLabel, "text-xs font-500 mulish")}
        >
          {hideLabel ? "" : label}
        </label>
      </div>
    </div>
  );
};
export default RadioTile;

type RadioTileProps = {
  iconOptions?: iconOptions;
  selectedColor?: string;
  label?: string;
  value: string;
  width?: string;
  name: string;
  onClick?: any;
  hideLabel?: boolean;
  defaultValue?: string;
  checked?: boolean;
  disabled?: boolean;
};

RadioTile.defaultProps = {
  selectedColor: "text-green-500",
  label: "Option",
  value: "",
  width: "w-1/3",
  hideLabel: false,
};
