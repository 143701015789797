import { Hole, Simulator } from "Models";
import { useFacilityCourses } from "modules/course/courseHooks";
import { useFormContext } from "react-hook-form";
import { RootState } from "StoreTypes";
import { useSelector } from "react-redux";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { getSimulatorsByIdArray } from "modules/simulator/simulatorSelectors";
import MissingSimsList from "./../round-control/round-configuration-form/facility/MissingSimsList";
import EventCourseAndHolesForm from "./EventCourseAndHolesForm";

const EventCourseAndHoles: React.FC<EventCourseAndHolesProps> = (props) => {
  const { watch } = useFormContext();
  const currentFacilityId = useGetCurrentLocalFacilityId();

  const [queryCourses] = useFacilityCourses(currentFacilityId);
  const courseId = watch(`roundInfo[${props.roundNumber}].courseId`);
  const courseName =
    queryCourses.find((course) => course.id === courseId?.toString())
      ?.shortName || "";

  const selectedCourseHoles: Hole[] = useSelector(
    (state: RootState) =>
      state.entities.courses?.courseData?.[courseName]?.holes || []
  );

  const missingSimIds: string[] = useSelector(
    (state: RootState) =>
      state.entities.courses?.missingSimulatorsByCourse?.[courseName] || []
  );

  const selectedCourseMissingSims: Simulator[] = useSelector(
    (state: RootState) => getSimulatorsByIdArray(state, missingSimIds)
  );

  return (
    <>
      {selectedCourseMissingSims.length ? (
        <MissingSimsList missingSimulators={selectedCourseMissingSims} />
      ) : null}
      <EventCourseAndHolesForm
        courses={queryCourses}
        holes={selectedCourseHoles}
        roundNumber={props.roundNumber}
      />
    </>
  );
};

export default EventCourseAndHoles;

type EventCourseAndHolesProps = {
  roundNumber: number;
};
