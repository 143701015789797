import { Course } from "Models";
import { UseDataInfo } from "QueryTypes";
import { useSelector } from "react-redux";
import { useRequest } from "redux-query-react";
import { RootState } from "StoreTypes";
import {
  getCoursesByFacilityQuery,
  getCoursesBySimQuery,
} from "./courseQueries";

export const useSimulatorCourses = (simId: string): UseDataInfo<Course[]> => {
  const [queryState, refreshCourses] = useRequest(getCoursesBySimQuery(simId));

  const simulatorCourses = useSelector((state: RootState): Course[] => {
    if (!state.entities?.courses?.courseData) return [];
    return Object.values(state.entities?.courses.courseData).filter((course) =>
      state.entities?.courses?.bySimId?.[simId]?.includes(course.shortName)
    );
  });

  return [simulatorCourses, queryState, refreshCourses];
};

export const useFacilityCourses = (
  facilityId: string
): UseDataInfo<Course[]> => {
  const [queryState, refreshFacilityCourses] = useRequest(
    getCoursesByFacilityQuery(facilityId)
  );

  const allCourses = useSelector((state: RootState): Course[] => {
    if (!state.entities.courses?.courseData) return [];
    return Object.values(state.entities?.courses?.courseData);
  });

  return [allCourses, queryState, refreshFacilityCourses];
};
