import ReservationsIcon from "components/svg/ReservationsIcon";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "StoreTypes";
import { queueAction } from "ui-modules/nav/navSlice";
import { useAppDispatch } from "utils/hooks";
import {
  setLocalStorageValue,
  LocalStorageItems,
} from "utils/hooks/localStorage";

const QueueTrayButton: React.FC = () => {
  const dispatch = useAppDispatch();

  const showQueueOnDashboard = useSelector(
    (state: RootState) => state.nav?.queue?.showOnDashboard
  );

  const handleButtonClink = async () => {
    await setLocalStorageValue(
      LocalStorageItems.DASHBOARDQUEUE,
      JSON.stringify(!showQueueOnDashboard)
    );
    dispatch(queueAction({ showOnDashboard: !showQueueOnDashboard }));
  };

  return (
    <div className="">
      <button
        type="button"
        onClick={() => handleButtonClink()}
        className="flex items-center p-2 bg-white hover:bg-brandGreen text-zinc-800 dark:text-gray-50 hover:text-white cursor-pointer dark:bg-zinc-800 dark:hover:bg-zinc-600 border border-gray-200 dark:border-gray-500 rounded-lg"
      >
        <ReservationsIcon class="size-5" />
      </button>
    </div>
  );
};
export default QueueTrayButton;
