import { RoundConfigurationsEntityType } from "EntityTypes";
import { RoundConfiguration } from "Models";
import {
  AppQueryConfig,
  RoundConfigurationToMultiSimsPayload,
} from "QueryTypes";
import { reduxQueryDefaultOptions } from "utils/queries/queryHelpers";

const urls = {
  getAllRoundConfigs(facilityId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facilities/${facilityId}/round-configuration/all`;
  },
  addRoundConfig(facilityId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facilities/${facilityId}/round-configuration`;
  },
  deleteRoundConfig(facilityId: string, roundConfigId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facilities/${facilityId}/round-configuration/${roundConfigId}`;
  },
  sendToMultipleSims(): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/simulators/round-configuration/send`;
  },
};

export const getFacilityRoundConfigs = (
  facilityId: string
): AppQueryConfig => ({
  url: urls.getAllRoundConfigs(facilityId),
  queryKey: `facilityRoundConfigs:${facilityId}`,
  options: { ...reduxQueryDefaultOptions() },
  transform: (responseJson: RoundConfiguration[]) => {
    let obj: { [id: string]: RoundConfiguration } = {};
    responseJson?.map((config) => (obj[config.id] = config));
    return {
      roundConfigurations: {
        byFacilityId: { [facilityId]: obj },
      },
    };
  },
  update: {
    roundConfigurations: (
      oldValue: RoundConfigurationsEntityType,
      newValue: RoundConfigurationsEntityType
    ): RoundConfigurationsEntityType => ({
      ...oldValue,
      byFacilityId: {
        ...oldValue?.byFacilityId,
        ...newValue.byFacilityId,
      },
    }),
  },
});

export const makeAddRoundConfigurationMutation = (
  facilityId: string,
  body: {}
): AppQueryConfig => ({
  url: urls.addRoundConfig(facilityId),
  queryKey: `addRoundConfig:${facilityId}`,
  options: {
    ...reduxQueryDefaultOptions(),
    method: "POST",
  },
  body,
  transform: (responseJson: RoundConfiguration) => {
    return {
      roundConfigurations: {
        byFacilityId: {
          [facilityId]: { [responseJson.id]: responseJson },
        },
      },
    };
  },
  update: {
    roundConfigurations: (oldValue, newValue) => ({
      ...oldValue,
      byFacilityId: {
        ...oldValue?.byFacilityId,
        ...{
          [facilityId]: {
            ...oldValue.byFacilityId?.[facilityId],
            ...newValue.byFacilityId[facilityId],
          },
        },
      },
    }),
  },
});

export const makeDeleteRoundConfigurationMutation = (
  facilityId: string,
  roundConfigId: string
): AppQueryConfig => ({
  url: urls.deleteRoundConfig(facilityId, roundConfigId),
  queryKey: `deleteRoundConfig:${roundConfigId}`,
  options: {
    ...reduxQueryDefaultOptions(),
    method: "DELETE",
  },
  optimisticUpdate: {
    roundConfigurations: (reduxState) => {
      delete reduxState.byFacilityId[facilityId][roundConfigId];
      return reduxState;
    },
  },
});

export const makeSendRoundConfigurationToSimulatorsMutation = (
  body: RoundConfigurationToMultiSimsPayload
): AppQueryConfig => ({
  url: urls.sendToMultipleSims(),
  options: {
    ...reduxQueryDefaultOptions(),
    method: "POST",
  },
  body,
});
