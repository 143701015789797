import { ServiceRequest } from "Models";
import { useSelector } from "react-redux";
import { getSimulatorBySimId } from "modules/simulator/simulatorSelectors";
import {
  serviceAlertIconColorHelper,
  serviceAlertIconHelper,
} from "utils/icons";
import { DateTime } from "luxon";
import { RootState } from "StoreTypes";
import { motion } from "framer-motion";
import { ServiceRequestType, MotionVariantType } from "types/enums";
import { serviceRequestEnumDisplayName } from "utils/enums/enumHelper";
import { useDismissServiceRequest } from "modules/service-requests/serviceRequestHooks";
import { containerChildMotionVariants } from "utils/animations/animationHelper";
import { createElement } from "react";

type ServiceRequestProps = {
  serviceRequest: ServiceRequest;
};

const ServiceRequestContainer: React.FC<ServiceRequestProps> = (props) => {
  const { serviceRequest } = props;

  const simulator = useSelector((state: RootState) =>
    getSimulatorBySimId(state, { simId: serviceRequest.simulatorId })
  );

  const timeAgoRequestCreated = (timestamp: string) => {
    return DateTime.fromISO(timestamp).toRelative();
  };

  const [, dismissServiceRequest] = useDismissServiceRequest(serviceRequest.id);

  return (
    <motion.div
      className="w-full shadow rounded-lg bg-white  dark:bg-zinc-800  dark:text-gray-200 mb-4 p-4 notification-box flex cursor-pointer text-xl"
      variants={containerChildMotionVariants}
      initial={MotionVariantType.REST}
      whileHover={MotionVariantType.HOVER}
      whileTap={MotionVariantType.PRESSED}
      whileDrag={MotionVariantType.EXIT}
      drag="x"
      dragConstraints={{ left: 0 }}
      onTap={dismissServiceRequest}
      onDragEnd={dismissServiceRequest}
      key={serviceRequest.id}
    >
      {serviceRequest &&
        createElement(serviceAlertIconHelper[serviceRequest.type.toString()], {
          color: serviceAlertIconColorHelper[serviceRequest.type.toString()],
          className: "margin-right: 10px;",
        })}
      <div className="flex flex-col col-span-8 w-full">
        <div className="flex flex-row justify-between items-end">
          <h2 className="text-lg font-semibold text-gray-900 dark:text-gray-200">
            {simulator?.name}
          </h2>
          <small className="text-sm text-gray-700 dark:text-gray-200">
            {timeAgoRequestCreated(serviceRequest.createdDate.toString())}
          </small>
        </div>
        <div>
          <span className="text-gray-400 text-sm">
            {serviceRequestEnumDisplayName(
              ServiceRequestType[serviceRequest.type]
            )}
          </span>
        </div>
      </div>
    </motion.div>
  );
};
export default ServiceRequestContainer;
