import { Event, EventRoundInfo } from "Models";
import { useDeleteEvents } from "modules/events/eventHooks";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { setSelectedEventId } from "ui-modules/event/eventSlice";
import {
  createEventTrayAction,
  onlineEventsTrayAction,
} from "ui-modules/nav/navSlice";
import { useAppDispatch } from "utils/hooks";
import {
  GenericNotificationType,
  showCustomNotification,
} from "utils/notifications/notificationHelpers";
import { styles } from "./Events.styles";
import { eventStartDatePassed } from "modules/events/eventHelpers";
import {
  roundConfigEnumsAsReactSelectOptions,
  titleCase,
} from "utils/enums/enumHelper";
import { useFacilityCourses } from "modules/course/courseHooks";
import {
  ClubhouseEvents,
  pendoTrackBasicEvent,
} from "utils/pendo/pendoHelpers";

type OwnProps = {
  event: Event;
};

const EventItemDetails: React.FC<OwnProps> = (props) => {
  const dispatch = useAppDispatch();
  const currentFacilityId = useGetCurrentLocalFacilityId();
  const { event } = props;

  const startDatePassed = eventStartDatePassed(event.startDate);

  const [, deleteEvent] = useDeleteEvents(event.id, currentFacilityId);
  const [queryCourses] = useFacilityCourses(currentFacilityId);

  const handleDeleteEvent = async () => {
    if (window.confirm("Do you want to delete this event?")) {
      const response = await deleteEvent();

      if (response?.status === 200) {
        showCustomNotification(
          event.name + " has been deleted",
          GenericNotificationType.SUCCESS
        );
      } else {
        const errorMessage = response?.text
          ? response.text
          : "An error has occurred. Please try again.";

        showCustomNotification(errorMessage, GenericNotificationType.ERROR);
      }
      pendoTrackBasicEvent(ClubhouseEvents.DELETE_EVENT);
    }
  };

  const handleEditEvent = (eventId: number) => {
    dispatch(
      setSelectedEventId({
        selectedEventId: eventId,
      })
    );
    dispatch(onlineEventsTrayAction({ isOpen: true }));
    dispatch(createEventTrayAction({ isOpen: true }));

    pendoTrackBasicEvent(ClubhouseEvents.EDIT_EVENT);
  };

  return (
    <table className="table-auto w-full text-xs">
      <tbody>
        <tr key={`eventItemDetails-${event.id}-tour`}>
          <td className={styles.queuePreviewLeftColumn}>Tour:</td>
          <td className={styles.queuePreviewRightColumn}>{event.groupName}</td>
        </tr>

        <tr>
          <td className={styles.queuePreviewLeftColumn}>Type:</td>
          <td className={styles.queuePreviewRightColumn}>
            {titleCase(event.type)}
          </td>
        </tr>

        <tr>
          <td className={styles.queuePreviewLeftColumn}>Max Users:</td>
          <td className={styles.queuePreviewRightColumn}>
            {event.maxUserCount}
          </td>
        </tr>

        <tr>
          <td className={styles.queuePreviewLeftColumn}>Gimmes:</td>
          <td className={styles.queuePreviewRightColumn}>
            {
              roundConfigEnumsAsReactSelectOptions.eventGimmeDistance.find(
                (c) => c.value === event.gimmes
              )?.label
            }
          </td>
        </tr>

        <tr>
          <td className={styles.queuePreviewLeftColumn}>Replays:</td>
          <td className={styles.queuePreviewRightColumn}>{event.replays}</td>
        </tr>

        <tr>
          <td className={styles.queuePreviewLeftColumn}>Mulligans:</td>
          <td className={styles.queuePreviewRightColumn}>
            {event.mulligans ? "On" : "Off"}
          </td>
        </tr>

        <tr className="border-b border-gray-100">
          <td className={styles.queuePreviewLeftColumn}>Rounds:</td>
          <td className={styles.queuePreviewRightColumn}>{event.rounds}</td>
        </tr>

        {event.roundInfo.map((round: EventRoundInfo) => {
          return (
            <tr className="border-b border-gray-100" key={round.roundNumber}>
              <td className={styles.queuePreviewLeftColumn}>
                <span className="pl-3 text-gray-400">{round.roundNumber}</span>
              </td>
              <td className={styles.queuePreviewRightColumn}>
                {
                  queryCourses.find(
                    (course) => course.id === round.courseId?.toString()
                  )?.displayName
                }{" "}
                - {round.holes.length} holes
              </td>
            </tr>
          );
        })}
        <tr>
          <td colSpan={2} className="py-2 border-b-2">
            {event.imageUrl && (
              <img src={event.imageUrl} alt={`Poster of ${event.name}`} />
            )}
          </td>
        </tr>
        <tr>
          <td colSpan={2} className="pt-2 mt-2 pb-1 text-center text-gray-400">
            {startDatePassed ? (
              <span>
                Deleting is disabled since the Event has already started.
              </span>
            ) : null}
          </td>
        </tr>
        <tr>
          <td colSpan={2} className="align-right">
            <button
              className="w-1/2 font-bold text-left text-xs pt-2 text-green-700 disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={() => handleEditEvent(event.id)}
            >
              <i className="far fa-edit pr-1 text-xs"></i> Edit
            </button>
            <button
              className="w-1/2 font-bold text-right text-xs pt-2 text-red-700 disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={() => handleDeleteEvent()}
              disabled={startDatePassed}
            >
              <i className="far fa-trash pr-1 text-xs"></i> Delete
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
export default EventItemDetails;
