import { useAppDispatch } from "utils/hooks";
import { ButtonWithIcon } from "components/ui/form";
import { FormProvider, useForm } from "react-hook-form";
import { PlayerUser, SimulatorReservationPayload } from "Models";
import { useSelectedSimulatorReservation } from "modules/simulator-reservations/simulatorReservationHooks";
import { createRexReservationTrayAction } from "ui-modules/nav/navSlice";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { showCustomNotification } from "utils/notifications/notificationHelpers";
import DateSelect from "./DateSelect";
import ReactTooltip from "react-tooltip";
import Row from "components/ui/layout/Row";
import SimulatorSelect from "./SimulatorSelect";
import StartGameForm from "./StartGameForm";
import { FaCloudUploadAlt } from "react-icons/fa";
import {
  ClubhouseEvents,
  pendoTrackEventWithProperties,
} from "utils/pendo/pendoHelpers";
import { roundConfigEnumsAsReactSelectOptions } from "utils/enums/enumHelper";
import { useStartRound } from "modules/round/roundHooks";
import { useEffect, useState } from "react";
import { playerUsersByLockerId } from "utils/userSearch/userSearchHelpers";
import SimulatorReservationPlayerCheck from "components/control/simulator-reservations/SimulatorReservationPlayerCheck";
import ShowLoadingAnimation from "components/control/dashboard/ShowLoadingAnimation";
import { useFacilityDefaultRoundSettings } from "modules/default-round-settings";

const SimulatorReservationContainer = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(true);
  const currentFacilityId = useGetCurrentLocalFacilityId();
  const selectedSimulatorReservation = useSelectedSimulatorReservation(
    currentFacilityId
  );
  const [defaultRoundSettings] = useFacilityDefaultRoundSettings(
    currentFacilityId
  );
  const methods = useForm();
  const [, startRound] = useStartRound(
    currentFacilityId,
    selectedSimulatorReservation?.simulatorId
  );
  const existingPlayers = methods.watch("roundInfo.players", []);

  const options = playerUsersByLockerId(
    selectedSimulatorReservation?.lockerAccounts
  );

  useEffect(() => {
    options
      .then((players: PlayerUser[]) => {
        if (isLoading) {
          players.map((player: PlayerUser) => {
            return existingPlayers &&
              !existingPlayers?.find(
                (existingPlayer: PlayerUser) =>
                  existingPlayer.username === player.username
              )
              ? methods.setValue(
                  "roundInfo.players",
                  existingPlayers.concat(player)
                )
              : null;
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [options, methods, existingPlayers, isLoading]);

  const onSubmit = async (data: SimulatorReservationPayload) => {
    startRound(data);
    pendoTrackEventWithProperties(ClubhouseEvents.CREATE_RESERVATION, {
      simulatorId: data.simulatorId,
      facilityId: currentFacilityId,
      courseName: data.roundInfo?.courseName,
      roundType: roundConfigEnumsAsReactSelectOptions.gameType.find(
        (option) => option.value === data.roundType
      )?.label,
      scoringType: roundConfigEnumsAsReactSelectOptions.scoringType.find(
        (option) => option.value === data.roundInfo?.scoringType
      )?.label,
      timer: data.time,
      players: data.roundInfo?.players?.length,
    });
    dispatch(createRexReservationTrayAction({ isOpen: false }));
    showCustomNotification(`Reservation has been sent to sim.`);
  };

  useEffect(() => {
    if (selectedSimulatorReservation) {
      methods.setValue("time", selectedSimulatorReservation?.reservedDuration);
    }
  }, [methods, selectedSimulatorReservation]);

  useEffect(() => {
    if (defaultRoundSettings) {
      methods.setValue(
        "roundInfo.groundConditions",
        roundConfigEnumsAsReactSelectOptions?.groundConditions[2].value
      );
      methods.setValue(
        "roundInfo.windDirection",
        roundConfigEnumsAsReactSelectOptions?.windDirection[3].value
      );
      methods.setValue(
        "roundInfo.skyConditions",
        roundConfigEnumsAsReactSelectOptions?.weather[0].value
      );
      methods.setValue(
        "roundInfo.timeConditions",
        roundConfigEnumsAsReactSelectOptions?.timeOfDay[1].value
      );
      methods.setValue(
        "roundInfo.stimpSetting",
        roundConfigEnumsAsReactSelectOptions?.stimpSetting[1].value
      );
      methods.setValue(
        "roundInfo.mensTee",
        roundConfigEnumsAsReactSelectOptions?.teeType[2].value
      );
      methods.setValue(
        "roundInfo.womensTee",
        roundConfigEnumsAsReactSelectOptions?.teeType[0].value
      );
      methods.setValue(
        "roundInfo.autoDrop",
        defaultRoundSettings?.autoDrop || false
      );
      methods.setValue(
        "roundInfo.mulligans",
        defaultRoundSettings?.mulligans || false
      );
    }
  }, [methods, defaultRoundSettings]);

  return (
    <div className="flex w-full h-full flex-col">
      <Row cssClasses="mb-8">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {isLoading ? (
              <ShowLoadingAnimation showListView={false} />
            ) : (
              <>
                <SimulatorSelect
                  simulatorId={selectedSimulatorReservation?.simulatorId}
                />
                <DateSelect
                  reservedDuration={
                    selectedSimulatorReservation?.reservedDuration
                  }
                />
                <SimulatorReservationPlayerCheck />
                <StartGameForm
                  simulatorReservation={selectedSimulatorReservation}
                  simulatorId={selectedSimulatorReservation?.simulatorId}
                  allowCreateLockerUser={true}
                />
                <ButtonWithIcon
                  buttonType="submit"
                  text="Send to Simulator "
                  showIcon={true}
                  icon={FaCloudUploadAlt}
                  fullWidth={true}
                />
              </>
            )}
          </form>
        </FormProvider>
      </Row>
      <ReactTooltip backgroundColor="#777" textColor="#fff" />
    </div>
  );
};

export default SimulatorReservationContainer;
