import { ServiceRequest } from "Models";
import { UseDataInfo } from "QueryTypes";
import { useSelector } from "react-redux";
import { QueryState } from "redux-query";
import { RunMutation, useMutation, useRequest } from "redux-query-react";
import { EntitiesState, RootState } from "StoreTypes";
import {
  facilityServiceRequestsQuery,
  makeDismissAllServiceRequestsMutation,
  makeDismissServiceRequestMutation,
} from "./serviceRequestQueries";

export const useServiceRequestsByFacilityId = (
  facilityId: string
): UseDataInfo<ServiceRequest[]> => {
  const [queryState, refeshFacilityServiceRequests] = useRequest(
    facilityServiceRequestsQuery(facilityId)
  );

  const facilityServiceRequests = useSelector(
    (state: RootState): ServiceRequest[] => {
      if (!state.entities.serviceRequestsByFacilityId?.[facilityId]) return [];
      return Object.values(
        state.entities.serviceRequestsByFacilityId?.[facilityId]
      ).sort(
        (a: ServiceRequest, b: ServiceRequest) =>
          new Date(b.createdDate).valueOf() - new Date(a.createdDate).valueOf()
      );
    }
  );

  return [facilityServiceRequests, queryState, refeshFacilityServiceRequests];
};

export const useDismissServiceRequest = (
  serviceRequestId: string
): [QueryState, RunMutation<EntitiesState>] => {
  const [
    dismissServiceRequestQueryState,
    dismissServiceRequest,
  ] = useMutation(() => makeDismissServiceRequestMutation(serviceRequestId));

  return [dismissServiceRequestQueryState, dismissServiceRequest];
};

export const useDismissAllServiceRequests = (
  facilityId: string
): [QueryState, RunMutation<EntitiesState>] => {
  const [
    dismissAllServiceRequestQueryState,
    dismissAllServiceRequest,
  ] = useMutation(() => makeDismissAllServiceRequestsMutation(facilityId));

  return [dismissAllServiceRequestQueryState, dismissAllServiceRequest];
};
