import Timer from "./Timer";
import TimerIconControls from "./TimerIconControls";
import DisplayTimerStatus from "../DisplayTimerStatus";
import SpinnerAnimation from "components/svg/SpinnerAnimation";
import { styles } from "./Timer.styles";
import { SimulatorState } from "Models";
import { simulatorStateIsActive } from "modules/simulator-state/simulatorStateHelpers";

type SimTimerCardProps = {
  simulatorState: SimulatorState;
};

const SimTimerCard: React.FC<SimTimerCardProps> = (props) => {
  const { simulatorState } = props;
  const stateIsActive = simulatorStateIsActive(simulatorState);

  return (
    <div
      className={`${styles.simTimerCard} ${
        stateIsActive
          ? "dark:border-zinc-900 dark:bg-zinc-900/80"
          : "bg-gray-200 dark:bg-zinc-900/80 border border-gray-300 dark:border-zinc-900"
      }`}
    >
      {simulatorState ? (
        <>
          <div className="w-full">
            <DisplayTimerStatus
              timerStatus={simulatorState?.timerStatus?.toString()}
              showStatusName={true}
              badgeStyle={false}
            />
          </div>
          <div className="py-2 mt-2 text-lg poppins">
            <Timer simulatorId={simulatorState ? simulatorState.id : ""} />
          </div>
          <TimerIconControls simulatorState={simulatorState} />
        </>
      ) : (
        <SpinnerAnimation color={"#cccccc"} />
      )}
    </div>
  );
};
export default SimTimerCard;
