import "assets/css/logoAnimation.css";

type OwnProps = {
  color?: string;
};

const AnimatedLogo: React.FC<OwnProps> = (props) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 900 900"
      fill={props.color}
    >
      <g className="feat">
        <g>
          <g>
            <defs>
              <path
                id="SVGID_1"
                d="M365.8,21.8c86.9-16.6,178.8-6.2,259.6,30c35.7,15.9,69.3,36.7,99.5,61.5c4.1,3,7.4,7.1,11.9,9.6
		    c5.8-5.3,11-11.1,16.7-16.5c3.8-3.8,10.2-3,14.2-0.1c3.4,2.2,5.4,6,6,9.9c4.3,24.7,8.7,49.3,13,74c0.9,4.1-0.5,8.9-4.4,11
		    c-3.9,2.4-8.5,0.8-12.6,0.1c-21-3.7-42-7.3-63-10.9c-4.7-0.9-9.9-1-13.5-4.5c-4-3.4-6.1-9.2-4.2-14.2c3.9-6,10.3-10,14.5-15.8
		    c-0.6-0.8-1.3-1.6-2.1-2.3c-24.9-21.3-52.5-39.6-82-54c-79.1-39-171.7-49.8-257.8-30.2c-68.9,15.2-133.3,50-184.2,98.9
		    c-51.2,48.9-88.5,112-106.6,180.4c-20.6,76.7-17,159.7,10.1,234.3c24.6,68.5,69.3,129.6,126.7,174.4
		    c58.8,46.1,131.1,74.5,205.5,80.8c73.7,6.6,149.2-8.2,214.8-42.5C682.7,767.4,730.5,725.6,766,675c37-52.4,60.6-114,68.5-177.6
		    c5.9-47.7,3.1-96.6-8.7-143.2c-1.5-5.3-1.8-11.1,0.1-16.4c3-8.3,11.2-14.3,20.1-14.7c9.6-0.8,19.3,5.3,22.7,14.3
		    c1.9,5.1,2.9,10.6,4.2,15.9c17.5,77.6,13.3,160-12.2,235.4c-16.7,50.1-42.6,97.1-76,137.9c-33,40.3-73.3,74.6-118.3,100.7
		    c-57.5,33.4-122.8,53.1-189.2,57.5c-80.4,5.5-162.3-11.6-233.5-49.4c-58.9-31-110.3-75.8-149.3-129.7
		    c-43.4-59.5-71-130.4-79.6-203.6c-9-75.6,1.7-153.6,31.7-223.7c30.6-72.1,81.4-135.3,144.7-181.3C242.8,59.4,302.9,33.6,365.8,21.8
		    z"
              />
            </defs>
            <clipPath id="SVGID_2">
              <use xlinkHref="#SVGID_1" style={{ overflow: "visible" }} />
            </clipPath>
          </g>
          <g className="st0">
            <g className="sub_one">
              <polyline
                stroke={props.color}
                className="st1"
                opacity=".33"
                points="829,301 851,357 861,407 861,501 838,589 785,697 718,767 633,818 544,853 461,864 385,858 312.8,840 
	        245,813 186,767 128,712 78,637 44,549 35,441 51,334 91,240 152,156 224,99 295.6,64 386,41 471,36 554,48 654,89 714,135 793,210 
	        "
              />
              <polyline
                stroke={props.color}
                className="st1"
                opacity=".66"
                points="829,301 851,357 861,407 861,501 838,589 785,697 718,767 633,818 544,853 461,864 385,858 312.8,840 
	        245,813 186,767 128,712 78,637 44,549 35,441 51,334 91,240 152,156 224,99 295.6,64 386,41 471,36 554,48 654,89 714,135 793,210 
	        "
              />
              <polyline
                stroke={props.color}
                className="st1"
                points="829,301 851,357 861,407 861,501 838,589 785,697 718,767 633,818 544,853 461,864 385,858 312.8,840 
	        245,813 186,767 128,712 78,637 44,549 35,441 51,334 91,240 152,156 224,99 295.6,64 386,41 471,36 554,48 654,89 714,135 793,210 
	        "
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <defs>
              <path
                id="SVGID_3"
                d="M542.4,258.5c18.6-1.8,37.5-1.1,55.7,3.4c18.8,4.6,36.3,14.3,49.9,28.1c4.6,4.8,8.8,10.1,11.9,16
		    c6,11.4,8.3,24.4,10,37c1.3,9.6,1.6,19.3,1.9,28.9c0.2,8.5,0.6,17.1,0,25.7c-31.1,0-62.3,0.1-93.4-0.1c-0.3-12.2,0.2-24.4-0.3-36.6
		    c-0.3-10.7,0.1-21.5-2-32c-0.6-3.4-2.3-7-5.6-8.6c-5-2.5-11.6-2.5-16.1,0.9c-3.6,2.9-4.3,7.7-5,12c-1.3,11.2-1,22.4-1.1,33.7
		    c0,58.4,0,116.7,0,175.1c0.1,6.5,0.7,13,2.2,19.4c1,4.3,4.2,8.5,8.7,9.5c5,1.1,10.9,0.7,14.7-3c3.7-3.5,4.8-8.7,5.6-13.4
		    c2.2-15.4,1.3-31,1.5-46.5c-0.1-7,0.2-14-0.1-21c-6.2-0.8-12.3,0.1-18.5-0.3c-0.1-18.2-0.1-36.3,0-54.5c1.5-0.4,3.1-0.5,4.6-0.5
		    c29,0.1,58,0,87,0c6,0.1,11.9-0.4,17.9,0.3c0.3,41.3,0,82.7,0.1,124c-0.1,23,0.2,45.9-0.1,68.9c-19.6,0.2-39.2,0.2-58.7,0
		    c-3.6-8-5.6-16.5-8.6-24.7c-3.2,3.3-5.2,7.7-8.4,11c-10,11.9-24.8,19.3-40.1,20.9c-12.6,1-25.4,0.5-37.4-3.6
		    c-17.1-5.5-32.9-15.8-44.2-29.9c-11.5-15-17.8-33.7-18.5-52.6c-1.8-21.3-1.1-42.6-1.3-64c0-32.7,0-65.3,0-98c0-8,0.1-16,0.6-24.1
		    c0.8-15.3,1.6-30.8,6.3-45.5c5.1-14.9,16.4-26.8,28.7-36.2C505.2,266.6,523.8,260.5,542.4,258.5zM253.7,325.8c13.4-6.8,28.3-10,43.2-11.5c23.3-1.3,47.3-0.6,69.5,7.2c14.9,5.1,28.1,15,37.2,27.8
			c5.7,8.1,9.2,17.7,10.4,27.6c2.9,21.2,3.1,42.6,3.6,64c0.6,40.3,0.2,80.7,0.3,121c-0.1,21,0.2,42.1-0.1,63.1
			c-4.3,0.6-8.6,0.2-12.9,0.3c-24.7,0-49.4-0.1-74,0c-1.2,0-2.4-0.2-3.6-0.5c-0.4-8.4,0.4-16.8-0.3-25.1c-2.9,3.3-4.8,7.4-7.7,10.7
			c-7.6,9.4-18.4,16.2-30.2,18.8c-21.4,4.6-44.7-0.1-62.5-13c-7.4-5.5-11.8-14-14.4-22.6c-4.4-14.1-4.7-29-4.6-43.7
			c0.1-14.2-0.6-28.4,1.9-42.4c1.3-7,3.1-14,7.1-20c4.1-5.9,10.4-9.8,16.4-13.6c25.2-14.6,53.7-21.9,79.3-35.6
			c3.9-2.2,8.6-4.5,10-9.1c1.7-5.6,2-11.5,2-17.4c-0.1-11.1,0.6-22.4-2.2-33.2c-1.2-5-5-9.8-10.4-10.4c-4.8-0.6-11,0.1-13.6,4.8
			c-2.1,4.6-2.1,9.9-2.5,14.8c-0.1,10.3-0.6,20.6-0.4,31c-0.2,6.3,0.4,12.6-0.3,18.9c-21.7,0.3-43.4,0-65,0.1
			c-7.3-0.1-14.7,0.2-22-0.1c-0.6-13.5-0.3-27.1,0.5-40.6c1.4-13.4,3.2-27.2,9.7-39.2C226,343.7,239.6,333.5,253.7,325.8z
			 M307.6,494.6c-3.8,4.1-7.6,8.5-9.1,14c-3.9,13.4-2.9,27.6-2.9,41.4c0.3,6.8,0.4,14,3.1,20.3c3.1,6.1,11,7,17.1,5.6
			c3.8-0.7,6.5-4.2,7.2-7.9c2.1-10.2,1.2-20.7,1.4-31c-0.2-18.7,0.3-37.4-0.2-56.1C318,484.5,312.7,489.6,307.6,494.6z"
              />
            </defs>
            <clipPath id="SVGID_4">
              <use xlinkHref="#SVGID_3" style={{ overflow: "visible" }} />
            </clipPath>
          </g>
          <g className="st2 sub_two">
            <line
              stroke={props.color}
              className="st3"
              opacity=".33"
              x1="432"
              y1="885.8"
              x2="432"
              y2="68.8"
            />
            <line
              stroke={props.color}
              className="st3"
              opacity=".66"
              x1="432"
              y1="885.8"
              x2="432"
              y2="68.8"
            />
            <line
              stroke={props.color}
              className="st3"
              x1="432"
              y1="885.8"
              x2="432"
              y2="68.8"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
export default AnimatedLogo;

AnimatedLogo.defaultProps = {
  color: "#000000",
};
