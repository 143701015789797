import { useSelector } from "react-redux";
import { RootState } from "StoreTypes";
import { useMoveRound } from "modules/round/roundHooks";
import Select from "react-select";
import { useAppDispatch } from "utils/hooks";
import { modalAction } from "ui-modules/modals/modalSlice";
import axios from "axios";
import { getCurrentFacilityId } from "modules/facility/facilitySelectors";
import { Simulator } from "Models";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { getCurrentUserToken } from "modules/user";
import { useEffect, useState } from "react";
import { customStyles } from "utils/ui/uiHelpers";
import { globalStyles } from "components/globalStyles";

const MoveRoundContainer: React.FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const currentFacilityId = useSelector((state: RootState) =>
    getCurrentFacilityId(state)
  );
  const [, moveRound] = useMoveRound(props.roundId);
  const methods = useForm();

  const [options, setOptions] = useState<Simulator[]>();

  useEffect(() => {
    (async () => {
      const headers = { authorization: `Bearer ${getCurrentUserToken()}` };
      const url = `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facilities/${currentFacilityId}/eligible-simulators/${props.roundId}`;

      if (props.roundId) {
        const { data } = await axios.get<Simulator[]>(url, { headers });

        setOptions(data);
      }
    })();
  }, [props.roundId, currentFacilityId]);

  const onSubmit = (data: {
    destinationSimulator: Simulator;
    timeAddedToDestination: number;
  }) => {
    const { destinationSimulator, timeAddedToDestination } = data;
    const destinationSimulatorId = destinationSimulator.id;

    moveRound({
      originSimulatorId: props.simulatorId,
      timeAddedToDestination,
      destinationSimulatorId,
    });
    setModalClose();
  };

  const setModalClose = () =>
    dispatch(modalAction({ isOpen: false, modalName: props.modalName }));

  const flexDirection = props.listView ? "flex-row" : "flex-col";

  return (
    <div
      className={`flex ${flexDirection} w-full m-0 px-4 py-2 bg-white dark:bg-zinc-800`}
    >
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="w-full text-gray-500 dark:text-gray-200"
        >
          <div className={`flex ${flexDirection} w-full`}>
            <div
              className={`flex-col text-sm ${
                props.listView ? "w-1/3 pr-4" : "w-full"
              }`}
            >
              <div className="flex w-full text-xs poppins pt-4 pb-1">
                Select Sim To Move Round To:
              </div>
              <div className="flex w-full">
                <Controller
                  name="destinationSimulator"
                  control={methods.control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={options}
                      getOptionLabel={(sim: Simulator) => sim.name}
                      getOptionValue={(sim: Simulator) => sim.id}
                      isSearchable={false}
                      closeMenuOnSelect={true}
                      name="destinationSimulator"
                      styles={customStyles}
                      className="w-full"
                    />
                  )}
                />
              </div>
            </div>
            <div
              className={`flex-col text-sm ${
                props.listView ? "w-54 mr-6" : "w-full"
              }`}
            >
              <div className="flex w-full text-xs poppins pb-1 pt-4">
                How many minutes to add?
              </div>
              <div className="flex w-full">
                <input
                  className="border border-gray-300 rounded-lg text-sm h-12 w-full"
                  defaultValue={0}
                  type="number"
                  {...methods.register("timeAddedToDestination", {
                    valueAsNumber: true,
                    min: {
                      value: 0,
                      message: "Timer value must be positive",
                    },
                  })}
                />
              </div>
            </div>
            <div
              className={`flex-col text-sm items-center ${
                props.listView ? "w-48 text-left" : "w-full"
              }`}
            >
              <div className="flex w-full text-sm font-semibold pt-4"></div>
              <div className="flex w-full align-middle items-end content-end justify-end h-auto justify-items-center">
                <button
                  type="submit"
                  className={`${props.listView ? "mt-6" : ""} ${
                    globalStyles.primaryButton
                  } ${globalStyles.buttonLarge} flex text-right text-black`}
                  tabIndex={4}
                >
                  Move Round
                </button>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

type Props = {
  title?: string;
  modalName: string;
  simulatorId: string;
  simulatorName?: string;
  roundId: string;
  listView?: boolean;
};

MoveRoundContainer.defaultProps = {
  title: "Move Round",
  listView: false,
};

export default MoveRoundContainer;
