import { useSimulatorState } from "modules/simulator-state/simulatorStateHooks";
import { useRoundById } from "modules/round/roundHooks";
import { useEffect, useState } from "react";
import { SimStatus } from "types/enums";
import { motion } from "framer-motion";

type ProgressBarType = {
  simulatorId: string;
  completionPercentage?: string;
  primaryColor?: string;
  secondaryColor?: string;
  height?: string;
};

const RoundProgressBarSlim: React.FC<ProgressBarType> = (props) => {
  const [simulatorState] = useSimulatorState(props.simulatorId);
  const [round] = useRoundById(simulatorState?.roundId);
  const [progressBarValue, setProgressBarValue] = useState(0);
  const progressWidth = {
    width: progressBarValue + "%",
  } as React.CSSProperties;

  useEffect(() => {
    const calculateDiff = async () => {
      const difference =
        Number(round?.holesCompleted) /
        Number(round?.holesSelected.filter(Boolean).length);
      setProgressBarValue(Math.round(difference * 100));
    };

    if (
      simulatorState?.status !== SimStatus.STATUS_SIM_OFF &&
      simulatorState?.roundId
    ) {
      calculateDiff();
    }
  }, [
    simulatorState?.status,
    simulatorState?.roundId,
    round?.holesSelected,
    round?.holesCompleted,
  ]);

  return (
    <motion.div
      className="SimProgressBar w-full flex flex-col"
      key={simulatorState?.status}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 0.75,
      }}
    >
      <div
        className={`overflow-hidden ${props.height} text-xs flex ${props.secondaryColor} shadow`}
      >
        <div
          data-testid="progressBarContainer"
          style={progressWidth}
          className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${props.primaryColor} `}
        ></div>
      </div>
    </motion.div>
  );
};
export default RoundProgressBarSlim;

RoundProgressBarSlim.defaultProps = {
  completionPercentage: "50",
  primaryColor: "bg-brandGreen",
  secondaryColor: "bg-brandGreen bg-opacity-50 dark:bg-zinc-800",
  height: "h-1",
};
