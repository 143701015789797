import { useAppDispatch } from "utils/hooks";
import { ModalType } from "utils/modals/modalHelpers";
import { modalAction } from "ui-modules/modals/modalSlice";
import { setSelectedLeagueId } from "modules/leagues/leagueSlice";
import {
  useSelectedLeague,
  useSelectedLeagueTypeIsTeam,
} from "modules/leagues/leagueHooks";
import Row from "components/ui/layout/Row";
import Modal from "components/shared/Modal";
import CreateLeagueRosterForm from "./rounds/teams/CreateLeagueRosterForm";
import CreateTeamLeagueRoster from "./rounds/teams/CreateTeamLeagueRoster";

const LeagueRosterModal: React.FC<LeaguesModalProps> = (props) => {
  const dispatch = useAppDispatch();
  const selectedLeague = useSelectedLeague();
  const isTeamLeague = useSelectedLeagueTypeIsTeam();

  const handleCloseModal = async () => {
    dispatch(
      setSelectedLeagueId({
        selectedLeagueId: "",
      })
    );
    dispatch(
      modalAction({
        isOpen: false,
        modalName: ModalType.LeaugeCreateEdit,
      })
    );
  };

  return (
    <Modal
      modalName={props.modalName}
      closeModalCallback={handleCloseModal}
      hideHeader={false}
      title={selectedLeague?.name}
      hideFooter={true}
      modalSize="w-full h-full sm:w-2/3 md:1/2 3xl:w-1/3"
      body={
        <Row cssClasses="px-8 pb-4">
          {isTeamLeague ? (
            <CreateTeamLeagueRoster selectedLeague={selectedLeague} />
          ) : (
            <CreateLeagueRosterForm selectedLeague={selectedLeague} />
          )}
        </Row>
      }
    />
  );
};

type LeaguesModalProps = {
  title?: string;
  modalName: string;
};

LeagueRosterModal.defaultProps = {
  title: "League Roster",
};

export default LeagueRosterModal;
