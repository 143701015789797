import { ServiceRequest } from "Models";
import { AppQueryConfig } from "QueryTypes";
import { reduxQueryDefaultOptions } from "utils/queries/queryHelpers";
import { ServiceRequestEntityState } from "StoreTypes";

const urls = {
  facilityServiceRequests(facilityId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/service-requests/facilities/${facilityId}`;
  },
  serviceRequestById(serviceRequestId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/service-requests/${serviceRequestId}`;
  },
  handleAllServiceRequestsByFacility(facilityId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/service-requests/facilities/${facilityId}`;
  },
};

export const facilityServiceRequestsQuery = (
  facilityId: string
): AppQueryConfig => ({
  url: urls.facilityServiceRequests(facilityId),
  queryKey: `facilityServiceRequests:${facilityId}`,
  options: { ...reduxQueryDefaultOptions() },
  transform: (responseJson: ServiceRequest[]) => {
    const obj: {
      [facilityId: string]: { [serviceRequestId: string]: ServiceRequest };
    } = { [facilityId]: {} };
    responseJson?.map(
      (request) => (obj[request.facilityId][request.id] = request)
    );
    return { serviceRequestsByFacilityId: obj };
  },
  update: {
    serviceRequestsByFacilityId: (
      oldValue,
      newValue
    ): ServiceRequestEntityState => ({
      ...oldValue,
      ...newValue,
    }),
  },
});

export const makeDismissServiceRequestMutation = (
  serviceRequestId: string
): AppQueryConfig => ({
  url: urls.serviceRequestById(serviceRequestId),
  options: { ...reduxQueryDefaultOptions(), method: "DELETE" },
  optimisticUpdate: {
    serviceRequestsByFacilityId: (oldValue) => {
      const facilityId = Object.keys(oldValue).find((key) =>
        oldValue[key][serviceRequestId] ? true : false
      );
      if (facilityId) delete oldValue[facilityId][serviceRequestId];
      return oldValue;
    },
  },
});

export const makeDismissAllServiceRequestsMutation = (
  facilityId: string
): AppQueryConfig => ({
  url: urls.handleAllServiceRequestsByFacility(facilityId),
  options: { ...reduxQueryDefaultOptions(), method: "DELETE" },
  optimisticUpdate: {
    serviceRequestsByFacilityId: (oldValue) => {
      if (facilityId) delete oldValue[facilityId];
      return oldValue;
    },
  },
});
