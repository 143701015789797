import { SimulatorState } from "Models";
import { SimStatus } from "types/enums";
import { simStatusEnumDisplayNameKeyedByString } from "utils/enums/enumHelper";
import { simStatusColorHelper } from "utils/icons/iconHelpers";

type OwnProps = {
  simulatorState: SimulatorState;
};

const SimStatusName: React.FC<OwnProps> = ({ simulatorState }) => {
  return (
    <div className={simStatusColorHelper[SimStatus[simulatorState?.status]]}>
      {simStatusEnumDisplayNameKeyedByString[SimStatus[simulatorState?.status]]}
    </div>
  );
};
export default SimStatusName;
