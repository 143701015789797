import Tippy from "@tippyjs/react";
import { IoMdInformationCircleOutline } from "react-icons/io";

const Label: React.FC<LabelType> = (props) => {
  return (
    <div className="pb-2">
      <label className={props.cssClasses} htmlFor={props.for}>
        <span>{props.name}</span>
        {props.tooltipText && (
          <Tippy
            content={props.tooltipText}
            className="poppins text-black text-xs bg-white dark:bg-zinc-800 dark:text-gray-50 p-2 border rounded-xl float-right"
            placement="auto"
          >
            <div className="inline">
              <IoMdInformationCircleOutline className="size-4 text-zinc-800 dark:text-gray-50 inline ml-1" />
            </div>
          </Tippy>
        )}
      </label>
    </div>
  );
};
export default Label;

type LabelType = {
  name: string;
  tooltipText?: string;
  tooltipColor?: string;
  cssClasses?: string;
  for?: string;
};
Label.defaultProps = {
  tooltipText: "",
  tooltipColor: "gray",
  cssClasses: "text-xs poppins text-black dark:text-gray-200 mb-2",
};
