import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type UserState = {
  userId?: string;
  isAuthenticated?: boolean;
};

const initialState: UserState = {
  userId: "",
  isAuthenticated: false,
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserId(state, action: PayloadAction<{ userId: string }>): void {
      state.userId = action.payload.userId;
    },
    setIsAuthenticated(
      state,
      action: PayloadAction<{ isAuthenticated: boolean }>
    ): void {
      state.isAuthenticated = action.payload.isAuthenticated;
    },
  },
});

const { actions, reducer: userReducer } = slice;

const { setUserId, setIsAuthenticated } = actions;

export { userReducer, setUserId, setIsAuthenticated };
