import { useState } from "react";
import { useSendMessageToSimulator } from "modules/simulator";
import {
  notificationMessageGroup,
  showResponseNotification,
} from "utils/notifications/notificationHelpers";
import { useAppDispatch } from "utils/hooks";
import { modalAction } from "ui-modules/modals/modalSlice";
import { modalName, ModalType } from "utils/modals/modalHelpers";
import styles from "components/control/dashboard/DashboardComponents.styles";
import { VscSend } from "react-icons/vsc";

type OwnProps = {
  simulatorId?: string;
  simulatorIds?: string[];
};

const SimSendMessageSmall: React.FC<OwnProps> = (props) => {
  const dispatch = useAppDispatch();
  const [input, storeInput] = useState("");

  const sendSimMessageModalName = modalName(
    ModalType.SendSimulatorMessage,
    props.simulatorId
  );

  const handleInput = (event: any) => {
    storeInput(event.target.value);
  };

  const setModalClose = () => {
    dispatch(
      modalAction({ isOpen: false, modalName: sendSimMessageModalName })
    );
  };

  const useHandleMessage = () => {
    const response = useSendMessageToSimulator(
      input,
      props.simulatorId,
      props.simulatorIds
    );
    setModalClose();
    showResponseNotification(response, notificationMessageGroup.message);
    storeInput("");
  };

  return (
    <div className={styles.simSendMessageContainer}>
      <div className="font-semibold text-sm poppins dark:text-gray-200 pb-2">{`Send Message`}</div>
      <div
        className={`border border-gray-200 rounded-xl p-3 my-1 w-full bg-white dark:bg-zinc-900 $`}
      >
        <textarea
          name="messageArea"
          placeholder="Type message here..."
          className="resize-none rounded-xl border-0 text-sm dark:bg-zinc-900 dark:text-gray-50 focus:border-0 focus:ring-0 w-full"
          id="messageArea"
          onChange={handleInput}
          value={input}
          style={{ resize: "none" }}
        ></textarea>
        <div className="text-right">
          <button
            onClick={useHandleMessage}
            disabled={!input}
            className={`text-sm bg-brandYellow hover:bg-brandGreen text-black dark:text-white hover:text-white rounded-full p-2`}
          >
            <VscSend className="size-5 ml-0.5" />
          </button>
        </div>
      </div>
    </div>
  );
};
export default SimSendMessageSmall;
