const CreateAndLoadRounds = () => {
  return (
    <div className="pt-8">
      <strong>To Create a Round Setup:</strong>
      <br />
      Enter a Round Setup name and select the options. You can set the time at
      the range before the course, the course for this round and the various
      options for the selected course. Click the "Save Round Settings" button
      when you are finished.
      <br />
      <br />
      <strong>To Load a Round Setup:</strong>
      <br />
      On the Dashboard page you can click on the "Load Round" button on any card
      that has an available sim (ie. a sim that is online with no currently
      active rounds). Clicking this button will open the Load Round popup with a
      form to create a new round. At the top of the form is a "Saved Round
      Settings" dropdown with options for all of the Saved Round Setups that
      have been created.
      <br />
      <br />
      Selecting a saved round setup will load the round form with all the saved
      options from that setup. You can make any adjustments you like and then
      click the "Send to Sim" button to send that round right to the simulator.
      <br />
      <br />
      <strong>To Delete a Round Setup:</strong>
      <br />
      Click on the "Delete Saved Setups" button on the left side of the
      "Settings" page. This will show a list of all Saved Round Setups. Click
      the "Delete" button next to the setup you want to delete and confirm.
    </div>
  );
};
export default CreateAndLoadRounds;
