import { useState } from "react";
import { RootState } from "StoreTypes";
import { useSelector } from "react-redux";
import { styles } from "./Scorecard.styles";
import { GameScorecard } from "ScorecardModels";
import { MotionVariantType } from "types/enums";
import { ImSortNumericAsc } from "react-icons/im";
import { AnimateSharedLayout, motion } from "framer-motion";
import { dailyQueueContainer } from "utils/animations/animationHelper";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import ScorecardListItem from "./ScorecardListItem";

const ScorecardList: React.FC = () => {
  const [selectedScorecardId, setSelectedScorecardId] = useState<
    number | boolean
  >(false);
  const currentFacilityId = useGetCurrentLocalFacilityId();

  const gameScorecardList = useSelector((state: RootState): GameScorecard[] => {
    if (!state.entities.scorecards?.byFacilityId?.[currentFacilityId])
      return [];
    return Object.values(
      state.entities?.scorecards?.byFacilityId?.[currentFacilityId]
    ).sort(
      (a: GameScorecard, b: GameScorecard) =>
        new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
    );
  });

  return (
    <nav className={styles.scorecardNav} aria-label="Scorecard Nav">
      {gameScorecardList.length > 0 && (
        <div className="pb-0 pt-2">
          <ul className={styles.scorecardList} role="tablist">
            <li className="pr-2 relative">
              <a
                className="text-xs text-gray-400 hidden"
                onClick={(e) => {
                  e.preventDefault();
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
                aria-label="tab"
              >
                <ImSortNumericAsc className="text-base mr-1 font-semibold brand-light-green" />{" "}
                Date
              </a>
            </li>
          </ul>
        </div>
      )}
      <div className="relative">
        <div className="eventListContainer rounded-md w-full">
          <AnimateSharedLayout>
            {gameScorecardList.length > 0 && (
              <motion.ul
                className="relative z-0 w-full"
                variants={dailyQueueContainer}
                initial={MotionVariantType.HIDDEN}
                animate={MotionVariantType.SHOW}
              >
                {gameScorecardList.map((gameScorecard, index) => {
                  return (
                    <ScorecardListItem
                      scorecard={gameScorecard}
                      key={gameScorecard?.saveGameId}
                      isOpen={selectedScorecardId === index}
                      callback={() =>
                        setSelectedScorecardId(
                          selectedScorecardId === index ? false : index
                        )
                      }
                    />
                  );
                })}
              </motion.ul>
            )}
          </AnimateSharedLayout>
        </div>
      </div>
    </nav>
  );
};
export default ScorecardList;
