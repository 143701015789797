import { AnimatePresence, motion } from "framer-motion";
import { useSelector } from "react-redux";
import { RootState } from "StoreTypes";
import { createEventTrayAction } from "ui-modules/nav/navSlice";
import { useAppDispatch } from "utils/hooks";
import { styles } from "components/shared/helpdocs/HelpDocTray.styles";
import { useEffect, useCallback } from "react";
import CreateEventForm from "components/control/events/CreateEventForm";
import { setSelectedEventId } from "ui-modules/event/eventSlice";
import { useSelectedEvent } from "modules/events/eventHooks";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";

const CreateEventTray: React.FC = () => {
  const dispatch = useAppDispatch();
  const currentFacilityId = useGetCurrentLocalFacilityId();
  const selectedEvent = useSelectedEvent(currentFacilityId);
  const createEvent = selectedEvent ? false : true;

  const isCreateEventTrayOpen = useSelector(
    (state: RootState) => state.nav?.createEventTray?.isOpen
  );

  const handleCreateEventTrayClosed = useCallback(() => {
    dispatch(createEventTrayAction({ isOpen: false }));
    dispatch(
      setSelectedEventId({
        selectedEventId: 0,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (isCreateEventTrayOpen === false) {
      handleCreateEventTrayClosed();
    }
  }, [isCreateEventTrayOpen, handleCreateEventTrayClosed]);

  return (
    <AnimatePresence>
      {isCreateEventTrayOpen && (
        <motion.div
          initial={{ x: "100%" }}
          animate={{
            x: "0px",
          }}
          exit={{
            x: "100%",
          }}
          transition={{ type: "spring", bounce: 0, duration: 0.75 }}
          className={`${styles.trayContainer} z-9999`}
          key="createEventTray"
          data-testid="createEventTray"
          id="createEventTray"
        >
          <div className={styles.headerContainer}>
            <div className={styles.headerCloseButton}>
              <button
                onClick={() => handleCreateEventTrayClosed()}
                className={styles.button}
              >
                &laquo; Back
              </button>
            </div>
            <div className={styles.headerTitle}>
              <h2 className={styles.trayHeader}>
                {" "}
                {createEvent ? "Create" : "Edit"} Event
              </h2>
            </div>
            <div className={styles.headerRightButton}></div>
          </div>
          <div className={styles.innerContainer}>
            <CreateEventForm />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
export default CreateEventTray;
