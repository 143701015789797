import { useEffect, useState } from "react";
import {
  ClubhouseNewRoundInfoDto,
  LeagueRound,
  RoundCompetitionType,
} from "Models";
import { LeagueRoundCompetitionType } from "types/enums";
import { styles } from "../Leagues.styes";
import { useAppDispatch } from "utils/hooks";
import { customStyles } from "utils/ui/uiHelpers";
import { ErrorMessage } from "@hookform/error-message";
import { FormRow } from "components/ui/form";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { createLeagueRoundTrayAction } from "ui-modules/nav/navSlice";
import {
  useCreateLeagueRound,
  useSelectedLeagueId,
  useSelectedLeagueRound,
  useSelectedLeagueRoundId,
  useUpdateLeagueRound,
  useGetLatestLeagueRoundId,
  useDeleteLeagueRound,
} from "modules/leagues/leagueRoundHooks";
import { ActionPromiseValue, EntitiesState } from "redux-query";
import { getNextRound } from "modules/leagues/leagueRoundQueries";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { roundConfigEnumsAsReactSelectOptions } from "utils/enums/enumHelper";
import {
  GenericNotificationType,
  showCustomNotification,
} from "utils/notifications/notificationHelpers";
import {
  ClubhouseEvents,
  pendoTrackBasicEvent,
  pendoTrackEventWithProperties,
} from "utils/pendo/pendoHelpers";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import Row from "components/ui/layout/Row";
import CourseSettingsOptions from "./CourseSettingsOptions";
import LeagueRoundCourseAndHoles from "./LeagueRoundCourseAndHoles";
import {
  leagueRoundDefaults,
  useLeagueRoundStartDatePassed,
} from "modules/leagues/leagueHelpers";
import { useToasterStore } from "react-hot-toast";
import {
  getApiResponseErrorMessage,
  isApiStatusSuccess,
} from "utils/api/apiHelper";
import SpinnerAnimation from "components/svg/SpinnerAnimation";
import { TiInfoOutline } from "react-icons/ti";
import { useGetClubhouseLeagueDto } from "modules/leagues/leagueHooks";
import LeagueRoundDateSelect from "./LeagueRoundDateSelect";
import { CgSpinner } from "react-icons/cg";
import { modalAction } from "ui-modules/modals/modalSlice";
import { modalName, ModalType } from "utils/modals/modalHelpers";
import ConfirmDeleteAlert from "components/shared/ConfirmDeleteAlert";
import { setSelectedRoundId } from "modules/leagues/leagueSlice";

const CreateLeagueRoundForm = () => {
  const dispatch = useAppDispatch();

  const selectedLeagueRound = useSelectedLeagueRound();
  const currentFacilityId = useGetCurrentLocalFacilityId();
  const selectedLeagueId = useSelectedLeagueId();
  const selectedLeagueRoundId = useSelectedLeagueRoundId();
  const latestLeagueRoundId = useGetLatestLeagueRoundId(selectedLeagueId);
  const [nextLeagueRoundValues, setNextLeagueRoundValues] = useState<{
    roundStartDate: string;
    roundNumber: number;
  }>();

  const methods = useForm({
    defaultValues: selectedLeagueRound || leagueRoundDefaults(),
  });

  const toastIsActive = useToasterStore().toasts.length > 0 ? true : false;
  const startDatePassed = useLeagueRoundStartDatePassed();

  const [createLeagueRoundQueryState, createLeagueRound] = useCreateLeagueRound(
    currentFacilityId,
    selectedLeagueId
  );
  const [updateLeagueRoundQueryState, updateLeagueRound] = useUpdateLeagueRound(
    currentFacilityId,
    selectedLeagueId,
    selectedLeagueRoundId
  );
  const [, , refreshLeagueDto] = useGetClubhouseLeagueDto(
    currentFacilityId,
    selectedLeagueId
  );
  const [, deleteRound] = useDeleteLeagueRound(
    selectedLeagueId,
    currentFacilityId,
    selectedLeagueRoundId
  );

  const deleteConfirmModalName = modalName(
    `{${ModalType.ConfirmDeleteAlert}-leagueRound-${selectedLeagueRoundId}}`
  );

  function handleResponseCallback(leagueRound: LeagueRound) {
    pendoTrackEventWithProperties(ClubhouseEvents.CREATE_ROUND, leagueRound);
    dispatch(createLeagueRoundTrayAction({ isOpen: false }));
    if (!toastIsActive) {
      if (selectedLeagueRound) {
        showCustomNotification(
          `${leagueRound.name} has been updated`,
          GenericNotificationType.SUCCESS
        );
      } else {
        showCustomNotification(
          `${leagueRound.name} has been created`,
          GenericNotificationType.SUCCESS
        );
      }
    }
    refreshLeagueDto();
    dispatch(
      setSelectedRoundId({
        selectedLeagueRoundId: "",
      })
    );
    dispatch(
      modalAction({
        isOpen: false,
        modalName: ModalType.LeagueRoundsModal,
      })
    );
  }

  function handleDeleteLeagueRound() {
    dispatch(
      modalAction({
        isOpen: true,
        modalName: deleteConfirmModalName,
      })
    );
  }

  const onSubmit = async (data: LeagueRound) => {
    let response = {} as ActionPromiseValue<EntitiesState> | undefined;

    if (selectedLeagueRound) {
      response = await updateLeagueRound(data);
      pendoTrackBasicEvent(ClubhouseEvents.LEAGUE_ROUND_EDIT);
    } else {
      response = await createLeagueRound(data);
      pendoTrackBasicEvent(ClubhouseEvents.LEAGUE_ROUND_CREATE);
    }

    if (isApiStatusSuccess(response?.status)) {
      handleResponseCallback(data);
    } else {
      showCustomNotification(
        getApiResponseErrorMessage(response?.body),
        GenericNotificationType.ERROR
      );

      if (response?.body?.errors?.StartPlayDate) {
        methods.setError("startPlayDate", {
          message: response?.body?.errors?.StartPlayDate,
        });
        document.getElementById("startPlayDate")?.focus();
      }

      if (response?.body?.errors?.EndPlayDate) {
        methods.setError("endPlayDate", {
          message: response?.body?.errors?.EndPlayDate,
        });
        document.getElementById("endPlayDate")?.focus();
      }
    }
  };

  const handleCompetitionTypeChange = (e: {
    label: string;
    value: LeagueRoundCompetitionType;
  }) => {
    let competitionType = {} as RoundCompetitionType;
    competitionType.competitionType = e.value;

    methods.setValue("competitionType", [competitionType] as never);
  };

  const handleConfirm = (value: boolean) => {
    let response;
    if (value) {
      response = deleteRound();
      response?.then((res) => {
        if (isApiStatusSuccess(res?.status)) {
          showCustomNotification(
            "Round deleted successfully",
            GenericNotificationType.SUCCESS
          );
          refreshLeagueDto();
        } else {
          showCustomNotification(
            "An error occurred",
            GenericNotificationType.ERROR
          );
        }
      });
      pendoTrackBasicEvent(ClubhouseEvents.LEAGUE_ROUND_DELETE);
    }
  };

  useEffect(() => {
    if (selectedLeagueRound) {
      selectedLeagueRound?.courseShortName &&
        methods.setValue(
          "course",
          selectedLeagueRound?.courseShortName as never
        );
      if (selectedLeagueRound?.competitionType) {
        let competitionType = {} as RoundCompetitionType;
        competitionType.competitionType = (selectedLeagueRound?.competitionType as unknown) as LeagueRoundCompetitionType;

        methods.setValue("competitionType", [competitionType] as never);
      }
    }
  }, [methods, selectedLeagueRound]);

  useEffect(() => {
    const nextRound = getNextRound(
      currentFacilityId,
      selectedLeagueId,
      latestLeagueRoundId
    );

    if (!nextLeagueRoundValues) {
      nextRound.then(
        (response: { status: number; data: ClubhouseNewRoundInfoDto }) => {
          if (isApiStatusSuccess(response?.status)) {
            setNextLeagueRoundValues({
              roundStartDate: response.data.startDate,
              roundNumber: response.data.roundNumber,
            });
          } else {
            setNextLeagueRoundValues({
              roundStartDate: "",
              roundNumber: 1,
            });
          }
        }
      );
    }
  }, [
    nextLeagueRoundValues,
    currentFacilityId,
    selectedLeagueId,
    latestLeagueRoundId,
  ]);

  return (
    <>
      <ConfirmDeleteAlert
        modalName={deleteConfirmModalName}
        bodyText={`Are you sure you want to delete ${selectedLeagueRound?.name}?`}
        confirmButtonText="Delete"
        cancelButtonText="Cancel"
        callback={handleConfirm}
      />
      {nextLeagueRoundValues ? (
        <div className="flex w-full h-full flex-col">
          <Row>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                {startDatePassed && (
                  <div className="text-center m-auto text-red-500 text-xs pt-4">
                    <TiInfoOutline className="text-xl" /> Only certain fields
                    are able to be edited for a round that is in progress.
                  </div>
                )}
                <FormRow header="Round Name" fontSize={styles.formRowLabel}>
                  <input
                    {...methods.register("name")}
                    type="text"
                    className={styles.inputClassName}
                    required={true}
                    id="createEditLeagueName"
                    data-testid="createEditLeagueName"
                    autoFocus
                    placeholder="Enter Round Name"
                    onBlur={(e) => {
                      methods.setValue(
                        "name",
                        e.target.value.replace(/\s+/g, " ").trim()
                      );
                    }}
                    disabled={startDatePassed}
                  />
                </FormRow>
                <FormRow header="Round Number" fontSize={styles.formRowLabel}>
                  <input
                    className={styles.inputClassName}
                    type="number"
                    min={0}
                    defaultValue={nextLeagueRoundValues?.roundNumber}
                    {...methods.register("roundNumber", {
                      valueAsNumber: true,
                      min: {
                        value: 0,
                        message: "Round number must be positive",
                      },
                    })}
                    disabled={startDatePassed}
                  />
                </FormRow>
                <FormRow
                  header="Competition Type"
                  fontSize={styles.formRowLabel}
                  hidden={true}
                >
                  <Controller
                    name="competitionType"
                    control={methods.control}
                    rules={{ required: "Competition type is required" }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={
                          roundConfigEnumsAsReactSelectOptions.leagueRoundCompetitionType
                        }
                        defaultValue={
                          roundConfigEnumsAsReactSelectOptions
                            .leagueRoundCompetitionType[0]
                        }
                        value={roundConfigEnumsAsReactSelectOptions.leagueRoundCompetitionType.find(
                          (c) => c.value === field.value
                        )}
                        onChange={(val: {
                          label: string;
                          value: LeagueRoundCompetitionType;
                        }) => handleCompetitionTypeChange(val)}
                        styles={customStyles}
                        id="competitionType"
                        isDisabled={startDatePassed}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={methods.formState.errors}
                    name="competitionType"
                    render={({ message }) => (
                      <div className="text-sm text-red-500">{message}</div>
                    )}
                  />
                </FormRow>
                <LeagueRoundDateSelect
                  leagueRoundStart={nextLeagueRoundValues?.roundStartDate}
                  leagueRoundNumber={nextLeagueRoundValues?.roundNumber}
                />
                <LeagueRoundCourseAndHoles />
                <CourseSettingsOptions />
                <button
                  type="submit"
                  className="poppins bg-brandYellow text-black hover:bg-brandGreen hover:text-white mt-8 w-full text-center py-3 rounded-lg"
                >
                  {createLeagueRoundQueryState?.isPending ||
                  updateLeagueRoundQueryState?.isPending ? (
                    <>
                      <CgSpinner className="size-4 animate-spin mr-2" />{" "}
                      {selectedLeagueRound
                        ? "Saving Round..."
                        : "Creating Round..."}
                    </>
                  ) : selectedLeagueRound ? (
                    "Save Changes "
                  ) : (
                    "Create Round "
                  )}
                </button>
                <button
                  type="button"
                  className="poppins bg-gray-200 mt-4 text-red-500 hover:bg-black hover:text-white w-full text-center py-3 rounded-lg"
                  onClick={(e) => handleDeleteLeagueRound()}
                  disabled={startDatePassed}
                >
                  Delete Round
                </button>
              </form>
            </FormProvider>
          </Row>
          <ReactTooltip backgroundColor="#777" textColor="#fff" />
        </div>
      ) : (
        <div className="flex items-center h-1/2 w-full mt-12">
          <SpinnerAnimation color={"#f2f2e6"} />
        </div>
      )}
    </>
  );
};

export default CreateLeagueRoundForm;
