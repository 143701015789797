import { styles } from "./Leagues.styes";
import { RankedTeamResult } from "Models";
import { Controller, useFormContext } from "react-hook-form";

type LeagueRankedTeamScorecardProps = {
  callback?: any;
  teamScorecard: RankedTeamResult;
  showNetScore?: boolean;
  addPointsToRound?: boolean;
  index?: number;
};

const LeagueRankedTeamScorecard: React.FC<LeagueRankedTeamScorecardProps> = (
  props
) => {
  const { teamScorecard, showNetScore, addPointsToRound, index } = props;
  const { control } = useFormContext();

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      e.currentTarget.blur();
    }
  };

  return (
    <div className={`w-full font-bold bg-gray-100 dark:bg-zinc-900 px-12`}>
      <div
        key={teamScorecard.teamLeaderboardId}
        className={styles.modalResultsRow}
      >
        <div className="col-span-1 pl-1">
          {teamScorecard.currentRound?.place !== 0 ? (
            teamScorecard.currentRound?.placeDisplay
          ) : (
            <span className="text-gray-400">
              {teamScorecard.previousRound?.placeDisplay}
            </span>
          )}
        </div>
        <div className="col-span-3 font-bold poppins">
          {teamScorecard.teamName}
        </div>
        <div className="hidden md:inline-block col-span-2"></div>
        <div className="hidden md:inline-block col-span-2">
          {showNetScore ? teamScorecard?.netScore : teamScorecard?.grossScore}
        </div>

        {addPointsToRound ? (
          <div className="col-span-2 pl-2">
            <Controller
              name={`teams.[${index}].points`}
              control={control}
              rules={{
                required: "Please enter a point value",
              }}
              defaultValue={teamScorecard.currentRound?.points.toString()}
              render={({ field }) => (
                <input
                  {...field}
                  type="number"
                  min={0}
                  max={100}
                  className="border-1 border-gray-200 rounded-lg w-20 text-xs font-normal p-1"
                  onKeyDown={(e) => handleKeyDown(e)}
                />
              )}
            />
            <Controller
              name={`teams.[${index}].teamLeaderboardId`}
              control={control}
              defaultValue={teamScorecard.teamLeaderboardId.toString()}
              render={({ field }) => (
                <input
                  {...field}
                  type="hidden"
                  className="border-1 border-gray-200 rounded-lg w-20 text-xs font-normal p-1"
                />
              )}
            />
          </div>
        ) : (
          <div className="col-span-2 pl-2">
            <span className="border-1 border-transparent rounded-lg w-20 text-xs font-normal p-1">
              {teamScorecard.currentRound?.points}
            </span>
          </div>
        )}
        <div className="col-span-1"></div>
        <div className="col-span-1 items-center text-right pr-2"></div>
      </div>
    </div>
  );
};

export default LeagueRankedTeamScorecard;
