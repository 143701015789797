import { useState } from "react";
import { motion } from "framer-motion";
import { MdClose } from "react-icons/md";
import { CgSpinner } from "react-icons/cg";
import { MotionVariantType } from "types/enums";
import { useToasterStore } from "react-hot-toast";
import { AiOutlineMinusCircle } from "react-icons/ai";
import { globalStyles } from "components/globalStyles";
import { styles } from "../teams/RosterAndGroups.styles";
import { LeagueRosterEntryDto, PlayerRoundPayload } from "Models";
import {
  useCreateLeagueMatch,
  useGetLeagueRoundMatches,
} from "modules/leagues/leagueMatchHooks";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { useLeagueRosterByLeagueId } from "modules/leagues/leagueRosterHooks";
import {
  pendoTrackBasicEvent,
  ClubhouseEvents,
} from "utils/pendo/pendoHelpers";
import {
  dailyQueueContainer,
  dailyQueueListItemVariant,
} from "utils/animations/animationHelper";
import { useGetClubhouseLeagueRoundDto } from "modules/leagues/leagueRoundHooks";
import {
  isApiStatusSuccess,
  getApiResponseErrorMessage,
} from "utils/api/apiHelper";
import { useLeagueLeaderboardByLeagueId } from "modules/leagues/leagueScorecardHooks";
import {
  useCreateIndividualLeagueGroup,
  useLeagueGroupByFacilityLeagueRoundId,
  useRemovePlayerFromLeagueGroup,
} from "modules/leagues/leagueGroupHooks";
import {
  useGetSelectedLeague,
  useIsMatchLeague,
  useSelectedLeagueRoundId,
} from "modules/leagues/leagueHooks";
import {
  showCustomNotification,
  GenericNotificationType,
} from "utils/notifications/notificationHelpers";
import Tippy from "@tippyjs/react";
import { GetMatchResponseDtoParticipant } from "MatchPlayModels";
import { FaAngleDown } from "react-icons/fa";

interface AvailableLeagueRoundGroupsProps {
  player: LeagueRosterEntryDto;
}

const GroupRoster = () => {
  const toastIsActive = useToasterStore().toasts.length > 0 ? true : false;
  const currentFacilityId = useGetCurrentLocalFacilityId();
  const selectedLeague = useGetSelectedLeague();
  const selectedLeagueRoundId = useSelectedLeagueRoundId();
  const isMatchPlayLeague = useIsMatchLeague();
  const groupSize = isMatchPlayLeague
    ? 2
    : selectedLeague?.settings?.defaultGroupSize || 4;

  const [, setshowGroupView] = useState<boolean>(false);
  const [group, setAddToGroup] = useState<LeagueRosterEntryDto[]>([]);
  const [showCreateGroup, setShowCreateGroup] = useState<boolean>(false);
  const [leagueRoster, leagueRosterQueryState] = useLeagueRosterByLeagueId(
    currentFacilityId,
    selectedLeague?.id
  );

  const [leagueGroups] = useLeagueGroupByFacilityLeagueRoundId(
    currentFacilityId,
    selectedLeague?.id,
    selectedLeagueRoundId
  );

  const [leagueMatches] = useGetLeagueRoundMatches(
    currentFacilityId,
    selectedLeague?.id,
    selectedLeagueRoundId
  );

  const [
    createGroupQueryState,
    createIndividualGroup,
  ] = useCreateIndividualLeagueGroup(
    currentFacilityId,
    selectedLeague?.id,
    selectedLeagueRoundId
  );

  const [, , refreshLeagueRound] = useGetClubhouseLeagueRoundDto(
    currentFacilityId,
    selectedLeague?.id,
    selectedLeagueRoundId
  );

  const [leagueLeaderboard, ,] = useLeagueLeaderboardByLeagueId(
    currentFacilityId,
    selectedLeague?.id
  );

  const [
    removePlayerQueryStatus,
    removePlayerFromGroup,
  ] = useRemovePlayerFromLeagueGroup(
    currentFacilityId,
    selectedLeague?.id,
    selectedLeagueRoundId
  );

  const [createMatchQueryStatus, createSingleMatch] = useCreateLeagueMatch(
    currentFacilityId,
    selectedLeague?.id,
    selectedLeagueRoundId
  );

  const roundGroupNumbers = Array.from(
    new Set(leagueGroups?.map((player) => player.groupNumber).filter(Boolean))
  );

  function getGroupNumberFromPlayerId(playerId: string): number | undefined {
    let groupNumber;
    leagueGroups?.forEach((groupMember) => {
      if (groupMember.playerId === playerId) {
        return (groupNumber = groupMember.groupNumber);
      }
    });
    return groupNumber;
  }

  function getRoundHandicapFromPlayerId(playerId: string): number | undefined {
    let agHandicap;
    leagueGroups?.forEach((groupMember) => {
      if (groupMember.playerId === playerId) {
        return (agHandicap = groupMember.agHandicap);
      }
    });
    return agHandicap;
  }

  function getMatchFromPlayerId(
    playerId: string
  ): GetMatchResponseDtoParticipant[] | undefined {
    let matchie;
    leagueMatches?.forEach((match) => {
      match?.participants?.forEach((participant) => {
        if (participant.userGuid === playerId) {
          matchie = match.participants;
        }
      });
    });
    return matchie;
  }

  function getGroupMembers() {
    let count = 0;

    return count;
  }

  function nextRoundGroupNumber() {
    let groupNumbers = leagueGroups
      ?.map((player) => player?.groupNumber)
      .filter((groupNumber) => groupNumber !== undefined) as number[];

    return groupNumbers?.length > 0 ? Math.max(...groupNumbers) + 1 : 1;
  }

  function handleCheckboxChange(player: LeagueRosterEntryDto) {
    if (group.some((item) => item.userGuid === player.userGuid)) {
      setAddToGroup(group.filter((item) => item.userGuid !== player.userGuid));
    } else {
      if (group.length <= groupSize) setAddToGroup([...group, player]);
    }
  }

  function getPositionFromLeaderboardByPlayerId(playerId: string): string {
    let placeDisplay = "";

    leagueLeaderboard?.scores?.forEach((score) => {
      if (score.playerGuid === playerId) {
        placeDisplay = score.placeDisplay || "";
      }
    });

    return placeDisplay;
  }

  const handleCreateNewGroup = async () => {
    let response;
    let individualGroupPayload = [] as PlayerRoundPayload[];
    let groupNumber = nextRoundGroupNumber();

    group.map((player) => {
      return individualGroupPayload.push({
        playerGuid: player.userGuid,
        groupNumber: groupNumber,
      });
    });

    response = await createIndividualGroup(individualGroupPayload);

    if (response && isApiStatusSuccess(response?.status)) {
      showCustomNotification(
        "Group created successfully",
        GenericNotificationType.SUCCESS,
        "🎉"
      );
      setAddToGroup([]);
      refreshLeagueRound();
      pendoTrackBasicEvent(ClubhouseEvents.LEAGUE_ROUND_CREATE_GROUP);
    } else {
      if (!toastIsActive && response) {
        showCustomNotification(
          getApiResponseErrorMessage(response?.body),
          GenericNotificationType.ERROR
        );
      }
    }
  };

  const handleCreateNewMatch = async () => {
    let response;
    let groupNumber = nextRoundGroupNumber();
    let participantNumber = 0;

    const singleMatchParticipants = group.map((player) => ({
      userGuid: player.userGuid,
      groupNumber: groupNumber,
      number: (participantNumber += 1),
    }));

    const singleMatchPayload = {
      participants: singleMatchParticipants,
      groupNumber: groupNumber,
    };

    response = await createSingleMatch(singleMatchPayload);

    if (response && isApiStatusSuccess(response?.status)) {
      showCustomNotification(
        "Match created successfully",
        GenericNotificationType.SUCCESS,
        "🎉"
      );
      setAddToGroup([]);
      refreshLeagueRound();
      pendoTrackBasicEvent(ClubhouseEvents.LEAGUE_CREATE_MATCH);
      handleCreateNewGroup();
    } else {
      showCustomNotification(
        getApiResponseErrorMessage(response?.body),
        GenericNotificationType.ERROR
      );
    }
  };

  const handleShowGroupCreate = () => {
    setShowCreateGroup(true);
    setshowGroupView(false);
  };

  const handleRemovePlayerFromGroup = async (
    player: LeagueRosterEntryDto,
    groupNumber: number,
    showNotification?: boolean
  ) => {
    const teamGroupPayload = [{ playerGuid: player.userGuid }];
    const response = await removePlayerFromGroup(teamGroupPayload);

    if (response && isApiStatusSuccess(response?.status)) {
      showNotification &&
        showCustomNotification(
          `${player.firstName} removed from ${groupNumber}`,
          GenericNotificationType.SUCCESS,
          "🎉"
        );
      pendoTrackBasicEvent(ClubhouseEvents.LEAGUE_ROUND_CREATE_GROUP);
    } else {
      showCustomNotification(
        getApiResponseErrorMessage(response?.body),
        GenericNotificationType.ERROR
      );
    }
  };

  const handleAddPlayerToGroup = async (
    player: LeagueRosterEntryDto,
    groupNumber: number
  ) => {
    let response;
    let individualGroupPayload = [
      {
        playerGuid: player.userGuid,
        groupNumber: groupNumber,
      },
    ] as PlayerRoundPayload[];

    response = await createIndividualGroup(individualGroupPayload);

    if (response && isApiStatusSuccess(response?.status)) {
      showCustomNotification(
        player.firstName + " added to Group " + groupNumber,
        GenericNotificationType.SUCCESS,
        "🎉"
      );
      setAddToGroup([]);
      refreshLeagueRound();
      pendoTrackBasicEvent(ClubhouseEvents.LEAGUE_ROUND_CREATE_GROUP);
    } else {
      if (!toastIsActive && response) {
        showCustomNotification(
          getApiResponseErrorMessage(response?.body),
          GenericNotificationType.ERROR
        );
      }
    }
  };

  const ShowPlayerGroupNumber: React.FC<AvailableLeagueRoundGroupsProps> = (
    props
  ) => {
    const { player } = props;
    const playerGroupNumber = getGroupNumberFromPlayerId(player.userGuid);
    const [, setSelectedPlayerId] = useState<string>("");

    async function switchPlayerGroup(newGroupNumber: number) {
      setSelectedPlayerId(player.userGuid);

      if (playerGroupNumber) {
        await handleRemovePlayerFromGroup(player, playerGroupNumber, false);
        await handleAddPlayerToGroup(player, newGroupNumber);
        setSelectedPlayerId("");
      }
    }

    return !isMatchPlayLeague ||
      (isMatchPlayLeague && getMatchFromPlayerId(player.userGuid)) ? (
      <div>
        <Tippy
          className="poppins text-black text-xs bg-gray-100 rounded-xl dark:bg-zinc-800 dark:text-gray-50 left-0 top-2 float-right inline"
          placement="bottom"
          allowHTML={true}
          appendTo={document.body}
          interactive={true}
          interactiveBorder={5}
          zIndex={9999}
          content={
            <div className="">
              <ul className="z-9999 p-2 border rounded-xl">
                {roundGroupNumbers.length > 0 &&
                  !showCreateGroup &&
                  roundGroupNumbers.map((groupNumber) =>
                    leagueGroups?.filter(
                      (player) => player.groupNumber === groupNumber
                    )?.length === selectedLeague.settings.defaultGroupSize ? (
                      <li
                        key={groupNumber}
                        className="border-b border-gray-200 px-1 py-2 last:border-none"
                      >
                        <Tippy
                          content={`Group ${groupNumber} is full`}
                          className={globalStyles.toolTip}
                          placement="right"
                        >
                          <button className="cursor-not-allowed text-gray-400">
                            Group {groupNumber}
                          </button>
                        </Tippy>
                      </li>
                    ) : (
                      groupNumber &&
                      groupNumber !== playerGroupNumber && (
                        <li
                          key={groupNumber}
                          className="border-b border-gray-200 px-1 py-2 last:border-none"
                        >
                          <button
                            className="cursor-pointer"
                            onClick={() =>
                              playerGroupNumber
                                ? switchPlayerGroup(groupNumber)
                                : handleAddPlayerToGroup(player, groupNumber)
                            }
                          >
                            Group {groupNumber}
                          </button>
                        </li>
                      )
                    )
                  )}
                <li className="border-b border-gray-200 px-1 py-2 last:border-none">
                  <button
                    className="cursor-pointer"
                    onClick={() => handleShowGroupCreate()}
                    disabled={
                      leagueRoster.length === getGroupMembers() ? true : false
                    }
                  >
                    Create Group
                  </button>
                </li>
              </ul>
            </div>
          }
        >
          <div className="rounded-xl border inline px-2 py-0.5 border-gray-200 hover:bg-gray-100 hover:border-gray-400 cursor-pointer">
            <span className="ml-0.5 text-[10px] items-center">
              {playerGroupNumber || (
                <span className="text-[10px] md:text-xs">Add</span>
              )}
            </span>
            {removePlayerQueryStatus?.isPending ||
            createGroupQueryState?.isPending ? (
              <CgSpinner className="size-3 animate-spin ml-1" />
            ) : (
              <FaAngleDown className="size-3 fill-gray-500 text-gray-500 ml-1" />
            )}
          </div>
        </Tippy>
        {playerGroupNumber && (
          <button
            onClick={() =>
              handleRemovePlayerFromGroup(player, playerGroupNumber || 0, true)
            }
            className={`ml-2 mb-1.5 inline ${showCreateGroup ? "hidden" : ""}`}
          >
            {removePlayerQueryStatus?.isPending ? (
              <CgSpinner className="size-3 animate-spin mb-0.5" />
            ) : (
              <AiOutlineMinusCircle className="size-3 fill-gray-400 text-gray-400 hover:fill-red-500 hover:text-red-500 mb-0.5" />
            )}
          </button>
        )}
      </div>
    ) : (
      <div className="text-xs text-gray-500 dark:text-gray-50 relative w-full text-left">
        {getMatchFromPlayerId(player.userGuid) && (
          <button
            className=""
            onClick={(e) => {
              e.preventDefault();
              setShowCreateGroup(true);
            }}
          >
            {showCreateGroup ? "" : "Add to Group"}
          </button>
        )}
      </div>
    );
  };

  const ShowPlayerMatch: React.FC<AvailableLeagueRoundGroupsProps> = (
    props
  ) => {
    const { player } = props;
    const playerMatch = getMatchFromPlayerId(player.userGuid);
    const vsPlayer = playerMatch?.find(
      (matchPlayer) => matchPlayer.userGuid !== player?.userGuid
    );

    return vsPlayer ? (
      <span className="text-gray-400 dark:text-gray-50">
        <span>
          vs{" "}
          {vsPlayer?.lastName
            ? vsPlayer?.firstName + " " + vsPlayer?.lastName
            : vsPlayer?.userName}
        </span>
      </span>
    ) : null;
  };

  return leagueRosterQueryState.isFinished ? (
    leagueRoster && leagueRoster.length > 0 ? (
      <>
        <div className="pb-4">
          {showCreateGroup ? (
            <>
              <button
                className={`${globalStyles.primaryButton} ${globalStyles.buttonSmall}`}
                onClick={() =>
                  isMatchPlayLeague
                    ? handleCreateNewMatch()
                    : handleCreateNewGroup()
                }
              >
                {createGroupQueryState?.isPending ||
                createMatchQueryStatus.isPending ? (
                  <>
                    <CgSpinner className="size-4 animate-spin mr-2" />{" "}
                    {isMatchPlayLeague ? "Saving Match..." : "Saving Group..."}
                  </>
                ) : isMatchPlayLeague ? (
                  "Save Match"
                ) : (
                  "Save Group"
                )}
              </button>
              <button
                className={`${globalStyles.tertiaryButton} ${globalStyles.buttonSmall}`}
                onClick={() => setShowCreateGroup(false)}
              >
                Close
              </button>
            </>
          ) : isMatchPlayLeague ? (
            <button
              onClick={(e) => handleShowGroupCreate()}
              className={`${globalStyles.primaryButton} ${globalStyles.buttonSmall}`}
            >
              {createMatchQueryStatus && createMatchQueryStatus.isPending ? (
                <>
                  <CgSpinner className="size-4 animate-spin mr-2" /> Saving
                  Match...
                </>
              ) : (
                "Assign Match"
              )}
            </button>
          ) : (
            <button
              className={`${globalStyles.primaryButton} ${globalStyles.buttonSmall}`}
              onClick={() => handleShowGroupCreate()}
              disabled={
                leagueRoster.length === getGroupMembers() ? true : false
              }
            >
              Create Group
            </button>
          )}
        </div>
        <div className="grid grid-cols-12 gap-2 my-2 text-xs poppins">
          <div className="col-span-1 text-gray-400 text-center"></div>
          <div className="col-span-9 sm:col-span-3 text-gray-400 text-left">
            Full Name
          </div>
          <div className="hidden sm:inline-block sm:col-span-4 text-gray-400 text-left">
            {isMatchPlayLeague ? "Match" : "Email"}
          </div>
          <div className="hidden sm:inline-block sm:col-span-2 text-gray-400 text-left truncate">
            Group
          </div>
          <div className="hidden sm:inline-block sm:col-span-1 text-gray-400 text-left truncate">
            Pos
          </div>
          <div className="sm:inline-block col-span-1 text-gray-400 text-left">
            HCP
          </div>
        </div>
        <motion.ul
          variants={dailyQueueContainer}
          initial={MotionVariantType.HIDDEN}
          animate={MotionVariantType.SHOW}
          key={`leagueRosterList_${leagueRoster.length}`}
          className=""
        >
          {leagueRoster?.length > 0 ? (
            leagueRoster.map((player, index) => {
              let groupNumber = getGroupNumberFromPlayerId(player.userGuid);
              return (
                <motion.li
                  animate={{ opacity: 1, x: 0 }}
                  layout
                  variants={dailyQueueListItemVariant}
                  className={`grid grid-cols-12 gap-3 py-4 text-xs poppins justify-items-start items-center border-b border-gray-100 dark:text-gray-200`}
                  key={`leagueRoster-${player.userGuid}-${index}`}
                >
                  <div
                    className="col-span-1 text-gray-400 dark:text-gray-200 text-center"
                    key={index}
                  >
                    {showCreateGroup
                      ? (!groupNumber || isMatchPlayLeague) && (
                          <input
                            type="checkbox"
                            onChange={() => handleCheckboxChange(player)}
                            id={player.userGuid}
                            checked={group.some(
                              (item) => item.userGuid === player.userGuid
                            )}
                            disabled={
                              !group.some(
                                (item) => item.userGuid === player.userGuid
                              ) && group.length >= groupSize
                            }
                            className={styles.checkbox}
                          />
                        )
                      : index + 1 + "."}
                  </div>
                  <div className="col-span-9 sm:col-span-3">
                    {player?.firstName
                      ? player.firstName + " " + player?.lastName
                      : player?.userName}
                  </div>
                  <div className="hidden sm:block col-span-4 truncate w-full">
                    <div className="text-ellipsis truncate min-w-0">
                      {isMatchPlayLeague ? (
                        <ShowPlayerMatch player={player} />
                      ) : (
                        player.userEmail
                      )}
                    </div>
                  </div>
                  <div className="hidden sm:inline-block col-span-2">
                    <ShowPlayerGroupNumber player={player} />
                  </div>
                  <div className="hidden sm:inline-block sm:col-span-1 text-gray-400 text-left truncate">
                    {getPositionFromLeaderboardByPlayerId(player.userGuid)}
                  </div>
                  <div
                    className={`${
                      showCreateGroup ? "hidden" : "col-span-1 self-center"
                    }`}
                  >
                    {getRoundHandicapFromPlayerId(player.userGuid) ||
                      player.aGHandicapIndex}
                  </div>
                </motion.li>
              );
            })
          ) : (
            <div className={styles.noPlayersRosterMessage}>
              <MdClose className={styles.closeIcon} />
              All players have been added to groups.
            </div>
          )}
        </motion.ul>
      </>
    ) : (
      <div className={styles.noPlayersRosterMessage}>
        <MdClose className={styles.closeIcon} />
        No players found in roster.
      </div>
    )
  ) : (
    <div className={styles.noPlayersRosterMessage}>
      <MdClose className={styles.closeIcon} />
      Players that have been added to the league roster will appear here. Go to
      Leagues, then click on the League Roster button to add players to this
      league.
    </div>
  );
};
export default GroupRoster;
