import { useState } from "react";
import { RoundScorecardDto } from "ScorecardModels";
import { MotionVariantType } from "types/enums";
import { ImSortNumericAsc } from "react-icons/im";
import { styles } from "../scorecard/Scorecard.styles";
import { AnimateSharedLayout, motion } from "framer-motion";
import { dailyQueueContainer } from "utils/animations/animationHelper";
import ScorecardListItem from "components/scorecard/ScorecardListItem";

type LeagueRoundScorecardSearchResultsProps = {
  leagueRoundScorecards: RoundScorecardDto[];
};

const LeagueRoundScorecardSearchResults: React.FC<LeagueRoundScorecardSearchResultsProps> = (
  props
) => {
  const { leagueRoundScorecards } = props;

  const [selectedScorecardId, setSelectedScorecardId] = useState<
    number | boolean
  >(false);

  return (
    <nav className={styles.scorecardNav} aria-label="Scorecard Nav">
      {leagueRoundScorecards && leagueRoundScorecards?.length > 0 && (
        <div className="pb-0 pt-2">
          <ul className={styles.scorecardList} role="tablist">
            <li className="pr-2 relative">
              <a
                className="text-xs text-gray-400 hidden"
                onClick={(e) => {
                  e.preventDefault();
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
                aria-label="tab"
              >
                <ImSortNumericAsc className="text-base mr-1 font-semibold brand-light-green" />{" "}
                Date
              </a>
            </li>
          </ul>
        </div>
      )}
      <div className="relative">
        <div className="eventListContainer rounded-md w-full">
          <AnimateSharedLayout>
            {leagueRoundScorecards && leagueRoundScorecards?.length > 0 && (
              <motion.ul
                className="relative z-0 w-full"
                variants={dailyQueueContainer}
                initial={MotionVariantType.HIDDEN}
                animate={MotionVariantType.SHOW}
              >
                {leagueRoundScorecards.map((roundScorecard, index) => {
                  return (
                    <ScorecardListItem
                      scorecard={roundScorecard}
                      key={roundScorecard?.saveGameId}
                      isOpen={selectedScorecardId === index}
                      callback={() =>
                        setSelectedScorecardId(
                          selectedScorecardId === index ? false : index
                        )
                      }
                    />
                  );
                })}
              </motion.ul>
            )}
          </AnimateSharedLayout>
        </div>
      </div>
    </nav>
  );
};
export default LeagueRoundScorecardSearchResults;
