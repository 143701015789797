export const styles = {
  gridListViewToggleContainer:
    "bg-white dark:bg-zinc-900 poppins p-1 text-xs text-gray-500 dark:text-gray-200 leading-none border border-gray-200 dark:border-gray-500 shadow-sm rounded-xl inline-flex",
  gridListViewToggleButtonActive:
    "bg-brandGreen dark:bg-zinc-800 text-white dark:text-gray-200 rounded-lg inline-flex items-center transition-colors duration-300 ease-in focus:outline-none hover:dark-gray px-4 py-2",
  gridListViewToggleButtonInactive:
    " inline-flex items-center transition-colors duration-100 ease-out focus:outline-none hover:dark-gray rounded-lg px-4 py-2",
  scorecardModalButton:
    "h-full inline px-6 py-3 text-center text-sm text-gray-500 dark:text-gray-200 bg-gray-50 dark:bg-zinc-800 hover:bg-gray-100 border-r-2 border-l-2 hover:border-gray-200 hover:shadow-inner font-semibold",
  scoreCardModalContainer:
    "h-full text-right float-right rounded-tr-lg w-full m-0 background-gray-5-",
};
