import { styles } from "./Leagues.styes";
import { NavLink } from "react-router-dom";
import { useAppDispatch } from "utils/hooks";
import { BsChevronDoubleRight } from "react-icons/bs";
import { useSelectedLeague } from "modules/leagues/leagueHooks";
import {
  setSelectedLeagueId,
  setSelectedRoundId,
} from "modules/leagues/leagueSlice";

const LeagueBreadcrumbs = () => {
  const dispatch = useAppDispatch();
  const selectedLeague = useSelectedLeague();

  return (
    <>
      <ul className="inline-block list-none w-full">
        <li className="inline-block">
          <NavLink
            to={{
              pathname: `/leagues/`,
            }}
            className={styles.breadcrumbLink}
            onClick={() => {
              dispatch(setSelectedLeagueId({ selectedLeagueId: "" }));
              dispatch(setSelectedRoundId({ selectedLeagueRoundId: "" }));
            }}
          >
            <span className="border-b border-slate-700 hover:border-gray-400">
              Leagues
            </span>{" "}
            <BsChevronDoubleRight className="ml-1" />
          </NavLink>
        </li>
        {selectedLeague ? (
          <>
            <li className="inline-block pl-2 text-gray-500  dark:text-gray-200 poppins">
              {selectedLeague?.name} <BsChevronDoubleRight className="ml-1" />
            </li>
            <li className="inline-block pl-2 text-gray-500 dark:text-gray-200 poppins">
              Rounds
            </li>
          </>
        ) : (
          <span className="ml-2 dark:text-white">No league selected </span>
        )}
      </ul>
    </>
  );
};
export default LeagueBreadcrumbs;
