import { BsGrid } from "react-icons/bs";
import { styles } from "./dashboard.styles";
import { VscListSelection } from "react-icons/vsc";
import { BreakPoints, useMediaQuery } from "utils/animations/animationHelper";
import {
  ClubhouseEvents,
  pendoTrackEventWithProperties,
} from "utils/pendo/pendoHelpers";
import Row from "components/ui/layout/Row";

type OwnProps = {
  listView: boolean;
  setListView: React.Dispatch<React.SetStateAction<boolean>>;
};

const GridListViewToggle: React.FC<OwnProps> = ({ listView, setListView }) => {
  const isDesktop = useMediaQuery(BreakPoints.MD);

  const handleListViewToggle = (listView: boolean) => {
    setListView(listView);
    pendoTrackEventWithProperties(
      listView
        ? ClubhouseEvents.SHOW_LIST_VIEW
        : ClubhouseEvents.SHOW_GRID_VIEW,
      {}
    );
  };

  return (
    <Row cssClasses={`${isDesktop ? "" : "hidden"}`}>
      <div className={styles.gridListViewToggleContainer}>
        <button
          className={`${
            !listView ? styles.gridListViewToggleButtonActive : ""
          } ${styles.gridListViewToggleButtonInactive}`}
          id="grid"
          onClick={() => handleListViewToggle(false)}
        >
          <BsGrid className="size-4" />
          <span className="ml-1">Grid</span>
        </button>
        <button
          className={` ${
            listView ? styles.gridListViewToggleButtonActive : ""
          } ${styles.gridListViewToggleButtonInactive}`}
          id="list"
          onClick={() => handleListViewToggle(true)}
        >
          <VscListSelection className="size-4" />
          <span className="ml-1">List</span>
        </button>
      </div>
    </Row>
  );
};

export default GridListViewToggle;
