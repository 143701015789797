import { useAppDispatch } from "utils/hooks";
import { ModalType } from "utils/modals/modalHelpers";
import { modalAction } from "ui-modules/modals/modalSlice";
import { setSelectedRoundId } from "modules/leagues/leagueSlice";
import { useSelectedLeagueRound } from "modules/leagues/leagueHooks";
import Row from "components/ui/layout/Row";
import Modal from "components/shared/Modal";
import CreateLeagueRoundForm from "./CreateLeagueRoundForm";

const LeagueRoundsModal: React.FC<LeagueRoundsModalProps> = (props) => {
  const dispatch = useAppDispatch();
  const selectedLeagueRound = useSelectedLeagueRound();

  const handleCloseModal = async () => {
    dispatch(
      setSelectedRoundId({
        selectedLeagueRoundId: "",
      })
    );
    dispatch(
      modalAction({
        isOpen: false,
        modalName: ModalType.LeagueRoundsModal,
      })
    );
  };

  return (
    <Modal
      modalName={props.modalName}
      closeModalCallback={handleCloseModal}
      hideHeader={false}
      title={selectedLeagueRound?.name || "Create Round"}
      hideFooter={true}
      modalSize="w-full h-full sm:w-2/3 md:1/2 3xl:w-1/3"
      body={
        <Row cssClasses="px-8 pb-4">
          <CreateLeagueRoundForm />
        </Row>
      }
    />
  );
};

type LeagueRoundsModalProps = {
  title?: string;
  modalName: string;
};

LeagueRoundsModal.defaultProps = {
  title: "League Roster",
};

export default LeagueRoundsModal;
