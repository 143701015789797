import React, { useEffect, useRef, useState } from "react";
import { loginUser } from "modules/user";
import cn from "classnames";
import styles from "./Login.less";
import Row from "./ui/layout/Row";
import SocialIcons from "./ui/layout/SocialIcons";
import Copyright from "./ui/layout/Copyright";
import useAppDispatch from "../utils/hooks/useAppDispatch";
import { setIsAuthenticated } from "modules/user/userSlice";
import { motion } from "framer-motion";
import {
  showCustomNotification,
  GenericNotificationType,
} from "utils/notifications/notificationHelpers";
import {
  getLocalStorageValue,
  LocalStorageItems,
  localStorageValueToBoolean,
} from "utils/hooks/localStorage";
import AnimatedLogo from "./svg/AnimatedLogo";
import SpinnerAnimation from "./svg/SpinnerAnimation";

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const emailRef = useRef<HTMLInputElement | null>(null);
  const [isDarkMode] = useState(() =>
    localStorageValueToBoolean(getLocalStorageValue(LocalStorageItems.DARKMODE))
      ? true
      : false
  );
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    submitted: false,
    isAuthenticated: false,
    rememberMe: false,
  });

  const isEnabled =
    formValues.email.length > 0 &&
    formValues.password.length > 0 &&
    !formValues.submitted;

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, email: event.target.value });
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, password: event.target.value });
  };

  const handleRememberMe = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, rememberMe: event.target.checked });
  };

  const handleSubmit = async (event: React.SyntheticEvent) => {
    setFormValues({ ...formValues, submitted: true });
    event.preventDefault();

    const { success, error } = await loginUser(
      formValues.email,
      formValues.password,
      formValues.rememberMe
    );

    if (!success) {
      setFormValues({
        ...formValues,
        submitted: false,
        isAuthenticated: false,
      });
      emailRef.current?.focus();
      showCustomNotification(
        error?.response?.data?.message || "Login failed. Please try again.",
        GenericNotificationType.ERROR
      );
    } else {
      dispatch(setIsAuthenticated({ isAuthenticated: true }));
    }
  };

  useEffect(() => {
    emailRef.current?.focus();
  }, []);

  return (
    <motion.div
      className={cn(
        styles.Login,
        "flex items-center text-center justify-center justify-items-center w-full h-screen align-middle  dark:bg-zinc-800 "
      )}
      id="login"
      initial={{ opacity: 0, scale: 1 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.75,
      }}
    >
      <Row cssClasses=" dark:bg-zinc-800  dark:text-gray-200 w-10/12 sm:w-1/2 lg:w-1/3 xl:w-1/4 m-auto">
        <Row
          cssClasses={`flex flex-grow logo-large m-auto mb-4 m-auto ${
            formValues.submitted ? "hidden" : ""
          }`}
        >
          <AnimatedLogo color={isDarkMode ? "#f8f9f1" : "#000000"} />
        </Row>
        <Row
          cssClasses={`flex flex-grow logo-large m-auto mb-4 ${
            formValues.submitted ? "" : "hidden"
          }`}
        >
          <SpinnerAnimation color={isDarkMode ? "#f8f9f1" : "#000000"} />
        </Row>
        <form
          onSubmit={handleSubmit}
          className="bg-white  dark:bg-zinc-900  dark:border dark:border-gray-200 dark:text-gray-200  text-center rounded-xl py-8 px-5 shadow relative"
          name="submit"
        >
          <Row cssClasses="flex flex-col mt-2">
            <input
              id="email"
              type="email"
              name="email"
              className="mb-2 w-full rounded py-2 px-4 border-2 text-gray-500 dark:text-gray-700 dark:bg-gray-200 border-gray-200 dark:border-white focus:outline-none focus:ring focus:ring-brandGreen focus:border-transparent focus:text-brandGreen sm:placeholder-gray-500 sm:placeholder-opacity-50 dark:placeholder-gray-700"
              placeholder="Email Address"
              ref={emailRef}
              value={formValues.email}
              onChange={handleEmailChange}
              tabIndex={1}
              autoComplete="email-address"
            />
          </Row>
          <Row cssClasses="flex flex-col mb-2">
            <input
              id="password"
              type="password"
              name="password"
              className="mb-2 w-full rounded py-2 px-4 border-2 text-gray-500 dark:text-gray-700 dark:bg-gray-200 border-gray-200 dark:border-white focus:outline-none focus:ring focus:ring-brandGreen focus:border-transparent focus:text-brandGreen sm:placeholder-gray-500 sm:placeholder-opacity-50 dark:placeholder-gray-700"
              placeholder="Password"
              value={formValues.password}
              onChange={handlePasswordChange}
              tabIndex={2}
              autoComplete="current-password"
            />
          </Row>
          <Row cssClasses="my-2 text-gray-400 text-sm">
            <label
              htmlFor="rememberMe"
              className="hidden flex-inline items-center isolate px-4 py-2 bg-white dark:bg-transparent text-sm text-gray-500 dark:text-gray-200 leading-none border-0 border-gray-200 rounded-full float-left"
            >
              <input
                type="checkbox"
                name="rememberMe"
                id="rememberMe"
                className="relative peer z-20 rounded-md focus:ring-0 ring-opacity-0 border-2 border-gray-500 p-1 text-xl mr-2  focus:text-green-600 text-gray-500"
                tabIndex={3}
                onChange={handleRememberMe}
              />{" "}
              Remember me
            </label>
            <a
              href={`${process.env.REACT_APP_PW_RESET}`}
              className="flex-inline items-center isolate px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:text-green-700 float-right"
            >
              Forgot password?
            </a>
          </Row>
          <Row cssClasses="flex w-full justify-center items-center">
            <button
              disabled={!isEnabled}
              type="submit"
              className="bg-brandGreen hover:bg-darkCream text-white hover:text-brandGreen py-2 px-4 mt-2 border border-gray-200 rounded min-w-full w-full hover:animate-pulse focus:ring-2 focus:ring-blue-600 active:bg-brandGreen disabled:opacity-50 disabled:cursor-not-allowed"
              tabIndex={4}
            >
              <span className="poppins inline">
                {formValues.submitted ? "Logging in..." : "Login"}
              </span>
            </button>
          </Row>
        </form>
      </Row>
      <Row cssClasses="fixed bottom-0 text-center mx-auto w-full text-xs text-gray-500 mb-4">
        <SocialIcons />
        <Copyright />
      </Row>
    </motion.div>
  );
};
export default Login;
