import { Hole } from "Models";
import { useSimulatorCourses } from "modules/course/courseHooks";
import { useFormContext } from "react-hook-form";
import { RootState } from "StoreTypes";
import { useSelector } from "react-redux";
import CourseAndHolesForm from "components/shared/control/CourseAndHolesForm";

type SingleSimCourseAndHolesProps = {
  simulatorId: string;
};

const SingleSimCourseAndHoles: React.FC<SingleSimCourseAndHolesProps> = ({
  simulatorId,
}) => {
  const { watch } = useFormContext();
  const watchCourses = watch("roundInfo.courseName");
  const [queryCourses] = useSimulatorCourses(simulatorId);

  const selectedCourseHoles: Hole[] = useSelector(
    (state: RootState) =>
      state.entities.courses?.courseData?.[watchCourses]?.holes || []
  );

  const isCourseInstalled = Boolean(
    queryCourses.some((course) => {
      if (course.shortName === watchCourses) {
        return true;
      } else {
        return false;
      }
    })
  );
  return (
    <>
      {!isCourseInstalled && watchCourses && (
        <div className="mt-3 p-2 text-gray-600 text-center rounded-md border-2 bg-gray-100 border-gray-300">
          <strong className="text-red-500">Warning</strong> - this simulator
          does not have the selected round course ("{watchCourses}") installed.
          Please select a new course.
        </div>
      )}
      <CourseAndHolesForm courses={queryCourses} holes={selectedCourseHoles} />
    </>
  );
};

export default SingleSimCourseAndHoles;
