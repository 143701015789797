import { useEffect } from "react";
import { useSidebarCollapsed } from "utils/ui/uiHelpers";
import { BreakPoints, useMediaQuery } from "utils/animations/animationHelper";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";

const Reservations = () => {
  const isSidebarCollapsed = useSidebarCollapsed();
  const isDesktop = useMediaQuery(BreakPoints.LG);
  const currentFacilityId = useGetCurrentLocalFacilityId();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = process.env.REACT_APP_SRC_PATH || "";
    script.async = false;
    script.id = "rex-reservations-widget";
    document.getElementById("rex-reservations-widget")?.remove();
    document.body.appendChild(script);
  }, [currentFacilityId]);

  return (
    <div
      className={`reservations w-full ${
        isDesktop ? (isSidebarCollapsed ? "pl-24" : "pl-64") : "pl-0"
      }`}
    >
      <div className="flex flex-col md:flex-row min-h-screen w-full p-8 md:p-0">
        <div className="w-full">
          {process.env.REACT_APP_REX_DEVELOPMENT?.toLowerCase() === "true" ? (
            <div
              data-dev-mode="true"
              data-venue-id={currentFacilityId}
              className="rex-widget w-full h-full"
            ></div>
          ) : (
            <div
              data-venue-id={currentFacilityId}
              className="rex-widget w-full h-full"
            ></div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Reservations;
