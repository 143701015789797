import { useState } from "react";
import { motion } from "framer-motion";
import { styles } from "./RoundSettingsTray.styles";
import { FiEdit2, FiPlus, FiTrash } from "react-icons/fi";
import DefaultRoundSettings from "./DefaultRoundSettings";
import CreateRoundConfig from "components/control/dashboard/round-configuration-manage/CreateRoundConfiguration";
import DeleteRoundConfig from "components/control/dashboard/round-configuration-manage/DeleteRoundConfiguration";

const SettingsContainer: React.FC = () => {
  const [selectedValue, setSelectedValue] = useState("");

  return (
    <>
      <div className="my-4 px-8 md:px-0">
        <div className="flex flex-col sm:flex-row w-full">
          <div className="flex w-full sm:w-1/3 justify-center">
            <button
              className={styles.buttonStyle}
              onClick={() => setSelectedValue("saved-rounds")}
            >
              <FiPlus className="size-4 mr-1 mb-0.5" />
              Create New Preset
            </button>
          </div>
          <div className="flex w-full sm:w-1/3 justify-center">
            <button
              className={styles.buttonStyle + " mx-2"}
              onClick={() => setSelectedValue("delete-rounds")}
            >
              <FiTrash className="size-4 mr-1 mb-0.5" />
              Delete Presets
            </button>
          </div>
          <div className="flex w-full sm:w-1/3 justify-center">
            <button
              className={styles.buttonStyle}
              onClick={() => setSelectedValue("round-defaults")}
            >
              <FiEdit2 className="size-4 mr-1 mb-0.5" />
              Manage Defaults
            </button>
          </div>
        </div>
      </div>
      <div className="w-full">
        <motion.div>
          {selectedValue === "round-defaults" ? <DefaultRoundSettings /> : null}
          {selectedValue === "saved-rounds" ? <CreateRoundConfig /> : null}
          {selectedValue === "delete-rounds" ? <DeleteRoundConfig /> : null}
        </motion.div>
      </div>
    </>
  );
};

export default SettingsContainer;
