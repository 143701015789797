import { SetStateAction } from "react";
import { FaAngleDown } from "react-icons/fa";
import Tippy from "@tippyjs/react";
import {
  roundConfigEnumsAsReactSelectOptions,
  titleCase,
} from "utils/enums/enumHelper";

const LeagueFilter: React.FC<LeagueFilterProps> = (props) => {
  function filterLeagueType(filterOption: any) {
    const leagueCards = document.getElementsByClassName("leagueCard");
    Array.from(leagueCards).forEach((card) => {
      if (card.textContent?.toLocaleLowerCase().includes(filterOption)) {
        (card as HTMLElement).style.display = "block";
      } else {
        (card as HTMLElement).style.display = "none";
      }
    });
  }

  return (
    <>
      <Tippy
        className="poppins text-black text-xs bg-gray-100 dark:bg-zinc-800 dark:text-gray-50 left-0 top-2 rounded float-right inline"
        placement="bottom"
        allowHTML={true}
        appendTo={document.body}
        interactive={true}
        interactiveBorder={5}
        zIndex={9999}
        content={
          <ul className="z-9999 p-2 border-2 rounded-xl">
            <li
              key={`filter-viewAll`}
              className="border-b border-gray-200 px-1 py-2 last:border-none text-left"
            >
              <button
                className="cursor-pointer"
                onClick={() => {
                  filterLeagueType("");
                }}
              >
                View All
              </button>
            </li>
            {roundConfigEnumsAsReactSelectOptions.leagueType.map((type) => (
              <li
                key={type.label.toString()}
                className="border-b border-gray-200 px-1 py-2 last:border-none text-left"
              >
                <button
                  className="cursor-pointer"
                  onClick={() => {
                    filterLeagueType(type.label?.toLocaleLowerCase());
                  }}
                >
                  View {type.label}
                </button>
              </li>
            ))}
            {roundConfigEnumsAsReactSelectOptions.scoringType.map((type) => (
              <li
                key={type.label.toString()}
                className="border-b border-gray-200 px-1 py-2 last:border-none text-left"
              >
                <button
                  className="cursor-pointer"
                  onClick={() => {
                    filterLeagueType(type.label?.toLocaleLowerCase());
                  }}
                >
                  View {titleCase(type.label)}
                </button>
              </li>
            ))}
          </ul>
        }
      >
        <div className="rounded-lg border inline px-4 py-1 text-zinc-800 dark:text-gray-50 border-gray-100 dark:border-zinc-800 bg-white hover:bg-gray-100 hover:border-gray-400 dark:bg-zinc-600 cursor-pointer text-left">
          League Filter
          <FaAngleDown className="size-3 fill-gray-500 text-gray-500 ml-1" />
        </div>
      </Tippy>
    </>
  );
};
export default LeagueFilter;

type LeagueFilterProps = {
  leagueTypeFilter?: number[];
  callback: React.Dispatch<SetStateAction<number[]>>;
};
