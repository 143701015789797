import { FaGolfBall } from "react-icons/fa";
import { useRoundById } from "modules/round/roundHooks";
import { teeTypeIconColorHelper } from "utils/icons/iconHelpers";
import ReactTooltip from "react-tooltip";
import { useContext } from "react";
import { SimCardContext } from "components/control/dashboard/SingleSimCardView";

const PlayerListIcons: React.FC<PlayerListIconsType> = ({
  roundId,
  iconSize,
  showNames,
}) => {
  const [currentRound] = useRoundById(roundId);
  const { simulatorId } = useContext(SimCardContext);

  function handicapColorHelper(handicap: number) {
    if (handicap < 14.2) {
      return "bg-green-300";
    } else if (handicap < 28.4) {
      return "bg-yellow-500";
    } else {
      return "bg-gray-200";
    }
  }
  return (
    <div className="flex items-center z-10 ml-2">
      {currentRound?.players?.map((player) => {
        return (
          <div
            key={`${currentRound.sessionId}-player-icon-${player.username}-${simulatorId}`}
          >
            <div
              data-tip
              data-for={`${currentRound.sessionId}-tooltip-${simulatorId}`}
              className={`${iconSize} ${handicapColorHelper(
                player?.agHandicap
              )} rounded-full shadow dark:bg-zinc-800 dark:border dark:border-gray-200 flex items-center justify-center text-xs text-zinc-800 dark:text-gray-200 hover:scale-125 border border-white -ml-2`}
            >
              {player.displayName?.charAt(0) || player.username.charAt(0)}
            </div>
            <ReactTooltip
              className="tooltipTheme"
              place="bottom"
              id={`${currentRound.sessionId}-tooltip-${simulatorId}`}
            >
              <>
                {currentRound?.players?.map((playerTooltip) => {
                  return (
                    <div className="w-full border-b border-zinc-500 py-2 last:border-none text-xs">
                      <FaGolfBall
                        className={`fa fas fa-golf-ball mr-2 ${
                          teeTypeIconColorHelper[
                            playerTooltip.teeType?.toString()
                          ]
                        } border border-gray-400 rounded-full p-1 h-6 w-6 mr-2`}
                      />
                      {playerTooltip.displayName || playerTooltip.username}

                      <span className="float-right ml-4 pl-4 poppins text-gray-400 pt-1 text-xs">
                        {playerTooltip.score.reduce((acc, val) => acc + val)}
                      </span>
                    </div>
                  );
                })}
              </>
            </ReactTooltip>
          </div>
        );
      })}

      <span className={`${showNames} ml-2`}></span>
    </div>
  );
};
export default PlayerListIcons;

type PlayerListIconsType = {
  iconSize?: string;
  showNames?: string;
  roundId: string;
};

PlayerListIcons.defaultProps = {
  iconSize: "w-6 h-6",
  showNames: "block",
};
