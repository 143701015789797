import "assets/css/spinnerAnimation.css";

type OwnProps = {
  class?: string;
};

const SupportIcon: React.FC<OwnProps> = (props) => {
  return (
    <a
      href="https://www.aboutgolf.com/support"
      target="_blank"
      rel="noreferrer"
      data-tip="aG Support"
      data-for="headerButtonsTooltip"
    >
      <svg
        className={`${props.class} fill-current`}
        fill-current={props.class}
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={`${props.class} fill-current`}
          fill-current={props.class}
          d="M9 0C13.9705 0 18 4.02909 18 8.99924C18 13.9693 13.9705 17.9985 9 17.9985C7.54353 17.9985 6.13604 17.6517 4.87157 16.9978L1.42834 17.9583C0.829908 18.1254 0.209322 17.7758 0.042219 17.1774C-0.013086 16.9794 -0.0130589 16.77 0.0422551 16.5722L1.00315 13.1319C0.347733 11.8664 0 10.4574 0 8.99924C0 4.02909 4.02943 0 9 0ZM9 1.34989C4.77502 1.34989 1.35 4.77462 1.35 8.99924C1.35 10.3218 1.68549 11.5941 2.31573 12.7227L2.45131 12.9654L1.44986 16.5509L5.03779 15.55L5.28038 15.6852C6.40805 16.3139 7.67898 16.6486 9 16.6486C13.225 16.6486 16.65 13.2238 16.65 8.99924C16.65 4.77462 13.225 1.34989 9 1.34989ZM9 12.149C9.49707 12.149 9.9 12.5519 9.9 13.0489C9.9 13.5459 9.49707 13.9488 9 13.9488C8.50293 13.9488 8.1 13.5459 8.1 13.0489C8.1 12.5519 8.50293 12.149 9 12.149ZM9 4.27464C10.3669 4.27464 11.475 5.38264 11.475 6.74943C11.475 7.65907 11.2072 8.16591 10.529 8.87199L10.3773 9.02651C9.81756 9.58617 9.675 9.82384 9.675 10.3491C9.675 10.7219 9.37278 11.0241 9 11.0241C8.62722 11.0241 8.325 10.7219 8.325 10.3491C8.325 9.43948 8.59275 8.93265 9.27099 8.22657L9.42273 8.07205C9.98244 7.51239 10.125 7.27472 10.125 6.74943C10.125 6.12816 9.62136 5.62453 9 5.62453C8.41752 5.62453 7.93845 6.06717 7.88085 6.63441L7.875 6.74943C7.875 7.12219 7.57278 7.42437 7.2 7.42437C6.82721 7.42437 6.525 7.12219 6.525 6.74943C6.525 5.38264 7.63308 4.27464 9 4.27464Z"
          fill="black"
        />
      </svg>
    </a>
  );
};
export default SupportIcon;

SupportIcon.defaultProps = {
  class: "text-zinc-800 dark:text-white",
};
