import React, { ReactNode } from "react";
import { useAppDispatch } from "utils/hooks";
import { modalAction } from "ui-modules/modals/modalSlice";
import styles from "./Modal.styles";
import { AnimatePresence, motion } from "framer-motion";
import {
  modalMotionVariants,
  backgroundMotionVariants,
} from "utils/animations/animationHelper";
import { MotionVariantType } from "types/enums";
import { MdClose } from "react-icons/md";

type DefaultHeaderProps = {
  buttons?: React.ReactNode;
  title?: string;
  modalName: string;
  callback?: any;
};

type DefaultFooterProps = {
  modalName: string;
};

type ModalProps = {
  title?: string;
  hideHeader?: boolean;
  hideFooter?: boolean;
  showCommandButtons?: boolean;
  clickOverlayToClose?: boolean;
  header?: ReactNode;
  footer?: ReactNode;
  body?: ReactNode;
  modalSize?: string;
  modalName: string;
  disableContentScrolling?: boolean;
  closeModalCallback?: any;
};

const DefaultHeader: React.FC<DefaultHeaderProps> = (props) => {
  const dispatch = useAppDispatch();
  const setModalClose = () => {
    dispatch(modalAction({ isOpen: false, modalName: props.modalName }));
    if (props.callback) {
      props.callback();
    }
  };

  return (
    <div className={styles.headerRow}>
      <div className={styles.titleRow}>
        <span className={styles.span}>{props.title}</span>
      </div>
      <div className={styles.buttonContainer}>
        <button onClick={setModalClose} className="pr-4 pt-3">
          <MdClose className="font-bold poppins h-6 w-6 dark:text-gray-50" />
        </button>
      </div>
    </div>
  );
};

const DefaultFooter: React.FC<DefaultFooterProps> = (props) => {
  const dispatch = useAppDispatch();
  const setModalClose = () =>
    dispatch(modalAction({ isOpen: false, modalName: props.modalName }));

  return (
    <div className={styles.footerContainer}>
      <button onClick={setModalClose} className={styles.closeButton}>
        Close
      </button>
    </div>
  );
};

const Modal: React.FC<ModalProps> = (props) => {
  const nodeRef = React.useRef(null);

  const innerModal = (
    <div className={`${styles.innerModalContainer}`} data-testid="innerModal">
      <header
        ref={nodeRef}
        className={props.hideHeader ? "hidden" : styles.showInnerModalHeader}
        id="modalHeader"
      >
        {props.header ? (
          props.header
        ) : (
          <DefaultHeader
            title={props.title}
            modalName={props.modalName}
            callback={props?.closeModalCallback}
          />
        )}
      </header>
      <main
        data-testid="modalBody"
        className={
          props.disableContentScrolling ? "" : styles.showInnerModalBody
        }
      >
        {props.body}
      </main>
      <footer
        className={props.hideFooter ? "hidden" : styles.showInnerModalFooter}
        data-testid="modalFooter"
      >
        {props.footer ? (
          props.footer
        ) : (
          <DefaultFooter modalName={props.modalName} />
        )}
      </footer>
    </div>
  );

  const useOnClickOutside = (event: React.SyntheticEvent) => {
    const modal = document.getElementById("modalBackground");
    if (event.target === modal) {
    }
  };

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        className={styles.modalBackground}
        variants={backgroundMotionVariants}
        initial={MotionVariantType.HIDDEN}
        animate={MotionVariantType.VISIBLE}
        exit={MotionVariantType.HIDDEN}
        onClick={useOnClickOutside}
        id="modalBackground"
      >
        <motion.div
          className={`${
            props?.modalSize
              ? props?.modalSize.toString()
              : "h-full w-full md:w-2/3 2xl:w-1/2 "
          } pointer-events-none relative translate-y-[-50px] transition-all duration-300 ease-in-out mx-auto mt-8 transform-none opacity-100`}
          variants={modalMotionVariants}
          initial={MotionVariantType.HIDDEN}
          animate={MotionVariantType.VISIBLE}
          id="modalContent"
        >
          {innerModal}
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};
export default Modal;

Modal.defaultProps = {
  hideHeader: false,
  hideFooter: false,
  showCommandButtons: true,
  clickOverlayToClose: true,
  title: "Modal",
  modalSize: "",
};
