import { QueryState } from "redux-query";
import { UseDataInfo } from "QueryTypes";
import { useSelector } from "react-redux";
import { EntitiesState, RootState } from "StoreTypes";
import { leagueQueryDefaultOptions } from "./leagueHelpers";
import {
  LeagueRound,
  PostRound,
  RoundLeaderboardDto,
  ClubhouseRoundDto,
} from "Models";
import { RunMutation, useMutation, useRequest } from "redux-query-react";
import {
  getClubhouseRoundDto,
  getRoundsByLeagueId,
  makeCreateLeagueRoundMutation,
  makeDeleteLeagueRoundMutation,
  makeUpdateLeagueRoundMutation,
  putFinalizeLeagueRound,
} from "./leagueRoundQueries";
import axios from "axios";

export const useSelectedLeagueRound = () => {
  const selectedLeagueId = useSelectedLeagueId();
  const selectedLeagueRoundId = useSelectedLeagueRoundId();

  return useSelector(
    (state: RootState): ClubhouseRoundDto => {
      return state.entities?.leagueRounds?.byLeagueId?.[selectedLeagueId]?.[
        selectedLeagueRoundId
      ];
    }
  );
};

export const useSelectedLeagueRoundLeaderboard = () => {
  const selectedLeagueRoundId = useSelectedLeagueRoundId();

  return useSelector(
    (state: RootState): RoundLeaderboardDto => {
      return state.entities?.leagueRoundLeaderboard?.byRoundId?.[
        selectedLeagueRoundId
      ];
    }
  );
};

export const useSelectedLeagueRoundLeaderboardPlayer = (playerId: string) => {
  const selectedRoundLeaderboard = useSelectedLeagueRoundLeaderboard();

  return selectedRoundLeaderboard?.players?.find(
    (player) => player.playerGuid === playerId
  );
};

export const useSelectedLeagueRoundId = () => {
  return useSelector((state: RootState): string => {
    return state.league?.selectedLeagueRoundId;
  });
};

export const useSelectedLeagueRoundIsActive = () => {
  const selectedLeagueRound = useSelectedLeagueRound();
  return selectedLeagueRound?.roundIsActive;
};

export const useSelectedLeagueRoundIsScored = () => {
  const selectedLeagueRound = useSelectedLeagueRound();
  return selectedLeagueRound?.isScored;
};

export const useSelectedLeagueRoundIsCompleted = () => {
  const selectedLeagueRound = useSelectedLeagueRound();
  return selectedLeagueRound?.isCompleted;
};

export const useGetLatestLeagueRoundId = (leagueId: string) => {
  const leagueRounds = useSelector((state: RootState): ClubhouseRoundDto[] => {
    if (!state.entities.leagueRounds?.byLeagueId?.[leagueId]) return [];

    let leagueRounds: ClubhouseRoundDto[] = Object.values<ClubhouseRoundDto>(
      state.entities?.leagueRounds?.byLeagueId?.[leagueId]
    );

    return leagueRounds.sort(
      (a: ClubhouseRoundDto, b: ClubhouseRoundDto) =>
        (a?.roundNumber ?? 0) - (b?.roundNumber ?? 0) ||
        new Date(a.startPlayDate).valueOf() -
          new Date(b.startPlayDate).valueOf()
    );
  });

  return leagueRounds[leagueRounds.length - 1]?.id;
};

export const useCreateLeagueRound = (
  facilityId: string,
  leagueId: string
): [QueryState, RunMutation<EntitiesState, [LeagueRound]>] => {
  const [
    createLeagueQueryState,
    createLeagueRound,
  ] = useMutation((body: PostRound) =>
    makeCreateLeagueRoundMutation(facilityId, leagueId, body)
  );

  return [createLeagueQueryState, createLeagueRound];
};

export const useLeagueRoundsByLeagueId = (
  facilityId: string,
  leagueId: string
): UseDataInfo<ClubhouseRoundDto[]> => {
  const [queryState, refreshLeagues] = useRequest(
    getRoundsByLeagueId(facilityId, leagueId)
  );

  const leagueRounds = useSelector((state: RootState): ClubhouseRoundDto[] => {
    if (!state.entities.leagueRounds?.byLeagueId?.[leagueId]) return [];

    let leagueRounds: ClubhouseRoundDto[] = Object.values<ClubhouseRoundDto>(
      state.entities?.leagueRounds?.byLeagueId?.[leagueId]
    );

    return leagueRounds.sort(
      (a: ClubhouseRoundDto, b: ClubhouseRoundDto) =>
        (a?.roundNumber ?? 0) - (b?.roundNumber ?? 0) ||
        new Date(a.startPlayDate).valueOf() -
          new Date(b.startPlayDate).valueOf()
    );
  });

  return [leagueRounds, queryState, refreshLeagues];
};

export const useSelectedLeagueId = () => {
  return useSelector((state: RootState): string => {
    let selectedLeagueId = state.league?.selectedLeagueId;
    return selectedLeagueId;
  });
};

export const getSelectedLeagueRound = async (
  facilityId: string,
  leagueId: string,
  roundId: string
) => {
  let selectedLeagueRound = {} as LeagueRound;

  await axios
    .get<LeagueRound>(
      `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facility/${facilityId}/league/${leagueId}/round/${roundId}`,
      {
        ...leagueQueryDefaultOptions(),
      }
    )
    .then((res) => {
      selectedLeagueRound = res.data;
    })
    .catch((error) => {
      return error;
    });

  return selectedLeagueRound;
};

export const useDeleteLeagueRound = (
  leagueId: string,
  facilityId: string,
  roundId: string
): [QueryState, RunMutation<EntitiesState>] => {
  const [queryState, deleteLeagueRound] = useMutation(() =>
    makeDeleteLeagueRoundMutation(leagueId, facilityId, roundId)
  );
  return [queryState, deleteLeagueRound];
};

export const useUpdateLeagueRound = (
  facilityId: string,
  leagueId: string,
  roundId: string
): [QueryState, RunMutation<EntitiesState, [LeagueRound]>] => {
  const [queryState, updateLeagueRound] = useMutation((body: LeagueRound) =>
    makeUpdateLeagueRoundMutation(facilityId, leagueId, roundId, body)
  );

  return [queryState, updateLeagueRound];
};

export const useGetClubhouseLeagueRoundDto = (
  facilityId: string,
  leagueId: string,
  roundId: string
): UseDataInfo<ClubhouseRoundDto> => {
  const [queryState, refreshLeagueRound] = useRequest(
    getClubhouseRoundDto(facilityId, leagueId, roundId)
  );

  const leagueRound = useSelector(
    (state: RootState): ClubhouseRoundDto => {
      return state.entities.leagueRounds?.byLeagueId?.[leagueId]?.[roundId];
    }
  );

  return [leagueRound, queryState, refreshLeagueRound];
};

export const useFinalizeClubhouseLeagueRound = (
  facilityId: string,
  leagueId: string,
  roundId: string
): [QueryState, RunMutation<EntitiesState>] => {
  const [queryState, leagueRound] = useMutation(() =>
    putFinalizeLeagueRound(facilityId, leagueId, roundId)
  );

  return [queryState, leagueRound];
};
