import { customStyles } from "utils/ui/uiHelpers";
import { ErrorMessage } from "@hookform/error-message";
import { Controller, useFormContext } from "react-hook-form";
import { useGroupsByFacilityId } from "modules/events/eventHooks";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import Select from "react-select";
import CreateNewTour from "./CreateNewTour";
import { Group } from "Models";

const TourSelectDropdown = () => {
  const { control, formState, setValue, watch } = useFormContext();
  const currentFacilityId = useGetCurrentLocalFacilityId();
  const selectedGroupId = watch("groupId");

  const [groupsByFacility] = useGroupsByFacilityId(currentFacilityId);

  const groupOptions: { label: string; value: Group }[] = groupsByFacility.map(
    (group) => {
      return { label: group?.name, value: group };
    }
  );

  const handleChange = async (val: { label: string; value: Group }) => {
    await setValue("groupId", val.value.id);
  };

  return (
    <>
      <div className="mt-4">
        <span className="text-gray-600 font-bold">Select a Tour</span>
        <Controller
          name="groupId"
          control={control}
          rules={{ required: "Tour is required" }}
          render={({ field }) => (
            <Select
              {...field}
              closeMenuOnSelect={true}
              isMulti={false}
              styles={customStyles}
              options={groupOptions}
              noOptionsMessage={() =>
                "No tours found. Click `Add New Tour` to create a tour for this event."
              }
              onChange={(val: { label: string; value: Group }) =>
                handleChange(val)
              }
              value={groupOptions.find(
                (option) => option.value?.id === selectedGroupId
              )}
              id="eventTour"
            />
          )}
        />
        <ErrorMessage
          errors={formState.errors}
          name="groupId"
          render={({ message }) => (
            <div className="text-sm text-red-500">{message}</div>
          )}
        />
      </div>
      <div className="">
        <CreateNewTour />
      </div>
    </>
  );
};
export default TourSelectDropdown;
