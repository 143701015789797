import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type QueueState = {
  selectedQueueItem: string | never[];
};

const initialState: QueueState = {
  selectedQueueItem: [],
};

const slice = createSlice({
  name: "queue",
  initialState,
  reducers: {
    setSelectedQueueItem(
      state,
      action: PayloadAction<{
        selectedQueueItem: string;
      }>
    ): void {
      state.selectedQueueItem = action.payload.selectedQueueItem;
    },
  },
});

const { actions, reducer: queueReducer } = slice;

const { setSelectedQueueItem } = actions;

export { queueReducer, setSelectedQueueItem };
