import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type SimulatorState = {
  modalSettings: {
    [key: string]: { isOpen: boolean };
  };
  roundModalForm: {
    [key: string]: {
      [key: string]: string | number;
    };
  };
};

const initialState: SimulatorState = {
  modalSettings: {},
  roundModalForm: {},
};

const slice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    modalAction(
      state,
      action: PayloadAction<{ isOpen: boolean; modalName: string }>
    ): void {
      state.modalSettings[action.payload.modalName] = {
        isOpen: action.payload.isOpen,
      };
    },
    roundModalFormFieldUpdateAction(
      state,
      action: PayloadAction<{
        modalName: string;
        fieldName: string;
        value: string | number;
      }>
    ): void {
      state.roundModalForm[action.payload.modalName][action.payload.fieldName] =
        action.payload.value;
    },
  },
});

const { actions, reducer: modalReducer } = slice;

const { modalAction } = actions;

export { modalReducer, modalAction };
