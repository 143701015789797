import { useSelector } from "react-redux";
import { RootState } from "StoreTypes";
import { styles } from "./SimulatorReservation.styles";
import { queueAction } from "ui-modules/nav/navSlice";
import { useAppDispatch } from "utils/hooks";
import {
  setLocalStorageValue,
  LocalStorageItems,
} from "utils/hooks/localStorage";

const ShowQueueOnDashboardCheckbox: React.FC<ShowQueueOnDashboardCheckboxProps> = (
  props
) => {
  const dispatch = useAppDispatch();
  const showQueueOnDashboard = useSelector(
    (state: RootState) => state.nav?.queue?.showOnDashboard
  );

  const handleCheckboxChange = async () => {
    await setLocalStorageValue(
      LocalStorageItems.DASHBOARDQUEUE,
      JSON.stringify(!showQueueOnDashboard)
    );
    dispatch(queueAction({ showOnDashboard: !showQueueOnDashboard }));
  };

  return (
    <>
      <label className={`${styles.label} hidden`}>
        <input
          type="checkbox"
          name="showShowQueueOnDashboardCheckbox"
          id="showShowQueueOnDashboardCheckbox"
          data-testid="showShowQueueOnDashboardCheckbox"
          className={styles.checkbox}
          defaultChecked={showQueueOnDashboard ? true : false}
          onChange={() => handleCheckboxChange()}
        />
        <span className="mr-1 poppins text-xs">{props.labelText}</span>
      </label>
    </>
  );
};
export default ShowQueueOnDashboardCheckbox;

type ShowQueueOnDashboardCheckboxProps = {
  labelText: string;
};
