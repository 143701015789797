import { LeagueRosterEntryDto } from "Models";
import { QueryState, EntitiesState } from "redux-query";
import { RunMutation, useMutation, useRequest } from "redux-query-react";
import {
  getRosterByLeagueId,
  makeAddPlayersToRosterMutation,
  makeRemovePlayersFromRosterMutation,
} from "./leagueRosterQueries";
import { UseDataInfo } from "QueryTypes";
import { useSelector } from "react-redux";
import { RootState } from "StoreTypes";

export const useAddPlayersToLeagueRoster = (
  facilityId: string,
  leagueId: string,
  playerId: string
): [QueryState, RunMutation<EntitiesState, LeagueRosterEntryDto[]>] => {
  const [addPlayersToRosterQueryState, addPlayersToRoster] = useMutation(() =>
    makeAddPlayersToRosterMutation(facilityId, leagueId, playerId)
  );

  return [addPlayersToRosterQueryState, addPlayersToRoster];
};

export const useRemovePlayerFromLeagueRoster = (
  facilityId: string,
  leagueId: string,
  playerId: string
): [QueryState, RunMutation<EntitiesState, LeagueRosterEntryDto[]>] => {
  const [
    removePlayerFromRosterQueryState,
    removePlayerFromRoster,
  ] = useMutation(() =>
    makeRemovePlayersFromRosterMutation(facilityId, leagueId, playerId)
  );

  return [removePlayerFromRosterQueryState, removePlayerFromRoster];
};

export const useLeagueRosterByLeagueId = (
  facilityId: string,
  leagueId: string
): UseDataInfo<LeagueRosterEntryDto[]> => {
  const [queryState, refreshLeagueRoster] = useRequest(
    getRosterByLeagueId(facilityId, leagueId)
  );

  const leagueRoster = useSelector(
    (state: RootState): LeagueRosterEntryDto[] => {
      if (!state.entities.leagueRoster?.byLeagueId?.[leagueId]) return [];

      let leagueRoster: LeagueRosterEntryDto[] = Object.values<LeagueRosterEntryDto>(
        state.entities?.leagueRoster?.byLeagueId?.[leagueId]
      );

      return leagueRoster.sort(
        (a: LeagueRosterEntryDto, b: LeagueRosterEntryDto) =>
          a.firstName && b.firstName
            ? a.firstName > b.firstName
              ? 1
              : -1
            : a.userName > b.userName
            ? 1
            : -1
      );
    }
  );

  return [leagueRoster, queryState, refreshLeagueRoster];
};
