export const styles = {
  label: "text-sm text-gray-500 dark:text-gray-200 float-left py-1",
  checkbox:
    "text-gray-600 rounded focus:ring-0 ring-opacity-0 border-2 border-gray-500 text-xl mr-2 mb-1",
  createButton:
    "float-right bg-gray-600 hover:bg-green-600 text-white text-xs w-6 h-6 rounded shadow-md hover:animate-pulse",
  refreshButton:
    "float-right bg-gray-600 hover:bg-green-600 text-white text-xs w-6 h-6 rounded shadow-md hover:animate-pulse mr-1",
  dateContainer: "grid grid-cols-2 gap-2 pt-4",
  dateSpan: "text-md font-bold text-gray-600 dark:text-gray-200 pt-4",
  datePicker:
    "dark:text-black w-full border-2 border-gray-200 rounded disabled:cursor-not-allowed disabled:bg-gray-100 text-sm",
  formRowLabel: "text-md font-normal text-gray-800 dark:text-gray-200",
  inputClassName:
    "block w-full px-3 py-2 border-2 border-gray-200 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue-400 focus:border-blue-400 sm:text-sm bg-white valid:border-green-500 disabled:cursor-not-allowed disabled:bg-gray-100",
  overlay:
    "bg-gray-600 bg-opacity-30 px-5 fixed h-full w-full flex items-center justify-center top-0 left-0 z-9998",
  trayContainer:
    "fixed bg-gray-50 dark:bg-zinc-800 text-gray-500 drop-shadow-xl top-0 right-0 w-full max-w-xl h-screen z-9999 overflow-y-scroll text-sm border-l border-gray-400 dark:border-zinc-900",
  trayHeaderContainer:
    "h-full flex flex-col bg-gray-100  dark:bg-zinc-800  shadow-xl overflow-y-scroll border-t-4",
  trayHeader:
    "text-sm md:text-xl font-bold leading-loose pb-4 inline text-white",
  trayHeaderInnerContainer: "flex items-start justify-between",
  title: "text-lg font-semibold text-gray-800 dark:text-gray-200 py-5",
  button: "text-gray-50",
  helpDocText: "mt-6 relative flex-1 px-8 text-sm",
  headerContainer:
    "grid grid-cols-5 items-center w-full bg-gray-800 dark:bg-zinc-900 h-16 border-b border-white dark:border-zinc-900 px-4 md:px-8",
  headerCloseButton: "text-left pl-2 md:pl-0 align-baseline",
  headerTitle: "col-span-3 text-center",
  headerRightButton: "text-right align-baseline",
  innerContainer: "px-4 md:px-8",
  eventListItem:
    "my-2 bg-transparent dark:bg-zinc-800 dark:text-gray-200 hover:drop-shadow-lg hover:border-gray-300 border-0 hover:border-2 hover:cursor-pointer w-full",
  eventListItemHighlight:
    "my-2 bg-white dark:bg-zinc-800 dark:text-gray-200 hover:drop-shadow hover:border-gray-400 drop-shadow-sm rounded border border-gray-300 hover:cursor-pointer w-full",
  eventContainer: "px-4 py-4 sm:px-6",
  eventContainerTopRow: "flex items-center justify-between",
  eventContainerBottomRow: "ml-2 flex-shrink-0 flex",
  eventTime: "text-xs text-gray-800 font-semibold",
  eventName:
    "inline-flex text-xs leading-5 font-semibold text-gray-800 dark:text-gray-200",
  eventPlayers: "flex items-center text-xs text-gray-400",
  eventOpenItem: "mt-2 flex items-center text-xs text-gray-600 sm:mt-0 pr-1",
  eventSelectDayOptionContainer: "flex items-center mb-4",
  eventSelectDayOptionInnerContainer:
    "flex items-center rounded-md md:items-stretch w-full",
  eventSelectDayOptionLeftButton:
    "flex items-center justify-center rounded-l-md border border-r-0 py-1.5 pl-3 pr-4 focus:relative md:w-1/2 md:px-2 md:hover:bg-gray-50 text-xs",
  eventSelectDayOptionRightButton:
    "flex items-center justify-center rounded-r-md border border-l-0 py-1.5 pl-4 pr-3 focus:relative md:w-1/2 md:px-2 md:hover:bg-gray-50 text-xs",
  eventSelectDayOptionSpan: "relative -mx-px h-9 w-px bg-gray-300",
  queueActiveDay:
    "text-black dark:text-gray-200, bg-transparent  dark:bg-zinc-800 ",
  queueInActiveDay: "text-gray-400 dark:text-gray-400 bg-gray-50",
  queuePreviewRightColumn: "text-right text-gray-600 py-1 capitalize",
  queuePreviewLeftColumn: "text-left text-gray-900 font-bold",
  eventRoundListItem:
    "font-semibold text-md text-gray-600 dark:text-gray-200 p-2 mb-1 rounded cursor-pointer",
  eventRoundListItemErrors:
    "bg-red-500 border-2 border-red-500 text-red-500 font-bold",
  eventRoundListItemIcon:
    "font-semibold text-lg text-gray-600 dark:text-gray-200 w-auto text-right",
  invalidInstallIdContainer: [
    "absolute h-full w-full text-white font-semibold flex flex-col justify-center items-center rounded top-0 left-0",
  ].join(" "),
  invalidInstallIdOverlay: [
    "absolute h-full w-full bg-gray-700 opacity-75 backdrop-blur-md rounded top-0 left-0 z-999 ",
  ].join(" "),
  invalidInstallIdContent: ["z-999 text-center text-shadow p-8 text-lg"].join(
    " "
  ),
  invalidInstallIdIcon: [
    "fas fa-exclamation-triangle block text-red-500 text-3xl text-md",
  ].join(" "),
  invalidInstallIdButton: [
    "bg-brandGreen poppins border-2 rounded px-4 py-2 mt-4 border-gray-400 opacity-100 text-sm shadow-md",
  ].join(" "),
};
