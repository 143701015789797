import { AppQueryConfig } from "QueryTypes";
import { reduxQueryDefaultOptions } from "utils/queries/queryHelpers";

const urls = {
  updateAgUserHandicap(userId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/users/${userId}/ag-handicap`;
  },
};

export const makeAgHandicapByUserIdMutation = (
  userId: string,
  body: string
): AppQueryConfig => ({
  url: urls.updateAgUserHandicap(userId),
  queryKey: `updateAgUserHandicap:${userId}`,
  options: { ...reduxQueryDefaultOptions(), method: "POST" },
  body,
  transform: (responseBody: any) => {
    return responseBody;
  },
});
