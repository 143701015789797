import { FaRedo } from "react-icons/fa";
import { styles } from "./Events.styles";

const ReloadEventsButton: React.FC<ReloadEventsButtonProps> = (props) => {
  const { callback } = props;

  return (
    <>
      <button onClick={callback} className={`${styles.refreshButton}`}>
        <span className="sr-only">Refresh Events Button</span>
        <FaRedo />
      </button>
    </>
  );
};
export default ReloadEventsButton;

type ReloadEventsButtonProps = {
  showIconOnly?: boolean;
  callback?: any;
};
