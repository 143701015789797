import { IconType } from "react-icons";
import { createElement } from "react";

const ButtonWithIcon: React.FC<ButtonWithIconType> = (props) => {
  const {
    buttonType,
    colorCSSClassnames,
    fullWidth,
    text,
    showIcon,
    icon,
    disabled,
  } = props;

  return (
    <>
      <button
        type={buttonType}
        className={`poppins items-center px-3 py-3 border border-transparent shadow-md text-sm leading-4 rounded-lg text-white  ${colorCSSClassnames} ${
          fullWidth ? "w-full text-center" : "inline-flex"
        } disabled:cursor-not-allowed disabled:opacity-50`}
        disabled={disabled}
      >
        {text}
        {showIcon && icon ? createElement(icon) : ""}
      </button>
    </>
  );
};

export default ButtonWithIcon;

type ButtonWithIconType = {
  showIcon?: boolean;
  icon?: IconType;
  text?: string;
  buttonType: "button" | "submit" | "reset" | undefined;
  status?: string;
  colorCSSClassnames?: string;
  group?: boolean;
  groupAlign?: string;
  fullWidth?: boolean;
  disabled?: boolean;
};

ButtonWithIcon.defaultProps = {
  showIcon: false,
  text: "",
  buttonType: "button",
  status: "",
  colorCSSClassnames:
    "bg-brandGreen hover:bg-brandYellow hover:text-black hover:animate-pulse mt-4",
  group: false,
  groupAlign: "",
  fullWidth: false,
  disabled: false,
};
