import { useEffect, useState } from "react";

export const containerMotionVariants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
  hiddenY: {
    x: -1000,
  },
  showY: {
    x: 0,
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.1,
    },
  },
};

export const containerChildMotionVariants = {
  hidden: {
    scale: 0,
    opacity: 0,
  },
  show: {
    scale: 1,
    opacity: 1,
    transition: { type: "tween", ease: "easeInOut" },
  },
  hiddenY: {
    x: -2000,
  },
  showY: {
    x: 0,
    show: { duration: 0.1 },
  },
  hover: {
    scale: 0.95,
  },
  pressed: {
    scale: 0.9,
    opacity: 1,
    backgroundColor: "#ff3300",
  },
  rest: {
    scale: 1,
    opacity: 1,
  },
  exit: {
    backgroundColor: "#ff3300",
    x: 500,
    transition: { type: "tween", duration: 1 },
  },
};

export const buttonMotionVariants = {
  hover: {
    scale: 1.05,
  },
  pressed: {
    scale: 0.9,
  },
  rest: {
    scale: 1,
  },
  tap: {
    scale: 0.9,
  },
};

export const modalMotionVariants = {
  hidden: {
    y: "-1000vh",
    opacity: 0,
  },
  visible: {
    y: "0px",
    opacity: 1,
    transition: {
      delay: 0.25,
      type: "easeIn",
    },
  },
};

export const backgroundMotionVariants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

export const trayVriants = {
  show: {
    x: 0,
  },
  hide: {
    x: 1000,
  },
};

export const trayBodyStyles = {
  active: {
    x: 0,
  },
  inactive: {
    x: -100,
  },
};

export const trayMenuHandlerStyles = {
  active: {
    x: 0,
    color: "#000",
  },
  inactive: {
    x: 60,
    color: "#fff",
  },
};

export const trayListItem = {
  hidden: { x: 1000 },
  show: {
    x: 0,
  },
  hover: { scale: 1.05 },
};

export const dailyQueueContainer = {
  hidden: { opacity: 1 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.3,
      ease: "easeInOut",
    },
  },
};

export const dailyQueueListItemVariant = {
  hidden: { opacity: 0, x: 1000 },
  show: {
    opacity: 1,
    x: 0,
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.3,
      ease: "easeInOut",
    },
  },
};

export const listItemTopDownShow = {
  hidden: { opacity: 0, y: -100 },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.3,
      ease: "easeInOut",
    },
  },
};

export const subMenuAnimate = {
  enter: {
    opacity: 1,
    rotateX: 0,
    transition: {
      duration: 0.25,
    },
    display: "block",
  },
  exit: {
    opacity: 0,
    rotateX: -15,
    transition: {
      duration: 0.25,
      delay: 0.1,
    },
    transitionEnd: {
      display: "none",
    },
  },
};

export const desktopSidebarVariants = {
  open: { width: "256px", height: "100vh" },
  closed: { width: "96px", height: "100vh" },
};

export const desktopSidebarVariantsClosed = {
  open: { width: "0px", height: "0px" },
  closed: { width: "0px", height: "0px" },
};

export const desktopReservationSidebarVariants = {
  open: {
    left: "0px",
    opacity: 1,
    transition: {
      duration: 1.25,
      delay: 0.1,
    },
  },
  closed: {
    right: "0px",
    opacity: 1,
    transition: {
      duration: 0.25,
      delay: 0.1,
    },
  },
};

export const dropInVariant = {
  hidden: {
    y: "-100vh",
    opacity: 0,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: "100vh",
    opacity: 0,
  },
};

export const leagueModalVariant = {
  hidden: {
    x: "0",
  },
  visible: {
    x: "0",
    height: "100%",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    x: "0",
    opacity: 0,
  },
};

export function useMediaQuery(query: string) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [matches, query]);

  return matches;
}

export enum BreakPoints {
  SM = "(min-width: 640px)",
  MD = "(min-width: 768px)",
  LG = "(min-width: 1024px)",
  XL = "(min-width: 1280px)",
  XXL = "(min-width: 1536px)",
}
