import {
  getEventsByFacilityId,
  getGroupsByFacilityId,
  makeCreateGroupMutation,
  makeDeleteEventMutation,
  makeUpdateEventMutation,
} from "modules/events/eventQueries";
import { UseDataInfo } from "QueryTypes";
import { useSelector } from "react-redux";
import { RunMutation, useMutation, useRequest } from "redux-query-react";
import { EntitiesState, RootState } from "StoreTypes";
import { CreateGroupPayload, Group, Event } from "Models";
import { makeCreateEventMutation } from "modules/events/eventQueries";
import { QueryState } from "redux-query";
import FormData from "form-data";

export const useEventsByFacilityId = (
  facilityId: string
): UseDataInfo<Event[]> => {
  const [queryState, refreshEvents] = useRequest(
    getEventsByFacilityId(facilityId)
  );

  const events = useSelector((state: RootState): Event[] => {
    if (!state.entities.events?.byFacilityId?.[facilityId]) return [];

    let events: Event[] = Object.values<Event>(
      state.entities?.events?.byFacilityId?.[facilityId]
    );

    return events.sort(
      (a: Event, b: Event) =>
        new Date(b.startDate).valueOf() - new Date(a.startDate).valueOf()
    );
  });

  return [events, queryState, refreshEvents];
};

export const useCreateEvent = (
  facilityId: string
): [QueryState, RunMutation<EntitiesState, [FormData]>] => {
  const [createEventQueryState, createEvent] = useMutation((body: FormData) =>
    makeCreateEventMutation(facilityId, body)
  );

  return [createEventQueryState, createEvent];
};

export const useCreateGroup = (
  facilityId: string
): [QueryState, RunMutation<EntitiesState, [CreateGroupPayload]>] => {
  const [
    createGroupQueryState,
    createGroup,
  ] = useMutation((body: CreateGroupPayload) =>
    makeCreateGroupMutation(facilityId, body)
  );

  return [createGroupQueryState, createGroup];
};

export const useDeleteEvents = (
  eventId: number,
  facilityId: string
): [QueryState, RunMutation<EntitiesState>] => {
  const [queryState, deleteEvent] = useMutation(() =>
    makeDeleteEventMutation(eventId, facilityId)
  );
  return [queryState, deleteEvent];
};

export const useUpdateEvent = (
  eventId: number,
  facilityId: string
): [QueryState, RunMutation<EntitiesState, [FormData]>] => {
  const [queryState, updateEvent] = useMutation((body: FormData) =>
    makeUpdateEventMutation(eventId, facilityId, body)
  );

  return [queryState, updateEvent];
};

export const useGroupsByFacilityId = (
  facilityId: string
): UseDataInfo<Group[]> => {
  const [queryState, refreshGroups] = useRequest(
    getGroupsByFacilityId(facilityId)
  );

  const events = useSelector((state: RootState): Group[] => {
    if (!state.entities.groups?.byFacilityId?.[facilityId]) return [];

    let groups: Group[] = Object.values<Group>(
      state.entities?.groups?.byFacilityId?.[facilityId]
    );

    return groups;
  });

  return [events, queryState, refreshGroups];
};

export const useSelectedEvent = (facilityId: string) => {
  const selectedEvent = useSelector(
    (state: RootState): Event => {
      let selectedEventId = state.event?.selectedEventId;
      return state.entities?.events?.byFacilityId?.[facilityId]?.[
        selectedEventId
      ];
    }
  );

  return selectedEvent;
};
