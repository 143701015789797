import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { isModalOpen } from "utils/modals/modalHelpers";
import { useAppDispatch } from "utils/hooks";
import { modalAction } from "ui-modules/modals/modalSlice";
import { styles } from "../../shared/control/SimTimerControls.styles";
import { globalStyles } from "components/globalStyles";
import { DateTime, Interval } from "luxon";
import { addMinutesToTimerArray, isNegative } from "utils/timer/timerHelpers";
import { sendTimerToSimulator } from "modules/simulator";
import { TimerAction, TimerStatus } from "types/enums";
import {
  showResponseNotification,
  notificationMessageGroup,
} from "utils/notifications/notificationHelpers";
import {
  pendoTrackBasicEvent,
  ClubhouseEvents,
} from "utils/pendo/pendoHelpers";
import { SimulatorState } from "Models";
import DatePicker from "react-datepicker";
import { updateEntities } from "redux-query";
import { MdClose, MdOutlineTimer } from "react-icons/md";

const AddTimeInnerModal: React.FC<AddTimeInnerModalProps> = (props) => {
  const dispatch = useAppDispatch();
  const { simControlModalName, simulatorState } = props;

  const simulatorId = simulatorState ? simulatorState.id : "";

  const [minutes, storeMinutes] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [datePickerTime, setDatePickerTime] = useState(new Date());

  const setModalClose = () =>
    dispatch(modalAction({ isOpen: false, modalName: simControlModalName }));

  const handleMinuteChange = (event: any) => {
    storeMinutes(event.target.value < 0 ? 0 : event.target.value);
  };

  const handleTimePickerChange = (e: Date) => {
    if (e) {
      const i = Interval.fromDateTimes(DateTime.now(), DateTime.fromJSDate(e));
      storeMinutes(Math.ceil(i.length("minutes")));
      setDatePickerTime(e);
    }
  };

  const handleAddToTimer = async () => {
    const endTime = calculateEndTime();
    if (isNegative(endTime)) {
      setErrorMessage(
        "The timer is currently set to a time after this. Please clear the timer first and then set a new end time."
      );
      return;
    }

    const response = sendTimerToSimulator(
      TimerAction[TimerAction.ADD_TIME],
      simulatorId,
      endTime.toString()
    );
    await showResponseNotification(response, notificationMessageGroup.addTime);
    await updateReduxTimerValues(TimerStatus.RUNNING, true);

    storeMinutes(0);
    setErrorMessage("");
    pendoTrackBasicEvent(ClubhouseEvents.ADD_TIME, simulatorId);

    const unpause = sendTimerToSimulator(
      TimerAction[TimerAction.UNPAUSE],
      simulatorId,
      undefined,
      []
    );
    await showResponseNotification(
      unpause,
      notificationMessageGroup.unpauseTimer
    );

    setModalClose();
  };

  const calculateEndTime = () => {
    const currentTimerMinutes = simulatorState.timerValue[1];

    return endTime
      ? Math.ceil(
          Interval.fromDateTimes(DateTime.now(), datePickerTime).length(
            "minutes"
          ) - currentTimerMinutes
        )
      : minutes;
  };

  const updateReduxTimerValues = async (
    status: TimerStatus,
    updateTimer?: boolean
  ) => {
    dispatch(
      updateEntities({
        simulatorStates: (prev) => ({
          ...prev,
          ...{
            [simulatorId]: {
              ...prev[simulatorId],
              ...{
                timerStatus: status,
                timerValue: updateTimer
                  ? addMinutesToTimerArray(
                      prev[simulatorId]?.timerValue,
                      calculateEndTime()
                    )
                  : prev[simulatorId]?.timerValue,
              },
            },
          },
        }),
      })
    );
  };

  return (
    <>
      <AnimatePresence>
        {isModalOpen(simControlModalName) && (
          <motion.div
            className="z-9999 h-full w-full rounded-xl absolute bg-white dark:bg-zinc-800 shadow-lg"
            initial={{ width: 0 }}
            animate={{ width: "100%" }}
            exit={{
              width: 0,
              opacity: 0.7,
              transition: { delay: 0.15, duration: 0.3 },
            }}
            key={`addTimeInnerModal-${simControlModalName}`}
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key={`addTimeInnerModalHeader-${simControlModalName}`}
            >
              <div className="relative flex flex-col w-full items-center overflow-hidden">
                <div className="relative flex flex-row w-full items-end justify-end mr-2 mt-2 overflow-hidden h-6 pt-1">
                  <button
                    onClick={() => setModalClose()}
                    className="z-10 flex gap-2 font-bold top-1 w-6 h-6 right-1"
                  >
                    <MdClose className="w-6 h-6 text-black dark:text-white" />
                  </button>
                </div>
                <nav className="flex items-center w-full justify-start h-10 ml-4 p-1 ">
                  <div
                    className={`
                      ${
                        styles.listItem +
                        (endTime === 1
                          ? styles.inactiveTab
                          : styles.itemSelected)
                      } `}
                  >
                    <button
                      onClick={(e) => {
                        setEndTime(0);
                      }}
                    >
                      <MdOutlineTimer className="size-4 mr-2" />
                      <span className="text-xs">Add Time</span>
                    </button>
                  </div>
                  <div
                    className={`
                      ${
                        styles.listItem +
                        (endTime === 0
                          ? styles.inactiveTab
                          : styles.itemSelected)
                      }`}
                  >
                    <button
                      onClick={(e) => {
                        setEndTime(1);
                      }}
                    >
                      <MdOutlineTimer className="size-4 mr-2" />
                      <span className="text-xs">Set End Time</span>
                    </button>
                  </div>
                </nav>
              </div>

              <div className="flex flex-col px-4 pt-2 pb-4">
                {!endTime ? (
                  <div id="link1" className={styles.timerInnerContainers}>
                    <input
                      name="minutes"
                      type="number"
                      placeholder="Enter Minutes"
                      pattern="[0-9]"
                      min="0"
                      className="border border-gray-300 rounded dark:text-black w-full text-sm"
                      onChange={(e) => handleMinuteChange(e)}
                      value={minutes}
                      autoFocus
                    />
                    <br />
                    <div className="text-xs pt-2">
                      Enter a number above to add minutes to the timer
                    </div>
                  </div>
                ) : (
                  <div id="link2" className={styles.timerInnerContainers}>
                    <DatePicker
                      onChange={(date: Date) => handleTimePickerChange(date)}
                      selected={datePickerTime}
                      showTimeSelect
                      showTimeSelectOnly
                      minTime={new Date()}
                      maxTime={DateTime.now().endOf("day").toJSDate()}
                      timeIntervals={5}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      className={styles.datePicker}
                    />
                    <br />
                    <div className="text-xs text-gray-500 pt-2 dark:text-white">
                      Click, then choose an end time from the dropdown box above
                    </div>
                  </div>
                )}
              </div>
              <div className="flex flex-row px-4">
                <div className="flex flex-auto h-8 content-end text-right justify-end">
                  <button
                    disabled={!minutes}
                    onClick={() => handleAddToTimer()}
                    className={
                      globalStyles.primaryButton + globalStyles.buttonLarge
                    }
                  >
                    {!endTime ? "Add time" : "Set end time"}
                  </button>
                </div>
              </div>
              <div className="text-red-600 p-2 w-full text-sm">
                {errorMessage}
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
export default AddTimeInnerModal;

type AddTimeInnerModalProps = {
  simControlModalName: string;
  simulatorState: SimulatorState;
  listView: boolean;
};
