import { useEffect } from "react";
import { EntitiesState, RootState } from "StoreTypes";
import { useSelector } from "react-redux";
import { getCurrentFacilityId } from "modules/facility/facilitySelectors";
import { StartRoundPayload } from "Models";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useAddRoundConfiguration } from "modules/round-configuration/roundConfigurationHooks";
import { styles } from "./RoundConfigurationManagement.styles";
import Row from "components/ui/layout/Row";
import { getFacilityDefaultRoundSettings } from "modules/default-round-settings";
import {
  showCustomNotification,
  GenericNotificationType,
} from "utils/notifications/notificationHelpers";
import { useAppDispatch } from "utils/hooks";
import { helpDocsAction, roundSettingsAction } from "ui-modules/nav/navSlice";
import RoundSpecificForm from "components/control/round-control/round-configuration-form/RoundSpecificForm";
import { RoundType } from "types/enums";
import { ActionPromise } from "redux-query-react";
import {
  isApiStatusSuccess,
  getApiResponseErrorMessage,
} from "utils/api/apiHelper";
import {
  pendoTrackEventWithProperties,
  ClubhouseEvents,
} from "utils/pendo/pendoHelpers";
import { useToasterStore } from "react-hot-toast";
import { CgSpinner } from "react-icons/cg";

const CreateRoundConfiguration: React.FC = () => {
  const toastIsActive = useToasterStore().toasts.length > 0 ? true : false;
  const dispatch = useAppDispatch();
  const methods = useForm();
  const isHelpDocsTrayOpen = useSelector(
    (state: RootState) => state.nav?.helpDocs?.isOpen
  );

  const currentFacilityId = useSelector((state: RootState) =>
    getCurrentFacilityId(state)
  );

  const currentFacilityDefaultRoundSettings = useSelector((state: RootState) =>
    getFacilityDefaultRoundSettings(state, currentFacilityId)
  );

  const [
    roundConfigQueryState,
    addRoundConfiguration,
  ] = useAddRoundConfiguration(currentFacilityId);

  const setHelpDocsTray = () =>
    dispatch(helpDocsAction({ isOpen: !isHelpDocsTrayOpen }));

  function handleResponseCallback(roundConfig: StartRoundPayload) {
    pendoTrackEventWithProperties(
      ClubhouseEvents.CREATE_ROUND_CONFIG,
      roundConfig
    );
    dispatch(roundSettingsAction({ isOpen: false }));
    if (!toastIsActive)
      showCustomNotification(`Round has been created successfully`);
  }

  const useOnSubmit = (data: StartRoundPayload) => {
    let response = {} as ActionPromise<EntitiesState> | undefined;
    response = addRoundConfiguration(data);

    response?.then((response) => {
      if (isApiStatusSuccess(response?.status)) {
        handleResponseCallback(data);
      } else {
        if (!toastIsActive) {
          showCustomNotification(
            getApiResponseErrorMessage(response),
            GenericNotificationType.ERROR
          );
        }
      }
    });
  };

  useEffect(() => {
    if (!methods.watch("roundType")) {
      methods.setValue("roundType", RoundType.ROUND);
    }
  }, [methods]);

  useEffect(() => {
    let errors = methods.formState.errors;
    const firstError = (Object.keys(errors) as Array<
      keyof typeof errors
    >).reduce<keyof typeof errors | null>((field, a) => {
      const fieldKey = field as keyof typeof errors;
      return !!errors[fieldKey] ? fieldKey : a;
    }, null);

    if (firstError) {
      methods.setFocus(firstError);
    }
  }, [methods, methods.formState.errors]);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(useOnSubmit)}
        className="px-8 md:px-0 pt-3"
      >
        <Row>
          <div className="w-full block text-lg md:text-md font-semibold pl-0 md:pl-2 dark:text-gray-50">
            Create New Saved Round Setup
          </div>
        </Row>
        <Row cssClasses="pl-0 md:pl-2">
          <div className="block w-full text-sm dark:text-gray-50">
            Create a new Saved Round Settings and then use that to quickly send
            that round to a sim on demand.
          </div>
        </Row>
        <Row cssClasses={`${styles.row} pl-0 md:pl-2 pt-4`}>
          <button
            onClick={() => setHelpDocsTray()}
            className="text-red-700 text-sm font-semibold block text-left"
          >
            Need Help?
          </button>
        </Row>
        <Row cssClasses={styles.row}>
          <Row cssClasses={styles.headerColumn}>Round Setup Name</Row>
          <Row cssClasses={styles.fieldColumn}>
            <Row>
              <Controller
                name="name"
                control={methods.control}
                rules={{
                  required: "Please name this round setting",
                  pattern: {
                    value: /^[0-9a-zA-Z ]*$/,
                    message:
                      "The input cannot contain punctuation or special characters",
                  },
                }}
                render={({ field }) => (
                  <input
                    {...field}
                    className={
                      methods.formState.errors.name
                        ? styles.inputError
                        : styles.input
                    }
                    placeholder="Enter a name"
                  />
                )}
              />
              <ErrorMessage
                errors={methods.formState.errors}
                name="name"
                render={({ message }) => (
                  <div className="text-sm text-red-500 font-semibold">
                    {message}
                  </div>
                )}
              />
            </Row>
          </Row>
        </Row>
        <Row cssClasses={styles.row}>
          <Row cssClasses={styles.headerColumn}>
            <Row>Round Settings</Row>
          </Row>
          <Row cssClasses={styles.fieldColumn}>
            <Row>
              <RoundSpecificForm
                defaultRoundSettings={currentFacilityDefaultRoundSettings}
              />
            </Row>
          </Row>
        </Row>
        <Row cssClasses={styles.row}>
          <Row cssClasses={styles.headerColumn}>
            <Row></Row>
          </Row>
          <Row cssClasses={styles.fieldColumn}>
            <button type="submit" className={styles.submit} tabIndex={4}>
              <span className="inline font-semibold">
                {roundConfigQueryState?.isPending ? (
                  <>
                    <CgSpinner className="size-4 animate-spin mr-2" />
                    Saving Round Settings...
                  </>
                ) : (
                  "Save Round Settings"
                )}
              </span>
            </button>
          </Row>
        </Row>
      </form>
    </FormProvider>
  );
};
export default CreateRoundConfiguration;
