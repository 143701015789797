export const styles = {
  overlay:
    "bg-gray-600 bg-opacity-30 px-5 fixed h-full w-full flex items-center justify-center top-0 left-0 z-9998",
  trayContainer:
    "fixed bg-gray-50 dark:bg-zinc-800 brand-dark-gray  drop-shadow-xl top-0 right-0 w-full max-w-xl h-screen z-9999 overflow-y-scroll text-sm border-l border-gray-400 dark:border-gray-800",
  trayHeaderContainer:
    "h-full flex flex-col bg-gray-100  dark:bg-zinc-800  shadow-xl overflow-y-scroll border-t-4",
  trayHeader: "text-xl font-bold leading-loose pb-4 inline dark:text-gray-200",
  trayHeaderInnerContainer: "flex items-start justify-between",
  title: "text-lg font-semibold brand-dark-gray dark:text-gray-200",
  button: "text-white bg-brandGreen rounded text-xs px-2 py-1 poppins",
  helpDocText: "mt-6 relative flex-1 px-8 text-sm",
  headerContainer:
    "grid grid-cols-5 items-center w-full h-16 border-b border-gray-300 dark:border-gray-800 bg-white  dark:bg-zinc-800  shadow-sm px-4 md:px-8",
  headerCloseButton: "dark:text-gray-200 text-xs px-2 py-1 poppins text-white",
  headerTitle: "col-span-3 text-center ",
  headerRightButton: "text-right align-baseline",
  innerContainer: "px-4 md:px-8",
};
