import toast from "react-hot-toast";

export type ResponseMessageType = {
  error: string;
  sent: string;
  inProgress: string;
};

export enum GenericNotificationType {
  SUCCESS = "success",
  ERROR = "error",
}

export const notificationMessageGroup = {
  message: {
    inProgress: "Sending message...",
    sent: "Your message has been sent",
    error: "An error has occurred. Please try your request again.",
  },
  endRound: {
    inProgress: "Ending the current round...",
    sent: "Round has been ended",
    error: "An error occurred when trying to end the round. Please try again.",
  },
  lockSim: {
    inProgress: "Sending lock sim command...",
    sent: "Sim has been locked",
    error: "An error occurred when trying to lock the round. Please try again.",
  },
  unlockSim: {
    inProgress: "Sending unlock sim command...",
    sent: "Sim has been unlocked",
    error: "An error occurred when trying to unlock the sim. Please try again.",
  },
  shutdown: {
    inProgress: "Sending shutdown command...",
    sent: "Sim is now shutting down.",
    error:
      "An error occurred when trying to turn off the sim. Please try again.",
  },
  restart: {
    inProgress: "Sending restart sim command...",
    sent: "Sim is now being restarted.",
    error:
      "An error occurred when trying to restart the sim. Please try again.",
  },
  pauseTimer: {
    inProgress: "Pausing timer in progress...",
    sent: "Timer has been paused",
    error: "An error has occurred. Please try again.",
  },
  unpauseTimer: {
    inProgress: "Unpausing timer in progress...",
    sent: "Timer has been unpaused",
    error: "An error has occurred. Please try again.",
  },
  clearTimer: {
    inProgress: "Clear timer in progress...",
    sent: "Timer has been cleared",
    error: "An error has occurred. Please try again.",
  },
  addTime: {
    inProgress: "Adding time in progress...",
    sent: "Time has been added",
    error: "An error has occurred. Please try again.",
  },
};

export const showResponseNotification = async (
  response: Promise<void>,
  messageGroup: ResponseMessageType
) => {
  toast.promise(
    response,
    {
      loading: messageGroup.inProgress,
      success: messageGroup.sent,
      error: (err) => `${messageGroup.error}: ${err.toString()}`,
    },
    {
      duration: 4000,
      position: "bottom-left",
    }
  );
};

export const showCustomNotification = (
  message: string,
  notificationType: string = GenericNotificationType.SUCCESS,
  notificationIcon?: string
) => {
  notificationType === GenericNotificationType.SUCCESS
    ? toast.success(message, {
        icon: notificationIcon,
        position: "bottom-left",
        duration: 4000,
      })
    : toast.error(message, {
        icon: notificationIcon,
        position: "bottom-left",
        duration: 4000,
      });
};
