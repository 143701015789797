import "assets/css/spinnerAnimation.css";

type OwnProps = {
  class?: string;
};

const TodayIcon: React.FC<OwnProps> = (props) => {
  return (
    <svg
      className={`${props.class} fill-current`}
      fill-current={props.class}
      width="15"
      height="16"
      viewBox="0 0 15 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15 2.73684C15 1.22532 13.7874 0 12.2917 0H2.70833C1.21256 0 0 1.22532 0 2.73684V12.4211C0 13.9325 1.21256 15.1579 2.70833 15.1579H6.04167C6.04167 15.1579 6.04167 14.7523 6.04167 14.5263V13.8947H2.70833C1.90292 13.8947 1.25 13.2349 1.25 12.4211V4.63158H13.75V12.4211C13.75 13.2349 13.0971 13.8947 12.2917 13.8947H8.95833V14.5263C8.95833 14.7523 8.95833 15.1579 8.95833 15.1579H12.2917C13.7874 15.1579 15 13.9325 15 12.4211V2.73684ZM2.70833 1.26316H12.2917C13.0971 1.26316 13.75 1.92295 13.75 2.73684V3.36842H1.25V2.73684C1.25 1.92295 1.90292 1.26316 2.70833 1.26316ZM8.54167 7.36842C8.54167 7.94981 8.07533 8.42105 7.5 8.42105C6.92467 8.42105 6.45833 7.94981 6.45833 7.36842C6.45833 6.78703 6.92467 6.31579 7.5 6.31579C8.07533 6.31579 8.54167 6.78703 8.54167 7.36842ZM8.95975 12.8931C9.21775 13.1248 9.61275 13.1014 9.84208 12.8407C10.0714 12.58 10.0482 12.1807 9.79017 11.949L7.91517 10.2648C7.67842 10.0521 7.3215 10.0521 7.08475 10.2648L5.20972 11.949C4.95174 12.1807 4.9285 12.58 5.15782 12.8407C5.38715 13.1014 5.78219 13.1248 6.04017 12.8931L6.875 12.1432V15.3684C6.875 15.7172 7.15475 16 7.5 16C7.84517 16 8.125 15.7172 8.125 15.3684V12.1432L8.95975 12.8931Z" />
    </svg>
  );
};
export default TodayIcon;

TodayIcon.defaultProps = {
  class: "text-zinc-800 dark:text-white",
};
