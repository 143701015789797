import { League } from "Models";
import { DateTime } from "luxon";
import { TbGolf } from "react-icons/tb";
import { FiEdit2 } from "react-icons/fi";
import { styles } from "./Leagues.styes";
import { MdSportsGolf } from "react-icons/md";
import { titleCase } from "utils/enums/enumHelper";
import { LeagueScoring, LeagueType } from "types/enums";
import { useAppDispatch } from "utils/hooks";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import { IoIosArrowRoundForward } from "react-icons/io";
import { useFlags } from "launchdarkly-react-client-sdk";
import { modalAction } from "ui-modules/modals/modalSlice";
import { ModalType } from "utils/modals/modalHelpers";
import { setSelectedLeagueId } from "modules/leagues/leagueSlice";
import { leagueTypeColorHelper } from "modules/leagues/leagueHelpers";
import { containerChildMotionVariants } from "utils/animations/animationHelper";
import { BsPerson } from "react-icons/bs";
import {
  ClubhouseEvents,
  pendoTrackBasicEvent,
} from "utils/pendo/pendoHelpers";
import ReactTooltip from "react-tooltip";
import ReservationsIcon from "components/svg/ReservationsIcon";

const LeagueCard: React.FC<LeagueCardProps> = (props) => {
  const dispatch = useAppDispatch();
  const navigate = useHistory();

  const { league } = props;
  const { leagueScoring } = useFlags();

  const handleEditLeagueClick = (leagueId: string) => {
    dispatch(
      setSelectedLeagueId({
        selectedLeagueId: leagueId,
      })
    );
    dispatch(
      modalAction({ isOpen: true, modalName: ModalType.LeaugeCreateEdit })
    );
  };

  const handleViewLeaderboardClick = (league: League) => {
    dispatch(
      setSelectedLeagueId({
        selectedLeagueId: league?.id,
      })
    );
    dispatch(
      modalAction({ isOpen: true, modalName: ModalType.LeagueLeaderboard })
    );
    pendoTrackBasicEvent(ClubhouseEvents.LEAGUE_VIEW_LEADERBOARD);
  };

  const handleViewLeagueRosterClick = useCallback(
    async (leagueId: string) => {
      await dispatch(setSelectedLeagueId({ selectedLeagueId: leagueId }));
      await dispatch(
        modalAction({ isOpen: true, modalName: ModalType.LeagueRoster })
      );
      pendoTrackBasicEvent(ClubhouseEvents.LEAGUE_VIEW_ROSTER);
    },
    [dispatch]
  );

  const handleViewRoundsClick = async (leagueId: string) => {
    await dispatch(
      setSelectedLeagueId({
        selectedLeagueId: leagueId,
      })
    );
    pendoTrackBasicEvent(ClubhouseEvents.LEAGUE_VIEW_ROUNDS);
    navigate.push("/leagues/rounds");
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <motion.div
        variants={containerChildMotionVariants}
        key={`league-${league.name}`}
        className={`${styles.leagueCard} ${LeagueType[
          league.settings.leagueType
        ].toLowerCase()} ${LeagueScoring[
          league.settings.leagueScoringType
        ].toLowerCase()} hover:shadow-lg hover:rounded-xl leagueCard`}
      >
        <div>
          <div className={styles.leagueCardHeaderRow}>
            <div className="w-full h-8">
              <div className={styles.leagueTitle}>{league.name}</div>
            </div>
            <div
              className={`${styles.leagueEditDeleteContainer} leagueCardEditDelete`}
            >
              <button
                type="button"
                className={`${styles.leagueEditButton}`}
                data-for={`edit-${league.name}`}
                data-tip={`Edit ${league.name}`}
                onClick={() => handleEditLeagueClick(league.id)}
              >
                <FiEdit2 className="size-4 mt-2" />
              </button>
            </div>
          </div>
          <div className={styles.leagueCardHeaderRow}>
            <small
              className={`${
                leagueTypeColorHelper[league.settings.leagueType]
              } rounded-2xl px-2 py-0.5 poppins h-5 dark:text-black dark:bg-opacity-50`}
            >
              {LeagueType[league.settings.leagueType]}
            </small>
            {league.numberOfRounds > 0 && (
              <small className={`ml-0.5 rounded-md px-0.5 py-0.5 poppins h-5`}>
                <TbGolf className="h-4 w-4 mr-0.5" />
                {league.numberOfRounds} Rounds
              </small>
            )}
            {league.participantCount > 0 && (
              <small className={`ml-0.5 rounded-md px-0.5 py-0.5 poppins`}>
                <BsPerson className="h-4 w-4 mr-0.5" />
                {league.participantCount}{" "}
                {league.settings.leagueType === LeagueType.TEAM
                  ? "Teams"
                  : "Players"}
              </small>
            )}
            {league.settings?.leagueScoringType && (
              <small
                className={`ml-0.5 rounded-md px-0.5 py-0.5 poppins block`}
              >
                <MdSportsGolf className="h-4 w-4 mr-0.5" />
                {titleCase(
                  LeagueScoring[league?.settings.leagueScoringType]
                ).replace(" Play", "")}
              </small>
            )}
          </div>
          <div className={styles.leagueCardDateRow}>
            <div className={styles.leagueCardDateCol}>
              <div className="w-6 mr-1">
                <ReservationsIcon class="size-4" />
              </div>
              <div className="w-full">
                <span className="font-normal brand-text text-xs">
                  Start Date
                </span>
                <br />
                <small>(Earliest Round)</small>
              </div>
            </div>
            <div className={styles.leagueCardDateCol}>
              <div className="w-6 mr-1">
                <ReservationsIcon class="size-4" />
              </div>
              <div className="w-full greyText dark:text-gray-50">
                <span className="font-normal brand-text text-xs">End Date</span>
                <br />
                <small>(Latest Round)</small>
              </div>
            </div>
          </div>
          <div className={styles.leagueCardDateRow}>
            <div className="pl-6 w-1/2 text-xs poppins">
              {DateTime.fromISO(
                league.leagueStartDate.toString()
              ).toLocaleString(DateTime.DATE_MED)}
            </div>
            <div className="pl-6 w-1/2 text-xs poppins">
              {DateTime.fromISO(league.leagueEndDate.toString()).toLocaleString(
                DateTime.DATE_MED
              )}
            </div>
          </div>
          <div
            className={`${styles.leagueCardRow} border-t border-b border-gray-200 dark:border-zinc-900`}
          >
            <div className="h-20 overflow-hidden text-ellipsis brand-text">
              {league.description}
            </div>
          </div>
          <div className={styles.leagueCardRow}>
            <button
              onClick={() => handleViewRoundsClick(league?.id)}
              className={styles.leagueViewRoundsButton}
            >
              View Rounds{" "}
              <IoIosArrowRoundForward className="size-5 items-center" />
            </button>
          </div>
          <div className={styles.leagueCardFooterRow}>
            <div className="w-1/2 mr-1">
              <button
                type="button"
                className={styles.leagueOtherButton}
                onClick={() => handleViewLeagueRosterClick(league?.id)}
              >
                Roster
              </button>
            </div>
            <div className="w-1/2 ml-1 text-xs">
              {leagueScoring && (
                <button
                  type="button"
                  className={styles.leagueOtherButton}
                  onClick={() => handleViewLeaderboardClick(league)}
                >
                  Leaderboard
                </button>
              )}
            </div>
          </div>
        </div>
        <ReactTooltip
          className="text-xs mt-4"
          backgroundColor="#f5f5f5"
          textColor="#000"
          border={true}
          borderColor="#cccccc"
          id={`edit-${league.name}`}
          place="bottom"
        ></ReactTooltip>
        <ReactTooltip
          className="text-xs mt-4"
          backgroundColor="#f5f5f5"
          textColor="#000"
          border={true}
          borderColor="#cccccc"
          id={`delete-${league.name}`}
          place="bottom"
        ></ReactTooltip>
      </motion.div>
    </>
  );
};
export default LeagueCard;

type LeagueCardProps = {
  league: League;
  editLeague?: boolean;
};
