import { useAppDispatch } from "utils/hooks";
import { ModalType } from "utils/modals/modalHelpers";
import { modalAction } from "ui-modules/modals/modalSlice";
import Row from "components/ui/layout/Row";
import Modal from "components/shared/Modal";
import MarketingHubFileList from "./MarketingHubFileList";

const MarketingModal: React.FC<LeagueRoundGroupModalProps> = (props) => {
  const dispatch = useAppDispatch();

  const handleCloseModal = async () => {
    dispatch(
      modalAction({
        isOpen: false,
        modalName: ModalType.MarketingModal,
      })
    );
  };

  return (
    <Modal
      modalName={props.modalName}
      closeModalCallback={handleCloseModal}
      hideHeader={false}
      title={`aG Marketing Hub` || props.title}
      hideFooter={true}
      modalSize="w-full h-full md:w-1/2 2xl:w-1/2"
      body={
        <Row cssClasses="px-8 pb-4">
          <MarketingHubFileList />
        </Row>
      }
    />
  );
};

type LeagueRoundGroupModalProps = {
  title?: string;
  modalName: string;
};

MarketingModal.defaultProps = {
  title: "aG Marketing Hub",
};

export default MarketingModal;
