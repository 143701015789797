import { AppQueryConfig } from "QueryTypes";
import { leagueQueryDefaultOptions } from "./leagueHelpers";
import {
  CreateMatchResponseDto,
  GetMatchResponseDto,
  LeagueMatchesIndividual,
  MultipleMatchIndividualPayload,
  MultipleMatchTeamPayload,
  SingleMatchIndividualPayload,
  SingleMatchTeamPayload,
} from "MatchPlayModels";

const urls = {
  leagueCreateSingleMatch(
    facilityId: string,
    leagueId: string,
    roundId: string
  ): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facility/${facilityId}/league/${leagueId}/round/${roundId}/match`;
  },
  leagueMatches(facilityId: string, leagueId: string, roundId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facility/${facilityId}/league/${leagueId}/round/${roundId}/matches`;
  },
  leagueSingleMatch(
    facilityId: string,
    leagueId: string,
    roundId: string,
    matchId: string
  ): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facility/${facilityId}/league/${leagueId}/round/${roundId}/match/${matchId}`;
  },
};

export const getLeagueRoundMatches = (
  facilityId: string,
  leagueId: string,
  roundId: string
): AppQueryConfig => ({
  url: urls.leagueMatches(facilityId, leagueId, roundId),
  options: {
    ...leagueQueryDefaultOptions(),
    method: "GET",
  },
  transform: (responseJson: GetMatchResponseDto) => {
    return {
      leagueMatches: {
        byRoundId: {
          [roundId]: responseJson,
        },
      },
    };
  },
  update: {
    leagueMatches: (oldValue, newValue) => ({
      byRoundId: {
        ...oldValue?.byRoundId,
        ...{
          [roundId]: {
            ...oldValue?.byRoundId?.[roundId],
            ...newValue.byRoundId[roundId],
          },
        },
      },
    }),
  },
});

export const makeCreateSingleMatch = (
  facilityId: string,
  leagueId: string,
  roundId: string,
  body: SingleMatchIndividualPayload | SingleMatchTeamPayload
): AppQueryConfig => ({
  url: urls.leagueCreateSingleMatch(facilityId, leagueId, roundId),
  options: {
    ...leagueQueryDefaultOptions(),
    method: "POST",
  },
  body,
  transform: (responseJson: CreateMatchResponseDto) => {
    return {
      leagueMatches: {
        byRoundId: {
          [roundId]: { [responseJson.id]: responseJson },
        },
      },
    };
  },
  update: {
    leagueMatches: (oldValue, newValue) => ({
      byRoundId: {
        ...oldValue?.byRoundId,
        ...{
          [roundId]: {
            ...oldValue?.byRoundId?.[roundId],
            ...newValue.byRoundId[roundId],
          },
        },
      },
    }),
  },
});

export const makeCreateMultipleMatches = (
  facilityId: string,
  leagueId: string,
  roundId: string,
  body: MultipleMatchIndividualPayload | MultipleMatchTeamPayload
): AppQueryConfig => ({
  url: urls.leagueMatches(facilityId, leagueId, roundId),
  options: {
    ...leagueQueryDefaultOptions(),
    method: "POST",
  },
  body,
  transform: (responseJson: LeagueMatchesIndividual[]) => {
    let obj: { [id: string]: LeagueMatchesIndividual } = {};

    responseJson.map((league) => {
      return (obj[league?.id] = league);
    });

    return {
      leagueMatches: {
        byRoundId: {
          [roundId]: obj,
        },
      },
    };
  },
  update: {
    leagueMatches: (oldValue, newValue) => ({
      byRoundId: {
        ...oldValue?.byRoundId,
        ...{
          [roundId]: {
            ...oldValue?.byRoundId?.[roundId],
            ...newValue.byRoundId[roundId],
          },
        },
      },
    }),
  },
});

export const makeDeleteSingleMatch = (
  facilityId: string,
  leagueId: string,
  roundId: string,
  matchId: string
): AppQueryConfig => ({
  url: urls.leagueSingleMatch(facilityId, leagueId, roundId, matchId),
  queryKey: `deleteSingleMatch:${leagueId}`,
  options: {
    ...leagueQueryDefaultOptions(),
    method: "DELETE",
  },
  optimisticUpdate: {
    leagueMatches: (oldValue) => {
      let obj = oldValue?.byRoundId[roundId];
      delete obj[matchId];

      return {
        leagueMatches: {
          byRoundId: {
            [roundId]: obj,
          },
        },
      };
    },
  },
});
