export const styles = {
  dateContainer: "grid grid-cols-2 gap-2",
  dateSpan: "text-sm poppins block text-black dark:text-gray-200 pt-4 pb-2",
  datePicker: "dark:text-black w-full border-2 border-gray-200 rounded text-sm",
  lockerUserInputForm:
    "appearance-none border border-gray-300 dark:border-white rounded-xl w-full py-2 px-3 text-gray-700 dark:text-white dark:bg-zinc-900 focus:outline-none focus:shadow-outline",
  lockerUserInputFormError:
    "appearance-none border border-red-200 rounded-xl w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline dark:text-black",
  lockerUserInputFormButton:
    "poppins font-normal text-brandDarkGray bg-brandGreen text-white hover:bg-brandYellow hover:text-black text-xs rounded-xl px-6 py-2 inline items-center hover:cursor-pointer w-1/2 text-center",
  lockerUserInputFormButtonAlt:
    "ml-2 poppins font-normal text-brandDarkGray bg-darkCream dark:text-black text-xs rounded-xl px-6 py-2 inline items-center hover:cursor-pointer w-1/2 text-center",
  lockerUserInputFormLabel:
    "text-brandDarkGray dark:text-black text-xs poppins pb-2",
};
export default styles;
