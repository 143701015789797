export const styles = {
  container: "SimTimerControls flex flex-col pb-8 w-full",
  headerRow: "poppins mb-2 text-gray-700",
  heading: "text-md font-bold block text-black dark:text-gray-200 pb-2",
  small: "text-xs text-gray-400 dark:text-gray-200 mb-2 mt-2",
  list: "flex mb-0 list-none flex-wrap flex-row font-bold",
  timerToggle:
    "bg-white dark:bg-zinc-900 poppins p-1 text-xs text-gray-500 dark:text-gray-200 border-2 border-gray-200 dark:border-gray-500 shadow-sm rounded-xl inline-flex",
  listItem:
    "text-sm w-32 text-left p-2 flex justify-center items-center text-center rounded-lg",
  inactiveTab:
    " bg-white dark:bg-zinc-800 text-gray-400 inline-flex items-center transition-colors duration-100 ease-out focus:outline-none hover:dark-gray rounded-lg px-2 py-2 text-xs",
  activeTab:
    " bg-brandGreen dark:bg-zinc-800 text-white dark:text-gray-200 rounded-lg inline-flex items-center transition-colors duration-50 ease-out focus:outline-none hover:dark-gray px-2 py-2 text-xs",
  timerContainer: "bg-white dark:bg-zinc-800 rounded-xl py-2",
  timerInnerContainers: "",
  input:
    "border-2 border-gray-200 rounded-xl text-sm dark:bg-zinc-900 dark:text-gray-50 w-full",
  datePicker:
    "border-2 border-gray-200 rounded-xl p-3 dark:text-black w-full text-xs",
  buttonOuterRow: "flex flex-col lg:flex-row",
  buttonInnerContainerLeft: "w-full lg:w-1/3 md:mt-4 lg:text-left",
  buttonInnerContainerRight: "w-full lg:w-2/3 mt-4 text-left lg:text-right",
  buttonSetTime:
    "w-full md:w-36 bg-lime-900 h-12 hover:bg-lime-700 text-white text-sm p-2 mb-2 border border-green-600 rounded shadow-md hover:animate-pulse focus:ring-2 focus:ring-blue-600 active:bg-green-700 disabled:opacity-50 disabled:cursor-not-allowed",
  buttonPause:
    "w-full md:w-36 md:inline-flex rounded-tl rounded-bl h-12 mb-2 bg-white text-gray-800 font-semibold text-sm border-b-2 border-yellow-400 hover:border-yellow-400 hover:bg-yellow-100 active:bg-yellow-200 hover:text-yellow-800 shadow hover:shadow-lg hover:border-r hover:border-l p-4 items-center",
  buttonUnpause:
    "w-full md:w-36 md:inline-flex animate-pulse rounded-tl rounded-bl h-12 mb-2 bg-white text-gray-800 font-semibold text-xs border-b-2 border-green-500 hover:border-green-400 hover:bg-green-100 active:bg-green-200 hover:text-green-800 shadow hover:shadow-lg hover:border-r hover:border-l p-4 items-center",
  buttonClear:
    "w-full md:w-36 md:inline-flex rounded-br rounded-tr h-12 mb-2 bg-white text-gray-800 font-semibold text-sm border-b-2 border-red-500 hover:border-red-600 hover:bg-red-100 active:bg-red-200 hover:text-red-800 shadow hover:shadow-lg p-4 items-center",
  itemSelected:
    "bg-transparent border-b-2 border-brandYellow text-black dark:text-white",
};
