import { UseDataInfo } from "QueryTypes";
import { QueryState } from "redux-query";
import { useSelector } from "react-redux";
import { EntitiesState, RootState } from "StoreTypes";
import {
  ClubhouseLeagueRoundPlayerDto,
  ClubhouseRoundGroupSimulatorDto,
  PlayerRoundPayload,
  TeamRoundPayload,
} from "Models";
import { getTeamsByFacilityAndLeagueId } from "./leagueTeamsQueries";
import {
  getRosterByRoundId,
  makeAddPlayerToGroupMutation,
  makeAssignGroupToSim,
  makeCreateIndividualLeagueGroupMutation,
  makeCreateTeamLeagueGroupMutation,
  makeRemovePlayerFromGroupMutation,
} from "./leagueGroupQueries";
import { RunMutation, useMutation, useRequest } from "redux-query-react";

export const useLeagueGroupsByFacilityAndLeagueId = (
  facilityId: string,
  leagueId: string
): UseDataInfo<ClubhouseLeagueRoundPlayerDto[][]> => {
  const [queryState, refreshLeagueTeams] = useRequest(
    getTeamsByFacilityAndLeagueId(facilityId, leagueId)
  );
  const leagueGroups = useSelector(
    (state: RootState): ClubhouseLeagueRoundPlayerDto[][] => {
      if (!state.entities.leagueGroups?.byLeagueId?.[leagueId]) return [];

      let leagueGroups: ClubhouseLeagueRoundPlayerDto[][] = Object.values<
        ClubhouseLeagueRoundPlayerDto[]
      >(state.entities?.leagueGroups?.byLeagueId?.[leagueId]);

      return leagueGroups;
    }
  );

  return [leagueGroups, queryState, refreshLeagueTeams];
};

export const useCreateIndividualLeagueGroup = (
  facilityId: string,
  leagueId: string,
  roundId: string
): [QueryState, RunMutation<EntitiesState, [PlayerRoundPayload[]]>] => {
  const [
    createLeagueTeamQueryState,
    createLeagueGroup,
  ] = useMutation((body: PlayerRoundPayload[]) =>
    makeCreateIndividualLeagueGroupMutation(facilityId, leagueId, roundId, body)
  );

  return [createLeagueTeamQueryState, createLeagueGroup];
};

export const useAddPlayerToLeagueGroup = (
  facilityId: string,
  leagueId: string,
  roundId: string
): [QueryState, RunMutation<EntitiesState, [PlayerRoundPayload[]]>] => {
  const [
    createLeagueTeamQueryState,
    createLeagueGroup,
  ] = useMutation((body: PlayerRoundPayload[]) =>
    makeAddPlayerToGroupMutation(facilityId, leagueId, roundId, body)
  );

  return [createLeagueTeamQueryState, createLeagueGroup];
};

export const useRemovePlayerFromLeagueGroup = (
  facilityId: string,
  leagueId: string,
  roundId: string
): [QueryState, RunMutation<EntitiesState, [PlayerRoundPayload[]]>] => {
  const [
    createLeagueTeamQueryState,
    createLeagueGroup,
  ] = useMutation((body: PlayerRoundPayload[]) =>
    makeRemovePlayerFromGroupMutation(facilityId, leagueId, roundId, body)
  );

  return [createLeagueTeamQueryState, createLeagueGroup];
};

export const useCreateTeamLeagueGroup = (
  facilityId: string,
  leagueId: string,
  roundId: string
): [QueryState, RunMutation<EntitiesState, [TeamRoundPayload[]]>] => {
  const [
    createLeagueTeamQueryState,
    createLeagueGroup,
  ] = useMutation((body: TeamRoundPayload[]) =>
    makeCreateTeamLeagueGroupMutation(facilityId, leagueId, roundId, body)
  );

  return [createLeagueTeamQueryState, createLeagueGroup];
};

export const useAssignGroupToSim = (
  facilityId: string,
  leagueId: string,
  roundId: string
): [
  QueryState,
  RunMutation<EntitiesState, [ClubhouseRoundGroupSimulatorDto[]]>
] => {
  const [
    createLeagueTeamQueryState,
    createLeagueGroup,
  ] = useMutation((body: ClubhouseRoundGroupSimulatorDto[]) =>
    makeAssignGroupToSim(facilityId, leagueId, roundId, body)
  );

  return [createLeagueTeamQueryState, createLeagueGroup];
};

export const useLeagueGroupByFacilityLeagueRoundId = (
  facilityId: string,
  leagueId: string,
  roundId: string
): UseDataInfo<ClubhouseLeagueRoundPlayerDto[]> => {
  const [queryState, refreshLeagueGroups] = useRequest(
    getRosterByRoundId(facilityId, leagueId, roundId)
  );

  const leagueRoundGroups = useSelector(
    (state: RootState): ClubhouseLeagueRoundPlayerDto[] => {
      if (!state.entities.leagueGroups?.byRoundId?.[roundId]) return [];

      let leagueRoundGroups: ClubhouseLeagueRoundPlayerDto[] = Object.values<ClubhouseLeagueRoundPlayerDto>(
        state.entities?.leagueGroups?.byRoundId?.[roundId]
      );

      return leagueRoundGroups;
    }
  );

  return [leagueRoundGroups, queryState, refreshLeagueGroups];
};
