import { EventCompetitionType, Rounds } from "types/enums";
import { SetStateAction, useMemo } from "react";
import { styles } from "./Events.styles";
import { FormRow } from "components/ui/form";
import { customStyles } from "utils/ui/uiHelpers";
import { ErrorMessage } from "@hookform/error-message";
import { Controller, useFormContext } from "react-hook-form";
import { roundConfigEnumsAsReactSelectOptions } from "utils/enums/enumHelper";
import Select from "react-select";
import EventDateSelect from "./EventDateSelect";
import TourSelectDropdown from "./TourSelectDropdown";
import { useSelectedEvent } from "modules/events/eventHooks";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { eventStartDatePassed } from "modules/events/eventHelpers";
import { TiInfoOutline } from "react-icons/ti";

const CreateEventFormEventOptionContainer: React.FC<CreateEventFormEventOptionContainerProps> = (
  props
) => {
  const { control, formState, register, watch } = useFormContext();
  const { callback } = props;

  const currentFacilityId = useGetCurrentLocalFacilityId();
  const selectedEvent = useSelectedEvent(currentFacilityId);
  const competitionType = watch("type") || EventCompetitionType.STROKE;

  const hideStrokeOnlyInputs = useMemo(() => {
    return competitionType !== EventCompetitionType.STROKE;
  }, [competitionType]);

  const startDatePassed = selectedEvent
    ? eventStartDatePassed(selectedEvent.startDate)
    : false;

  const handleEventImageChange = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      callback(event.target.files[0]);
    }
  };

  return (
    <section id="event">
      {startDatePassed && (
        <div className="text-center m-auto text-red-500 text-xs">
          <TiInfoOutline className="text-xl" /> Only certain fields are able to
          be edited for an event that is in progress.
        </div>
      )}
      <FormRow header="Event Name" fontSize={styles.formRowLabel}>
        <input
          {...register("name")}
          type="text"
          className={styles.inputClassName}
          required={true}
          id="eventName"
          data-testid="eventName"
          autoFocus
        />
      </FormRow>
      <FormRow header="Event Description" fontSize={styles.formRowLabel}>
        <input
          {...register("description")}
          type="text"
          autoComplete="Event Description"
          className={styles.inputClassName}
          id="eventDescription"
          data-testid="eventDescription"
        />
      </FormRow>
      <FormRow header="Event Image" fontSize={styles.formRowLabel}>
        <Controller
          name="file"
          control={control}
          render={({ field }) => (
            <input
              {...field}
              type="file"
              alt="Upload event image file"
              aria-label="Upload event image file"
              className={styles.inputClassName}
              id="file"
              onChange={handleEventImageChange}
              disabled={startDatePassed}
            />
          )}
        />
      </FormRow>
      {selectedEvent && (
        <FormRow>
          {selectedEvent.imageUrl && (
            <img
              src={selectedEvent.imageUrl}
              alt={`Poster of ${selectedEvent.name}`}
            />
          )}
        </FormRow>
      )}
      <TourSelectDropdown />
      <FormRow header="Competition Type" fontSize={styles.formRowLabel}>
        <Controller
          name="type"
          control={control}
          rules={{ required: "Competition type is required" }}
          defaultValue={EventCompetitionType.STROKE}
          render={({ field }) => (
            <Select
              {...field}
              options={
                roundConfigEnumsAsReactSelectOptions.eventCompetitionType
              }
              value={roundConfigEnumsAsReactSelectOptions.eventCompetitionType.find(
                (c) => c.value === field.value
              )}
              onChange={(val: { label: string; value: string }) =>
                field.onChange(val.value)
              }
              styles={customStyles}
              isDisabled={startDatePassed}
              id="eventCompetitionType"
            />
          )}
        />
        <ErrorMessage
          errors={formState.errors}
          name="scoringType"
          render={({ message }) => (
            <div className="text-sm text-red-500">{message}</div>
          )}
        />
      </FormRow>
      <EventDateSelect />
      <FormRow
        header="Number of Rounds"
        fontSize={styles.formRowLabel}
        hidden={hideStrokeOnlyInputs}
      >
        <Controller
          name="rounds"
          control={control}
          defaultValue={Rounds.ONE}
          render={({ field }) => (
            <Select
              {...field}
              closeMenuOnSelect={true}
              isMulti={false}
              options={roundConfigEnumsAsReactSelectOptions.rounds}
              value={roundConfigEnumsAsReactSelectOptions.rounds.find(
                (c) => c.value === field.value
              )}
              onChange={(val: { label: string; value: number }) =>
                field.onChange(val.value)
              }
              styles={customStyles}
              isDisabled={startDatePassed}
              id="eventRounds"
            />
          )}
        />
      </FormRow>
    </section>
  );
};
export default CreateEventFormEventOptionContainer;

type CreateEventFormEventOptionContainerProps = {
  callback: React.Dispatch<SetStateAction<Blob | MediaSource | undefined>>;
};
