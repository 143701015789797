import {
  FacilityNormalized,
  ScorecardImageType,
  ScorecardTextType,
} from "EntityTypes";
import {
  CourseListMessage,
  RoundEndedMessage,
  RoundUpdateMessage,
  ScorecardMessage,
  SendRoundRangeToSimMessage,
  ServiceRequestMessage,
  SimulatorStatusMessage,
  TimerUpdateMessage,
} from "Messages";
import { Course, ServiceRequest, SimulatorState } from "Models";
import { getCurrentUserToken } from "modules/user";
import { updateEntities } from "redux-query";
import {
  withCallbacks,
  signalMiddleware,
  HubConnectionBuilder,
  LogLevel,
} from "updated-redux-signalr";
import store from "store/rootReducer";
import {
  CourseEntitiesState,
  EntitiesById,
  RoundsEntitiesState,
  ServiceRequestEntityState,
  SimulatorsEntitiesState,
} from "StoreTypes";
import { ServiceRequestType, SimStatus } from "types/enums";
import { serviceRequestEnumDisplayName } from "utils/enums/enumHelper";
import {
  GenericNotificationType,
  showCustomNotification,
} from "utils/notifications/notificationHelpers";
import notificationMp3 from "notification.mp3";
import { timerStringToTimerArray } from "utils/timer/timerHelpers";
import { createRexReservationTrayAction } from "ui-modules/nav/navSlice";
import { setSelectedSimulatorReservation } from "ui-modules/simulator-reservation/simulatorReservationSlice";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";

const token = () => getCurrentUserToken();
const client = `${process.env.REACT_APP_WSS_PROTOCOL}${process.env.REACT_APP_WEBSOCKETS_BASE}/sockets/control`;
const notification = new Audio(notificationMp3);
const playSound = () => {
  notification.play();
};

const messageSimulatorBelongToUser = (messageSimulatorId: string): boolean => {
  const userSimulatorIds: string[] = store.getState().entities?.simulators
    ? Object.keys(store.getState().entities.simulators)
    : [];

  return userSimulatorIds?.includes(messageSimulatorId);
};

const getFacilityById = (facilityId: string): FacilityNormalized => {
  const selectedFacility: FacilityNormalized = store.getState().entities
    ?.facilities?.[facilityId];

  return selectedFacility;
};

export const connection = new HubConnectionBuilder()
  .configureLogging(LogLevel.Debug)
  .withUrl(client, {
    accessTokenFactory: () => token(),
  })
  .withAutomaticReconnect()
  .build();

const callbacks = withCallbacks()
  .add("ReceiveCourseListMessage", (message: string) => (dispatch) => {
    const parsedMessage: CourseListMessage = JSON.parse(message);

    let obj: { [id: string]: Course } = {};
    parsedMessage.courses.forEach(
      (course: Course) => (obj[course.shortName] = course)
    );
    delete obj["range"];
    dispatch(
      updateEntities({
        courses: (prev: CourseEntitiesState) => ({
          ...prev,
          ...{ [parsedMessage.simulatorId]: obj },
        }),
      })
    );
  })
  .add("ReceiveSendRoundRangeToSimMessage", (message: string) => (dispatch) => {
    const currentFacilityId = useGetCurrentLocalFacilityId();
    const parsed: SendRoundRangeToSimMessage = JSON.parse(message);
    playSound();

    if (currentFacilityId === parsed.sendRoundRangeToSimInfo?.facilityId) {
      dispatch(
        setSelectedSimulatorReservation({
          selectedSimulatorReservation: parsed.sendRoundRangeToSimInfo,
        })
      );
      dispatch(createRexReservationTrayAction({ isOpen: true }));

      showCustomNotification(
        `A new reservation has been made for ${parsed.sendRoundRangeToSimInfo?.numberInParty} players. Confirm reservation and send to sim to begin.`,
        GenericNotificationType.SUCCESS
      );
    } else {
      const selectedFacility = getFacilityById(
        parsed.sendRoundRangeToSimInfo?.facilityId
      );
      showCustomNotification(
        `A new reservation has been made for ${selectedFacility.name}`,
        GenericNotificationType.SUCCESS
      );
    }
  })
  .add(
    "ReceiveSimulatorServiceRequestMessage",
    (message: string) => (dispatch) => {
      const parsed: ServiceRequestMessage = JSON.parse(message);
      const request = parsed.request;

      if (messageSimulatorBelongToUser(parsed.simulatorId)) {
        playSound();
        showCustomNotification(
          `${serviceRequestEnumDisplayName(
            ServiceRequestType[request.type]
          )} Request Recieved: See full notification for more info`,
          GenericNotificationType.SUCCESS
        );

        const obj: {
          [serviceRequestId: string]: ServiceRequest;
        } = {};
        obj[request.id] = request;
        dispatch(
          updateEntities({
            serviceRequestsByFacilityId: (prev: ServiceRequestEntityState) => ({
              ...prev,
              ...{
                [request.facilityId]: { ...prev[request.facilityId], ...obj },
              },
            }),
          })
        );
      }
    }
  )
  .add("ReceiveSimulatorRoundBegan", (message: string) => (dispatch) => {
    const parsedMessage: RoundUpdateMessage = JSON.parse(message);

    if (messageSimulatorBelongToUser(parsedMessage.simulatorId)) {
      const round = parsedMessage.round;
      const roundObject = { [parsedMessage.roundId]: round };
      const simStateUpdate = {
        roundId: parsedMessage.roundId,
        estimatedTimeRemaining: parsedMessage.estimatedTimeRemaining,
      };
      dispatch(
        updateEntities({
          simulatorStates: (prev: SimulatorsEntitiesState) => ({
            ...prev,
            ...{
              [parsedMessage.simulatorId]: {
                ...prev[parsedMessage.simulatorId],
                ...simStateUpdate,
              },
            },
          }),
          rounds: (prev: RoundsEntitiesState) => ({
            ...prev,
            ...roundObject,
          }),
        })
      );
    }
  })
  .add("ReceiveSimulatorRoundUpdate", (message: string) => (dispatch) => {
    const parsedMessage: RoundUpdateMessage = JSON.parse(message);
    if (messageSimulatorBelongToUser(parsedMessage.simulatorId)) {
      const round = parsedMessage.round;
      const roundObject = { [parsedMessage.roundId]: round };
      const simStateUpdate = {
        roundId: parsedMessage.roundId,
        estimatedTimeRemaining: parsedMessage.estimatedTimeRemaining,
      };
      dispatch(
        updateEntities({
          simulatorStates: (prev: SimulatorsEntitiesState) => ({
            ...prev,
            ...{
              [parsedMessage.simulatorId]: {
                ...prev[parsedMessage.simulatorId],
                ...simStateUpdate,
              },
            },
          }),
          rounds: (prev: RoundsEntitiesState) => ({
            ...prev,
            ...roundObject,
          }),
        })
      );
    }
  })
  .add("ReceiveSimulatorRoundEnded", (message: string) => (dispatch) => {
    const parsedMessage: RoundEndedMessage = JSON.parse(message);

    if (messageSimulatorBelongToUser(parsedMessage.simulatorId)) {
      const round = parsedMessage.round;
      const roundObject = { [parsedMessage.roundId]: round };
      const simStateUpdate = {
        roundId: parsedMessage.roundId,
        estimatedTimeRemaining: "",
        status: SimStatus.STATUS_SIM_EXIT,
      };
      dispatch(
        updateEntities({
          simulatorStates: (prev: SimulatorsEntitiesState) => ({
            ...prev,
            ...{
              [parsedMessage.simulatorId]: {
                ...prev[parsedMessage.simulatorId],
                ...simStateUpdate,
              },
            },
          }),
          rounds: (prev: RoundsEntitiesState) => ({
            ...prev,
            ...roundObject,
          }),
        })
      );
    }
  })
  .add("ReceiveSimulatorTimerUpdate", (message: string) => (dispatch) => {
    const parsedMessage: TimerUpdateMessage = JSON.parse(message);
    if (messageSimulatorBelongToUser(parsedMessage.simulatorId)) {
      const simStateUpdate = {
        timerValue: timerStringToTimerArray(parsedMessage.value),
        timerStatus: parsedMessage.status,
      };
      dispatch(
        updateEntities({
          simulatorStates: (prev: EntitiesById<SimulatorState>) => ({
            ...prev,
            ...{
              [parsedMessage.simulatorId]: {
                ...prev[parsedMessage.simulatorId],
                ...simStateUpdate,
              },
            },
          }),
        })
      );
    }
  })
  .add("ReceiveSimulatorStatus", (message: string) => (dispatch) => {
    const parsedMessage: SimulatorStatusMessage = JSON.parse(message);
    if (messageSimulatorBelongToUser(parsedMessage.simulatorId)) {
      const simStateUpdate = {
        status: parsedMessage.status,
      };
      dispatch(
        updateEntities({
          simulatorStates: (prev: EntitiesById<SimulatorState>) => ({
            ...prev,
            ...{
              [parsedMessage.simulatorId]: {
                ...prev[parsedMessage.simulatorId],
                ...simStateUpdate,
              },
            },
          }),
        })
      );
    }
  })
  .add("ReceiveTextScorecardMessage", (message: string) => (dispatch) => {
    const parsedMessage: ScorecardMessage = JSON.parse(message);
    if (messageSimulatorBelongToUser(parsedMessage.simulatorId)) {
      const textScorecard = btoa(parsedMessage.textScorecard);
      dispatch(
        updateEntities({
          scorecardTextStates: (prev: ScorecardTextType) => ({
            ...prev,
            ...{
              [parsedMessage.simulatorId]: textScorecard,
            },
          }),
        })
      );
    }
  })
  .add("ReceiveImageScorecardMessage", (message: string) => (dispatch) => {
    const parsedMessage: ScorecardMessage = JSON.parse(message);
    if (messageSimulatorBelongToUser(parsedMessage.simulatorId)) {
      const imageScorecard = parsedMessage.base64ImageScorecard;
      dispatch(
        updateEntities({
          scorecardImageStates: (prev: ScorecardImageType) => ({
            ...prev,
            ...{
              [parsedMessage.simulatorId]: imageScorecard,
            },
          }),
        })
      );
    }
  })
  .add("ReceiveSimulatorMessageProcessed", (message: string) => (dispatch) => {
    const parsedMessage: ScorecardMessage = JSON.parse(message);
    console.log(parsedMessage);
  });

export const signalRMiddleware = signalMiddleware({
  callbacks,
  connection,
  shouldConnectionStartImmediately: false,
});
