import { motion } from "framer-motion";
import { sendTimerToSimulator } from "modules/simulator";
import { MotionVariantType, TimerAction } from "types/enums";
import { buttonMotionVariants } from "utils/animations/animationHelper";
import styles from "./DashboardComponents.styles";
import {
  notificationMessageGroup,
  showResponseNotification,
} from "utils/notifications/notificationHelpers";
import ShowLoadingAnimation from "./ShowLoadingAnimation";
import { GoAlert } from "react-icons/go";

const SimulatorCardOverlay: React.FC<SimulatorCardOverlayProps> = (props) => {
  const useHandleUnlock = () => {
    const response = sendTimerToSimulator(
      TimerAction[TimerAction.CLEAR_DISABLE],
      props.simulatorId
    );

    showResponseNotification(response, notificationMessageGroup.unlockSim);
  };

  return (
    <>
      {props.showLoadingAnimation ? (
        <ShowLoadingAnimation showListView={false} />
      ) : null}
      {props.showOverlay ? (
        <div className={styles.simulatorIsOffContainer}>
          <div className={styles.simulatorIsOffContent}>
            {props.showIcon ? <GoAlert /> : null}
            <div className="simulatorOffMessage">{props.message}</div>
            <br />
            {props.showButton ? (
              <motion.button
                variants={buttonMotionVariants}
                initial={MotionVariantType.REST}
                whileHover={MotionVariantType.HOVER}
                whileTap={MotionVariantType.PRESSED}
                key={`powerSimOn-${props.simulatorId}`}
                className={styles.simulatorIsOffButton}
                onClick={useHandleUnlock}
              >
                {props.buttonText}
              </motion.button>
            ) : null}
          </div>
          <div className={`${styles.simulatorIsOffOverlay} opacity-25`}></div>
        </div>
      ) : null}
    </>
  );
};
export default SimulatorCardOverlay;

type SimulatorCardOverlayProps = {
  simulatorId: string;
  showOverlay?: boolean;
  showIcon?: boolean;
  showButton?: boolean;
  message?: string;
  buttonText?: string;
  showLoadingAnimation: boolean;
};

SimulatorCardOverlay.defaultProps = {
  showOverlay: true,
  showIcon: true,
  showButton: true,
  message: "Simulator Off",
  buttonText: "Turn On",
  showLoadingAnimation: false,
};
