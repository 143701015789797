import { createElement } from "react";
import { IconType } from "react-icons/lib";

type OwnProps = {
  title: string;
  description: string;
  icon: IconType;
  enabled: boolean;
  testId?: string;
};

const EventCard: React.FC<OwnProps> = (props) => {
  return (
    <>
      <div
        className={`flex-1 flex flex-col p-4 rounded-xl h-full ${
          props.enabled === false
            ? "hover:cursor-not-allowed bg-gray-200  dark:bg-zinc-800 "
            : "bg-white  dark:bg-zinc-800 "
        }`}
        data-testid={props.testId}
      >
        {createElement(props.icon, {
          className: "text-4xl mt-2 dark:text-gray-200",
        })}
        <h3 className="mt-6 text-gray-900 dark:text-gray-200 text-md font-semibold">
          {props.title}
        </h3>
        <dl className="mt-1 flex-grow flex flex-col justify-between mb-2">
          <dd className="text-gray-500 dark:text-gray-200 text-xs px-2">
            {props.description}
          </dd>
          <dt className="sr-only">Event Card</dt>
        </dl>
      </div>
    </>
  );
};
export default EventCard;
