import { motion } from "framer-motion";
import { SimulatorState } from "Models";
import { SimStatus } from "types/enums";
import { simStatusEnumDisplayNameKeyedByString } from "utils/enums/enumHelper";
import { simStatusColorHelper } from "utils/icons/iconHelpers";

type SimStatusCardProps = {
  simulatorState: SimulatorState;
};

const SimStatusCard: React.FC<SimStatusCardProps> = (props) => {
  const { simulatorState } = props;

  return (
    <div className="flex flex-col flex-auto">
      <span className="text-xs font-normal brand-gray poppins">
        Current Sim Status
      </span>
      <motion.div
        key={simulatorState?.status}
        className={`inline font-semibold text-xs ${
          simStatusColorHelper[SimStatus[simulatorState?.status]]
        } mt-1 h-6`}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 0.75,
        }}
      >
        {
          simStatusEnumDisplayNameKeyedByString[
            SimStatus[simulatorState?.status]
          ]
        }
      </motion.div>
    </div>
  );
};
export default SimStatusCard;
