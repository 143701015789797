export const styles = {
  headerRow: ["flex", "w-full"].join(" "),
  innerContainer: ["flex", "flex-col", "w-full"].join(" "),
  titleRow: ["w-1/2", "sm:w-3/4", "p-3", "ml-4", "text-left"].join(" "),
  span: ["poppins", "text-md", "text-brandDarkGray dark:text-gray-50"].join(
    " "
  ),
  buttonContainer: ["w-1/2", "sm:w-1/4", "text-right"].join(" "),
  footerContainer: ["w-full", "md:text-right"].join(" "),
  closeButton: [
    "px-6 py-3 m-4",
    "poppins",
    "text-sm",
    "bg-dark-cream",
    "float-right",
    "hover:bg-gray-100",
    "rounded-xl",
    "w-full",
    "sm:w-auto",
    "hover:shadow-none",
    "text-black",
  ].join(" "),
  closeIcon: ["fas", "fa-window-close", "mr-2", "text-red-500"].join(" "),
  innerModalContainer: [
    "bg-white dark:bg-zinc-800 ",
    "pointer-events-auto relative flex max-h-[90%] w-full flex-col overflow-hidden rounded-md border-none bg-white bg-clip-padding text-current shadow-4 outline-none dark:bg-surface-dark",
  ].join(" "),
  showInnerModalHeader: [
    "flex flex-shrink-0 items-center justify-between",
    "bg-white dark:bg-zinc-900",
    "rounded-none",
    "md:rounded-t-xl",
    "cursor-move border-b border-gray-200 dark:border-zinc-900",
  ].join(" "),
  showInnerModalBody: [
    "scrollbar-thumb-gray-400 scrollbar-track-gray-300 relative overflow-y-scroll overflow-x-hidden py-4",
  ].join(" "),
  showInnerModalFooter: [
    "w-full",
    "items-left",
    "bg-white dark:bg-zinc-800",
    "rounded-none",
    "md:rounded-b-lg",
    "flex flex-shrink-0 flex-wrap items-center justify-end ",
    "bottom-0",
  ].join(" "),
  modalBackground: [
    "inset-0",
    "fixed",
    "flex",
    "z-9998",
    "bg-gray-600/50",
    "left-0 top-0 h-full w-full outline-none",
  ].join(" "),
};
export default styles;
