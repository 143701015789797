import FormData from "form-data";
import { DateTime } from "luxon";
import { Simulator } from "Models";
import {
  AutoConcede,
  AutoContinue,
  AutoGimme,
  GimmeDistance,
  HandicapMode,
  PinType,
  ScoringType,
  WindSpeed,
  TeeType,
  Ground,
  Sky,
  WindDirection,
  TimeOfDay,
} from "types/enums";
import { greenStimpOptions } from "utils/enums/enumHelper";

export const eventObjectKeys = [
  "id",
  "name",
  "description",
  "imageUrl",
  "startDate",
  "endDate",
  "type",
  "rounds",
  "replays",
  "maxUserCount",
  "gimmes",
  "mulligans",
  "handicapMode",
  "handicapLevel",
  "applyHandicaps",
  "groupId",
  "groupName",
  "facilityId",
  "roundInfo",
];

export const verifySimulatorInstallIds = (simulators: Simulator[]) => {
  const installIds = simulators.map((simulator) => simulator.installId) || [];

  return !installIds.some((item) => item === 0);
};

export const convertObjectToFormData = (
  formData: any,
  image?: Blob | MediaSource | undefined
) => {
  const data = new FormData();

  Object.keys(formData).map((key) => {
    if (key === "file" && image) {
      return data.append(key, image);
    } else if (key === "roundInfo") {
      return data.append(key, JSON.stringify(formData[key]));
    } else if (key === "startDate" || key === "endDate") {
      return data.append(key, new Date(formData[key]).toISOString());
    } else {
      return data.append(key, formData[key]);
    }
  });

  return data;
};

export function convertFormValueToBoolean(value: string | null): boolean {
  return value && value === "true" ? true : false;
}

export function eventStartDatePassed(startDate: string) {
  return DateTime.fromISO(startDate) < DateTime.now() ? true : false;
}

export const formatEventPayloadToObject = (payload: any) => {
  return payload
    ? Object.fromEntries(
        eventObjectKeys
          .filter((key) => key in payload)
          .map((key) => [key, payload[key]])
      )
    : {};
};

export const eventDefaults = () => {
  return {
    name: "",
    description: "",
    imageUrl: "",
    type: "team",
    rounds: 1,
    replays: 1,
    maxUserCount: 4,
    gimmes: false,
    mulligans: false,
    handicapMode: "off",
    handicapLevel: 0,
    applyHandicaps: false,
    groupId: 0,
    groupName: "",
    facilityId: 0,
    roundInfo: {
      name: "",
      description: "",
      startPlayDate: new Date().toISOString(),
      endPlayDate: new Date().toISOString(),
      startPlayTimezone: DateTime.local().zoneName,
      endPlayTimezone: DateTime.local().zoneName,
      course: "",
      courseShortName: "",
      selectedHoles: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      autoConcede: AutoConcede.OFF,
      autoContinue: AutoContinue.OFF,
      autoDrop: false,
      autoGimmes: AutoGimme["1STROKE"],
      gimmeDistance: GimmeDistance.OFF,
      handicapMode: HandicapMode.OFF,
      mulligans: false,
      pinType: PinType.NOVICE,
      scoringType: ScoringType.STROKE,
      windSpeed: WindSpeed.CALM,
      stimpSetting: greenStimpOptions[0].value,
      mensTee: TeeType.BLUE,
      womensTee: TeeType.RED,
      ground: Ground.NORMAL,
      sky: Sky.CLEAR,
      windDirection: WindDirection.N,
      timeConditions: TimeOfDay.AFTERNOON,
      competitionType: [
        {
          competitionType: "strokeplay",
        },
      ],
    },
  };
};
