import { useRequest } from "redux-query-react";
import {
  getTextScorecardBySimQuery,
  getImageScorecardBySimQuery,
} from "./scorecardQueries";
import { useSelector } from "react-redux";
import { RootState } from "StoreTypes";
import { UseDataInfo } from "QueryTypes";

export const useScorecardTextState = (
  simulatorId: string
): UseDataInfo<string> => {
  const [queryState, refreshScorecardTextState] = useRequest(
    getTextScorecardBySimQuery(simulatorId)
  );

  const scorecardState = useSelector(
    (state: RootState) => state.entities.scorecardTextStates?.[simulatorId]
  );

  return [scorecardState, queryState, refreshScorecardTextState];
};

export const useScorecardImageState = (
  simulatorId: string
): UseDataInfo<string> => {
  const [queryState, refreshScorecardImageState] = useRequest(
    getImageScorecardBySimQuery(simulatorId)
  );

  const scorecardState = useSelector(
    (state: RootState) => state.entities.scorecardImageStates?.[simulatorId]
  );

  return [scorecardState, queryState, refreshScorecardImageState];
};
