import { useAppDispatch } from "utils/hooks";
import { ModalType } from "utils/modals/modalHelpers";
import { modalAction } from "ui-modules/modals/modalSlice";
import { setSelectedLeagueId } from "modules/leagues/leagueSlice";
import { useSelectedLeagueId } from "modules/leagues/leagueRoundHooks";
import Row from "components/ui/layout/Row";
import Modal from "components/shared/Modal";
import CreateLeagueForm from "./CreateLeagueForm";

const LeaguesModal: React.FC<LeaguesModalProps> = (props) => {
  const dispatch = useAppDispatch();
  const selectedLeagueId = useSelectedLeagueId();
  const editLeague = selectedLeagueId ? true : false;

  const handleCloseModal = async () => {
    dispatch(
      setSelectedLeagueId({
        selectedLeagueId: "",
      })
    );
    dispatch(
      modalAction({
        isOpen: false,
        modalName: ModalType.LeaugeCreateEdit,
      })
    );
  };

  return (
    <Modal
      modalName={props.modalName}
      closeModalCallback={handleCloseModal}
      hideHeader={false}
      title={editLeague ? "Edit League" : "Create League"}
      hideFooter={true}
      modalSize="w-full h-full md:w-1/2 2xl:w-1/2"
      body={
        <Row cssClasses="px-8 pb-4">
          <CreateLeagueForm />
        </Row>
      }
    />
  );
};

type LeaguesModalProps = {
  title?: string;
  modalName: string;
};

LeaguesModal.defaultProps = {
  title: "Handicap Index",
};

export default LeaguesModal;
