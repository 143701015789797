import axios from "axios";
import debounce from "debounce-promise";
import { PlayerUser } from "Models";
import { getCurrentUserToken } from "modules/user";

const headers = {
  authorization: `Bearer ${getCurrentUserToken()}`,
  "Content-Type": "application/json",
};

export const arrayRemove = (arr: any, value: any) => {
  return arr.filter(function (ele: any) {
    return ele !== value;
  });
};

export const userSearch = async (query: string) => {
  const {
    data,
  } = await axios.get(
    `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/user-search/Search?name=${query}`,
    { headers }
  );
  return data;
};

export const playerUsersByLockerId = async (
  lockerUserGuids: string[] | undefined
) => {
  const response = await axios.get(
    `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/user-search/GetByGuid?guid=${lockerUserGuids}`,
    { headers }
  );
  return response.data;
};

export const isPlayerInArray = (player: PlayerUser, array: PlayerUser[]) => {
  return array?.some((item) => player.username === item.username)
    ? true
    : false;
};

const getAsyncOptions = (inputValue: string) => {
  return new Promise<PlayerUser[]>((resolve) => {
    const options = userSearch(inputValue);
    resolve(options);
  });
};

export const getReservationPlayerUsers = async (
  lockerUserGuids: string[] | undefined
) => {
  if (!lockerUserGuids)
    return new Promise<PlayerUser[]>((resolve) => resolve([]));
  return new Promise<PlayerUser[]>(async (resolve) => {
    const options = await playerUsersByLockerId(lockerUserGuids);
    resolve(options);
  });
};

const loadOptions = (inputValue: string) => {
  return getAsyncOptions(inputValue);
};

export const debouncedLoadOptions = debounce(loadOptions, 500, {
  leading: true,
});
