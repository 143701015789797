import {
  simStatusIconColorHelper,
  timerStatusColorHelper,
} from "utils/icons/iconHelpers";
import { titleCase } from "utils/enums/enumHelper";
import { TimerStatus as TimerStatusCode } from "types/enums";
import { motion } from "framer-motion";

const DisplayTimerStatus: React.FC<DisplayTimerStatusType> = (props) => {
  return (
    <>
      {props.badgeStyle ? (
        <div
          className={`px-2 rounded text-xs font-medium ${
            timerStatusColorHelper[props?.timerStatus]
          } text-gray-600`}
        >
          {titleCase(TimerStatusCode[Number(props?.timerStatus)])}
        </div>
      ) : (
        <motion.span
          key={props?.timerStatus}
          className={`${
            simStatusIconColorHelper[props?.timerStatus]
          } text-xs w-24 dark:text-gray-200 text-center`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.75,
          }}
        >
          {props.timerStatus !== undefined
            ? "Timer: " + titleCase(TimerStatusCode[Number(props?.timerStatus)])
            : ""}
        </motion.span>
      )}
    </>
  );
};
export default DisplayTimerStatus;

type DisplayTimerStatusType = {
  timerStatus: string;
  className?: string;
  showStatusName?: boolean;
  badgeStyle?: boolean;
};
DisplayTimerStatus.defaultProps = {
  timerStatus: "",
};
