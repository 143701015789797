import "assets/css/spinnerAnimation.css";

type OwnProps = {
  class?: string;
};

const PlayerSettingsIcon: React.FC<OwnProps> = (props) => {
  return (
    <svg
      className={`${props.class} fill-current`}
      fill-current={props.class}
      width="21"
      height="20"
      viewBox="0 0 21 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.4 0C5.74903 0 3.6 2.14903 3.6 4.8C3.6 7.45097 5.74903 9.6 8.4 9.6C11.0509 9.6 13.2 7.45097 13.2 4.8C13.2 2.14903 11.0509 0 8.4 0ZM2.41048 10.8C1.08378 10.8 0 11.8643 0 13.2C0 15.2294 0.999372 16.7596 2.56199 17.7559C4.10036 18.7368 6.17431 19.2 8.4 19.2C8.89302 19.2 9.3786 19.1773 9.8526 19.1312C8.94379 18.0004 8.4 16.5637 8.4 15C8.4 13.4045 8.96618 11.9411 9.90864 10.8H2.41048ZM10.3621 14.3039C11.6676 13.9704 12.4428 12.6277 12.0788 11.3304L11.8914 10.6622C12.1963 10.4279 12.5268 10.2271 12.8777 10.0652L13.2826 10.4948C14.2292 11.4994 15.8262 11.5002 16.774 10.4966L17.1636 10.0841C17.521 10.2527 17.8567 10.4618 18.1654 10.7058L18.0139 11.2126C17.6188 12.5351 18.418 13.9176 19.7612 14.2352L20.18 14.3342C20.206 14.5524 20.2193 14.7745 20.2193 14.9999C20.2193 15.1871 20.21 15.372 20.1922 15.5543L19.6394 15.6954C18.334 16.029 17.5588 17.3717 17.9227 18.669L18.11 19.337C17.8051 19.5714 17.4748 19.7723 17.1238 19.9342L16.719 19.5046C15.7723 18.5 14.1754 18.4992 13.2276 19.5028L12.8376 19.9157C12.4802 19.7471 12.1446 19.538 11.836 19.2942L11.9876 18.7868C12.3828 17.4643 11.5836 16.0817 10.2403 15.7642L9.8208 15.6649C9.795 15.447 9.78168 15.225 9.78168 14.9999C9.78168 14.8126 9.7908 14.6275 9.8088 14.4452L10.3621 14.3039ZM16.2005 14.9999C16.2005 14.3371 15.6632 13.7999 15.0005 13.7999C14.3377 13.7999 13.8005 14.3371 13.8005 14.9999C13.8005 15.6625 14.3377 16.1999 15.0005 16.1999C15.6632 16.1999 16.2005 15.6625 16.2005 14.9999Z" />
    </svg>
  );
};
export default PlayerSettingsIcon;

PlayerSettingsIcon.defaultProps = {
  class: "text-zinc-800 dark:text-white",
};
