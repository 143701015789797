import "assets/css/spinnerAnimation.css";

type OwnProps = {
  class?: string;
};

const ReservationsIcon: React.FC<OwnProps> = (props) => {
  return (
    <svg
      className={`${props.class} fill-current`}
      fill-current={props.class}
      width="19"
      height="19"
      viewBox="0 0 20 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.75 0C17.5449 0 19 1.45507 19 3.25V14.75C19 16.5449 17.5449 18 15.75 18H4.25C2.45507 18 1 16.5449 1 14.75V3.25C1 1.45507 2.45507 0 4.25 0H15.75ZM17.5 5.5H2.5V14.75C2.5 15.7165 3.2835 16.5 4.25 16.5H15.75C16.7165 16.5 17.5 15.7165 17.5 14.75V5.5ZM5.75 11.5C6.44036 11.5 7 12.0596 7 12.75C7 13.4404 6.44036 14 5.75 14C5.05964 14 4.5 13.4404 4.5 12.75C4.5 12.0596 5.05964 11.5 5.75 11.5ZM10 11.5C10.6904 11.5 11.25 12.0596 11.25 12.75C11.25 13.4404 10.6904 14 10 14C9.3096 14 8.75 13.4404 8.75 12.75C8.75 12.0596 9.3096 11.5 10 11.5ZM5.75 7.5C6.44036 7.5 7 8.0596 7 8.75C7 9.4404 6.44036 10 5.75 10C5.05964 10 4.5 9.4404 4.5 8.75C4.5 8.0596 5.05964 7.5 5.75 7.5ZM10 7.5C10.6904 7.5 11.25 8.0596 11.25 8.75C11.25 9.4404 10.6904 10 10 10C9.3096 10 8.75 9.4404 8.75 8.75C8.75 8.0596 9.3096 7.5 10 7.5ZM14.25 7.5C14.9404 7.5 15.5 8.0596 15.5 8.75C15.5 9.4404 14.9404 10 14.25 10C13.5596 10 13 9.4404 13 8.75C13 8.0596 13.5596 7.5 14.25 7.5ZM15.75 1.5H4.25C3.2835 1.5 2.5 2.2835 2.5 3.25V4H17.5V3.25C17.5 2.2835 16.7165 1.5 15.75 1.5Z" />
    </svg>
  );
};
export default ReservationsIcon;

ReservationsIcon.defaultProps = {
  class: "text-zinc-800 dark:text-white",
};
