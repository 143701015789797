import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SendRoundRangeToSimInfo } from "Messages";

export type SimulatorReservationState = {
  selectedSimulatorReservation: SendRoundRangeToSimInfo | never[];
};

const initialState: SimulatorReservationState = {
  selectedSimulatorReservation: [],
};

const slice = createSlice({
  name: "simulatorReservation",
  initialState,
  reducers: {
    setSelectedSimulatorReservation(
      state,
      action: PayloadAction<{
        selectedSimulatorReservation: SendRoundRangeToSimInfo;
      }>
    ): void {
      state.selectedSimulatorReservation =
        action.payload.selectedSimulatorReservation;
    },
  },
});

const { actions, reducer: simulatorReservationReducer } = slice;

const { setSelectedSimulatorReservation } = actions;

export { simulatorReservationReducer, setSelectedSimulatorReservation };
