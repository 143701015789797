interface PaginationProps {
  postsPerPage: number;
  totalPosts: number;
  paginate: (pageNumber: number) => void;
  currentPage: number;
}

const Pagination: React.FC<PaginationProps> = ({
  postsPerPage,
  totalPosts,
  paginate,
  currentPage,
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav className={`${pageNumbers?.length === 1 ? "hidden" : "p-5"}`}>
      <ul className="flex">
        {pageNumbers.map((number) => (
          <li className="mr-1" key={number}>
            <button
              className={` ${
                currentPage === number
                  ? "text-gray-50 border rounded-lg bg-brandGreen dark:bg-zinc-600"
                  : "text-gray-500"
              } hover:text-black px-3 py-2`}
              onClick={() => paginate(number)}
              aria-label={`Go to Page ${number}`}
            >
              {number}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Pagination;
