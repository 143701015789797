const Row: React.FC<RowProps> = (props) => {
  return (
    <div className={props.cssClasses} id={props.id ? props.id : ""}>
      {props.children}
    </div>
  );
};
export default Row;

type RowProps = {
  cssClasses?: string;
  id?: string;
};
Row.defaultProps = {
  cssClasses: "w-full",
};
