import cn from "classnames";
import { useState } from "react";
import styles from "./Counter.less";

const Counter: React.FC<CounterProps> = (props) => {
  const [count, setCount] = useState(props.count);

  const increaseCount = () => {
    setCount(count + 1);
  };

  const decreaseCount = () => {
    setCount(count - 1);
  };

  return (
    <div className="custom-number-input h-10 w-32">
      <label
        htmlFor="custom-input-number"
        className="w-full text-gray-700 text-sm font-semibold"
      ></label>
      <div className="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
        <button
          onClick={decreaseCount}
          className=" bg-gray-200 text-red-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-l cursor-pointer outline-none"
        >
          <span className="m-auto text-2xl font-thin">−</span>
        </button>
        <input
          type="number"
          className="outline-none focus:outline-none text-center w-full bg-white font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center justify-items-center pl-3 text-gray-700"
          name={cn(styles.customInputuNumber)}
          value={count}
          readOnly
        ></input>
        <button
          onClick={increaseCount}
          className="bg-gray-200 text-green-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-r cursor-pointer"
        >
          <span className="m-auto text-2xl font-thin">+</span>
        </button>
      </div>
    </div>
  );
};
export default Counter;

type CounterProps = {
  count: number;
};

Counter.defaultProps = {
  count: 0,
};
