import { useMemo } from "react";
import { styles } from "./Events.styles";
import { customStyles } from "utils/ui/uiHelpers";
import { ErrorMessage } from "@hookform/error-message";
import { FormRow } from "components/ui/form";
import { Controller, useFormContext } from "react-hook-form";
import { roundConfigEnumsAsReactSelectOptions } from "utils/enums/enumHelper";
import { eventStartDatePassed } from "modules/events/eventHelpers";
import React from "react";
import Select from "react-select";

const CreateEventFormTeeOptions: React.FC<CreateEventFormTeeOptionsProps> = React.forwardRef(
  (props, ref) => {
    const { control, formState, getValues } = useFormContext();
    const { roundNumber } = props;

    const startDatePassed = eventStartDatePassed(getValues("startDate"));

    const teeOptionNames = useMemo(() => {
      return {
        mensTee: `roundInfo[${roundNumber}].mensTees`,
        womensTee: `roundInfo[${roundNumber}].womensTees`,
      };
    }, [roundNumber]);

    return (
      <>
        <FormRow header={`Mens Tee`} fontSize={styles.formRowLabel}>
          <Controller
            name={teeOptionNames.mensTee}
            control={control}
            rules={{ required: "Mens Tee is required" }}
            defaultValue={
              getValues(teeOptionNames.mensTee)
                ? getValues(teeOptionNames.mensTee)
                : roundConfigEnumsAsReactSelectOptions?.teeType[0].value
            }
            render={({ field }) => (
              <Select
                {...field}
                options={roundConfigEnumsAsReactSelectOptions.teeType}
                value={roundConfigEnumsAsReactSelectOptions.teeType.find(
                  (c) => c.value === field.value
                )}
                onChange={(val: { label: string; value: string }) =>
                  field.onChange(val.value)
                }
                styles={customStyles}
                isDisabled={startDatePassed}
              />
            )}
          />
          <ErrorMessage
            errors={formState.errors}
            name={teeOptionNames.mensTee}
            render={({ message }) => (
              <div className="text-sm text-red-500">{message}</div>
            )}
          />
        </FormRow>
        <FormRow header="Womens Tee" fontSize={styles.formRowLabel}>
          <Controller
            name={teeOptionNames.womensTee}
            control={control}
            rules={{ required: "Womens Tee is required" }}
            defaultValue={
              getValues(teeOptionNames.womensTee)
                ? getValues(teeOptionNames.womensTee)
                : roundConfigEnumsAsReactSelectOptions?.teeType[3].value
            }
            render={({ field }) => (
              <Select
                {...field}
                options={roundConfigEnumsAsReactSelectOptions.teeType}
                value={roundConfigEnumsAsReactSelectOptions.teeType.find(
                  (c) => c.value === field.value
                )}
                onChange={(val: { label: string; value: string }) =>
                  field.onChange(val.value)
                }
                styles={customStyles}
                isDisabled={startDatePassed}
              />
            )}
          />
          <ErrorMessage
            errors={formState.errors}
            name={teeOptionNames.womensTee}
            render={({ message }) => (
              <div className="text-sm text-red-500">{message}</div>
            )}
          />
        </FormRow>
      </>
    );
  }
);
export default CreateEventFormTeeOptions;

type CreateEventFormTeeOptionsProps = {
  roundNumber: number;
};
