import { SimulatorState } from "Models";
import { SimStatus, TimerStatus } from "types/enums";

const simulatorInRoundStatuses = [
  SimStatus.STATUS_RND_START,
  SimStatus.STATUS_RND_SHOT,
  SimStatus.STATUS_RND_IDLE,
  SimStatus.STATUS_RND_HOLE_END,
  SimStatus.STATUS_RANGE_SHOT,
  SimStatus.STATUS_RANGE_IDLE,
  SimStatus.STATUS_RANGE_ENTER,
];

export const simulatorStateIsActive = (simulatorState: SimulatorState) => {
  return simulatorState?.status === SimStatus.STATUS_SIM_OFF ||
    simulatorState?.status === SimStatus.STATUS_SIM_EXIT ||
    simulatorState === undefined
    ? false
    : true;
};

export const isInRound = (simulatorState: SimulatorState) => {
  return (
    simulatorState?.roundId &&
    simulatorInRoundStatuses?.includes(simulatorState?.status)
  );
};

export const isActive = (simulatorState: SimulatorState) => {
  return simulatorInRoundStatuses?.includes(simulatorState?.status);
};

export const isSimulatorLocked = (simulatorState: SimulatorState) => {
  return simulatorState?.timerStatus === TimerStatus.EXPIRED ? true : false;
};

export const isSimulatorOff = (simulatorState: SimulatorState) => {
  return simulatorState?.status === SimStatus.STATUS_SIM_OFF ? true : false;
};
