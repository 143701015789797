import { useSelector } from "react-redux";
import { RootState } from "StoreTypes";
import { helpDocsAction } from "ui-modules/nav/navSlice";
import { useAppDispatch } from "utils/hooks";
import { styles } from "./HelpDocTray.styles";
import CreateAndLoadRounds from "components/shared/helpdocs/CreateAndLoadRounds";
import { AnimatePresence, motion } from "framer-motion";
import { FaChevronLeft } from "react-icons/fa";

const HelpDocTray: React.FC<HelpDocProps> = (props) => {
  const dispatch = useAppDispatch();

  const setHelpDocsTray = () =>
    dispatch(helpDocsAction({ isOpen: !setHelpDocsTray }));

  const isHelpDocsTrayOpen = useSelector(
    (state: RootState) => state.nav?.helpDocs?.isOpen
  );

  return (
    <AnimatePresence>
      {isHelpDocsTrayOpen && (
        <>
          <motion.div
            initial={{ x: "100%" }}
            animate={{
              x: 0,
            }}
            exit={{
              x: "100%",
            }}
            transition={{ type: "spring", bounce: 0, duration: 0.4 }}
            className={styles.trayContainer}
          >
            <div className={styles.headerContainer}>
              <div className={styles.headerCloseButton}>
                <button
                  onClick={() => setHelpDocsTray()}
                  className={styles.button}
                >
                  <FaChevronLeft /> Back
                </button>
              </div>
              <div className={styles.headerTitle}>
                <h2 className={styles.trayHeader}>How To Create Rounds</h2>
              </div>
              <div className={styles.headerRightButton}></div>
            </div>
            <div className={styles.innerContainer}>
              <CreateAndLoadRounds />
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
            transition={{ type: "spring", bounce: 0, duration: 0.2 }}
            onClick={() => setHelpDocsTray()}
            className={styles.overlay}
          />
        </>
      )}
    </AnimatePresence>
  );
};

export default HelpDocTray;

type HelpDocProps = {
  title?: string;
  body?: React.ReactNode;
};

HelpDocTray.defaultProps = {
  title: "Help Docs",
};
