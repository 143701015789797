import logo from "aGClubhouseLogoBlack.png";
import logoWhite from "aGClubhouseLogoWhite.png";
import logoIcon from "logo.png";
import logoIconWhite from "logo-white.png";
import { useSidebarCollapsed } from "utils/ui/uiHelpers";

const Logo: React.FC<LogoType> = (props) => {
  const isSidebarCollapsed = useSidebarCollapsed();

  return (
    <div className="flex items-center justify-center">
      <img
        src={isSidebarCollapsed ? logoIconWhite : logoWhite}
        className={`logo hidden dark:inline " + ${
          isSidebarCollapsed ? "w-8" : props.logoSize
        }`}
        alt="aboutGolf header logo"
      />
      <img
        src={isSidebarCollapsed ? logoIcon : logo}
        className={`logo inline dark:hidden " + ${
          isSidebarCollapsed ? "w-9" : props.logoSize
        }`}
        alt="aboutGolf header logo"
      />
    </div>
  );
};

export default Logo;
