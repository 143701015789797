import { ImWarning } from "react-icons/im";
import { ModalType } from "utils/modals/modalHelpers";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { useFinalizeClubhouseLeagueRound } from "modules/leagues/leagueRoundHooks";
import {
  useSelectedLeague,
  useSelectedLeagueRoundId,
} from "modules/leagues/leagueHooks";
import Row from "components/ui/layout/Row";
import Modal from "components/shared/Modal";
import { isApiStatusSuccess } from "utils/api/apiHelper";
import {
  showCustomNotification,
  GenericNotificationType,
} from "utils/notifications/notificationHelpers";
import { ProblemDetailsException } from "Models";

const LeagueRoundFinalizeConfirm: React.FC<Props> = (props) => {
  const { confirmButtonText, cancelButtonText, callback, bodyText } = props;

  const currentFacilityId = useGetCurrentLocalFacilityId();
  const selectedLeague = useSelectedLeague();
  const selectedLeagueRoundId = useSelectedLeagueRoundId();

  const [, finalizeRound] = useFinalizeClubhouseLeagueRound(
    currentFacilityId,
    selectedLeague.id,
    selectedLeagueRoundId
  );

  const handleConfirm = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    let response = finalizeRound();

    response?.then((res) => {
      if (isApiStatusSuccess(res?.status)) {
        showCustomNotification(
          "Round ended successfully",
          GenericNotificationType.SUCCESS
        );
        if (callback) callback(true);
      } else {
        let errorMessage = (res.body as unknown) as ProblemDetailsException;
        showCustomNotification(
          errorMessage.detail || "Error ending round",
          GenericNotificationType.ERROR
        );
      }
    });
  };

  const handleCancel = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    if (callback) callback(true);
  };

  return (
    <>
      <Modal
        hideFooter={true}
        hideHeader={true}
        modalName={ModalType.ConfirmDeleteAlert}
        modalSize="w-3/4 sm:w-1/2 md:w-2/3 xl:w-1/2 rounded-lg"
        body={
          <Row cssClasses="p-4">
            <Row cssClasses="flex flex-col text-md items-center justify-center my-4 text-center poppins">
              <div className="flex flex-none items-center justify-center w-16 h-16 rounded-full bg-brandGreen text-[0.625rem] font-medium text-white">
                <ImWarning className="h-10 w-10" />
              </div>
              <div className="flex pt-4 dark:text-gray-50">{bodyText}</div>
            </Row>
            <Row cssClasses="mt-6 text-center pr-1 flex flex-row w-full pt-2.5 text-xs items-center">
              <div className="flex w-1/2 m-auto">
                <button
                  onClick={(e) => handleConfirm(e)}
                  className="poppins py-2 px-4 bg-red-500 hover:bg-red-700 text-white rounded shadow hover:shadow-inner hover:text-white text-sm font-semibold w-full"
                  tabIndex={4}
                >
                  {confirmButtonText}
                </button>
              </div>

              {cancelButtonText && (
                <div className="flex w-1/2 ml-2">
                  <button
                    onClick={(e) => handleCancel(e)}
                    className="poppins py-2 px-4 bg-white-500 hover:bg-gray-700 text-black rounded shadow hover:shadow-inner hover:text-white text-sm font-semibold w-full dark:bg-gray-50 dark:hover:bg-gray-200 dark:text-zinc-800"
                    tabIndex={4}
                  >
                    {cancelButtonText}
                  </button>
                </div>
              )}
            </Row>
          </Row>
        }
      />
    </>
  );
};
export default LeagueRoundFinalizeConfirm;

type Props = {
  title?: string;
  modalName: string;
  callback?: any;
  bodyText: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  showModal?: boolean;
  confirmed?: boolean;
};

LeagueRoundFinalizeConfirm.defaultProps = {
  title: "",
  confirmButtonText: "Confirm",
  showModal: false,
  confirmed: false,
};
