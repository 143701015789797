import "assets/css/spinnerAnimation.css";

type OwnProps = {
  class?: string;
};

const LeaguesIcon: React.FC<OwnProps> = (props) => {
  return (
    <svg
      className={`${props.class} fill-current`}
      fill-current={props.class}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18 14.75C18 16.5449 16.5449 18 14.75 18H3.25C1.45507 18 0 16.5449 0 14.75V3.25C0 1.45507 1.45507 0 3.25 0H14.75C16.5449 0 18 1.45507 18 3.25V14.75ZM16.5 14.75V3.25C16.5 2.2835 15.7165 1.5 14.75 1.5H3.25C2.2835 1.5 1.5 2.2835 1.5 3.25V14.75C1.5 15.7165 2.2835 16.5 3.25 16.5H14.75C15.7165 16.5 16.5 15.7165 16.5 14.75ZM14 4.75C14 5.1297 13.7178 5.44349 13.3518 5.49315L13.25 5.5H4.75C4.33579 5.5 4 5.16421 4 4.75C4 4.3703 4.28215 4.05651 4.64823 4.00685L4.75 4H13.25C13.6642 4 14 4.33579 14 4.75ZM14 13.25C14 13.6297 13.7178 13.9435 13.3518 13.9932L13.25 14H4.75C4.33579 14 4 13.6642 4 13.25C4 12.8703 4.28215 12.5565 4.64823 12.5068L4.75 12.5H13.25C13.6642 12.5 14 12.8358 14 13.25ZM14 9C14 9.3797 13.7178 9.6935 13.3518 9.7432L13.25 9.75H4.75C4.33579 9.75 4 9.4142 4 9C4 8.6203 4.28215 8.3065 4.64823 8.2568L4.75 8.25H13.25C13.6642 8.25 14 8.5858 14 9Z" />
    </svg>
  );
};
export default LeaguesIcon;

LeaguesIcon.defaultProps = {
  class: "text-zinc-800 dark:text-white",
};
