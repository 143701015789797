import { ValidationException, ProblemDetailsException } from "Models";

export enum ApiResponseErrorType {
  ValidationException = "ValidationException",
  ProblemDetailsException = "ProblemDetailsException",
}

export enum ApiExceptionClassType {
  SCORECARD_NOT_FINALIZED = "error.scorecardnotfinalizedexception",
}

export const isApiStatusSuccess = (status: number | undefined) => {
  return status === 200 || status === 201 || status === 202 ? true : false;
};

export const getApiResponseErrorMessage = (response: any) => {
  if (
    response.type === ApiResponseErrorType.ValidationException ||
    response?.errors
  ) {
    return (response as ValidationException)?.errors[
      Object.keys(response?.errors)[0]
    ].toString();
  } else {
    return (response as ProblemDetailsException)?.detail;
  }
};
