import { useDispatch } from "react-redux";
import { ReduxQueryDispatch } from "QueryTypes";

/**
 * A hook that returns a dispatch function with our app's custom dispatch type.
 * This should be preferred over react-redux's `useDispatch` because it understands
 * our middleware-powered async actions, while the bare `useDispatch` does not.
 */
export default function useAppDispatch(): ReduxQueryDispatch {
  const appDispatch = useDispatch<ReduxQueryDispatch>();

  return appDispatch;
}
