import "assets/css/spinnerAnimation.css";

type OwnProps = {
  color?: string;
};

const SpinnerAnimation: React.FC<OwnProps> = (props) => {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 216 216"
      className="_spinner"
    >
      <g className="feat_spinner">
        <g className="sub_one_spinner">
          <path
            stroke={props.color}
            className="st0_spinner"
            d="M206.3,80.8c2.4,8.7,3.7,17.8,3.7,27.2c0,56.3-45.7,102-102,102S6,164.3,6,108S51.7,6,108,6
	c26.2,0,50.1,9.9,68.2,26.1"
          />
          <path
            fill={props.color}
            d="M179.5,27c1.7-1.5,3-3.3,4.9-4.5c2-0.8,4.2,1,4.5,3c1.1,6.4,2.3,12.7,3.3,19.1c0,1.4-1.4,2.5-2.7,2.4
	c-6.3-1.1-12.7-2.2-19.1-3.3c-2.1-0.2-3.8-2.9-2.6-4.8c1-1.3,2.3-2.3,3.4-3.5"
          />
          <path
            stroke={props.color}
            className="st0_spinner"
            d="M176.2,32.1c-6.7-6-14.3-11.2-22.8-15.4C103-8.4,41.8,12.1,16.7,62.6s-4.5,111.7,45.9,136.7
	s111.7,4.5,136.7-45.9c11.7-23.5,13.5-49.3,7-72.7"
          />
        </g>
        <g>
          <path
            fill={props.color}
            d="M129.9,60.8c6.6-0.9,13.4-0.6,19.6,1.9c4.2,1.9,8.1,4.7,10.7,8.6c1.8,2.7,2.4,5.9,2.9,9c0.7,5,0.7,10,0.6,14.9
	c-7.8,0-15.6,0-23.3,0c0-5.2,0.1-10.5-0.3-15.7c-0.2-1.1-0.2-2.4-1.1-3.1c-1.4-1.2-3.9-1-4.9,0.7c-0.9,2.5-0.8,5.3-0.9,7.9
	c0,14.8,0,29.7,0,44.5c0.2,2.2,0.1,4.5,0.9,6.6c1,2.4,4.8,2.2,5.9,0c0.7-1.3,0.7-2.8,0.9-4.2c0.4-5,0.2-9.9,0.2-14.9
	c-1.5,0-3.1,0-4.6,0c0-4.6-0.1-9.2,0-13.8c9.1,0,18.2,0,27.3,0c0,16,0,32,0,48c-4.9,0-9.7,0-14.6-0.1c-0.9-2-1.6-4.1-2.2-6.1
	c-2,3.7-5.5,6.6-9.7,7.5c-9.8,2.4-21.1-2.8-25.1-12.2c-2.1-4.6-2-9.7-2.3-14.7c0-8.8,0-17.7,0-26.5c0-7.5-0.2-15,1-22.4
	c0.4-1.6,0.8-3.3,1.7-4.8c2.4-4.2,6.2-7.4,10.6-9.4C125.3,61.7,127.6,61.2,129.9,60.8z"
          />
          <path
            fill={props.color}
            d="M65.5,75.3c4.5-1.3,9.2-1,13.9-0.9c4,0.1,8,1.2,11.6,2.9c4.7,2.4,8.4,7.1,9.1,12.5c1,8.4,0.7,16.9,1,25.3
	c0.1,8.2,0,16.4,0,24.6c0,3.9-0.1,7.8-0.2,11.7c-7.5,0-15,0-22.5-0.1c0-2.1,0-4.3,0-6.4c-1.7,3.4-4.9,6.1-8.5,7.3
	c-4.5,1.1-9.4,0.8-13.6-1.4c-1.8-0.9-3.6-2-4.6-3.7c-0.7-1-1.3-2.1-1.7-3.3c-1.5-5.2-1-10.7-1.1-16.1c0.1-4.2,0.3-9,3.5-12.1
	c4.1-3.1,8.9-4.8,13.6-6.8c3.5-1.4,7-2.8,10.2-4.8c1-0.8,1.3-2.1,1.3-3.3c0-1.8,0-3.7,0-5.5c0-1.9,0-3.9-0.9-5.7
	c-0.9-1.9-3.7-2.2-5.1-0.7c-0.5,0.5-0.6,1.3-0.7,2c-0.5,4.8-0.3,9.6-0.4,14.4c-7.2,0-14.4,0-21.6,0c-0.2-5.2-0.2-10.4,0.8-15.5
	c0.6-4.1,3.3-7.6,6.7-9.9C59.2,77.6,62.2,76,65.5,75.3z M71.2,123.2c-0.6,2.5-0.6,5-0.5,7.5c0,2.1,0,4.3,0.6,6.4
	c0.7,2.4,4.8,2.7,5.8,0.3c0.9-2.7,0.4-5.5,0.5-8.3c-0.1-4.5,0.3-9-0.1-13.5C75,117.8,72.1,119.9,71.2,123.2z"
          />
        </g>
      </g>
    </svg>
  );
};
export default SpinnerAnimation;

SpinnerAnimation.defaultProps = {
  color: "#000000",
};
