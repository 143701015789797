import { MotionVariantType } from "types/enums";
import { useFormContext } from "react-hook-form";
import { AnimateSharedLayout, motion } from "framer-motion";
import { dailyQueueContainer } from "utils/animations/animationHelper";
import CreateEventFormRoundOptions from "./CreateEventFormRoundOptions";
import { useState } from "react";

const CreateEventFormRoundContainer: React.FC = () => {
  const { watch } = useFormContext();

  const [expanded, setExpanded] = useState<boolean | number>(0);
  const rounds = watch("rounds") || 1;

  return (
    <section id="course" className="mt-6">
      <AnimateSharedLayout>
        <motion.ul
          className="relative z-0 divide-y divide-gray-200 mb-8 w-full"
          variants={dailyQueueContainer}
          initial={MotionVariantType.HIDDEN}
          animate={MotionVariantType.SHOW}
        >
          {Array(Number(rounds))
            .fill(null)
            .map((value, index) => (
              <CreateEventFormRoundOptions
                key={index}
                roundNumber={index}
                i={index}
                expanded={expanded}
                setExpanded={setExpanded}
              />
            ))}
        </motion.ul>
      </AnimateSharedLayout>
    </section>
  );
};
export default CreateEventFormRoundContainer;
