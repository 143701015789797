import { AppQueryConfig } from "QueryTypes";
import { SimulatorState, SimulatorStateJsonReponse } from "Models";
import { normalize } from "normalizr";
import { simulatorState } from "./simulatorStateSchema";
import { EntitiesById } from "StoreTypes";
import { reduxQueryDefaultOptions } from "utils/queries/queryHelpers";
import { timerStringToTimerArray } from "utils/timer/timerHelpers";

const urls = {
  simulatorState(simId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/simulators/${simId}/state`;
  },
};

export const getSimulatorStateQuery = (simId: string): AppQueryConfig => ({
  url: urls.simulatorState(simId),
  queryKey: `getSimulatorState:${simId}`,
  options: { ...reduxQueryDefaultOptions() },
  transform: (responseJson: SimulatorStateJsonReponse) => {
    const responseJsonTimerUpdated = {
      ...responseJson,
      ...{ timerValue: timerStringToTimerArray(responseJson.timerValue) },
    };
    return normalize(responseJsonTimerUpdated, simulatorState).entities;
  },
  update: {
    simulatorStates: (oldValue, newValue): EntitiesById<SimulatorState> => ({
      ...oldValue,
      ...newValue,
    }),
  },
});
